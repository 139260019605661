import { Component } from '@angular/core';
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss'],


})
export class FaqComponent {
  panelOpenState = false;
  ngOnInit()
  {
    document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
  }
}
