<div class="ai-container " style="position: relative;margin-top: 24px;">
    <div class=" col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden  position-relative">
        <div>
            <div id="rn_SearchControls" class="w-100 py-2">
                <h1 class="rn_ScreenReaderOnly">Search</h1>
                <form (ngSubmit)="OnSubmit(f)" #f="ngForm" name="chatBot" class="kbai-form">
                    <div class="d-search-tb d-flex " >
                        <div class=" boxT position-relative w-100">

                            <div class="search-box w-100">
                                <label class="seaech-ico"><i class="bi bi-search"></i>
                            </label>
                                <input [(ngModel)]="user.sendMsg" [ngModelOptions]="{standalone: true}"
                                    #selectControl="ngModel" placeholder="Find the answer to your question"
                                     type="text" maxlength="251" (input)="checkMaxLength()"
                                    class="form-control" autocomplete="new-password">{{user.query}}
                                <button type="submit" [disabled]="isMaxLengthExceeded" class="btn btn-primary d-none">Search</button>
                                <div *ngIf="isMaxLengthExceeded"  class="error-message text-danger" style="font-size: 11px; padding: 5px 0 0 5px;">
                                  The input cannot exceed 250 characters.
                              </div>
                            </div>
                        </div>
                    </div>
                    <img *ngIf="loading" src="../assets/images/writing-loading.gif" width="50px" height="25px">
                    <div class="d-answer-tb d-flex mt-3">
                        <div class=" boxT position-relative answersearch">
                            <div class="search-box minheight">
                                <span *ngIf="!user.response">Your answer will be displayed here... </span>
                                <markdown [data]="user.response" class="bg-white  agent-row-box variable-binding">
                                </markdown>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <div>
        </div>
    </div>
</div>
