<div class="row m-0">
    <div class="marquee mx-auto ">
        <div *ngFor="let obj of responseData">            
           <span>{{obj.subject}}<i class="bi bi-star-fill " ></i>
          {{obj.subject}}<i class="bi bi-star-fill " ></i>
          {{obj.subject}}<i class="bi bi-star-fill " ></i>
          {{obj.subject}}<i class="bi bi-star-fill " ></i>
        </span>
          </div>
       
      </div>
</div>