



<div class="dialog" >

  <div class="dialog-header">
      <img src="../../assets/images/fort-wayne-logo-seal-dark.svg" class="me-auto mb-2" height="42px">
    <button type="button" class="btn-close" (click)="onCloseClick()"></button>
  </div>
  <div class="dialog-body text-center">
<p class="text-center mt-3"> <img src="../../assets/images/verified.svg" width="58px">  </p>
<div class="row">
<div class="col-md-12 text-center">Your feedback has been submitted successfully.</div>
</div>



  </div>
  <br>
  <div class="dialog-footer text-center action-from">

  <button  class="btn btn-primary m-0" (click)="onCloseClick()">OK</button>



  </div>

</div>
