<div class="container p-2">
<div class="col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">
  <div class="boxed" >
    <div class="row">
      <div class="col-sm-12">
        <h2 class="head">Feedback Survey</h2>
        <form [formGroup]="feedbackForm" (ngSubmit)="onSubmit()">
          <ul class="feedback-ul">
            <li>
              <p>1. Did you find the information on the website helpful?</p>
              <div class="d-flex gap-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="helpful" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="helpful" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </li>

            <li>
              <p>2. Did you find the website easy to use?</p>
              <div class="d-flex gap-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="easyToUse" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="easyToUse" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </li>

            <li>
              <p>3. Did our knowledge base have the information you were looking for?</p>
              <div class="d-flex gap-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="knowledgeBase" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="knowledgeBase" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </li>

            <li>
              <p>4. Would you use the website again and/or recommend calling 311 to others?</p>
              <div class="d-flex gap-3">
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="recommended" [value]="true">
                  <label class="form-check-label">Yes</label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" formControlName="recommended" [value]="false">
                  <label class="form-check-label">No</label>
                </div>
              </div>
            </li>

            <li>
              <p>5. How would you rate your overall experience with 311 with 5 being exceptional and 1 being poor</p>
              <div class="d-flex gap-2">
                <div class="wrapper">
                  <input type="radio" formControlName="rating" id="st1" value="5">
                  <label for="st1"></label>
                  <input type="radio" formControlName="rating" id="st2" value="4">
                  <label for="st2"></label>
                  <input type="radio" formControlName="rating" id="st3" value="3">
                  <label for="st3"></label>
                  <input type="radio" formControlName="rating" id="st4" value="2">
                  <label for="st4"></label>
                  <input type="radio" formControlName="rating" id="st5" value="1">
                  <label for="st5"></label>
                </div>
              </div>
            </li>



            <li>
              <p>6. Any additional comments or feedback for 311?</p>
              <div class="d-flex ">
                <div class="mb-3 col-md-6">
                  <textarea class="form-control" formControlName="feedbackText" id="exampleFormControlTextarea1" rows="2" width="320px"></textarea>
                </div>
              </div>
            </li>
          </ul>
          <button type="submit" class="btn btn-primary ms-4" [disabled]="feedbackForm.invalid"><i class="bi bi-save mr-1"></i> Submit</button>
        </form>

        <!-- <iframe width="100%" height="660" src="https://cityoffortwayne.custhelp.com/ci/documents/detail/5/1/12/d20f5cee1379622717570b0dd5ba13012e07435c"></iframe> -->
      </div>
    </div>
  </div>
</div>
</div>

