import { Component } from '@angular/core';
import { FormBuilder, NgForm, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as CryptoJS from 'crypto-js';
import { ChatbotService } from '../services/chatbot.service';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;
export interface User {
  message: string;
  allmessages: any[];
  sendMsg: string;
}
@Component({
  selector: 'app-saic',
  templateUrl: './saic.component.html',
  styleUrls: ['./saic.component.scss']
})
export class SaicComponent {
  isChatwindow: boolean = false;
  showFeedbackPopup: boolean = false;
  isAgreed: boolean = false;
  sessionTimer: any;
  feedbackForm: any;
  conversationId: string | undefined;
  showPopchat: boolean = false;
  countdownValue: number = 60;
  countdownInProgress: boolean = false;
  countdownInterval: any;
  showFeedbackQuestion: boolean = false;
  initialDateTime: any;
  feedback: any;
  likeStatus: boolean;
  user: User = {
    message: "",
    allmessages: [],
    sendMsg: ""
  };
  constructor(public router: Router, private fb: FormBuilder,private chatbotService: ChatbotService)
  {
    this.feedbackForm = this.fb.group({
      // name: ['', [Validators.required]],
      feedback: ['', [Validators.required]],
    })
  }

  agreeContinue() {
    this.isAgreed = true;
    this.initialDateTime = this.getCurrentDate();
    if (this.isChatwindow && (!this.user.allmessages || this.user.allmessages.length === 0)) {
      this.conversationId = this.generateUniqueID();

      this.startSessionTimer();

      console.log("generated conversationid", this.conversationId);
    }

  }
  stayChat() {
    this.countdownInProgress = false;
    this.showPopchat = false;
    clearTimeout(this.sessionTimer);
    this.startSessionTimer();
  }

  closeChat() {
    this.clearChat();
    this.countdownInProgress = false;
    clearTimeout(this.sessionTimer);

  }
  minimizeChat() {
    this.isChatwindow = true;
    clearTimeout(this.sessionTimer);
    this.startSessionTimer();
  }

  clearChat() {
    this.user.allmessages = [];
    this.conversationId = "";
    this.isChatwindow = false;
    this.showPopchat = false;

  }

  startCountdown() {
    this.countdownValue = 60;
    this.countdownInProgress = true;
    const countdownInterval = setInterval(() => {
      this.countdownValue--;
      if (this.countdownValue <= 0) {
        clearInterval(countdownInterval);
        this.clearChat();
        this.countdownInProgress = false;
      }
    }, 1000);
    if (this.countdownInterval) {
      clearInterval(this.countdownInterval);
    }
    this.countdownInterval = countdownInterval;
  }
  startSessionTimer() {
    if (this.isAgreed) {
      this.sessionTimer = setTimeout(() => {
        this.openPopup();
        this.startCountdown();
      }, 5 * 60 * 1000);
    }
  }
  openPopup() {
    this.isAgreed=true;
    console.log(this.isAgreed);
    console.log(this.isChatwindow);
    if (this.isChatwindow && this.isAgreed) {
      this.showPopchat = true;
      return;
    }
    this.isChatwindow = false;
    clearTimeout(this.sessionTimer);

  }
  openChat() {
    this.showFeedbackPopup=false;
    this.isAgreed = false

    if (!this.isChatwindow) {
      this.minimizeChat();
      clearTimeout(this.sessionTimer);
      this.startSessionTimer();
    } else {
      this.isChatwindow = false;
    }
  }
  resetSessionTimer() {
    clearTimeout(this.sessionTimer);
    this.countdownValue = 60;
    this.startSessionTimer();
  }
  closeBox() {
    this.showPopchat = !this.showPopchat
    clearTimeout(this.sessionTimer);
    this.startSessionTimer();

  }
  f:NgForm;
  openFeedbackPopup() {
    this.showFeedbackPopup = true;
    clearTimeout(this.sessionTimer);
  }
  generateUniqueID() {
    return uuidv4();
  }
  getCurrentDate(): string {
    return new Date().toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
  }
  onSendMessage() {
    if (this.isInputEmpty() || this.loading) return; // Prevent sending if input is empty or loading

    this.OnSubmit(this.f); // Assuming `f` is the reference to the form

    // You might want to add logic to reset or update state here if needed
}
  isInputEmpty(): boolean {
    return !this.user.sendMsg || this.user.sendMsg.trim() === '' ||this.isMaxLengthExceeded;
  }
  loading = false;
  isMaxLengthExceeded = false;

  checkMaxLength() {
    this.isMaxLengthExceeded = this.user.sendMsg.length > 250;
  }
  submitFeedback(likeStatus: boolean) {
    console.log("likestatus",likeStatus);
      const thankYouMessage = {
          query: likeStatus? "<i class='fa fa-thumbs-up'></i>": "<i class='fa fa-thumbs-down'></i>" ,
          response: likeStatus ? "Thank you for the feedback": "I am sorry to hear that we will improve it.Is there anything else that I can help you with?",
          timestamp: new Date().getTime()
      };
      this.user.allmessages.push(thankYouMessage);
      setTimeout(() => {
          $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
      }, 200);
      this.showFeedbackQuestion=false;
    this.feedback = this.feedbackForm.value.feedback
    let payLoad = {
      "conversation_id": this.conversationId,
      "feedback": this.feedback,
      "likeStatus": likeStatus
    }
    console.log('payLoad', payLoad);
    this.chatbotService.submitFeedback(payLoad).subscribe((res: any) => {
      this.feedbackForm.reset();
      this.showFeedbackPopup = false;
      // this.clearChat()

    })
  }
  closeFeedbackPopup() {
    this.showFeedbackPopup = false;
    this.feedbackForm.reset();

  }
//   OnSubmit(form: NgForm) {
//     this.showFeedbackQuestion = false;
//     $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
//     console.log(this.user);
//     const currentTime:any = new Date().getTime();
//     const expiresIn:any =currentTime + (5 * 60 * 1000);
//     var header = {
//       "alg": "HS256",
//       "typ": "JWT"
//     };

//     var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
//     var encodedHeader = this.base64url(stringifiedHeader);
// console.log(this.conversationId);
//     var data:any = {
//       "conversation_id": this.conversationId,
//       "exp": parseInt((expiresIn / 1000).toString()),
//       "iat":parseInt((currentTime/1000).toString()),
//       "iss":'Customer portal'
//     };
//     console.log("submitdata", data);
//     var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
//     var encodedData = this.base64url(stringifiedData);

//     var token = encodedHeader + "." + encodedData;
//     var secret = "cqLfvq3KRTmP5vE8f2SdHyiKViPTx0L0";
//     var signature: any = CryptoJS.HmacSHA256(token, secret);
//     signature = this.base64url(signature);

//     var signedToken = token + "." + signature;
//     console.log("token", signedToken);
//     let payLoad = {
//       "query": signedToken
//     }
//     console.log(payLoad);
//     this.loading = true;

//     const userMessage = {
//       query: this.user.sendMsg,
//       response: '<img src="../assets/images/writing-loading.gif" width="50px" height="25px">'
//     };
//     this.user.allmessages.push(userMessage);
//     this.user.sendMsg = "";
//     this.chatbotService.sendMessage(payLoad).subscribe((res: any) => {
//       this.loading = false;
//       res.timestamp = new Date().getTime();
//       this.user.allmessages[this.user.allmessages.length - 1].timestamp = res.timestamp;
//       this.user.allmessages[this.user.allmessages.length - 1].response = res.response;
//       this.showFeedbackQuestion = true;
//       setTimeout(() => {
//         $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
//       }, 200)
//       this.resetSessionTimer();

//     })
//   }


OnSubmit(form: NgForm) {
    this.showFeedbackQuestion = false;
    $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
    console.log(this.user);

    const currentTime = new Date().getTime();
    const expiresIn = currentTime + (30 * 1000); // 5 minutes expiration

    // Define JWT header
    const header = {
        "alg": "HS256",
        "typ": "JWT"
    };

    // Encode header and payload to Base64
    const encodedHeader = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(header)))
                                .replace(/\+/g, '-')
                                .replace(/\//g, '_')
                                .replace(/=+$/, '');

    // Define JWT payload
    const data = {
        "conversation_id": this.conversationId,
        "exp": Math.floor(expiresIn / 1000), // Expiration time in seconds
        "iat": Math.floor(currentTime / 1000), // Issued at time in seconds
        "iss": 'Customer portal'
    };

    const encodedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(data)))
                              .replace(/\+/g, '-')
                              .replace(/\//g, '_')
                              .replace(/=+$/, '');

    // Create JWT token
    const token = `${encodedHeader}.${encodedData}`;
    const secret = "cqLfvq3KRTmP5vE8f2SdHyiKViPTx0L0";
    const signature = CryptoJS.HmacSHA256(token, secret);
    const encodedSignature = CryptoJS.enc.Base64.stringify(signature)
                                 .replace(/\+/g, '-')
                                 .replace(/\//g, '_')
                                 .replace(/=+$/, '');

    const signedToken = `${token}.${encodedSignature}`;
    console.log("token", signedToken);

    const payLoad = {
        "query": signedToken
    };
    console.log(payLoad);
    this.loading = true;

    const userMessage = {
        query: this.user.sendMsg,
        response: '<img src="../assets/images/writing-loading.gif" width="50px" height="25px">'
    };
    this.user.allmessages.push(userMessage);
    this.user.sendMsg = "";
    console.log(userMessage.query)
    console.log(payLoad);
    this.chatbotService.sendMessage(signedToken,{ query: userMessage.query}).subscribe((res: any) => {
      // this.user.sendMsg = "";
      console.log(res)
        this.loading = false;
        res.timestamp = new Date().getTime();
        this.user.allmessages[this.user.allmessages.length - 1].timestamp = res.timestamp;
        this.user.allmessages[this.user.allmessages.length - 1].response = res.response;
        this.showFeedbackQuestion = true;
        setTimeout(() => {
            $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
        }, 200);
        this.resetSessionTimer();
    });
}

  // base64url(source: any) {
  //   // Encode in classical base64
  //   let encodedSource = CryptoJS.enc.Base64.stringify(source);

  //   // Remove padding equal characters
  //   encodedSource = encodedSource.replace(/=+$/, '');

  //   // Replace characters according to base64url specifications
  //   encodedSource = encodedSource.replace(/\+/g, '-');
  //   encodedSource = encodedSource.replace(/\//g, '_');

  //   return encodedSource;
  // }
  isSignupRoute(): boolean {
    return this.router.url.includes('signup');
  }
}
