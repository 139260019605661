<div class="container px-2 ">

  <div class=" mb-4 border-radius-20 bg-white     overflow-hidden pt-3 position-relative">
    <div class="row top10 m-0">
      <div class="col-sm-12">
        <ul class="fw-bc d-flex align-items-center gap-1">
          <li><a [routerLink]="['/']" class="tex-primary fw-bold">Home</a></li>&nbsp;
          <li> <i class="fa-solid fa-chevron-right"></i></li>&nbsp;
          <li>Track SR</li>
        </ul>
      </div>
    </div>

    <!-- <button  type="button" value="prv-btn" class="btn btn-outline-primary btn-sm border-0 ms-2     position-absolute" tabindex="0" [routerLink]="['/']">
      <i  class="fa fa-arrow-left fw-bold" style="font-size: 20px;"></i>
    </button> -->



    <h3 class="mb-0 me-1 text-center">Track a Service Request </h3>

    <div class="data-table ">
      <div class="d-search-tb m-0">
        <div class="boxT position-relative search-container ">

          <input placeholder="Enter Service Request Number" (keyup)="checkIfEmpty($event)"
            [(ngModel)]="track_with_refno"> <img src="../../assets/images/search-line.png" class="searchline">
<!--
          <i class="bi bi-search" style="margin-left: -30px;">
            <i *ngIf="track_with_refno!==''"
              class="fa-solid fa-xmark c-pointer" style="cursor:pointer"
              (click)="clearsearch()"></i>
            </i> -->


            <!-- <button (click)="getByreferrenceId()"
            [disabled]="track_with_refno==''" class="btn btn-primary ms-2">
            <img  src="../../assets/images/search.svg" alt="search" class="searchline" height="20px">
            Search</button> -->

        </div>

        <!-- <div class="nav-item dropdown boxT position-relative">
          <label class="seaech-ico"><i class="bi bi-search"></i><i *ngIf="searchterms!==''" style="margin-left: 360px;"
              class="fa-solid fa-xmark c-pointer"></i></label>
          <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="search-box">
            <input placeholder="Missed Garbage, Dead Animal etc." type="text"
              class="form-control"data-bs-toggle="dropdown" aria-expanded="false">

            <div class="dropdown-menu dropdownn">
              <span
                style=" height: 35px;  display: flex; align-items: center;cursor:pointer" class="dropdown-item"
                ><i class="fa-solid fa-clock-rotate-left"
                  style="color: #86a1d0;"></i>&nbsp;<span style="font-size: 13px;"> </span><span style="position: absolute;right: 10px;"
                  class="text-primary hover-text ml-2 mb-0 pointer new-btn"
                  > <i
                  class="fa-solid fa-add"></i></span></span>
            </div>
          </div>
        </div> -->
        <!-- <div class="box-btn">
              <button class="btn-action " (click)="ShowDiv()">
                <i class="bi bi-list-task"></i> </button>
              <button class="btn-action active-b" (click)="ShowDiv()">
                <i class="bi bi-grid-3x3-gap-fill"></i> </button>
            </div> -->
      </div>

      <div class="mat-elevation-z8 custom-mat-table">
        <!-- <table mat-table [dataSource]="recentincidents" matSort>
              {{recentincidents}}
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>#</th>
                <td mat-cell *matCellDef="let row">{{ row.id }}</td>
              </ng-container>
              <ng-container matColumnDef="srID">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SR NUMBER</th>
                <td mat-cell *matCellDef="let row">{{ row.refno }}</td>
              </ng-container>
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>DEPARTMENT</th>
                <td mat-cell *matCellDef="let row">{{ row.department }}</td>
              </ng-container>
              <ng-container matColumnDef="content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SERVICE REQUEST TYPE</th>
                <td mat-cell *matCellDef="let row" class="text-primary pointer">{{ row.content }}</td>
              </ng-container>
              <ng-container matColumnDef="srReportDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SR REPORTED DATE</th>
                <td mat-cell *matCellDef="let row">{{ row.createdtime | date : 'short' }}</td>
              </ng-container>
              <ng-container matColumnDef="srStatus">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>STATUS</th>
                <td mat-cell *matCellDef="let row" [ngClass]="{'text-danger': row.incidentstatus === 'Unresolved', 'text-warning': row.incidentstatus === 'In Progress', 'text-success': row.incidentstatus === 'Resolved'}">{{ row.incidentstatus }}</td>
              </ng-container>
              <ng-container matColumnDef="serviceAddress">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>SERVICE ADDRESS</th>
                <td mat-cell *matCellDef="let row" title="{{ row.addressline1 }}">{{ row.addressline1 }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table> -->

            <div class=" query table-cont query-table query-table-common bg-white" >
        <table class="scroll order-column display" id="trackservicetable" *ngIf="datatableload">
          <thead>
            <tr>
              <th>#</th>
              <th>SR ID</th>
              <th>SR Type</th>
              <th>Department</th>
              <th>SR Reported Date</th>
              <th>Status</th>
              <th>Service Address</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of recentincidents;let i=index" class="table-row">
              <td>{{i+1}}</td>
              <td><a [routerLink]="['/track-sr/ref_no', item.refno]" class="text-decoration-underline">{{ item.refno }}</a></td>
              <td><img [src]="'../../assets/images/casetypeImage/'+item.casetypeid+'.svg'" width="25px"> {{ item.casetype }}</td>
              <td>{{ item.department }} </td>
              <td>{{ item.createdtime | date:"MMMM dd, yyyy h:mm a"}}</td>
              <td>
                <span class="status-badge">
                <i class="bi bi-circle-fill text-danger" *ngIf="item.incidentstatus==='Unresolved'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-success" *ngIf="item.incidentstatus==='Resolved'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-warning" *ngIf="item.incidentstatus==='Waiting'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-blue" *ngIf="item.incidentstatus==='Reopen'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-info" *ngIf="item.incidentstatus==='Close-Reclassify'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-secondary" *ngIf="item.incidentstatus==='Close-Disregard'"    style="font-size: 10px; margin-right: 10px;"> </i>
                <i class="bi bi-circle-fill text-primary" *ngIf="item.incidentstatus==='Close-Resolved'"    style="font-size: 10px; margin-right: 10px;"> </i>

              {{ item.incidentstatus}} </span>
            </td>
              <td>{{Nanaddress(item.addressline1)}}</td>
              <td><button type="button" class="btn btn-light-track" [routerLink]="['/track-sr/ref_no', item.refno]">
                <img src="../../assets/images/expand-icon.svg">  </button>
              </td>
            </tr>
          </tbody>
        </table>
</div>

        <!-- <mat-paginator [pageSizeOptions]="[8, 16, 24, 32]" aria-label="Select page of users"></mat-paginator> -->
      </div>
    </div>
  </div>
</div>
