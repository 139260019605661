import { EventEmitter, Injectable } from '@angular/core';
import {  BehaviorSubject,Observable, Subject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpResponse, HttpErrorResponse, HttpParams } from '@angular/common/http';
import{environment} from 'src/environments/environment';

export const httpJsonPostOptions = {
  headers: new HttpHeaders({
      'Content-Type':  'application/json',

  })
};
export const httpGetOptions = {
  headers: new HttpHeaders({

  })
};
export const httpFormPostOptions = {
  headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded'

  })
};
@Injectable({
  providedIn: 'root',
})

export class makeServiceRequestService {

  loggedinemail:any;
  loggedin:any;
  addresstobesearched:any;
  referrence_number:any=0;
  locationcoordinates:any
  casetype:any='';
  department:any='';
  servicedetails:any;
  incidentdetails:any;

  subGroupItem:BehaviorSubject<any> = new BehaviorSubject<any>({});
  subGroupItemQuery$: Observable<any> = this.subGroupItem.asObservable();
  incident_id: any;
  serviceReqPayLoad!: Observable<Object>;
  selectedDepartmentType: any=0;
  selectedcaseType:any=0
  constructor(private httpClient:HttpClient) { }
  httpHeaders = new HttpHeaders();
  // backendUrl = environment.apiURL;
  incidents=environment.backendurl;
  referencenum:any;
  setSubGroupItem(item: string): void {
    this.subGroupItem.next(item);
  }
  onSaveMakeSr(payload:any):Observable<any> {
    return this.httpClient.post(this.incidents+"page/entity/customcreate", payload, httpJsonPostOptions);
  }




  apiUrl="http://172.16.1.30:7080/getByIncidentId";
  // fetchdata(incident_id:number){
  //   console.log(this.httpClient.get(this.apiUrl+"/"+incident_id))
  //    this.serviceReqPayLoad=this.httpClient.get(this.apiUrl+"/"+incident_id)
  // }

  private eventData = new Subject<any>();
  eventData$ = this.eventData.asObservable();

  sendEvent(event: any) {
    this.eventData.next(event);
    console.log(event, 'eventaa')
  }
  public sendEmailAttachment(params) {
    return this.httpClient.post(this.incidents+`/sendEmailFAQ`, params);//not using
    // return this.http.post(`${this.backendUrl}/sendEmailFAQAttachment`, params);
  }
  fetchData(incidentId: number): Observable<any> {
    const url = `${this.apiUrl}/${incidentId}`;
    console.log( this.httpClient.get(url))
    return this.httpClient.get(url);
  }
  getrecentIncidents(): Observable<any> {
   return  this.httpClient.get(this.incidents + "getrecentIncidents")
}
getByIncidentByRefno(refno:any)
{
  return this.httpClient.get(environment.backendurl + "getByIncidentByRefno/" + refno, httpJsonPostOptions);

}
getsignup(payload:any)
{
  console.log(payload)

  return this.httpClient.post(environment.backendurl + "v1/open/register",payload);

}

getfilterbyseason(filtertype:any)
{
  return this.httpClient.get(environment.backendurl+'getFilterDepartment/'+filtertype)
}

getrecentsdata()
{
  return this.httpClient.get(environment.backendurl+'getTrendingDepartment')
}

fetchaddressdetails(address:any,casetypeid:any,addresstype:any)
{
  // const formData: FormData = new FormData();
  const formData={
    "Address":address,
    "DeptId":"",
    "CasetypeId":casetypeid,
    "Color": "",
    "AddressType":addresstype
  }

    // formData.append('Address',address);
    // formData.append('AddressType',addresstype );
  return  this.httpClient.post(environment.backendurl + 'showLocationFromAddress', formData)
  // .subscribe(response => {
  //   console.log(response)
  // }, error => {
  //   console.log(error)
  // });
}
mapsuggestions(suggestion:any)
{
  // return this.httpClient.get<any>("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text="+suggestion+"&location=%7B%22x%22%3A-9477697.545503102%2C%22y%22%3A5024044.098940275%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&distance=50000")
  return this.httpClient.get<any>("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text=" + suggestion + "%20Fort%20Wayne&location=%7B%22x%22%3A-9477652.390546516%2C%22y%22%3A5024207.888201883%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&distance=50000");

}
// mapsuggestions(suggestion:any)
// {
//   // return this.httpClient.get<any>("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text="+suggestion+"&location=%7B%22x%22%3A-9477697.545503102%2C%22y%22%3A5024044.098940275%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&distance=50000")
//   return this.httpClient.get<any>("https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/suggest?f=json&text="+suggestion+"%20Fort%20Wayne&location=%7B%22x%22%3A-9477652.390546516%2C%22y%22%3A5024207.888201883%2C%22spatialReference%22%3A%7B%22wkid%22%3A102100%7D%7D&distance=50000")

// }
private readonly geocodingUrl = 'https://geocode.arcgis.com/arcgis/rest/services/World/GeocodeServer/findAddressCandidates';
addresslocator(queryStringAddress:any)
{
  return this.httpClient.get<any>("https://gis.acimap.us/311/rest/services/locator/AddressLocator/GeocodeServer/findAddressCandidates?Street=&Single+Line+Input=" + queryStringAddress + "&outFields=*&maxLocations=&outSR=4326&searchExtent=&f=pjson")
}
streetlocator(streetaddresss:any)
{
  return this.httpClient.get<any>("https://gis.acimap.us/311/rest/services/locator/StreetFinder/GeocodeServer/findAddressCandidates?Street=&Single+Line+Input=" + streetaddresss + "&outFields=*&maxLocations=&outSR=4326&searchExtent=&f=pjson")
}
searchAddress:any;

  geocodeAddress(address: string): Observable<any> {
    const params = new HttpParams()
      .set('f', 'json')
      .set('singleLine', address);

    return this.httpClient.get<any>(this.geocodingUrl, { params });
  }
  addressEmitter: EventEmitter<any> = new EventEmitter<any>();
  // itemtext:any
  totalitemtext:any

  emitAddress(address: any) {
    this.addressEmitter.emit(address);
  }
  usernotifications:any;
  unreadCount:any;
  filteredNotifications:any;
  getallnotifications()
  {

  this.httpClient.get(environment.backendurl + "getnotification").subscribe((data:any)=>
  {
    this.usernotifications = data;
    console.log(this.usernotifications)
    // Initially show all notifications
    if(this.usernotifications!=null)
      {
        this.filteredNotifications = this.usernotifications;
        this.unreadCount =this.filteredNotifications.filter((notification:any) => !notification.readmode).length;
        console.log(this.unreadCount)
        console.log(data)
      }
  })
  }
  updatenotification(id:any,readmode:any)
  {

    const formData={
      "id":id,
      "readmode":readmode,
    }
    return  this.httpClient.post(environment.backendurl + 'updatenotification', formData)
  }
  getuserIncidents()
  {
    return  this.httpClient.get(environment.backendurl + "getuserIncidents")
  }
  getTrendingdata()
  {
    return  this.httpClient.get(environment.backendurl + "getTrendingDepartment")

  }
  getusersubscription()
  {
    return  this.httpClient.get(environment.backendurl + "getUserSubscribed")

  }
  savesubscription(loggedinemail:any,incidentid:any)
  {
    // var email="ritikareddy.etikala@nebulogic.com"
    const formData={
      "email":loggedinemail,
      "incidentid":incidentid,
    }
    console.log(formData)
    return  this.httpClient.post(environment.backendurl + 'Subscribed', formData)
  }
  unsubscribe(incidentid:any)
  {
    return  this.httpClient.put(environment.backendurl + 'unSubscribed/'+incidentid,'')
  }
  feedbacksurvey(survey:any)
  {
    return this.httpClient.post(environment.backendurl + 'Feedback', survey)
  }
  // getrecentIncidents
}
