import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { makeServiceRequestService } from '../make-service-request.service';
import { MatDialog } from '@angular/material/dialog';
import { FeedbacksurveypopupComponent } from '../feedbacksurveypopup/feedbacksurveypopup.component';

@Component({
  selector: 'app-feedbacksurvey',
  templateUrl: './feedbacksurvey.component.html',
  styleUrls: ['./feedbacksurvey.component.scss']
})
export class FeedbacksurveyComponent {
  feedbackForm: FormGroup;
  constructor(private formBuilder: FormBuilder,private httpClient:HttpClient,private makeservice:makeServiceRequestService,public DialogComponent: MatDialog,) { }
  ngOnInit()
{
  document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    this.feedbackForm = this.formBuilder.group({
      helpful: ['', Validators.required], // Default value is true
      easyToUse: ['', Validators.required], // Default value is true
      knowledgeBase: ['', Validators.required], // Default value is true
      recommended: ['', Validators.required], // Default value is true
      rating: ['', Validators.required],
      feedbackText: ['', Validators.required]
    });
}
onSubmit() {
  if (this.feedbackForm.valid) {
    const ratingValue = this.feedbackForm.value.rating.toString(); // Convert rating to string

    const payload = {
      "helpful": this.feedbackForm.value.helpful,
      "easytouse": this.feedbackForm.value.easyToUse,
      "knowledgebase": this.feedbackForm.value.knowledgeBase ,
      "recommended": this.feedbackForm.value.recommended,
      "rating": ratingValue, // Send rating as string
      "feedbacktext": this.feedbackForm.value.feedbackText
    };

    console.log(payload);

    this.makeservice.feedbacksurvey(payload).subscribe((data:any) => {
      console.log(data);
    });
    this.feedbackForm.reset();
    this.openDialog();
  } else {
    // Form is invalid, display error messages or handle accordingly
  }
}

openDialog(): void {

  const dialogRef = this.DialogComponent.open(FeedbacksurveypopupComponent, {
    width: '450px',
    // data: { dataInPopup }
  });

  dialogRef.afterClosed().subscribe(() => {
    console.log('The dialog was closed');
    // this.router.navigateByUrl('/track-sr')
    // You can handle any actions after the dialog is closed
  });


  // let :any;
  // let incident_id:number
  // console.log(this.agentScriptSaveData.payload)
  // this.makeSerReqService.onSaveMakeSr(this.agentScriptSaveData.payload).subscribe((res: any) => {
  //   console.log("payload is submitted")
  //   console.log(res);
  //   this.incident_payload = res
  //   console.log(this.agentScriptSaveData.caseTypeData.casetypeid)
  //   // if (this.agentScriptInfo.caseTypeData.casetypeid == '211') {

  //     let additionalpayload = this.agentScriptSaveData.additionalpayload;

  //     this.incident_payload.forEach((ele: any) => {
  //       if (ele.entityName == 'Incident')
  //         this.incident_id = ele.payLoad.id;
  //       this.makeSerReqService.incident_id = ele.payLoad.id;
  //     })

  //     if (this.agentScriptSaveData.caseTypeData.casetypeid == '211') {
  //       console.log("211")
  //       additionalpayload["Addfields.incidentid"] = this.incident_id
  //       let payload = [
  //         { entityName: 'Addfields', payLoad: additionalpayload }
  //       ]
  //       console.log(payload)
  //         this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
  //           console.log("addfields payload is submitted")
  //           console.log(res);
  //         });
  //     }
  //     else if(this.agentScriptSaveData.caseTypeData.casetypeid == '293'){
  //       console.log("293")
  //       additionalpayload["Cart.incidentid"] = this.incident_id
  //       let payload = [
  //         { entityName: 'Cart', payLoad: additionalpayload }
  //       ]
  //       console.log(payload)
  //         this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
  //           console.log("Cart payload is submitted")
  //           console.log(res);
  //         });
  //     }



  //     // })

  //   // }


  // });

}


}
