import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { makeServiceRequestService } from '../make-service-request.service';

@Component({
  selector: 'app-servicerequestconfirmation',
  templateUrl: './servicerequestconfirmation.component.html',
  styleUrls: ['./servicerequestconfirmation.component.scss']
})
export class ServicerequestconfirmationComponent {
  refno:any;
  trackrefno:any;
  constructor(private route: ActivatedRoute,private http:HttpClient,private makeservicerequest:makeServiceRequestService) {
    this.refno = this.route.snapshot.paramMap.get('id');
    console.log(this.refno);
    // this.getByreferrenceId();
  }
  ngOnInit(): void
  {
    this.refno = this.route.snapshot.paramMap.get('id');
    console.log(this.refno);
    this.getByreferrenceId();
  }
  Nanaddress(address: any) {
    if (address) {
      return address.replace(/\d+/g, '');
    } else {
      return ''; // or any other default value you want to return
    }
  }

  getByreferrenceId() {
    console.log(this.refno)
    // console.log("hi")
    // console.log(this.track_with_refno.length)
    // if (true) {
this.makeservicerequest.getByIncidentByRefno(this.refno).subscribe((data: any) => {
      console.log(data)
      // console.log(data['refno'])
      console.log(data)
      this.refno=data;
      // this.recentincidents = []
      // if (Object.keys(data).length === 0 && data.constructor === Object) {
      //   this.recentincidents = []
      // }
      // else {
      //   this.recentincidents = []
      //   console.log(this.recentincidents)
      //   this.recentincidents.push(data)
      // }

      // console.log(this.recentincidents)
      // this.datatablepagination()
    })
    // }
    // else {
    //   this.recentincidents = this.updatedrecentincidents
    //   // this.datatablepagination()
    // }

    // alert(this.track_with_refno.value.length)

  }
}
