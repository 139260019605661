
<div id="map"></div>
<!-- <div class="map-search-box">
  <input type="text" id="pac-input" class="form-control mt-10" placeholder="Search for an address" />
  <i class="bi bi-geo-alt-fill pac-tar-icon"></i>
</div> -->

 <div id="map" style=" width: 100%;"></div>

    <div class="stored-addresses" *ngIf="isLocRequired" >

       <div class="long-lat">

         <p class="m-0 p-1 fs-small">Latitude:<span class="fw-500"> {{ storedAddresses[storedAddresses.length-1].lat }}</span></p>
         <p class="m-0 p-1 ms-4 fs-small">Longitude: <span class="fw-500"> {{ storedAddresses[storedAddresses.length-1].lon }}</span></p>
         </div>

   </div>
