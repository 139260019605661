import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { KnowledgebaseService } from '../knowledgebase.service';
import { HttpClient } from '@angular/common/http';
import { ChatbotService } from '../services/chatbot.service';
import { v4 as uuidv4 } from 'uuid';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import * as CryptoJS from 'crypto-js';






export interface User {
    response: string;
    message: string;
    allmessages: any[];
    sendMsg: string;
    query: string;
}
@Component({
    selector: 'app-knowledgebaseai',
    templateUrl: './knowledgebaseai.component.html',
    styleUrls: ['./knowledgebaseai.component.scss']
})

export class KnowledgebaseAiComponent {
    user: User = {
        message: "",
        allmessages: [],
        sendMsg: "",
        response: "",
        query: "",
    };
    conversationId: string;

    constructor(private chatbotService: ChatbotService) {

        this.conversationId = this.generateUniqueID();
    }
    generateUniqueID() {
        return uuidv4();
    }
    loading = false;
    isMaxLengthExceeded = false;

    checkMaxLength() {
      this.isMaxLengthExceeded = this.user.sendMsg.length > 250;
    }

    OnSubmit(form: NgForm) {

      if(this.user.sendMsg!='')
      {
        // return false;

        $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
        console.log(this.user);
        const currentTime:any = new Date().getTime();
        const expiresIn:any = currentTime + (30 * 1000);
        var header = {
            "alg": "HS256",
            "typ": "JWT"
        };

        // var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
        // var encodedHeader = this.base64url(stringifiedHeader);
        const encodedHeader = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(header)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');

        var data = {
            // "query": this.user.sendMsg,
            "conversation_id": this.conversationId,
            "exp": parseInt((expiresIn / 1000).toString()),
            "iat":parseInt((currentTime/1000).toString()),
            "iss":'Customer portal'
        };
        const encodedData = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(data)))
        .replace(/\+/g, '-')
        .replace(/\//g, '_')
        .replace(/=+$/, '');

// Create JWT token
const token = `${encodedHeader}.${encodedData}`;
const secret = "cqLfvq3KRTmP5vE8f2SdHyiKViPTx0L0";
const signature = CryptoJS.HmacSHA256(token, secret);
const encodedSignature = CryptoJS.enc.Base64.stringify(signature)
           .replace(/\+/g, '-')
           .replace(/\//g, '_')
           .replace(/=+$/, '');

const signedToken = `${token}.${encodedSignature}`;
console.log("token", signedToken);

const payLoad = {
"query": signedToken
};
console.log(payLoad);
const userMessage = {
  query: this.user.sendMsg,
  response: '<img src="../assets/images/writing-loading.gif" width="50px" height="25px">'
};
        this.loading = true;
        this.user.response = "";

          this.chatbotService.sendMessage(signedToken,{ query: userMessage.query}).subscribe((res: any) => {
            this.loading = false;

            res.timestamp = new Date().getTime();
            console.log("Response from chatbot:", res);
            this.user.response = res.response;
            setTimeout(() => {
                $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
            }, 200)
        })

      }
    }
    base64url(source: any) {
        let encodedSource = CryptoJS.enc.Base64.stringify(source);
        encodedSource = encodedSource.replace(/=+$/, '');
        encodedSource = encodedSource.replace(/\+/g, '-');
        encodedSource = encodedSource.replace(/\//g, '_');
        return encodedSource;
    }
}
