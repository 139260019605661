import { Component, EventEmitter, Input, Output } from '@angular/core';
import { KnowledgebaseService } from '../knowledgebase.service';
import { HttpClient } from '@angular/common/http';
import { Router, ResolveStart, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-layouts',
  templateUrl: './layouts.component.html',
  styleUrls: ['./layouts.component.scss']
})
export class LayoutsComponent {

  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  @Input() serviceTypeInfo: any;

  caseTypeVal: any = [];
  newcaseTypeVal: any = [];
  departmentItems: any = [];
  
  ngOnInit() {
     
    this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
      this.departmentItems = data;
      this.departmentItems.forEach((obj: { caseType: any[]; }) => {
        obj.caseType.forEach((ele: any) => {
          this.caseTypeVal.push(ele);
        });

      })
      console.log(this.caseTypeVal)
      this.newcaseTypeVal = this.caseTypeVal
    });

    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
  }

  filterlist() {
    console.log(this.caseTypeVal)
    this.caseTypeVal = this.newcaseTypeVal
    this.caseTypeVal = [...this.caseTypeVal.filter((x: any) => x.content.toLowerCase().includes(this.searchQuery.trim().toLowerCase()))]
    console.log(this.caseTypeVal)
  }

  currDiv: string = 'mainView';
  constructor(private authService: MsalService,private http: HttpClient, private knowledgeSearchService: KnowledgebaseService, private router: Router) {
    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e:any) => {
      
      console.log('test layout',e.url.search('state'));
      if(e.url.search('state') != -1){
        this.router.navigateByUrl("/login");
      }

      
    });
  }

  onSelect(item: any) {
    // console.log(item)
  }
  ShowDiv(divVal: string) {
    this.currDiv = divVal;
    localStorage.setItem('ServiceTypeInfo', '');
    localStorage.setItem('userInfo', '');
  }

  gotoNext(data: object, rowInfo?: Object) {
    // console.log(data,rowInfo)

    this.knowledgeSearchService.opentab(data);

    this.router.navigate(['/create-sr'])

    // this.serviceTypeDetailsOutputEvent.emit(data)
  }
  onSearch(): void {
    console.log("clicked")
    // this.knowledgeSearchService.updateSearchQuery(this.searchQuery,'','');

  }
  searchQuery: string = '';

}
