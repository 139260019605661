<div dropdown class="notifications-cont  position-relative" placement="bottom left">

  <a class=" " style="width: 22px; display: block; cursor: pointer;" id="Notifications"
  data-bs-toggle="dropdown" aria-expanded="false">
    <img src="../../assets/images/Notifications.svg" height="20px" class="noti" />
    <span class="badge bg-danger rounded-circle noti-icon-badge">
      {{ makeservicerequest.unreadCount != null ? makeservicerequest.unreadCount : 0 }}
    </span>
        <!-- {{notificationscount}} -->
  </a>

  <div class="dropdown-menu " aria-labelledby="logoutUser">

    <div class="card-header d-flex px-3">
      <h6 class="card-header-title">Notifications (  {{ makeservicerequest.unreadCount != null ? makeservicerequest.unreadCount : 0 }})</h6>
      <!-- <a  class="m-0 text-primary">View all
        <i class="fas fa-chevron-right fa-xs ms-1"></i>
      </a> -->

      <div class="form-check form-switch m-0 d-flex align-items-center">
        <input mdbCheckbox class="form-check-input" type="checkbox" id="unreadnotification" (change)="toggleShowUnread($event)"
        style="margin-top: -1px;"/>
        <label class="form-check-label" for="unreadnotification" style="font-size: 12px;margin-left: 2px;" >Show unread </label>
      </div>
    </div>

    <div class="card-body">

      <div *ngFor="let item of makeservicerequest.filteredNotifications;let i=index" class="border-bottom">
      <a (click)="updatemode(item)" class="row text-reset text-decoration-none py-2 gx-3 mb-0" [ngClass]="{'bg-read':item.readmode== true,'bg-unread':item.readmode == false}">
        <div class="col-auto">
          <div class="avatar">
            <img src="../../../assets/images/icon-10.png" class="avatar-image rounded-circle" alt="">
          </div>
        </div>
        <div class="col">
          <div class="fs-sm" [routerLink]="['/track-sr/ref_no', item.refno]" (click)="updatemode(item)">
           {{item.message}}
          </div>
          <div class="fs-xs  text-gray-500 mt-2"><i class="bi bi-stopwatch"></i> {{item.createdtime |date:"MMM dd, yyyy hh:mm aa "}}</div>
        </div>
      </a>
  </div>
     <div class="viewall-but">
      <a  class="text-center text-primary notify-item notify-all" >View All <i
          class="bi bi-arrow-right"></i></a>
        </div>
    </div>


  </div>
</div>
