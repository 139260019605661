import { NgModule } from '@angular/core';
import {HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { DatePipe } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule,FormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutsComponent } from './layouts/layouts.component';
import { CreateNewSRComponent } from './create-new-sr/create-new-sr.component';
import { PageContentComponent } from './page-content/page-content.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import {MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { TrackSRComponent } from './track-sr/track-sr.component';
import { TopSRComponent } from './top-sr/top-sr.component';
import { SelectServiceTypeComponent } from './select-service-type/select-service-type.component';


import { GridViewSrComponent } from './grid-view-sr/grid-view-sr.component';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { AdditionalInfoComponent } from './additional-info/additional-info.component';
import { FaqComponent } from './faq/faq.component';
import { ReviewSubmitComponent } from './review-submit/review-submit.component';
import { SideNavComponent } from './side-nav/side-nav.component';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { PopupSubmitComponent } from './popup-submit/popup-submit.component';
import { PopupComponent } from './popup/popup.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { MyServiceRequestComponent } from './my-service-request/my-service-request.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AgentScriptComponent } from './agent-script/agent-script.component';
import { EsriMapComponent } from './esri-map/esri-map.component';
import { PreviewComponent } from './preview/preview.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { NgxPaginationModule } from 'ngx-pagination';
import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';
import { KnowledgebaseAiComponent } from './knowledgebaseai/knowledgebaseai.component';
import { DetailsComponent } from './details/details.component';
import { A2zComponent } from './a2z/a2z.component';
import { HomeEsrimapComponent } from './home-esrimap/home-esrimap.component';
import { About311Component } from './about311/about311.component';
import { WaystocontactComponent } from './waystocontact/waystocontact.component';
import { FeedbacksurveyComponent } from './feedbacksurvey/feedbacksurvey.component';
import { ReviewDialogComponent } from './review-dialog/review-dialog.component';
import { MsalBroadcastService, MsalGuard, MsalInterceptor, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { CustomInterceptor } from './azure-interceptor';
import { environment } from 'src/environments/environment';
import { IPublicClientApplication, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { InteractionType, PublicClientApplication } from '@azure/msal-browser';
import {  MsalModule ,MsalRedirectComponent, MsalGuardConfiguration, MsalInterceptorConfiguration} from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { MatTabsModule } from '@angular/material/tabs';
import { NgSelectModule } from '@ng-select/ng-select';
import { PhoneMaskDirective } from './phone-mask.directive';
import { ServicerequestconfirmationComponent } from './servicerequestconfirmation/servicerequestconfirmation.component';
import { NotificationComponent } from './notification/notification.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { EmailpopupComponent } from './emailpopup/emailpopup.component';
import { MarqueeComponent } from './marquee/marquee.component';
import { MarkdownModule } from 'ngx-markdown';
import { FeedbacksurveypopupComponent } from './feedbacksurveypopup/feedbacksurveypopup.component';
import { EmailsubmitpopupComponent } from './emailsubmitpopup/emailsubmitpopup.component';
import { GridViewComponent } from './grid-view/grid-view.component';
import { NewsComponent } from './news/news.component';
import { SaicComponent } from './saic/saic.component';
const routes: Routes = [
  { path: 'layout', component: LayoutsComponent }
];


const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1; // Remove this line to use Angular Universal

export function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      // clientId: '6226576d-37e9-49eb-b201-ec1eeb0029b6', // Prod enviroment. Uncomment to use.
      clientId: environment.clientId, // PPE testing environment
      // authority: 'https://login.microsoftonline.com/common', // Prod environment. Uncomment to use.
      authority: environment.msalAuthority, // PPE testing environment.
      redirectUri: '/',
      postLogoutRedirectUri: '/',
      // knownAuthorities:['https://smartchstsme.b2clogin.com']
      knownAuthorities:['https://fortwayne311.b2clogin.com']
    },
    cache: {
      cacheLocation: BrowserCacheLocation.SessionStorage,
      storeAuthStateInCookie: false, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  // protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']); // Prod environment. Uncomment to use.
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['openid','profile']);
  protectedResourceMap.set('https://graph.microsoft.com/', ['openid']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      // scopes: ['user.read']
      scopes: ['openid', 'profile']
      // scopes:['6b49ecf4-0a76-4526-b4af-97565de86af0']
    },

    // authRequest?: MsalGuardAuthRequest | ((authService: MsalService, state: RouterStateSnapshot) => MsalGuardAuthRequest);

    // loginFailedRoute: '/login-failed'
    // loginFailedRoute: '/forgot-pw'

  };
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    LayoutsComponent,
    CreateNewSRComponent,
    PageContentComponent,
    TrackSRComponent,
    TopSRComponent,
    SelectServiceTypeComponent,

    GridViewSrComponent,
      AddressDetailsComponent,
      AdditionalInfoComponent,
      FaqComponent,
      ReviewSubmitComponent,
      SideNavComponent,
      PopupSubmitComponent,
      PopupComponent,
      MyServiceRequestComponent,
      GoogleMapComponent,
      SignUpComponent,
      ThankYouComponent,
      AgentScriptComponent,
      EsriMapComponent,
      PreviewComponent,
      KnowledgebaseComponent,
      KnowledgebaseAiComponent,
      DetailsComponent,
      A2zComponent,
      HomeEsrimapComponent,
      About311Component,
      WaystocontactComponent,
      FeedbacksurveyComponent,
      ReviewDialogComponent,
      LoginComponent,
      PhoneMaskDirective,
      ServicerequestconfirmationComponent,
      NotificationComponent,
      EmailpopupComponent,
      MarqueeComponent,
      FeedbacksurveypopupComponent,
      EmailsubmitpopupComponent,
      GridViewComponent,
      NewsComponent,
      SaicComponent
  ],
  imports: [
    MatTabsModule,
    TabsModule.forRoot(),
    MatExpansionModule,
    MatButtonModule,
    MatAutocompleteModule,
    FormsModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    NgSelectModule,
    NgbModule,
    NgbDropdownModule,
    MarkdownModule.forRoot(),
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
    },
    {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomInterceptor,
    multi: true
    },
    {
    provide: MSAL_INSTANCE,
    useFactory: MSALInstanceFactory
    },
    {
    provide: MSAL_GUARD_CONFIG,
    useFactory: MSALGuardConfigFactory
    },
    {
    provide: MSAL_INTERCEPTOR_CONFIG,
    useFactory: MSALInterceptorConfigFactory
    }
    ,MsalService,
    MsalGuard,
    MsalBroadcastService,
    DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
