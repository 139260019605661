<div class="container p-2">
  <div class="col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">
      <h4>SMART® FAQ</h4>


      <mat-accordion>
          <mat-expansion-panel  (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                  Why was 311 created?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>The 311 service is part of an ongoing effort by the City of Fort Wayne to make City government easier to access and more responsive to the needs of residents, its business owners, and visitors. The 311 Call Center has now expanded into the Department of Citizen Services. Through Citizen Services, city government continues to engage with the public to make improvements throughout the city, measures internal departmental service performance, and through the use of innovation, collects community voice - empowering citizens to drive change.
              </p>
              <!-- <p class="m-0"> <small>Date Updated: 01/06/2023</small></p> -->
          </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
            <mat-expansion-panel-header>
              <mat-panel-title>
                  Who can call 311?
              </mat-panel-title>
            </mat-expansion-panel-header>
            <p>311 can be dialed by anyone within the Fort Wayne city limits at no charge. If using your cell phone or pay phone airtime charges do apply.</p>
              <!-- <p class="m-0"> <small>Date Updated: 01/06/2023</small></p> -->
          </mat-expansion-panel>
          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                    When can call 311?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Citizen Service Specialists will staff the phones from 8:00a.m. until 5:00p.m. Monday through Friday. During after hours, including weekends, you may leave a message via voicemail or opt out to be connected directly to a dispatcher.</p>
                <p class="m-0"> <small>Date Updated: 01/06/2023</small></p>
            </mat-expansion-panel>
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
  What happens after I call 311?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>You will be assisted by a qualified member of our team to help resolve your issue. Upon completion of your call, you will be issued a service request number for tracking purposes. City departments will respond to all 311 service requests in the order in which they are received. Your request will be logged into an advanced tracking system that will show city leadership how responsive departments are in their service delivery.</p>
                <!-- <p class="m-0"> <small>Date Updated: 01/06/2023</small></p> -->
            </mat-expansion-panel>

          <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  What should I do if dialing 311 does not work from my phone or I am outside the city limits?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>There are a few wireless carriers that do not provide their customers with the ability to dial 311. In this instance, or if you are outside of the city, you may reach a 311 Citizen Service Specialist by calling (260)-427-8311.

    </p>
                <!-- <p class="m-0"> <small>Date Updated: 01/06/2023</small></p> -->
    </mat-expansion-panel>

    <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  311 or 911?

                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>The 911 service is set up to handle emergencies, such as house fires and violent crimes that require an immediate response by police, fire crews, or an ambulance. Please contact 311 for all other city nonemergency services.
    </p>
                <!-- <p class="m-0"> <small>Date Updated: 01/06/2023</small></p> -->
    </mat-expansion-panel>

    <!-- <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  Does every service request have a tracking number?
                </mat-panel-title>
              </mat-expansion-panel-header>
              <p>Yes. When you request a service, simply ask the operator to give you the number so you can track the progress of your request.
    </p>
                <p class="m-0"> <small>Date Updated: 01/06/2023</small></p>
    </mat-expansion-panel> -->

        </mat-accordion>

  </div>
</div>
