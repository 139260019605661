
<div class="container px-4">

  <div class=" mb-4">
      <div class="data-table ">
          <div class="d-search-tb">
            <div class="boxT position-relative">
              <label class="seaech-ico"><i class="bi bi-search"></i></label>
              <input matInput (keyup)="applyFilter($event)" placeholder="Enter SR Number" #input>
             
            </div>
          </div>
      
      
          <div class="mat-elevation-z8">
            <table mat-table [dataSource]="dataSource" matSort>
      
              <!-- ID Column -->
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> # </th>
                <!-- <td mat-cell *matCellDef="let row"> {{row.$index}} </td> -->
                <td mat-cell *matCellDef="let i = index">
                  {{!this.paginator|| this.paginator.pageIndex == 0 ? i + 1 : 1 + i + this.paginator.pageIndex * this.paginator.pageSize}}
                  </td>
              </ng-container>
              <!-- Service Number Column -->
              <ng-container matColumnDef="srID">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> SR NUMBER </th>
                  <td mat-cell *matCellDef="let row"> {{row.srID}} </td>
                </ng-container>
              <!-- CaseType Column -->
              <ng-container matColumnDef="department">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> DEPARTMENT </th>
                <td mat-cell *matCellDef="let row" > {{row.department}}</td>
              </ng-container>
              
              <ng-container matColumnDef="content">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SERVICE REQUEST TYPE </th>
                <td mat-cell *matCellDef="let row" class="text-primary pointer"> {{row.content}}</td>
              </ng-container>
              <ng-container matColumnDef="srReportDate">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> SR REPORTED DATE </th>
                <td mat-cell *matCellDef="let row"> {{row.srReportDate | date : 'short'}} </td>
              </ng-container>
              <ng-container matColumnDef="srStatus">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> STATUS </th>
                  <td mat-cell *matCellDef="let row" class="text-danger pointer"> {{row.srStatus}} </td>
                </ng-container>
                <ng-container matColumnDef="serviceAddress">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header> SERVICE ADDRESS </th>
                  <td mat-cell *matCellDef="let row" title="{{row.serviceAddress}}"> {{row.serviceAddress}} </td>
                </ng-container>
      
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      
              <!-- Row shown when there is no matching data. -->
              <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell p-2 text-center" colspan="7">No data matching the filter </td>
              </tr>
            </table>
      
            <mat-paginator [pageSizeOptions]="[8, 16, 24, 32]" aria-label="Select page of users"></mat-paginator>
          </div>
          <!--  -->
        </div>
      </div>
  </div>
      