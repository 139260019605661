<div class="container p-2">
    <div class="col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">

        <div class="boxed">
            <div class="row">
                <div class="col-sm-12 d-flex align-items-center " style="justify-content: space-between; flex-wrap: wrap;">
                    <h2 class="head">News You Can Use</h2>

                    <div class="d-flex gap-3" >                   
                     
<div ><a class="fb-share-button share-button" href="https://www.facebook.com/sharer/sharer.php?kid_directed_site=0&sdk=joey&u=https%3A%2F%2Fwww.cityoffortwayne.org%2Fnews-you-can-use.html&display=popup&ref=plugin&src=share_button"> <i class="bi bi-facebook"></i> Share </a> </div>
              
<div ><a  class="twitter-share-button share-button" href="https://twitter.com/intent/tweet?original_referer=http%3A%2F%2Flocalhost%3A4200%2F&ref_src=twsrc%5Etfw%7Ctwcamp%5Ebuttonembed%7Ctwterm%5Eshare%7Ctwgr%5E&text=News%20You%20Can%20Use&url=https%3A%2F%2Fwww.cityoffortwayne.org%2Fnews-you-can-use.html"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
  </svg> Post </a> </div>

                    </div>


                </div>
            </div>

            <div class="row">
                <div class="col-md-8">
                    <h3 class="my-2">Here are some helpful three digit phone numbers that you may need</h3>
                    <ul style="list-style: inside;     line-height: 26px; font-size: 13px;">
                        <li>211 or ( 260-744-0700) - First Call for Help (social services/United Way)</li>
                        <li>311 or ( 260-427-8311) - City Government ( for information, to request a city service or
                            report a non-emergency issue)</li>
                        <li>411 or (260-555-1212) Directory Assistance</li>
                        <li>511 or (1-866-227-3555) INDOT</li>
                        <li>711 or (1-800-934-6489) State Relay ( TTY - Deaf)</li>
                        <li>811 or (1-800-382-5544) Call before you dig</li>
                        <li>911 EMERGENCY</li>
                    </ul>

                    <h3 class="mb-1">General phone numbers that you may need to use</h3>
                    <ul style="list-style: inside;     line-height: 26px; font-size: 13px;">

                        <li>AEP ( electric company) 1-800-311-4634</li>
                        <li>Water Company (City Utilities) 260-427-1234</li>
                        <li>Nipsco (gas company) 1-800-464-7726</li>
                        <li>Gas Leaks (emergency) 911</li>
                        <li>Citilink 260-432-4546</li>
                        <li>Graffiti Hotline 260-449-4747</li>
                        <li>Drug tip Hotline 260-427-1262</li>
                        <li>Noise Complaints 260-427-1222 Option 1</li>
                        <li>Visitor's Bureau 260-424-3700</li>
                        <li>Better Business Bureau 260-423-4433</li>
                        <li>BMV 1-800-692-6841</li>
                        <li>Social Security Office 260-747-6072</li>
                        <li>Zagster City bike rental 202-999-3924</li>
                    </ul>
                    <h3 class="mb-1">Street Department's Street Closing and Lane Restrictions Map link</h3>
                    <ul style="line-height: 26px; font-size: 13px;">
                        <li><a href="http://www.cityoffortwayne.org/publicworks/traffic-engineering/street-closings.html"
                                target="_blank"
                                rel="noopener noreferrer">http://www.cityoffortwayne.org/publicworks/traffic-engineering/street-closings.html</a>
                        </li>
                    </ul>
                </div>

                <div class="col-md-4 ">
                    <h3>Useful Links</h3>

                    <div class="contact-wrapper bg-light rounded mb-2">
                        <ul class="list-unstyled p-0 m-0">
                            <li>Ask A Fire Fighter</li>
                            <li><a href="https://www.fortwaynefiredepartment.org/" target="_blank"
                                    rel="noopener noreferrer">http://www.fortwaynefiredepartment.org/</a></li>
                            <li>
                                <hr>
                            </li>
                            <li>Community Development</li>
                            <li><a href="http://www.fwcommunitydevelopment.org/" target="_blank"
                                    rel="noopener noreferrer">http://www.fwcommunitydevelopment.org/</a></li>
                            <li>
                                <hr>
                            </li>

                            <li>Visit Fort Wayne</li>
                            <li><a href="http://www.visitfortwayne.com/" target="_blank"
                                    rel="noopener noreferrer">http://www.visitfortwayne.com/</a></li>
                            <li>
                                <hr>
                            </li>

                            <li>The Downtown Improvement District</li>
                            <li><a href="http://downtownfortwayne.com/" target="_blank"
                                    rel="noopener noreferrer">http://downtownfortwayne.com/</a></li>
                            <li>
                                <hr>
                            </li>

                            <li>AccuWeather</li>
                            <li><a href="http://www.accuweather.com/en/weather-news" target="_blank"
                                    rel="noopener noreferrer">http://www.accuweather.com/en/weather-news</a></li>
                        </ul>
                    </div>



                </div>


            </div>

        </div>
    </div>
</div>