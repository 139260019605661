<!--
<div class="px-2">

    <div class="stepper ">
        <ol class="mt-4 mb-2 p-0">
            <li class='success'><div><h2>1</h2><p>Select a Service Type</p></div><div class="line"> </div></li>
            <li class='active'><div><h2>2</h2><p>Provide Contact & Address Details</p></div><div class="line"> </div></li>
            <li class=''><div><h2>3</h2><p>Provide Additional Information</p></div><div class="line"> </div></li>
            <li><div><h2>4</h2><p>Review & Submit</p></div></li>
          </ol>
      </div>

<div class="container address-details p-0">
    <p class="h6 mb-2 mt-3">Please search or use the map to select the address</p>
    <form [formGroup]="addressDetailsForm" (ngSubmit)= "gotoNext({viewname:'additionalinfoView','serviceDetails':'next-btn'},1)">
        <div class="row">
            <div class="col-md-4  ">

                <div class=" bg-white card-p h-100 p-3">
                    <div class="d-flex ">
                        <div class="h6 mb-3"><i class="bi bi-person-lines-fill"></i> Contact Details</div>

                    </div>
                    <div class="row m-0 mb-3">
                    <div class=" form-check">
                        <input type="checkbox" class="form-check-input" id="exampleCheck1"  formControlName="exampleCheck1"  (change)="toggleVisibility($event)">
                        <label class="form-check-label" for="exampleCheck1">Submit Anonymously</label>
                    </div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-6 ps-0">
                            <input type="text" class="did-floating-input" id="firstname"  formControlName="firstname"  [readOnly]="isLoginInfo || isAnonymous">
                            <label for="" class="did-floating-label">First Name</label>
                        </div>

                        <div class="did-floating-label-content  col-md-6 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="lastname" formControlName="lastname"  [readOnly]="isLoginInfo || isAnonymous">
                            <label for="" class="did-floating-label">Last Name</label>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="email" formControlName="emailaddress"  [readOnly]="isLoginInfo || isAnonymous">
                            <label for="" class="did-floating-label">Email Address</label>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="phonenumber" formControlName="phonenumber" [readOnly]="isLoginInfo || isAnonymous">
                            <label for="" class="did-floating-label">Phone Number</label>
                        </div>
                    </div>

                    <div class="d-flex mt-3">
                        <div class="h6 mb-4"><i class="bi bi-pin-map-fill"></i> Service Address Details</div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="streetaddress" formControlName="streetaddress" >
                            <label for="" class="did-floating-label">Street Address</label>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-6 ps-0">
                            <input type="text" class="did-floating-input" id="city" formControlName="city" >
                            <label for="" class="did-floating-label">City</label>
                        </div>

                        <div class="did-floating-label-content  col-md-6 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="state" formControlName="state" >
                            <label for="" class="did-floating-label">State</label>
                        </div>
                    </div>
                    <div class="row m-0">
                        <div class="did-floating-label-content  col-md-6 ps-0">
                            <!-- <input type="text" class="did-floating-input" id="country" formControlName="country"> -->
<!-- <select class="did-floating-input" formControlName="county">
                                <option [ngValue]="''"></option>
                                <option [ngValue]="'Davidson County'">Davidson</option>
                                <option [ngValue]="'Hamilton County'">Hamilton</option>
                                <option [ngValue]="'Washington County'">Washington</option>
                            </select>
                            <label for="" class="did-floating-label">County</label>
                        </div> -->

<!-- <div class="did-floating-label-content  col-md-6 ps-0 pe-0">
                            <input type="text" class="did-floating-input" id="zipcode" formControlName="zipcode">
                            <label for="" class="did-floating-label">Zip Code</label>
                        </div>
                    </div> -->
<!-- <p class="mt-1 mb-0 small" *ngIf="!isLoginInfo">In order to follow up/subscribe this issue:
                        <a class="text-primary pointer" (click)="openPopup()"> Sign In</a> / <a class="text-primary" routerLink="/sign-up" >Sign Up</a></p>
                </div>
            </div>
            <div class="col-md-8 ">
                <div class="border card-p h-100 position-relative"> -->
<!-- <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d19292.506159024455!2d-71.42967867416813!3d41.822418787549246!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89e444e0437e735d%3A0x69df7c4d48b3b627!2sProvidence%2C%20RI%2C%20USA!5e0!3m2!1sen!2sin!4v1689251515691!5m2!1sen!2sin"
                        width="100%" height="500" style="border:0;" allowfullscreen="" loading="lazy"
                        referrerpolicy="no-referrer-when-downgrade">
                    </iframe> -->
<!-- <app-google-map  (searchLocationEvent)="searchLocationEvent($event)"></app-google-map>

                </div>
            </div>
        </div>
        <div class="row">
        <div class="col action-from d-flex"> -->
<!-- <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto" (click)="gotoBack()"><i class="bi bi-arrow-left"></i> Back</button> -->

<!-- <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto" (click)="gotoNext({viewname:'gridView','serviceDetails':'prv-btn'},0)"><i class="bi bi-arrow-left"></i> Back</button>
        <button type="submit" value="next-btn" class="btn btn-primary"
         [disabled]="!addressDetailsForm.valid">
            <i class="bi bi-arrow-right"></i> Next
        </button>
    </div>
</div>

    </form>
</div>
</div> -->
<div class="px-2">
  <div class="stepper hide-mobile border-radius-20 bg-white mx-2 px-3 py-2 mt-2 mb-3">
      <div class="d-flex align-items-center" style="margin-bottom: 16px;">
          <h3 class="mb-0 me-1">New Service Request (SR): </h3>
          <span
              class="text-active fw-500"><span style="font-size: 1.2rem;">{{this.serviceTypeInfo.serviceDetails.content || this.serviceTypeInfo.serviceDetails.casetypecontent}}</span>&nbsp;&nbsp;</span>
              ({{serviceTypeInfo.serviceDetails.division.name || serviceTypeInfo.serviceDetails.division.valueen }}/{{serviceTypeInfo.serviceDetails.department.name ||serviceTypeInfo.serviceDetails.department.valueen}} )

              <span (click)="gotoBack({viewname:this.serviceTypeInfo.redirectTo?this.serviceTypeInfo.redirectTo:'gridView','serviceDetails':'prv-btn'},0)"
              class="text-active fw-500" style="cursor:pointer;font-size:12px">&nbsp;&nbsp;<i

                  class="bi bi-pencil"></i>Change</span>
      </div>

      <div class="my-2 p-1 ol justify-content-between">
          <div class='success li'>

              <i class="bi bi-check-circle-fill text-white" style="font-size: 18px;"></i>
              <h2>1. Select a Service Type</h2>


              <!-- <div class="line"> </div> -->
          </div>
          <div class="li active">

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 29" fill="none">
                  <path
                      d="M19.7096 2.80784C18.3893 1.72674 16.8454 0.952135 15.1895 0.539906C13.5335 0.127678 11.8067 0.0880968 10.1336 0.424018C7.98235 0.86507 6.00322 1.91521 4.43196 3.44935C2.8607 4.9835 1.76355 6.93697 1.27121 9.07707C0.778868 11.2172 0.912083 13.4537 1.65499 15.5202C2.39789 17.5868 3.71917 19.3962 5.46143 20.733C7.45061 22.1888 9.14426 24.0103 10.4518 26.1L11.3427 27.5815C11.4559 27.7697 11.6158 27.9254 11.8069 28.0335C11.998 28.1416 12.2139 28.1984 12.4334 28.1984C12.653 28.1984 12.8688 28.1416 13.06 28.0335C13.2511 27.9254 13.411 27.7697 13.5242 27.5815L14.3782 26.1586C15.5168 24.1571 17.0693 22.4215 18.932 21.0677C20.3926 20.0628 21.5997 18.7322 22.458 17.1809C23.3163 15.6297 23.8024 13.9002 23.878 12.1289C23.9535 10.3576 23.6164 8.59299 22.8932 6.97428C22.1701 5.35558 21.0806 3.92708 19.7109 2.80148L19.7096 2.80784ZM12.4322 16.7455C11.4253 16.7455 10.441 16.4469 9.6038 15.8875C8.7666 15.3281 8.11409 14.533 7.72877 13.6028C7.34345 12.6725 7.24263 11.6489 7.43907 10.6614C7.6355 9.67385 8.12036 8.76673 8.83234 8.05476C9.54432 7.34278 10.4514 6.85792 11.439 6.66148C12.4265 6.46505 13.4501 6.56587 14.3804 6.95118C15.3106 7.3365 16.1057 7.98902 16.6651 8.82622C17.2245 9.66341 17.5231 10.6477 17.5231 11.6546C17.5231 13.0048 16.9867 14.2997 16.032 15.2544C15.0773 16.2091 13.7824 16.7455 12.4322 16.7455Z"
                      fill="#275A8A" />
              </svg>
              <h2>2. Provide Contact & Address Details</h2>

          </div>
          <div class="li">

              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 24" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M1.19753 20.4147C1.87185 22.8401 3.82122 23.3575 6.08615 22.7254C7.66151 22.2857 9.30152 21.3385 10.6363 20.3758C11.6139 19.6708 12.7824 18.9731 13.9408 18.6212C14.9762 18.3067 16.2033 18.2098 16.7197 19.3675C16.9046 19.7823 16.9722 20.2664 16.9904 20.7168C17.0001 20.9565 17.202 21.144 17.442 21.1346C17.6814 21.1252 17.8692 20.923 17.8595 20.6834C17.7536 18.0687 16.2001 17.0273 13.689 17.79C12.4393 18.1697 11.1839 18.9096 10.1291 19.6703C8.87535 20.5745 7.33491 21.4737 5.85561 21.8866C4.69209 22.2113 3.2963 22.3072 2.50744 21.2135C2.2857 20.906 2.13717 20.5473 2.03614 20.1836C1.97193 19.9524 1.73244 19.816 1.50095 19.8799C1.27 19.9438 1.13323 20.1835 1.19753 20.4147ZM16.6978 1.18953C16.9138 0.973497 17.243 0.973142 17.4589 1.18931C17.6749 1.40547 19.1978 2.93009 19.1978 2.93009C19.4136 3.14611 19.4137 3.28839 19.1978 3.50433C18.9818 3.72027 5.028 17.674 5.028 17.674C4.84624 17.8094 2.33891 18.8409 2.05425 18.9582C1.6017 19.1448 1.14624 18.6927 1.3295 18.2388C1.50665 17.8 2.42507 15.5121 2.6102 15.2769L16.6978 1.18953Z"
                      fill="#275A8A" stroke="#275A8A" />
              </svg>
              <h2>3. Provide Additional Information</h2>

              <!-- <div class="line"> </div> -->
          </div>

          <div class="li">
              <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 25" fill="none">
                  <path
                      d="M15.1435 9.42638L4.06655 0.700245C3.74826 0.432636 3.35888 0.26359 2.94604 0.213782C2.53319 0.163975 2.11478 0.235565 1.74198 0.419797C1.21133 0.726018 0.775821 1.17318 0.483714 1.71173C0.191608 2.25028 0.0543107 2.85919 0.087037 3.47098V20.9218C0.0542249 21.5336 0.191485 22.1425 0.4836 22.6811C0.775715 23.2196 1.21127 23.6668 1.74198 23.9729C2.03553 24.1186 2.35837 24.1955 2.68606 24.1979C3.19099 24.1915 3.6788 24.0139 4.06955 23.694L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7204C16.2706 13.2403 16.3696 12.7196 16.3515 12.1964C16.3692 11.6738 16.27 11.1539 16.061 10.6747C15.8519 10.1954 15.5385 9.76895 15.1435 9.42638Z"
                      fill="#275A8A" />
                  <path fill-rule="evenodd" clip-rule="evenodd"
                      d="M16.3229 8.06656L16.2908 8.03872L11.3405 4.13899V3.4707C11.3078 2.85848 11.4455 2.24918 11.7383 1.71053C12.0311 1.17188 12.4676 0.724984 12.9992 0.419516C13.372 0.235474 13.7904 0.163989 14.2032 0.213791C14.616 0.263594 15.0054 0.432529 15.3237 0.699964L26.3992 9.42535C26.7945 9.76789 27.1081 10.1945 27.3173 10.6739C27.5264 11.1533 27.6257 11.6734 27.608 12.1961C27.6258 12.7188 27.5264 13.2388 27.3171 13.7181C27.1078 14.1974 26.7939 14.6238 26.3984 14.9661L15.3237 23.6945C14.9331 24.014 14.4456 24.1916 13.941 24.1984C13.6131 24.1958 13.2901 24.1188 12.9962 23.9734C12.4654 23.6671 12.0297 23.2198 11.7375 22.6812C11.4453 22.1425 11.3079 21.5334 11.3405 20.9215V20.258L16.2911 16.3583L16.3235 16.3302C16.9215 15.8111 17.3958 15.1649 17.7119 14.4389C18.0198 13.7318 18.17 12.9663 18.1523 12.1957C18.1694 11.4259 18.0189 10.6614 17.7109 9.95513C17.3947 9.23007 16.9205 8.58483 16.3229 8.06656ZM11.3405 17.9666L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7203C16.2706 13.2403 16.3697 12.7196 16.3516 12.1964C16.3693 11.6738 16.27 11.1539 16.061 10.6747C15.852 10.1954 15.5385 9.76895 15.1435 9.42638L11.3405 6.43043V17.9666Z"
                      fill="#275A8A" />
              </svg>
              <h2 class="pe-2">4. Review & Submit Request</h2>
              <!-- </div> -->

          </div>

      </div>
  </div>

  <div class="stepper show-mobile mb-3" >
    <div class="d-flex align-items-center" style="margin-bottom: 16px; flex-direction: column;">
        <div class="d-flex align-items-center">
        <h3 class="mb-0 me-1" style="white-space: nowrap;">New SR: </h3>
        <p  class="text-active m-0 fw-500"><span >{{this.serviceTypeInfo.serviceDetails.content || this.serviceTypeInfo.serviceDetails.casetypecontent}}</span>
        </p>
    </div>
<div class="d-flex">
         <span>  ({{serviceTypeInfo.serviceDetails.division.name || serviceTypeInfo.serviceDetails.division.valueen }}/{{serviceTypeInfo.serviceDetails.department.name ||serviceTypeInfo.serviceDetails.department.valueen}} )</span>

            <span (click)="gotoBack({viewname:this.serviceTypeInfo.redirectTo?this.serviceTypeInfo.redirectTo:'gridView','serviceDetails':'prv-btn'},0)"
            class="text-active fw-500" style="cursor:pointer;font-size:12px">&nbsp;&nbsp;<i

                class="bi bi-pencil"></i>Change</span>
            </div>
    </div>
      <div class="row">
          <div class="col-md-12">
              <ul class="progressbar">
                  <li class="success">Contact</li>
                  <li class="active">Address</li>
                  <li>Script</li>
                  <li>Review</li>
              </ul>
          </div>
      </div>
  </div>


  <div class="container address-details p-0">
      <!-- <p class="h6 mb-2 mt-3">Please search or use the map to select the address</p> -->
      <!-- <p class="h6 mb-2 mt-3">Create a new Service Request (SR):</p> -->

      <form [formGroup]="addressDetailsForm"
          (ngSubmit)="gotoNext({'redirectTo':this.serviceTypeInfo.redirectTo,viewname:'agentscriptView','serviceDetails':'next-btn'},1)">
          <div class="d-flex address-details-form row m-0">
              <div class="col-xl-12 col-xxl-3  p-0" formGroupName="contactDtls">

                  <div class=" bg-white card-p h-100 px-3 pt-3 pb-0">
                      <div class="d-flex mb-2">
                          <div class="h6 mb-3"> <img src="../../assets/images/contact-details.png"
                                  style="height: 18px;margin-right: 5px;"> Contact <span class="show-desktop"> Details</span> </div>
                          <div *ngIf="serviceTypeInfo.serviceDetails.casetypeid!=367" class=" form-check ms-auto d-flex">
                              <input type="checkbox" class="form-check-input" id="anonymous" [(ngModel)]="isAnonymous"
                                  formControlName="anonymous" (change)="toggleVisibility($event)" [readonly]="serviceTypeInfo.serviceDetails.department.id === 199"
                                  style="width: 18px;height: 18px;" autocomplete="new-password">

                              <label class="form-check-label ms-1"
                                  style="line-height: 25px;">Anonymous</label>
                          </div>
                      </div>
                      <!-- <div class="row m-0">
                      <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                          <input type="text" class="did-floating-input" id="contact" formControlName="contact"
                              [readOnly]="isLoginInfo || isAnonymous">
                          <label for="" class="did-floating-label">Contact*</label>
                      </div>
                  </div> -->
                  <div class="row m-0">
                    <div class="did-floating-label-content col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0 ">
                      <input type="text" class="did-floating-input" formControlName="firstname" [class.disabled]="isAnonymous" [readOnly]="isAnonymous" autocomplete="new-password">

                      <label class="did-floating-label" [class.active]="addressDetailsForm.get('contactDtls.firstname').value">
                        First Name <span class="red">*</span>
                      </label>
                      <div *ngIf="addressDetailsForm.get('contactDtls.firstname').invalid && formsubmitted " class="text-danger error-address">
                        First Name is required.
                      </div>
                    </div>

                          <div class="did-floating-label-content  col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0 ">
                              <input type="text" class="did-floating-input" id="lastname" formControlName="lastname"
                                  [class.disabled]="isAnonymous" [readOnly]="isAnonymous" autocomplete="new-password">
                              <label for="" class="did-floating-label">Last Name<span class="red">*</span></label>
                              <div *ngIf="addressDetailsForm.get('contactDtls.lastname').invalid && formsubmitted " class="text-danger error-address">
                        Last Name is required.
                      </div>
                          </div>

                          <div class="did-floating-label-content  col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0 ">
                              <input type="text" class="did-floating-input" id="email" formControlName="emailaddress"
                              [readOnly]="isAnonymous"
                              [class.disabled]="isAnonymous" autocomplete="new-password">
                              <label for="" class="did-floating-label">Email<span class="red">*</span></label>
                              <div *ngIf="addressDetailsForm.get('contactDtls.emailaddress')?.hasError('required') && formsubmitted" class="text-danger error-address">
                                Email is required.
                              </div>
                              <div *ngIf="addressDetailsForm.get('contactDtls.emailaddress')?.hasError('invalidEmailFormat')" class="text-danger error-address">
                                Invalid email format.
                              </div>

                          </div>

                        <div class="did-floating-label-content col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0">
                          <input type="text" class="did-floating-input" id="phonenumber1"
                                 formControlName="phonenumber1" [readOnly]="isAnonymous" [class.disabled]="isAnonymous"
                                 appPhoneMask minlength="14" maxlength="14" autocomplete="new-password">
                          <label class="did-floating-label" [class.active]="addressDetailsForm.get('contactDtls.phonenumber1').value">
                            Primary Phone Number
                          </label>
                          <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber1').invalid && formsubmitted" class="text-danger error-address">
                            Invalid Phone number
                          </div>
                        </div>

                        <!-- <div class="did-floating-label-content col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0">
                          <input type="text" class="did-floating-input" id="phonenumber2"
                                 formControlName="phonenumber2" [readOnly]="isAnonymous" [class.disabled]="isAnonymous"
                                 appPhoneMask minlength="14" maxlength="14">
                          <label class="did-floating-label" [class.active]="addressDetailsForm.get('contactDtls.phonenumber2').value">
                            Secondary Phone Number
                          </label>
                          <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber2').invalid &&
                                      addressDetailsForm.get('contactDtls.phonenumber2').touched" class="text-danger error-address">
                            Invalid Phone number
                          </div>
                        </div> -->

                        <div class="did-floating-label-content  col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0 ">
                            <input type="text" class="did-floating-input" id="phonenumber2"
                                formControlName="phonenumber2" [readOnly]="isAnonymous" [class.disabled]="isAnonymous" appPhoneMask  minlength="14"
                                maxlength="14" autocomplete="new-password">
                            <label for="" class="did-floating-label">Alternate Phone Number</label>
                            <!-- <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber2').invalid &&
                            addressDetailsForm.get('contactDtls.phonenumber2').touched" class="text-danger error-address">
                  Invalid Phone number
                </div> -->
                        <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber2')?.hasError('sameNumbers')" class="text-danger error-address">
                            Phone Numbers cannot be same
                          </div>

                          <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber2')?.hasError('minlength') && addressDetailsForm.get('contactDtls.phonenumber2').dirty" class="text-danger error-address">
                            Invalid Alternate number
                          </div>
                          <!-- <div *ngIf="addressDetailsForm.get('contactDtls.phonenumber2')?.hasError.minlength" class="text-danger error-address">
                            Phone number is invalid
                          </div> -->
                        </div>



                          <div class="did-floating-label-content  col-md-6 col-s-12 col-xl-6 col-xxl-12 ps-0 ">
                              <textarea type="text" class="did-floating-input" id="mailingadd" rows="3" style="height: 60px;"
                                  formControlName="mailingadd" [readOnly]="isAnonymous" [class.disabled]=" isAnonymous" autocomplete="new-password"></textarea>
                              <label for="" class="did-floating-label">Mailing Address</label>
                          </div>
                      </div>
                  </div>
              </div>
              <div class="col-xl-12 col-xxl-9 p-0 rob-box">
                  <div class="row m-0 " style="height: 100%;">
                      <div class="col-xl-12 col-xxl-3 px-1 " formGroupName='locationDtls'>
                          <div class=" bg-white card-p  px-3 py-3" [ngStyle]="tcontainer" style="overflow-x: auto; height: 100%;">
                              <div class="d-flex  mb-2">
                                  <div class="h6 mb-3"><i class="fa-solid fa-location-dot"></i>
                                      Location <span class="show-desktop"> Details</span>
                                  </div>
                                  <div class=" form-check ms-auto d-flex">
                                      <a href="javascript:void(0)">
                                          <span class="text-active font-14 pointer small-x underline-on-hover"
                                              (click)="clearForm()">Clear</span>
                                      </a>
                                  </div>
                              </div>
                              <div class="d-flex">
                                <small *ngIf="isGisVerified" class="text-success ms-auto d-flex align-items-center mb-2">
                                    <span>GIS Verified </span>
                                    <i class="bi bi-check-circle-fill ms-1"></i>
                                </small>
                            </div>
                              <!-- <div class="row m-0">
                        <div class="did-floating-label-content col-md-12 ps-0 pe-0 mb-2">
                            <div class="d-flex">
                                <label class="me-2">Address Type</label>
                                <small *ngIf="isGisVerified" class="text-success ms-auto d-flex align-items-center">
                                    <span>GIS Verified </span>
                                    <i class="bi bi-check-circle-fill ms-1"></i>
                                </small>
                            </div>
                            <div class="radio-buttons d-flex">
                                <input type="radio" id="address" name="addresstype" (change)="isAddresstypeChange()"
                                    formControlName="addresstype" value="1362">
                                <label for="address">Address</label>
                                <input type="radio" id="intersection" name="addresstype"
                                    (change)="isAddresstypeChange()" formControlName="addresstype" value="1363">
                                <label for="intersection">Intersection</label>
                            </div>
                        </div>
                    </div> -->

                    <div class="row m-0"  >
                      <div class=" col-md-12 ps-0 pe-0 mb-2 position-relative search-loc">

                          <div class="did-floating-label-content ps-0 pe-2"  >
                              <input type="text" class="form-control did-floating-input search-rwq" (click)="performAddressSearch()"
                                  (input)="suggestionSearch()" (keyup.enter)="keyupenter()" formControlName="addressline1"
                                  (change)="onDropdownChange()"(keyup)="openDropdown($event)" id="suggestionSearch"  autocomplete="new-password">

                                  <label for="" class="did-floating-label">Service Address<span class="red">*</span></label>

                                  <div *ngIf="addressDetailsForm.get('locationDtls.addressline1').invalid && formsubmitted " class="text-danger error-address">
                                  Service Address is required.
                                  </div>

                                  <div class="dropdown_check"  [style.display]="entiredata?.length > 0 ? 'block' : 'none'" aria-labelledby="suggestionSearch">
                                      <p *ngFor="let item of entiredata" class="dropdown-item " (click)="assignaddress(item)">
                                          <span style="font-size: 13px; margin-left: 10px;" style="cursor: pointer;"
                                              >{{item.text}} </span></p>
                                  </div>
                          </div>

<img src="../../assets/images/search-line.png" height="16px" style="position: absolute;right: 14px;top: 8px;z-index: 9;">

                      </div>
                  </div>

                              <!-- <div *ngIf="addressDetailsForm.value.locationDtls.addresstype=='1363'">

                        <div class="row m-0">
                            <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                                <input type="text" class="did-floating-input" id="addressline1"
                                    formControlName="addressline1">
                                <label for="" class="did-floating-label">Address Line 1</label>
                            </div>
                        </div>
                        <div class="row m-0">
                            <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                                <input type="text" class="did-floating-input" id="addressline2"
                                    formControlName="addressline2">
                                <label for="" class="did-floating-label">Address Line 2</label>
                            </div>
                        </div>
                    </div> -->

                              <!-- <div class="row m-0" *ngIf="addressDetailsForm.value.locationDtls.addresstype=='1363'">
                        <div class="did-floating-label-content  col-md-12 ps-0 pe-0 justify-content-center d-flex">
                            <button type="button" value="" (click)="onSearch()"
                                class="btn btn-primary ms-auto ml-1"><i class="bi-search"></i> Search</button>
                        </div>
                    </div> -->
                              <div class="row m-0">
                                  <div class="did-floating-label-content  col-md-12 ps-0 pe-2">
                                      <input type="text" class="did-floating-input " id="city"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="city">
                                      <label for="" class="did-floating-label">City</label>
                                  </div>
                                  <div class="did-floating-label-content  col-md-12 ps-0 pe-2">
                                      <input type="text" class="did-floating-input" id="state"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="state">
                                      <label for="" class="did-floating-label">State</label>
                                  </div>

                              </div>
                              <div class="row m-0">
                                  <div class="did-floating-label-content  col-md-12 ps-0 pe-2">
                                      <input type="text" class="did-floating-input" id="postalcode"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="postalcode">
                                      <label for="" class="did-floating-label ">Postal/Zip</label>
                                  </div>
                                  <div class="did-floating-label-content col-md-12 ps-0 pe-2" *ngIf="this.serviceTypeInfo.serviceDetails.casetypeid === 367 || this.serviceTypeInfo.serviceDetails.casetypeid === 233 || this.serviceTypeInfo.serviceDetails.casetypeid === 257">
                                    <select class="did-floating-input" formControlName="collectionday" [attr.disabled]="isLocationSearch ? true : null" [ngClass]="{'disabled': isLocationSearch}">
                                        <option value=""></option>
                                        <option *ngFor="let day of collectionday" [value]="day.id">{{ day.value }}</option>
                                    </select>
                                    <label for="" class="did-floating-label">Collection Day</label>
                                </div>

                                  <!-- <div class="did-floating-label-content col-md-6 ps-0 pe-2">
                                    <select class="did-floating-input" formControlName="withinlimits"
                                        [attr.disabled]="isLocationSearch ? true : null"
                                        [ngClass]="{'disabled': isLocationSearch}">
                                        <option value="''"></option>
                                        <option *ngFor="let item of withinlimits" [value]="item.id">{{ item.value }}</option>
                                    </select>
                                    <label for="" class="did-floating-label">Within Limits</label>
                                </div> -->

                              </div>

                              <!-- <div class="row m-0"> -->
                                <div style="display:none">

                                <div class="did-floating-label-content col-md-6 ps-0 pe-2">
                                    <select class="did-floating-input" formControlName="recycleweek"
                                        [attr.disabled]="isLocationSearch ? true : null"
                                        [ngClass]="{'disabled': isLocationSearch}">
                                        <option value="''"></option>
                                        <option *ngFor="let day of recycleweek" [value]="day.id">{{ day.value }}</option>
                                    </select>
                                    <label for="" class="did-floating-label">Recycle Week</label>
                                </div>


                                <div class="did-floating-label-content  col-md-6 ps-2 pe-0">
                                  <select class="did-floating-input " formControlName="collectionday"
                                  [attr.disabled]="isLocationSearch ? true : null"
                                  [ngClass]="{'disabled': isLocationSearch}">
                                  <option value="''"></option>
                                  <option *ngFor="let day of collectionday" [value]="day.id">{{ day.value }}</option>
                              </select>
                                    <label for="" class="did-floating-label ">Collection Day</label>
                                </div>
                              <div class="row m-0">
                                  <div class="did-floating-label-content  col-md-6 ps-0 pe-2">
                                      <input type="text" class="did-floating-input " id="latitude"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="latitude">
                                      <label for="" class="did-floating-label">Latitude</label>
                                  </div>

                                  <div class="did-floating-label-content  col-md-6 ps-2 pe-0">
                                      <input type="text" class="did-floating-input " id="longitude"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="longitude">
                                      <label for="" class="did-floating-label">Longitude</label>
                                  </div>
                              </div>

                            </div>
                              <!-- <div class="row m-0">
                                  <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                                      <input type="text" class="did-floating-input " id="parcelnumber"
                                      [readOnly]="isLocationSearch"
                                      [class.disabled]="isLocationSearch"
                                          formControlName="parcelnumber">
                                      <label for="" class="did-floating-label">Parcel Number</label>
                                  </div>
                              </div> -->
                              <!-- <div class="row m-0">
                                <div class="did-floating-label-content  col-md-12 ps-0 pe-0 m-0">
                                    <input type="datetime-local" class="did-floating-input " id="sla"
                                        formControlName="sla">
                                    <label for="" class="did-floating-label">SLA</label>
                                </div>
                            </div> -->
                          </div>
                      </div>
                      <!-- <div class="col-md-3 p-0 bg-white">

                      <div class="bg-white card-p p-3 mb-1">
                          <div class="d-flex ">
                              <div class="h6 mb-3">
                                  Nearby Service Requests</div>
                          </div>
                          <div class="row m-0 text-danger error-address">
                              <p for="">Request Time:4.179</p>
                          </div>
                      </div>
                      <div class="bg-white card-p  p-3 mb-1">
                          <div class="row m-0">
                              <div class="h6 mb-3">
                                  Alert Service Requests
                              </div>
                              <p for="">No Alert incidents found for this address</p>
                          </div>
                      </div>
                      <div class="bg-white card-p p-3">
                          <div class="row m-0     align-items-center;">
                              <div class="h6 mb-3">Service Requests</div>
                              <div class="p-0  d-flex m-0 gap-2">
                                      <i class="fa-solid fa-location-dot text-danger error-address fs-xs" style="font-size: 24px; "></i>
                                 <span for="">211344-3784868</span>
                                      <button type="button" class="btn btn-danger ms-auto">Associate</button>

                              </div>
                          </div>
                      </div>
                  </div>  *ngIf="!displayesri" *ngIf="displayesri && showin3dbutton" -->
                      <div class=" map-tab col-xl-12 col-xxl-9 px-1">
                        <div class="d-flex gap-2">
                          <button type="button" [ngClass]="{'btn btn-primary':displayesri}"  class=" btn-sm"  (click)="showesri()" >Esri Map</button>
                          <button type="button" [ngClass]="{'btn btn-primary':!displayesri}"  class=" btn-sm"  (click)="showgoogle()" >3D Map</button>
                        </div>
                <div class="d-block" style="height: calc(100% - 25px); overflow: hidden;">
                          <div [style.display]="displayesri ? 'block' : 'none'" class="border card-p position-relative address-map">
                              <app-esri-map [center]="mapCenter" [basemap]="basemapType"
                                  (childComponentValue)="receiveData($event)" [zoom]="mapZoomLevel"
                                  [searchLocation]="searchLocation" [serviceTypeInfo]="serviceTypeInfo" (mapLoaded)="mapLoadedEvent($event)">
                              </app-esri-map>
                          </div>

                          <div [style.display]="displayesri ? 'none' : 'block'" class="border card-p position-relative address-map">
                              <app-google-map style="height: fit-content; overflow: hidden;"></app-google-map>
                          </div>
                      </div>
                  </div></div>
              </div>
          </div>
          <div class="col-lg-12 col-md-12 col-xs-12 col-sm-12 fix-foot-btn p-2">
              <div class="col action-from text-center ">
                  <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto"
                      (click)="gotoBack({viewname:this.serviceTypeInfo.redirectTo?this.serviceTypeInfo.redirectTo:'gridView','serviceDetails':'prv-btn'},0)"><i
                          class="bi bi-arrow-left"></i> Back</button>
                  <!-- <button type="submit" value="next-btn" class="btn btn-primary ms-2"
                  [disabled]="addressDetailsForm.invalid"> -->
                  <button type="submit" value="next-btn" class="btn btn-primary ms-2">
                    <!-- [disabled]="(addressDetailsForm.invalid || this.addressDetailsForm.get(['locationDtls','addressline1']).value=='')" -->
                      Next <i class="bi bi-arrow-right"></i>
                  </button>
              </div>
          </div>
      </form>
  </div>
</div>
