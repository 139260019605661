<!-- <section  class="services mt-2  px-2">

  <div class="container section-title mt-4 px-0">
    <p>Fortwayne is the fastest, most convenient way to report your City of Fortwayne-related issues or to make requests.
       You can download our 311 application on your cellphone to report an issue directly or click New Service Request above. </p>
       <p>
       All Animal Services issues for the City of Fortwayne are managed through
      <a href="#" class="text-primary text-underline">the McKamey Animal Center</a>. Please contact them directly by <a href="#" class="text-primary text-underline">(423) 305 6500</a></p>

  </div>

</section> -->


<mat-tab-group (selectedTabChange)="tabChanged($event)">




  <mat-tab label="Active Service Requests">
    <ng-template mat-tab-label>
      <img src="../assets/images/ActiveSR.svg" alt="ActiveSR" height="20px" class="me-2">
      Active Service Requests
    </ng-template>

    <section class="  bg-transparent  px-2 ">
      <div class="container section-title mt-1">

        <div class="row mapviewsn">
          <div class="col-md-9  col pad-0 pb-3 position-relative ">

            <div class="row position-relative">
              <div class="map-filter-option" ngbDropdown>
                <input type="text" [(ngModel)]="searchAddress" (input)="suggestionSearch(searchAddress)"
                  (keyup.enter)="onAddressChange()"
                  placeholder="Location, Street" class="form-control" ngbDropdownToggle autocomplete="new-password">


                <div class="dropdown_check"  ngbDropdownMenu>
                  <p *ngFor="let item of entiredata" (click)="assignAddress(item.text); onAddressChange()">
                    {{ item.text }}
                </p>
                </div>
                <div class="dropdown map-drop">
                  <button class="btn btn-secondary dropdown-toggle custom-select" type="button" id="dropdownMenu2"
                    data-bs-toggle="dropdown" aria-expanded="false">

                  </button>
                  <ul class="dropdown-menu " aria-labelledby="dropdownMenu2" style="font-size: 14px;">
                    <li>
                      <button class="dropdown-item" type="button" (click)="onDepartmentChange('All')">All</button>
                    </li>
                    <li *ngFor="let department of departmentlist">
                      <button class="dropdown-item" type="button" (click)="onDepartmentChange(department)">{{ department
                        }}</button>
                    </li>
                  </ul>
                </div>
              </div>


            </div>




            <div class="card-p home-page">
              <!-- <app-google-map [isSearchNotRequired]="isSearchNotRequired" [isPopupShow]="clickAddress" [isAddressRequired]="isAddressRequired"></app-google-map> -->

              <app-home-esrimap [center]="mapCenter" [basemap]="basemapType" [zoom]="mapZoomLevel"
                [searchLocation]="searchLocation" class="position-relative esrimap-custom"
                (mapLoaded)="mapLoadedEvent($event)" [selectedDepartment]="selectedDepartment"
                [recentIncidents]="recentIncidents" (showIncidentLocationEvent)="showIncidentLocation($event)">
              </app-home-esrimap>


            </div>

            <div class="d-flex  card-p indication">
              <div class="flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-danger"> </span> Unresolved </p>
              </div>
              <div class=" flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-success"> </span> Resolved </p>
              </div>
              <div class="flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-blue"> </span> Re-Open </p>
              </div>
              <div class=" flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-warning"> </span> Waiting </p>
              </div>
              <div class=" flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-info"> </span> Close-Reclassify </p>
              </div>
              <div class=" flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-secondary"> </span> Close-Disregard </p>
              </div>
              <div class=" flex-fill ">
                <p class="resolved"> <span class="rounded-circle bg-primary"> </span> Close-Resolved </p>
              </div>
            </div>
          </div>


          <div class="col-md-3 col-sm-12 col-xs-12 mb-3 bg-white card-p p-0 position-relative1-2">
            <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
              <h6 class="bg-blue fw-500 text-white m-0 p-1">Recent Service Requests</h6>
            </div>

            <div class="map-value-box">
              <div *ngIf="filteredIncidents?.length > 0; else noDataFound">
                <div class="m-0 d-flex w-100 px-2 py-2 value2-box" *ngFor="let incident of filteredIncidents; let i = index">
                  <div class="d-flex gap-3 align-items-center">
                    <img [src]="getImagePath(incident.department, incident.incidentstatus)" width="28px">
                    <div>
                      <div class="text-primary pointer fw-500" (click)="showIncidentLocation(incident)">
                        <span>{{ incident.casetype }}</span>
                        <span class="badge rounded-pill  ms-2" style="background: #0072ff;font-weight: normal;" >{{incident.servicerequestsource}}</span>
                      </div>
                      <div class="w-100 d-block text-muted" style="font-size: 13px;padding: 0 0 2px;">
                        {{ Nanaddress(incident.addressline1) }}
                      </div>
                      <div class="w-100 d-block fw-500" style="font-size: 13px;padding: 0 0 2px ;color: green;">
                        {{incident.resolvedtime}}
                      </div>
                      <div class="w-100 d-block fw-500" style="font-size: 13px;padding: 0 0 2px ;color: blueviolet;">
                        {{createdago(incident.createdago)}}
                      </div>
                      <div>
                        <ng-container *ngIf="getIfSubscribed(incident.id); else notSubscribed">
                          <button class="btn fs-6 btn-danger" (click)="unsubscribe(incident.id)">Unsubscribe</button>
                        </ng-container>
                        <ng-template #notSubscribed>
                          <button class="btn fs-6 btn-primary" (click)="openSubscribePopup(incident.id)">Subscribe</button>
                        </ng-template>
                      </div>
                    </div>
                  </div>

                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Unresolved'">
                    <p class="resolved"><span class="rounded-circle bg-danger" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Resolved'">
                    <p class="resolved"><span class="rounded-circle bg-success" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Waiting'">
                    <p class="resolved"><span class="rounded-circle bg-warning" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Reopen'">
                    <p class="resolved"><span class="rounded-circle bg-blue" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Close-Reclassify'">
                    <p class="resolved"><span class="rounded-circle bg-info" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Close-Disregard'">
                    <p class="resolved"><span class="rounded-circle bg-secondary" style="width: 8px; height: 8px;"></span></p>
                  </div>
                  <div class="ps-2 ms-auto" *ngIf="incident.incidentstatus === 'Close-Resolved'">
                    <p class="resolved"><span class="rounded-circle bg-primary" style="width: 8px; height: 8px;"></span></p>
                  </div>
                </div>
              </div>
              <ng-template #noDataFound>
                <div class="row">
                  <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 reedent-nodata-found">
                    <h5>No Recent Service Requests Found</h5>
                  </div>
                </div>
              </ng-template>
            </div>

            <div [style.display]="showSubscribePopup ? 'block' : 'none'"
                 style="position: absolute; top: 0; left: 0; z-index: 999; background: #000000a8; width: 100%; height: 100%;">
              <div style="background-color: white; margin: 10px; border-radius: 10px; padding: 20px;">
                <h2>Subscribe</h2>
                <form [formGroup]="subscribeForm" (submit)="submitSubscribe()">
                  <div class="did-floating-label-content col-md-12 ps-0 pe-0">
                    <input type="text" class="did-floating-input" id="Subscribe" formControlName="Subscribe">
                    <label for="Subscribe" class="did-floating-label">Email</label>
                    <div *ngIf="subscribeForm.get('Subscribe').invalid && (subscribeForm.get('Subscribe').dirty || subscribeForm.get('Subscribe').touched)"
                         class="text-danger">
                      <div *ngIf="subscribeForm.get('Subscribe').errors.required">Email is required.</div>
                      <div *ngIf="subscribeForm.get('Subscribe').errors.invalidEmailFormat">Invalid email format.</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center justify-content-center">
                    <button type="button" value="cancel-btn" class="btn btn-outline-primary ms-2" (click)="closeSubscribePopup()">
                      <i class="bi bi-x-lg mr-1" style="font-size: 16px;"></i> Cancel
                    </button>
                    <button type="submit" value="submit-btn" class="btn btn-primary ms-2" [disabled]="subscribeForm.invalid">
                      <i class="bi bi-save mr-1"></i> Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>


        </div>
      </div>
    </section>
  </mat-tab>

  <mat-tab  label="Trending Service Requests" >
    <ng-template mat-tab-label>
      <img src="../assets/images/TrendingSR.svg" alt="TrendingSR" height="20px" class="me-2">
      Trending Service Requests
    </ng-template>

    <div class="box-btn">
      <button class="btn-action" [class.active-b]="showlist" (click)="toggleListView()">
        <i class="bi bi-grid-3x3-gap-fill"></i>
      </button>
      <button class="btn-action" [class.active-b]="!showlist" (click)="toggleListView()">
        <i class="bi bi-list-task"></i>
      </button>
    </div>

<div style=" height: 100%; background: white; margin-top: 40px; border-radius: 20px;display: flow-root;">
    <div class=" query table-cont query-table query-table-common" >

    <table class="scroll order-column display  mt-2 mb-0 bg-white" id="listviewtables" *ngIf="!showlist">
      <thead>
        <tr>
          <th ># </th>
          <th>Service Type</th>
          <th>Division/Department</th>
          <!-- <th>division</th> -->
          <!-- <th >Resolution Time</th> -->
          <th >Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of this.newcaseTypeVal;let i=index" class="table-row">
          <td >{{i+1}}</td>
          <td
           class="text-primary pointer">
            <img [src]="'../../assets/images/casetypeImage/'+item.casetype+'.svg'" width="16px" width="25px" class="me-2">

            <span (click)="gotoNext({redirectTo:'home',viewname:'addressView','serviceDetails':item})">{{ item.casetypecontent }}</span>
            </td>
          <td>{{ item.division.valueen }} > {{ item.department.valueen }}</td>
          <!-- <td></td> -->
          <!-- <td >{{ item.estimatetime }} Days</td> -->
          <td  >
            <!-- <ng-template #tipContent class="">
              <p class="fw-bold text-left"> FAQs:</p>
              <p class="fw-bold text-left m-0"> Are potholes and cave-ins the same?</p>

              <p class=" text-left">No, a cave-in is a collapse in the pavement into a deep empty space without a solid
                bottom.</p>

              <P class="fw-bold text-left m-0"> What is a pothole is known as?</P>
              <p class=" text-left">A pothole is either a circular-shaped or a shallow hole or crack in the street
                surface.</p>

              <P class="fw-bold text-left m-0">Is the hole that is hollow or deep also a pothole?</P>
              <p class=" text-left"> This is considered a cave-in.</p>

            </ng-template> -->
            <span class="d-flex     align-items-center gap-1" >
              <!-- <a class="info-table-bg mb-0 hover-text">
                <img class="" src="../../assets/images/i-icon.svg" height="14px" />
              </a> -->
              <a class="text-primary hover-text ml-2 mb-0 pointer new-btn"
              (click)="gotoNext({redirectTo:'home',viewname:'addressView','serviceDetails':item})"> <i
                  class="fa-solid fa-add"></i>
                New</a></span>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
    <div class="col-md-12 overflow-hidden" [style.display]="showlist ? 'block' : 'none'">

      <div class="row ">
        <div class="col-xxl-3 col-xl-3 col-xs-6 hover-text mb-2" *ngFor="let item of this.newcaseTypeVal">

          <div class="gallery card h-100 mb-0"
            (click)="gotoNext({redirectTo:'home',viewname:'addressView','serviceDetails':item})">
            <div class="gallery-item">
              <div class="d-flex     align-items-center">
                <span class=" ">
                  <!-- <img src="../../assets/images/{{item.imageUrl}}.svg" alt="Gallery Item"> -->

                  <img [src]="'../../assets/images/casetypeImage/'+item.casetype+'.svg'"  width="30px" class="me-2">
                </span>
                <p class="ms-2 mt-0 text-left">{{item.casetypecontent}} </p>
                <i class="fa-solid fa-add text-primary hover-text ms-auto pointer new-btn"></i>
              </div>
              <!-- <div class="d-flex align-items-center py-2">
                <span class="fw-500">Division:</span>
                <small class="ms-2 mt-0 text-left" style="font-weight: normal;">{{item.division.valueen}}</small>
              </div> -->
              <div class="d-flex align-items-center">
                <span class="fw-500">Department:</span>
                <small class="ms-2 mt-0 text-left" style="font-weight: normal;">{{item.department.valueen}}</small>
              </div>
              <!-- <span class=" badge-time ">
                <i class="bi bi-clock-fill"></i>
                Est.time to resolve:
                <strong> {{item.estimatetime}} Days</strong>
              </span> -->


            </div>
          </div>
        </div>
      </div>
    </div>
  </mat-tab>
  <!-- <mat-tab  label="My Service Requests">
    <ng-template mat-tab-label>
      <img src="../assets/images/MySR.svg" alt="My Service Requests" height="20px" class="me-2">
      My Service Requests
    </ng-template>
    <div class="container" *ngIf="!logInfo">
      <div class="row justify-content-center">


              <p class="warn-to-login" >To view your Service Request please

                <a class="login-mysr"  [routerLink]="['/login']">
                  <i class="bi bi-person-fill"></i> Login</a>

              </p>


      </div>
    </div>
    <div *ngIf="logInfo">
    <div class="box-btn">
      <button class="btn-action" [class.active-b]="mysrlist" (click)="togglemysrListView()">
        <i class="bi bi-list-task"></i>
      </button>
      <button class="btn-action" [class.active-b]="!mysrlist" (click)="togglemysrListView()">
        <i class="bi bi-grid-3x3-gap-fill"></i>
      </button>
    </div>

<div style=" height: 100%; background: white; margin-top: 40px; border-radius: 20px;">
    <div class=" query table-cont query-table query-table-common" >

    <table class="scroll order-column display  mt-2 mb-0 bg-white" id="myservicerequest" *ngIf="mysrlist">
      <thead>
        <tr>
          <th>#</th>
          <th>SR ID</th>
          <th>SR Type</th>
          <th>Department</th>
          <th>SR Reported Date</th>
          <th>Status</th>
          <th>Service Address</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of myservicerequest;let i=index" class="table-row">
          <td>{{i+1}}</td>
          <td><a [routerLink]="['/track-sr/ref_no', item.refno]" class="text-decoration-underline">{{ item.refno }}</a></td>
          <td><img [src]="'../../assets/images/casetypeImage/'+item.casetypeid+'.png'" width="16px"> {{ item.casetype }}</td>
          <td>{{ item.department }} </td>
          <td>{{ formatDate(item.createdtime)}}</td>
          <td>
            <span class="status-badge">
            <i class="bi bi-circle-fill text-danger" style="font-size: 10px; margin-right: 10px;"> </i>
          {{ item.incidentstatus}} </span>
        </td>
          <td>{{ item.addressline1}}</td>
          <td><button type="button" class="btn btn-light-track" [routerLink]="['/track-sr/ref_no', item.refno]">
            <img src="../../assets/images/expand-icon.png">  </button>
          </td>
        </tr>
      </tbody>
    </table>
    </div>
  </div>
    <div class="col-md-12 overflow-hidden" *ngIf="!mysrlist">
      <div class="row">
        <div class="col-xxl-3 col-xl-3 col-xs-6 hover-text" *ngFor="let item of myservicerequest">
          <div class="gallery card" [routerLink]="['/track-sr/ref_no', item.refno]"
            >
            <div class="gallery-item">
              <div class="d-flex     align-items-center">
                <span class=" ">

                  <td><img [src]="'../../assets/images/casetypeImage/'+item.casetypeid+'.png'" width="30px"> {{ item.casetype }}</td>
                </span>
                <p class="ms-2 mt-0 text-left"><a [routerLink]="['/track-sr/ref_no', item.refno]" class="text-decoration-underline">{{item.refno}} </a></p>
                <i class="fa-solid fa-add text-primary hover-text ms-auto pointer new-btn"></i>
              </div>
              <div class="d-flex align-items-center py-2">
                <strong>Department:</strong>
                <p class="ms-2 mt-0 text-left" style="font-weight: normal;">{{item.department}}</p>
              </div>
              <div class="d-flex align-items-center py-2">
                <strong>Status:</strong>
                <p class="ms-2 mt-0 text-left" style="font-weight: normal;">{{item.incidentstatus}}</p>
              </div>
              <div class="d-flex align-items-center py-2">
                <strong>Address:</strong>
                <p class="ms-2 mt-0 text-left" style="font-weight: normal;">{{item.addressline1}}</p>
              </div>
              <span class=" badge-time ">
                <i class="bi bi-clock-fill"></i>
                Date Created:

                <strong>{{ formatDate(item.createdtime)}}</strong>
              </span>


            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  </mat-tab> -->
</mat-tab-group>

<section class="services mt-2  px-4 bg-transparent">
  <!-- <h3 class=" p-2 mt-3 mb-0 px-0" >To report an issue</h3> -->
  <div class="p-2  mb-2   pb-4 px-0">
    <div class="d-flex report-issue bg-white card-p">
      <div class="p-2 flex-fill ">
        <h4 class="text-center">Phone</h4>
        <h3 class="text-center mb-0"><a href="tel:2604278311" target="_self">311</a> or <a href="tel:2604278311" target="_self">(260) 427-8311</a></h3>
      </div>

      <div class="p-2 flex-fill ">
        <h4 class="text-center">Youtube</h4>
        <h3 class="text-center mb-0"><a href="https://www.youtube.com/@FortWayneIND/videos" class="text-primary"
           >@FortWayneIND</a></h3>
      </div>

      <div class="p-2 flex-fill ">
        <h4 class="text-center">Facebook</h4>
        <h3 class="text-center mb-0"><a href="https://www.facebook.com/CityofFortWayne/" class="text-primary"
            >@CityofFortWayne</a></h3>
      </div>

      <div class="p-2 flex-fill border-0 ">
        <h4 class="text-center">Twitter</h4>
        <h3 class="text-center mb-0"><a href="https://twitter.com/CityofFortWayne" class="text-primary"
            >@CityofFortWayne</a></h3>
      </div>


    </div>

  </div>
</section>
