import { HttpClient, HttpParams } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import{environment} from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class KnowledgebaseService {


  private functionCallSource = new BehaviorSubject<any>({});
  functionCall$ = this.functionCallSource.asObservable();

  opentab(data: any) {
    console.log(data)
    this.functionCallSource.next(data);
  }


  incidents=environment.backendurl;
  searchterm:any;
  constructor(private httpClient:HttpClient) { }
  getallfaq(page:any,size:any): Observable<any> {
    const params = new HttpParams()
    .set('page', page)
    .set('size',size)
    return  this.httpClient.get(this.incidents + "getAllAnswersToCustomers",{params:params})
  }
  getallanswers(answerid:any){
    console.log(answerid)
    const params = new HttpParams()
      .set('answerid', answerid)
    return  this.httpClient.get(this.incidents+ "allAnswerById", { params: params })
  }
  getallkeyword(keyword:any,page:any,size:any)
  {
    const params = new HttpParams()
    .set('keyword', keyword)
    .set('page', page)
    .set('size',size)
  return  this.httpClient.get(this.incidents+ "allAnswersByKeyword", { params: params })
  }
   likeArticle(answerFeedbackYes:any) {
    console.log(answerFeedbackYes)
    return this.httpClient.post(`${this.incidents}answerFeedbackYes`, answerFeedbackYes);  //not using
  }
   dislikeArticle(answerFeedbackNo:any) {
    return this.httpClient.post(`${this.incidents}answerFeedbackNo`, answerFeedbackNo);  //not using
  }
  // getallautosuggest(suggestion:any):Observable<any>{
  //   console.log(suggestion)
  //   const params = new HttpParams()
  //   .set('letter', suggestion)
  //   return  this.httpClient.get(this.incidents+ "autoSuggest", { params: params })
  // }
  getpopularfaq(page:any,size:any):Observable<any>
  {
    const params = new HttpParams()
    .set('page', page)
    .set('size',size)
    return  this.httpClient.get(this.incidents + "popularAnswers",{params:params})
  }
  getrecentanswers():Observable<any>
  {

    return  this.httpClient.get(this.incidents + "RecentAnswers")
  }

  getquicklinks(answerid:any):Observable<any>
  {
    console.log(answerid)
    const params = new HttpParams()
      .set('answerid', answerid)
    return  this.httpClient.get(this.incidents+ "quicklinks", { params: params })
  }
  // getallsearchfilter(suggestion:any,page:any,size:any):Observable<any>{
  //   console.log(suggestion)
  //   const params = new HttpParams()
  //   .set('letter', suggestion)
  //   .set('page', page)
  //   .set('size',size)
  //   return  this.httpClient.get(environment.apiURL1+ "research", { params: params })
  // }
  getrecentsearch():Observable<any>{

    return  this.httpClient.get(this.incidents+ "recentsearch")
  }
  sendmail(params:any):Observable<any>
  {
    return this.httpClient.post(this.incidents+`sendMail`, params);//not using

  }
  private searchQuerySubject: BehaviorSubject<{ query: string, selectedDepartment: any, selectedCategory: any }> = new BehaviorSubject({ query: '', selectedDepartment: '', selectedCategory: '' });
  searchQuery$: Observable<{ query: string, selectedDepartment: any, selectedCategory: any }> = this.searchQuerySubject.asObservable();
  // searchQuery$:any
  updateSearchQuery(query: string, selectedDepartment: string, selectedCategory: string): void {
  this.searchQuerySubject.next({ query, selectedDepartment, selectedCategory });
  }
}
