import {AfterViewInit, Component, ViewChild, Output, EventEmitter} from '@angular/core';
import {MatPaginator, MatPaginatorModule} from '@angular/material/paginator';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';

@Component({
  selector: 'app-my-service-request',
  templateUrl: './my-service-request.component.html',
  styleUrls: ['./my-service-request.component.scss']
})
export class MyServiceRequestComponent implements AfterViewInit{
  userInfo: any = localStorage.getItem("userInfo");
  userListSubmit: any = localStorage.getItem("userListSubmit");

  @Output() serviceTypeDetailsEvent=new EventEmitter();
  gotoNext(data:object){
    this.serviceTypeDetailsEvent.emit(data)
  }
  dataSource: MatTableDataSource<any>;
  displayedColumns: string[] = ['id',  'srID', 'department', 'content', 'srReportDate', 'srStatus','serviceAddress'];

  
  searchTerm:string='';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  constructor() {
    let loginInfo:any =localStorage.getItem('loginUserInfoDetails');
    let jsonData =  (this.userListSubmit && loginInfo)? JSON.parse(this.userListSubmit):[]
    //let jsonData =  [{id:1,srID:1,srStatus:'Unresolved'}]
    //localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
    console.log("page");
    if(loginInfo){
      loginInfo=JSON.parse(loginInfo);
      // item.isLoginInfo &&
      jsonData = jsonData.filter((item: any) => item.addressDetails.firstname.toLowerCase() == loginInfo.firstname.toLowerCase());
    }
    this.dataSource = new MatTableDataSource(jsonData);
    // Create 100 users
    // const users = Array.from({length: 100}, (_, k) => createNewUser(k + 1));
    
    // Assign the data to the data source for the table to render
  }
  ngOnInit(){

  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
