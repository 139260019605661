/// <reference types="arcgis-js-api" />
/*
  Copyright 2020 Esri
  Licensed under the Apache License, Version 2.0 (the "License");
  you may not use this file except in compliance with the License.
  You may obtain a copy of the License at
    http://www.apache.org/licenses/LICENSE-2.0
  Unless required by applicable law or agreed to in writing, software
  distributed under the License is distributed on an "AS IS" BASIS,
  WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
  See the License for the specific language governing permissions and
  limitations under the License.
*/

import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  OnDestroy,
} from '@angular/core';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
import esri = __esri;
import { add } from 'esri/views/3d/externalRenderers';
import { HttpClient } from '@angular/common/http';
import { makeServiceRequestService } from '../make-service-request.service';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { take } from 'rxjs/operators';


declare var $: any;

@Component({
  selector: 'app-esri-map',
  templateUrl: './esri-map.component.html',
  styleUrls: ['./esri-map.component.css'],
})


export class EsriMapComponent implements OnInit, OnDestroy {
  searchAddress!: string;
  latitude!: number;
  longitude!: number;
  @Output() mapLoaded = new EventEmitter<boolean>();
  @Input()  searchLocation:any;
  @Input() serviceTypeInfo:any;
  @Output() childComponentValue = new EventEmitter<any>();
  @ViewChild('mapViewNode', { static: true })

  private mapViewEl!: ElementRef;

  /**
   * @private _zoom sets map zoom
   * @private _center sets map center
   * @private _basemap sets type of map
   */
  private _zoom: number = 19;
  private _center: Array<number> = [0.1278, 51.5074];
  private _basemap: string = 'streets-vector';
  EsriMapView: any;

  @Input()
  set zoom(zoom: number) {
      this._zoom = zoom;
  }

  get zoom(): number {
      return this._zoom;
  }

  @Input()
  set center(center: Array<number>) {
      this._center = center;
  }

  get center(): Array<number> {
      return this._center;
  }

  @Input()
  set basemap(basemap: string) {
      this._basemap = basemap;
  }

  get basemap(): string {
      return this._basemap;
  }

  constructor(private http: HttpClient,private agentscriptservice:makeServiceRequestService) { }

  async initializeMap() {

    console.log($('[data-basemap-id]').attr('data-basemap-id'))

    console.log("in initialize")
    try {
        const [EsriMap, EsriMapView, Search, BasemapToggle,Locate, Graphic] = await loadModules([
            'esri/Map',
            'esri/views/MapView',
            'esri/widgets/Search',
            'esri/widgets/BasemapToggle',
            'esri/widgets/Locate',
            'esri/Graphic'
        ]);

        const mapProperties: esri.MapProperties = {
            basemap: this.selectedmap=='streets-vector'?'satellite':'streets-vector',
        };
        const map: esri.Map = new EsriMap(mapProperties);

        const mapViewProperties: esri.MapViewProperties = {
            container: this.mapViewEl.nativeElement,
            center: this._center, // Center coordinates
            zoom: this._zoom,
            map: map,
        };
        const mapView: esri.MapView = new EsriMapView(mapViewProperties);

        var search = new Search({
          view: mapView,
          searchTerm: this.searchLocation?.text?this.searchLocation?.text:'',
      });

      console.log("in toggle")

      const toggle = new BasemapToggle({
        view: mapView,
        nextBasemap: this.selectedmap=='satellite'?'satellite':'streets-vector',

    });


        mapView.ui.add(toggle, {
            position: "bottom-right"
        });

        // mapView.ui.add(search, 'top-right');
        if (this.searchLocation) {
          // search.search(this.searchLocation);
      }
      search.on('search-complete', (e: any) => {
        if (e && e.results.length > 0 && e.results[0].results.length > 0) {
          console.log(e);

            const res = e.results[0].results[0];
            console.log
            const zipCode = res.name.split(',').pop().trim();
            let coords: any = {};
            if (res.name.includes('AND') || res.name.includes('and') || res.name.includes('&')) {
                const address = res.name.split(/AND|and|&/).map((address: any) => address.trim());
                const address2 = address[1].split(',').map((address2: any) => address2.trim());
                coords = {
                    msr_lng: res.feature.geometry.longitude,
                    msr_lat: res.feature.geometry.latitude,
                    address: address[0],
                    address2: address2[0] || '',
                    msr_city: address2[1] || '',
                    msr_state: address2[2] || '',
                    msr_zip: zipCode || '',
                    address_t: 'INTERSECTION'
                };
            } else {
                const address = res.name.split(',').map((address: any) => address.trim());
                coords = {
                    msr_lng: res.feature.geometry.longitude,
                    msr_lat: res.feature.geometry.latitude,
                    address: address[0],
                    address2: '',
                    msr_city: address[1] || '',
                    msr_state: address[2] || '',
                    msr_zip: zipCode || '',
                    address_t: 'SINGLE_LINE'
                };
            }
            // Emit the coordinates to the parent component
            // this.childComponentValue.emit(coords);
        }
    });
  const locateWidget = new Locate({
            view: mapView,
            graphic: "circle",
            useHeadingEnabled: false,
            goToLocationEnabled: true
        });
        mapView.when(() => {
          // Create a PictureMarkerSymbol with your custom image
          const customSymbol = {
              type: "picture-marker",
              url: "../../assets/images/map-pin-red.svg",
              width: "18px",
              height: "27px"
          };


          const customGraphic = new Graphic({
              geometry: mapView.center,
              symbol: customSymbol
          });

          // Set the graphic of the Locate widget to the custom graphic
          locateWidget.graphic = customGraphic;
      });
        mapView.ui.add(locateWidget, {
            position: "top-left"
        });
        // Wait for the map to load
        await mapView.when();

        // Get user's current position


        mapView.when(() => {
          this.mapLoaded.emit(true);
          setTimeout(() => {
              search.emit('search-complete');
          }, 5000);
      });
    } catch (error) {
        alert('We have an error: ' + error);
    }
}

alertShown = false;
dataSubscription:any
  ngOnInit() {
    this.alertShown=false;
    this.dataSubscription =this.agentscriptservice.addressEmitter.pipe(
      take(1)
    ).subscribe((address:any) => {

    this.selectedmap=$('[data-basemap-id]').attr('data-basemap-id')

        // Update the searchLocation property and initialize the map
        this.searchLocation = address;
        console.log(this.searchLocation)
        this.addressvalue=this.searchLocation;
        console.log(this.selectedmap)
        // this.selectedmap=this.selectedmap
        // this.initializeMap();
        // this.geocodeAndCenter(this.addressvalue)
        var streetAddress = this.addressvalue.split(',')[0].trim();
        this.pointlocationaddress(streetAddress)
        console.log(this.queryStringAddress)
        // this.dataSubscription.unsubscribe();
        this.ngOnInit()
    });
    this.searchLocation=this.agentscriptservice.searchAddress;
    this.initializeMap();

}

ngOnDestroy(): void {
  if (this.dataSubscription) {
    this.dataSubscription.unsubscribe();
  }
}
ngOnChanges(changes: SimpleChanges) {
    if (changes['searchLocation'] && !changes['searchLocation'].firstChange) {
        this.initializeMap();
    }
}
queryStringAddress:any;
searchAddress1(queryStringAddress: string, result: any) {
  console.log("in search address1")

this.queryStringAddress=queryStringAddress;
  this.http.get<any>("https://gis.acimap.us/311/rest/services/locator/AddressLocator/GeocodeServer/findAddressCandidates?Street=&Single+Line+Input=" + this.queryStringAddress + "&outFields=*&maxLocations=&outSR=4326&searchExtent=&f=pjson").subscribe((response) => {
    if (response && response.candidates && response.candidates.length > 0) {
      const candidate = response.candidates[0];
      console.log(candidate)

      const coords = {
        msr_lng: candidate.location.x,
        msr_lat: candidate.location.y,
        address: candidate.address,
        msr_city: candidate.attributes.City,
        msr_state: candidate.attributes.State,
        msr_zip: candidate.attributes.ZIP,
        address_t: candidate.attributes.Addr_type
      };

      this.childComponentValue.emit(coords);
    } else if(!this.alertShown)
      {
        this.alertShown = true;
      const coords = {
        msr_lng:'',
        msr_lat: '',
        address: '',
        msr_city: '',
        msr_state: '',
        msr_zip: '',
        address_t: ''
      };

      this.childComponentValue.emit(coords);
      // Swal.fire({
      //   title: '<div class="popup-content"><img src="../../assets/images/fort-wayne-logo-seal-dark.svg" alt="Selected Image" class="selected-image"><div class="popup-text"><img src="../../assets/images/warning.svg" class="animated-check"> Given address is either not in Fort Wayne City limits or not a valid address</div></div>',
      //   icon: 'error',
      //   showCloseButton: true,
      //   showCancelButton: false,
      //   confirmButtonText: 'OK',
      // })
      this.invalidaddressswal()
      console.log(this.addressvalue)
      this.addressvalue='';

      this.initializeMap();

    }
  });
}
showDropdown:boolean=false;
addressSuggestions:any;
onSearch() {

//   this.isLocationSearch = true;
// this.isGisVerified = true;
//   this.searchAddress=this.addressDetailsForm.value.locationDtls.addressline1;
//   this.searchAddress1=this.addressDetailsForm.value.locationDtls.addressline2;
//   console.log(this.addressDetailsForm.value.locationDtls.addresstype)
//   this.agentscriptservice.emitAddress(this.searchAddress);
//  if(this.addressDetailsForm.value.locationDtls.addresstype == "1362"){
//    this.agentscriptservice.emitAddress(this.searchAddress);
//   }else{
//     var concatenateAddress= this.searchAddress + ' & ' + this.searchAddress1
//     this.agentscriptservice.emitAddress(concatenateAddress);

//   }
}

fetchaddressdetails:any;
private geocodedLocation: Array<number> | null = null;
async geocodeAndCenter(address: any) {
  this.alertShown=false
  console.log(address)
  if (!address) {
    console.log("it is not addrss")

this.initializeMap();
    return;
  }
  console.log("loadmap address", address);
  console.log(address);
  var streetAddress = address.split(',')[0].trim();
  console.log(streetAddress);
  if(streetAddress.includes('&') || streetAddress.includes('and'))
  {
    console.log("presence of and / || detected")
    this.agentscriptservice.streetlocator(streetAddress).subscribe(async (response) => {
      console.log(streetAddress);
      console.log(response.candidates);
      if (response && response.candidates && response.candidates.length > 0) {
        const candidate = response.candidates[0];
        const location = candidate.location;
        console.log(location);
        if (location && location.x && location.y) {
          const [EsriMap, EsriMapView, Graphic,GraphicsLayer, PopupTemplate,BasemapToggle] = await loadModules([
            'esri/Map',
            'esri/views/MapView',
            'esri/Graphic',
            "esri/layers/GraphicsLayer",
            'esri/PopupTemplate',
            'esri/widgets/BasemapToggle',

          ]);
          const returnedAddress = this.agentscriptservice.locationcoordinates.returned_address;
          const mailingAddress = this.agentscriptservice.locationcoordinates.personal_data.mailing_address_1.replace(/\d+/g, '');
          // Create a popup template for the address

          const popupTemplate = new PopupTemplate({
            title: "Location",
            content: function() {
              return `<b>${returnedAddress}</b>`;
            }
          });
          // Create a popup template for the address
          // const popupTemplate = new PopupTemplate({

          //   title: "Location",
          //   content: [{
          //     type: "fields",
          //     fieldInfos: [
          //       // {
          //       //   fieldName: "deedowner",
          //       //   label: "Deed Owner"
          //       // },
          //       {
          //         fieldName: "mailing",
          //         label: "Mailing Address"
          //       },
          //       // {
          //       //   fieldName: "township",
          //       //   label: "Township"
          //       // },
          //       // {
          //       //   fieldName: "muncipality",
          //       //   label: "Muncipality"
          //       // },
          //       // {
          //       //   fieldName: "subdivision",
          //       //   label: "Subdivision"
          //       // },
          //       // {
          //       //   fieldName: "propertyAddress",
          //       //   label: "Property Address"
          //       // },
          //       // {
          //       //   fieldName: "propertyClass",
          //       //   label: "Property Class"
          //       // },
          //       // {
          //       //   fieldName: "parcelNumber",
          //       //   label: "Parcel Number"
          //       // }
          //     ]
          //   }
          // ]
          // });

          const graphicsLayer = new GraphicsLayer();

            const markerSymbol = {
              type: 'picture-marker',
              url: '../../assets/images/map-pin-red.svg',
              width: "18px",
              height: "27px"
            };

          console.log(this.selectedmap)
          const map = new EsriMap({
            // basemap: this.selectedmap=='satellite'?'streets-vector':'satellite',

            basemap: this.selectedmap=='satellite'?'streets-vector':'satellite',
          });

          console.log(map)

          // console.log(this.agentscriptservice.locationcoordinates)

          const view = new EsriMapView({
            container: this.mapViewEl.nativeElement,
            center: [location.x, location.y],
            zoom: 19, // Adjust the zoom level as needed
            map: map
          });

          // Create a popup template for the address
          // const popupTemplate = new PopupTemplate({
          //   title: "Location",
          //   content: streetAddress // You can customize the content of the popup as needed
          // });
          // const markerSymbol = {
          //   type: 'picture-marker',
          //   url: '../../assets/images/map-pin-red.svg',
          //   width: '20px',
          //   height: '30px'
          // };
          // Create a graphic (marker) at the geocoded location
          const markerGraphic = new Graphic({
            geometry: {
              type: 'point',
              longitude: location.x,
              latitude: location.y
            }
            ,
            attributes: {
              deedowner:    this.agentscriptservice.locationcoordinates.personal_data.deed_owner_1,
              mailing:      this.agentscriptservice.locationcoordinates.personal_data.mailing_address_1,
              township:     this.agentscriptservice.locationcoordinates.personal_data.township,
              muncipality:  this.agentscriptservice.locationcoordinates.personal_data.muncipality,
              subdivision:      this.agentscriptservice.locationcoordinates.personal_data.subdivision,
              propertyAddress: this.agentscriptservice.locationcoordinates.personal_data.property_address,
              propertyClass: this.agentscriptservice.locationcoordinates.personal_data.property_class,
              parcelNumber:  this.agentscriptservice.locationcoordinates.personal_data.parcel_number,

            },
            // attributes: {
            //   deedowner:    "hehe",
            //   mailing:      "hehe",
            //   township:     "hehe",
            //   muncipality:  "hehe",
            //   subdivision:      "hehe",
            //   propertyAddress: "hehe",
            //   propertyClass: "hehe",
            //   parcelNumber:  "hehe",
            // },
            symbol: markerSymbol,
            popupTemplate: popupTemplate
          });

          const toggle = new BasemapToggle({
            view: view,
            nextBasemap: this.selectedmap=='satellite'?'satellite':'streets-vector'
        });


        view.ui.add(toggle, {
            position: "bottom-right"
        });
          // Add the marker graphic to the map
          view.graphics.add(markerGraphic);
          // .subscribe((data:any)=>{
          //   console.log(data)
          // })
          // Show the popup for the marker graphic by default
          view.popup.open({
            features: [markerGraphic],
            location: markerGraphic.geometry
          });

        }
      }
      else if(response.candidates.length == 0 && !this.alertShown)
        {
          this.alertShown = true;
        // Swal.fire({
        //   title: '<div class="popup-content"><img src="../../assets/images/fort-wayne-logo-seal-dark.svg" alt="Selected Image" class="selected-image"><div class="popup-text"><img src="../../assets/images/warning.svg" class="animated-check"> Given address is either not in Fort Wayne City limits or not a valid address</div></div>',
        //   icon: 'error',
        //   showCloseButton: true,
        //   showCancelButton: false,
        //   confirmButtonText: 'OK',
        // })
      this.invalidaddressswal()

        // streetAddress=streetAddress+''
        this.addressvalue='';
        this.initializeMap();
      }
    },

    (error) => {
      console.error('Error geocoding address:', error);
    });
  }
  else if (streetAddress) {
    console.log("in else"+streetAddress)
    this.agentscriptservice.addresslocator(streetAddress).subscribe(async (response) => {
      // alert()
      console.log(response)
      console.log(streetAddress);
      console.log(response.candidates.length);
      if (response && response.candidates && response.candidates.length>0) {
        const candidate = response.candidates[0];
        const location = candidate.location;
        console.log(location);
        if (location && location.x && location.y) {
          const [EsriMap, EsriMapView, Graphic,GraphicsLayer, PopupTemplate,BasemapToggle] = await loadModules([
            'esri/Map',
            'esri/views/MapView',
            'esri/Graphic',
            "esri/layers/GraphicsLayer",
            'esri/PopupTemplate',
            'esri/widgets/BasemapToggle',

          ]);

          console.log(this.agentscriptservice.locationcoordinates.returned_address)
          const returnedAddress = this.agentscriptservice.locationcoordinates.returned_address;
          const mailingAddress = this.agentscriptservice.locationcoordinates.personal_data.mailing_address_1.replace(/\d+/g, '');
          // Create a popup template for the address

          const popupTemplate = new PopupTemplate({
            title: "Location",
            content: function() {
              return `<b>${returnedAddress}</b>`;
            }
          });

          const graphicsLayer = new GraphicsLayer();

            const markerSymbol = {
              type: 'picture-marker',
              url: '../../assets/images/map-pin-red.svg',
              width: "18px",
              height: "27px"
            };

          console.log(this.selectedmap)
          const map = new EsriMap({
            // basemap: this.selectedmap=='satellite'?'streets-vector':'satellite',

            basemap: this.selectedmap=='satellite'?'streets-vector':'satellite',
          });

          console.log(map)

          // console.log(this.agentscriptservice.locationcoordinates)

          const view = new EsriMapView({
            container: this.mapViewEl.nativeElement,
            center: [location.x, location.y],
            zoom: 19, // Adjust the zoom level as needed
            map: map
          });

          // Create a popup template for the address
          // const popupTemplate = new PopupTemplate({
          //   title: "Location",
          //   content: streetAddress // You can customize the content of the popup as needed
          // });
          // const markerSymbol = {
          //   type: 'picture-marker',
          //   url: '../../assets/images/map-pin-red.svg',
          //   width: '20px',
          //   height: '30px'
          // };
          // Create a graphic (marker) at the geocoded location
          const markerGraphic = new Graphic({
            geometry: {
              type: 'point',
              longitude: location.x,
              latitude: location.y
            }
            ,
            attributes: {
              deedowner:    this.agentscriptservice.locationcoordinates.personal_data.deed_owner_1,
              mailing:      this.agentscriptservice.locationcoordinates.personal_data.mailing_address_1,
              township:     this.agentscriptservice.locationcoordinates.personal_data.township,
              muncipality:  this.agentscriptservice.locationcoordinates.personal_data.muncipality,
              subdivision:      this.agentscriptservice.locationcoordinates.personal_data.subdivision,
              propertyAddress: this.agentscriptservice.locationcoordinates.personal_data.property_address,
              propertyClass: this.agentscriptservice.locationcoordinates.personal_data.property_class,
              parcelNumber:  this.agentscriptservice.locationcoordinates.personal_data.parcel_number,

            },
            // attributes: {
            //   deedowner:    "hehe",
            //   mailing:      "hehe",
            //   township:     "hehe",
            //   muncipality:  "hehe",
            //   subdivision:      "hehe",
            //   propertyAddress: "hehe",
            //   propertyClass: "hehe",
            //   parcelNumber:  "hehe",
            // },
            symbol: markerSymbol,
            popupTemplate: popupTemplate
          });

          const toggle = new BasemapToggle({
            view: view,
            nextBasemap: this.selectedmap=='satellite'?'satellite':'streets-vector'
        });


        view.ui.add(toggle, {
            position: "bottom-right"
        });
          // Add the marker graphic to the map
          view.graphics.add(markerGraphic);
          // .subscribe((data:any)=>{
          //   console.log(data)
          // })
          // Show the popup for the marker graphic by default
          view.popup.open({
            features: [markerGraphic],
            location: markerGraphic.geometry
          });

        }
      }

      else if(response.candidates == 0 && !this.alertShown)
      {
        this.alertShown = true;
        const coords = {
          msr_lng:'',
          msr_lat: '',
          address: '',
          msr_city: '',
          msr_state: '',
          msr_zip: '',
          address_t: ''
        };
        // Swal.fire({
        //   title: '<div class="popup-content"><img src="../../assets/images/fort-wayne-logo-seal-dark.svg" alt="Selected Image" class="selected-image"><div class="popup-text"><img src="../../assets/images/warning.svg" class="animated-check"> Given address is either not in Fort Wayne City limits or not a valid address</div></div>',
        //   icon: 'error',
        //   showCloseButton: true,
        //   showCancelButton: false,
        //   confirmButtonText: 'OK',
        // })
      this.invalidaddressswal()

        this.childComponentValue.emit(coords);
        // alert("please enter valid address")

        console.log(this.addressvalue)
        this.addressvalue='';
        this.initializeMap();
      }
    },

    (error) => {
      console.error('Error geocoding address:', error);
    });
  }
}

// 0-streets-vector 1-satellite
// selectedmap:any
// togglemap:any
selectedmap='streets-vector'
assignAddress(item:any)
{
  this.addressSuggestions=[]
  this.selectedmap=$('[data-basemap-id]').attr('data-basemap-id')
  // this.togglemap=
  // console.log(this.selectedmap)
  // console.log($('[data-basemap-id]').attr('data-basemap-id'))
  this.addressvalue=item
  console.log(this.addressvalue)
  // this.suggestionSearch()
  var streetAddress = this.addressvalue.split(',')[0].trim()
  this.pointlocationaddress(streetAddress)
  // this.geocodeAndCenter(this.addressvalue)
}
casetypeid:any;

invalidaddressswal()
{
  const coords = {
    msr_lng:'',
    msr_lat: '',
    address: '',
    msr_city: '',
    msr_state: '',
    msr_zip: '',
    address_t: ''
  };
  this.childComponentValue.emit(coords);
  this.addressvalue=''
  Swal.fire({
    title: '<div class="popup-content"> <div class="popup-text"><img src="../../assets/images/warning.svg" class="animated-check"> Given address is either not in Fort Wayne City limits or not a valid address</div></div>',
    imageUrl: "https://fortwayne.thesmartcity311.com/assets/images/fort-wayne-logo-seal-dark.svg",
    imageHeight: 45,
    icon: 'error',
    showCloseButton: true,
    showCancelButton: false,
    confirmButtonText: 'OK',
  })
}
pointlocationaddress(streetAddress:any)
{
console.log(this.serviceTypeInfo)
  // var streetAddress = item.split(',')[0].trim();
  this.agentscriptservice.fetchaddressdetails(streetAddress,this.serviceTypeInfo.serviceDetails.casetypeid,'Address').subscribe((data:any)=>{
    this.fetchaddressdetails=data
    if(data?.status == "NOTOK")
      {
       this.invalidaddressswal()
      }
      else
      {
        console.log(this.fetchaddressdetails)

    this.agentscriptservice.locationcoordinates=this.fetchaddressdetails

    const coords = {
      msr_addresstype:this.fetchaddressdetails?.address_type,
      msr_serviceaddress:this.fetchaddressdetails?.returned_address,
      msr_city: this.fetchaddressdetails?.personal_data?.property_address_city,
      msr_state: this.fetchaddressdetails?.personal_data?.mailing_state,
      msr_zip: this.fetchaddressdetails?.address_details?.zip,
      msr_withinlimits: this.fetchaddressdetails?.address_details?.within_limits,
      msr_recycleweek:this.fetchaddressdetails?.address_details?.recycle_week_ID,
      msr_collectionday:this.fetchaddressdetails?.address_details?.collection_day,
      msr_jurisdiction:this.fetchaddressdetails?.address_details?.jurisdiction,
      msr_zoning:this.fetchaddressdetails?.address_details?.zoning_id,
      msr_lat: this.fetchaddressdetails?.lat,
      msr_lng: this.fetchaddressdetails?.lng,
      msr_parcelnumber: this.fetchaddressdetails?.personal_data?.parcel_number,
      msr_nearbyIncidents:this.fetchaddressdetails?.nearby_incidents,
      msr_next_collection_day:this.fetchaddressdetails?.address_details?.next_collection_day,
      msr_next_collection_date:this.fetchaddressdetails?.address_details?.next_collection_day_date
      // msr_parcelnumber: this.fetchaddressdetails.personal_data.parcel_number,
    };
    this.addressvalue=this.fetchaddressdetails?.returned_address
    console.log(coords)

    // this.agentscriptservice.isGisVerified=true;
    this.childComponentValue.emit(coords);


    this.geocodeAndCenter(streetAddress)
      }


  })
}

addressvalue:any;
onDropdownChange() {
  console.log(this.addressvalue)
// this.geocodeAndCenter(this.addressvalue)
}

suggestionSearch(){
  console.log(this.addressSuggestions?.length)
  console.log(this.addressvalue);

  if(this.addressvalue=='')
      {
        this.addressSuggestions=[]
      }

   this.performAddressSearch(this.addressvalue);
}

// address:any;
// onDropdownChange(selectedValue: any) {
//   this.address = selectedValue;
//   console.log(this.address)
// this.geocodeAndCenter(this.address)
// }

// suggestionSearch(event:any){
//   console.log(event.term);

//    this.performAddressSearch(event.term);
// }




isClearable(): boolean {
  // this.initializeMap()
  return !!this.searchAddress;
}


async performAddressSearch(searchTerm: string) {
  try {
      // Trim the search term to remove any leading or trailing spaces
      var trimmedSearchTerm = searchTerm.trim();
      console.log("trimmedSearchTerm "+trimmedSearchTerm);

      if(trimmedSearchTerm.includes('&'))
      {
        console.log("& detected")
        let rep1 = "and"
        trimmedSearchTerm.replace("&", rep1)
      }
      console.log("After manipulation"+trimmedSearchTerm)

      if (trimmedSearchTerm !== '') { // Check if search term is not empty
          this.agentscriptservice.mapsuggestions(trimmedSearchTerm).subscribe((response: any) => {
              console.log(response.suggestions);
              this.addressSuggestions = response.suggestions.map((suggestion: { text: any; }) => suggestion.text);
              console.log(this.addressSuggestions);
          });
      } else {
          // Clear suggestions if search term is empty
          this.addressSuggestions = [];
      }
  } catch (error) {
      console.error('Error fetching address suggestions:', error);
  }
}



}
