<div class="px-2 mb-4 new-sr">
  <div class="stepper hide-mobile border-radius-20 bg-white mx-2 px-3 py-2 mt-2 mb-3">
    <!-- <div class="d-flex     align-items-center mb-2">
      <button type="button" value="prv-btn" class="btn btn-outline-primary btn-sm border-0 "  [routerLink]="['/']">
        <i class="fa fa-arrow-left fw-bold me-3"></i>
      </button>
         <h3 class="mb-0">New Service Request (SR):</h3>
        </div> -->

    <div class="my-2 p-1 ol justify-content-between">
      <div class='active li'>

        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
          <path
            d="M8.49772 12.5423C10.4537 12.5423 12.0394 10.9567 12.0394 9.00065C12.0394 7.04464 10.4537 5.45898 8.49772 5.45898C6.54171 5.45898 4.95605 7.04464 4.95605 9.00065C4.95605 10.9567 6.54171 12.5423 8.49772 12.5423Z"
            fill="#275A8A" />
          <path
            d="M16.2907 8.29167H15.5462C15.2133 4.95046 12.5486 2.28571 9.20736 1.95279V1.20833C9.20736 1.02047 9.13273 0.840304 8.99989 0.707466C8.86705 0.574628 8.68688 0.5 8.49902 0.5C8.31116 0.5 8.13099 0.574628 7.99816 0.707466C7.86532 0.840304 7.79069 1.02047 7.79069 1.20833V1.95279C4.44948 2.28571 1.78473 4.95046 1.45182 8.29167H0.707357C0.519495 8.29167 0.339328 8.36629 0.206489 8.49913C0.0736512 8.63197 -0.000976562 8.81214 -0.000976562 9C-0.000976562 9.18786 0.0736512 9.36803 0.206489 9.50087C0.339328 9.63371 0.519495 9.70833 0.707357 9.70833H1.45182C1.78544 13.0495 4.44948 15.7143 7.79069 16.0472V16.7917C7.79069 16.9795 7.86532 17.1597 7.99816 17.2925C8.13099 17.4254 8.31116 17.5 8.49902 17.5C8.68688 17.5 8.86705 17.4254 8.99989 17.2925C9.13273 17.1597 9.20736 16.9795 9.20736 16.7917V16.0472C12.5486 15.7136 15.2133 13.0495 15.5462 9.70833H16.2907C16.4786 9.70833 16.6587 9.63371 16.7916 9.50087C16.9244 9.36803 16.999 9.18786 16.999 9C16.999 8.81214 16.9244 8.63197 16.7916 8.49913C16.6587 8.36629 16.4786 8.29167 16.2907 8.29167ZM9.19744 14.6192C9.17194 14.2516 8.87373 13.9583 8.49902 13.9583C8.12432 13.9583 7.82611 14.2516 7.80061 14.6192C6.55052 14.4621 5.38833 13.8932 4.49743 13.0023C3.60653 12.1114 3.03764 10.9492 2.88052 9.69913C3.24815 9.67292 3.54069 9.374 3.54069 9C3.54069 8.626 3.24815 8.32708 2.87981 8.30158C3.03693 7.05149 3.60582 5.88931 4.49672 4.99841C5.38762 4.10751 6.54981 3.53862 7.7999 3.3815C7.82611 3.74842 8.12432 4.04167 8.49902 4.04167C8.87373 4.04167 9.17194 3.74842 9.19744 3.38079C10.4475 3.53791 11.6097 4.1068 12.5006 4.9977C13.3915 5.8886 13.9604 7.05079 14.1175 8.30087C13.7499 8.32708 13.4574 8.626 13.4574 9C13.4574 9.374 13.7499 9.67292 14.1182 9.69842C13.9609 10.9486 13.3918 12.1108 12.5008 13.0018C11.6099 13.8928 10.4476 14.4619 9.19744 14.6192Z"
            fill="#275A8A" />
        </svg>
        <h2>1. Select a Service Type</h2>


        <!-- <div class="line"> </div> -->
      </div>
      <div class="li">

        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 29" fill="none">
          <path d="M19.7096 2.80784C18.3893 1.72674 16.8454 0.952135 15.1895 0.539906C13.5335 0.127678 11.8067 0.0880968 10.1336 0.424018C7.98235 0.86507 6.00322 1.91521 4.43196 3.44935C2.8607 4.9835 1.76355 6.93697 1.27121 9.07707C0.778868 11.2172 0.912083 13.4537 1.65499 15.5202C2.39789 17.5868 3.71917 19.3962 5.46143 20.733C7.45061 22.1888 9.14426 24.0103 10.4518 26.1L11.3427 27.5815C11.4559 27.7697 11.6158 27.9254 11.8069 28.0335C11.998 28.1416 12.2139 28.1984 12.4334 28.1984C12.653 28.1984 12.8688 28.1416 13.06 28.0335C13.2511 27.9254 13.411 27.7697 13.5242 27.5815L14.3782 26.1586C15.5168 24.1571 17.0693 22.4215 18.932 21.0677C20.3926 20.0628 21.5997 18.7322 22.458 17.1809C23.3163 15.6297 23.8024 13.9002 23.878 12.1289C23.9535 10.3576 23.6164 8.59299 22.8932 6.97428C22.1701 5.35558 21.0806 3.92708 19.7109 2.80148L19.7096 2.80784ZM12.4322 16.7455C11.4253 16.7455 10.441 16.4469 9.6038 15.8875C8.7666 15.3281 8.11409 14.533 7.72877 13.6028C7.34345 12.6725 7.24263 11.6489 7.43907 10.6614C7.6355 9.67385 8.12036 8.76673 8.83234 8.05476C9.54432 7.34278 10.4514 6.85792 11.439 6.66148C12.4265 6.46505 13.4501 6.56587 14.3804 6.95118C15.3106 7.3365 16.1057 7.98902 16.6651 8.82622C17.2245 9.66341 17.5231 10.6477 17.5231 11.6546C17.5231 13.0048 16.9867 14.2997 16.032 15.2544C15.0773 16.2091 13.7824 16.7455 12.4322 16.7455Z" fill="#275A8A"/>
          </svg>
          <h2>2. Provide Contact & Address Details</h2>

        <!-- <svg xmlns="http://www.w3.org/2000/svg" width="20" height="16" viewBox="0 0 20 16" fill="none">
          <path
            d="M9.88969 6.24547L2.88051 0.723816C2.67911 0.554481 2.43272 0.447513 2.17148 0.415997C1.91025 0.38448 1.64549 0.42978 1.40959 0.546356C1.07381 0.740124 0.798231 1.02307 0.613394 1.36385C0.428558 1.70463 0.34168 2.08993 0.362388 2.47706V13.5194C0.341625 13.9065 0.42848 14.2919 0.613322 14.6327C0.798164 14.9734 1.07377 15.2564 1.40959 15.4501C1.59534 15.5423 1.79962 15.591 2.00697 15.5925C2.32648 15.5884 2.63515 15.476 2.88241 15.2736L9.88969 9.75385C10.1399 9.53667 10.3384 9.26632 10.4706 8.96256C10.6029 8.65881 10.6656 8.32933 10.6541 7.99823C10.6653 7.66759 10.6025 7.33861 10.4702 7.03536C10.338 6.7321 10.1396 6.46223 9.88969 6.24547Z"
            fill="#275A8A" />
          <path
            d="M18.5317 6.24482L11.5235 0.723639C11.322 0.554414 11.0756 0.447517 10.8144 0.416003C10.5532 0.38449 10.2885 0.429723 10.0526 0.54618C9.71619 0.739471 9.44001 1.02225 9.25472 1.3631C9.06944 1.70394 8.98229 2.08949 9.00299 2.47688V13.5192C8.98234 13.9065 9.0693 14.2919 9.25421 14.6327C9.43913 14.9736 9.71479 15.2566 10.0507 15.4504C10.2366 15.5424 10.441 15.5911 10.6485 15.5928C10.9678 15.5885 11.2763 15.4761 11.5235 15.2739L18.5312 9.75083C18.7815 9.53426 18.9801 9.26445 19.1125 8.96117C19.245 8.65788 19.3078 8.32881 19.2966 7.99806C19.3078 7.66729 19.245 7.33819 19.1127 7.03484C18.9803 6.73149 18.7818 6.46156 18.5317 6.24482Z"
            fill="#275A8A" />
        </svg> -->
        <!-- <h2>2. Provide Contact & Address Details</h2> -->


        <!-- <div class="line"> </div> -->
      </div>
      <div class="li">

          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.19753 20.4147C1.87185 22.8401 3.82122 23.3575 6.08615 22.7254C7.66151 22.2857 9.30152 21.3385 10.6363 20.3758C11.6139 19.6708 12.7824 18.9731 13.9408 18.6212C14.9762 18.3067 16.2033 18.2098 16.7197 19.3675C16.9046 19.7823 16.9722 20.2664 16.9904 20.7168C17.0001 20.9565 17.202 21.144 17.442 21.1346C17.6814 21.1252 17.8692 20.923 17.8595 20.6834C17.7536 18.0687 16.2001 17.0273 13.689 17.79C12.4393 18.1697 11.1839 18.9096 10.1291 19.6703C8.87535 20.5745 7.33491 21.4737 5.85561 21.8866C4.69209 22.2113 3.2963 22.3072 2.50744 21.2135C2.2857 20.906 2.13717 20.5473 2.03614 20.1836C1.97193 19.9524 1.73244 19.816 1.50095 19.8799C1.27 19.9438 1.13323 20.1835 1.19753 20.4147ZM16.6978 1.18953C16.9138 0.973497 17.243 0.973142 17.4589 1.18931C17.6749 1.40547 19.1978 2.93009 19.1978 2.93009C19.4136 3.14611 19.4137 3.28839 19.1978 3.50433C18.9818 3.72027 5.028 17.674 5.028 17.674C4.84624 17.8094 2.33891 18.8409 2.05425 18.9582C1.6017 19.1448 1.14624 18.6927 1.3295 18.2388C1.50665 17.8 2.42507 15.5121 2.6102 15.2769L16.6978 1.18953Z"
              fill="#275A8A" stroke="#275A8A" />
          </svg>
          <h2>3. Provide Additional Information</h2>

        <!-- <div class="line"> </div> -->
      </div>

        <div class="li">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 25" fill="none">
            <path d="M15.1435 9.42638L4.06655 0.700245C3.74826 0.432636 3.35888 0.26359 2.94604 0.213782C2.53319 0.163975 2.11478 0.235565 1.74198 0.419797C1.21133 0.726018 0.775821 1.17318 0.483714 1.71173C0.191608 2.25028 0.0543107 2.85919 0.087037 3.47098V20.9218C0.0542249 21.5336 0.191485 22.1425 0.4836 22.6811C0.775715 23.2196 1.21127 23.6668 1.74198 23.9729C2.03553 24.1186 2.35837 24.1955 2.68606 24.1979C3.19099 24.1915 3.6788 24.0139 4.06955 23.694L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7204C16.2706 13.2403 16.3696 12.7196 16.3515 12.1964C16.3692 11.6738 16.27 11.1539 16.061 10.6747C15.8519 10.1954 15.5385 9.76895 15.1435 9.42638Z" fill="#275A8A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3229 8.06656L16.2908 8.03872L11.3405 4.13899V3.4707C11.3078 2.85848 11.4455 2.24918 11.7383 1.71053C12.0311 1.17188 12.4676 0.724984 12.9992 0.419516C13.372 0.235474 13.7904 0.163989 14.2032 0.213791C14.616 0.263594 15.0054 0.432529 15.3237 0.699964L26.3992 9.42535C26.7945 9.76789 27.1081 10.1945 27.3173 10.6739C27.5264 11.1533 27.6257 11.6734 27.608 12.1961C27.6258 12.7188 27.5264 13.2388 27.3171 13.7181C27.1078 14.1974 26.7939 14.6238 26.3984 14.9661L15.3237 23.6945C14.9331 24.014 14.4456 24.1916 13.941 24.1984C13.6131 24.1958 13.2901 24.1188 12.9962 23.9734C12.4654 23.6671 12.0297 23.2198 11.7375 22.6812C11.4453 22.1425 11.3079 21.5334 11.3405 20.9215V20.258L16.2911 16.3583L16.3235 16.3302C16.9215 15.8111 17.3958 15.1649 17.7119 14.4389C18.0198 13.7318 18.17 12.9663 18.1523 12.1957C18.1694 11.4259 18.0189 10.6614 17.7109 9.95513C17.3947 9.23007 16.9205 8.58483 16.3229 8.06656ZM11.3405 17.9666L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7203C16.2706 13.2403 16.3697 12.7196 16.3516 12.1964C16.3693 11.6738 16.27 11.1539 16.061 10.6747C15.852 10.1954 15.5385 9.76895 15.1435 9.42638L11.3405 6.43043V17.9666Z" fill="#275A8A"/>
            </svg>
          <h2 class="pe-2">4. Review & Submit Request</h2>
        <!-- </div> -->

      </div>

    </div>
  </div>
  <div class="stepper show-mobile">
    <div class="row">
      <div class="col-md-12">
        <ul class="progressbar">
          <li class="active">Contact</li>
          <li class="">Address</li>
          <li>Script</li>
          <li>Review</li>
        </ul>
      </div>
    </div>
  </div>


  <div class="row data-table bg-white border-radius-20  mx-2 py-2 ">

    <div class="d-search-tb d-flex ">

      <div class="nav-item dropdown boxT position-relative">
        <label class="seaech-ico"><i class="bi bi-search"></i><i *ngIf="searchterms!==''" style="margin-left: 360px;"
            class="fa-solid fa-xmark c-pointer" (click)="clearsearch()"></i></label>
        <div data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" class="search-box">
          <input placeholder="Missed Garbage, Dead Animal etc." type="text" [(ngModel)]="searchterms"
            class="form-control"  (keyup)="applyFilter($event)" data-bs-toggle="dropdown" aria-expanded="false">

        </div>
      </div>
      <div class="filter-box">
        <span *ngFor="let item of filterboxes"  >
          <span (click)="filter(item.name)" attr.data-filter-id="{{item.name}}" class="btn btn-light filter-btn"> 
            <img [src]="item.image" height="16px"> {{item.name}} </span>
          <span *ngIf="displayclose(item.name)=='close'" class="filter-close-rob">&nbsp;&nbsp; <i class="fa-solid fa-xmark"
              (click)="closefilter(item.name)"></i></span>
        </span>
      </div>

      <!-- <div class="filter-box">
        <span *ngFor="let item of filterboxes" attr.data-filter-id="{{item.name}}" (click)="filter(item.name)" class="btn btn-light filter-btn" >
          <span>
            <img [src]="item.image" height="16px">
            <span >{{item.name}}</span>
          </span>
          <span *ngIf="displayclose(item.name)=='close'">&nbsp;&nbsp; <i class="fa-solid fa-xmark"
              (click)="closefilter(item.name)"></i></span>
          </span>
      </div> -->


      <div class="box-btn">
        <button class="btn-action " (click)="ShowDiv()">
          <i class="bi bi-grid-3x3-gap-fill"></i> </button>
        <button class="btn-action active-b" (click)="ShowDiv()">
          <i class="bi bi-list-task"></i> </button>
      </div>
    </div>


    <div class=" query table-cont query-table query-table-common bg-white" >

    <table class="scroll order-column display mt-2 mb-0" id="listviewtable" *ngIf="datatableload">
      <thead>
        <tr>
          <th ># </th>
          <th>Service Type</th>
          <th>Division/Department</th>
          <!-- <th>division</th> -->
          <!-- <th >Estimated Resolution Time</th> -->
          <th >Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of caseTypeVal;let i=index" class="table-row">
          <td >{{i+1}}</td>
          <td
           class="text-primary pointer">
            <!-- <img [src]="'../../assets/images/casetypeImage/'+item.casetypeid+'.png'" width="16px" class="me-2"> -->
            <img [src]="'../../assets/images/casetypeImage/'+item.casetypeid+'.svg'" width="25px"  class="me-2">

            <!-- <img src="../../assets/images/{{item.imageUrl}}" alt="Gallery Item" width="16px" class="me-2"> -->
            <span (click)="gotoNext({redirectTo:'listView',viewname:'addressView','serviceDetails':item})">{{ item.content }}</span>
            </td>
          <td>{{ item.division.name }} > {{ item.department.name }}</td>
          <!-- <td></td> -->
          <!-- <td >{{item.estimatetime }}</td> -->
          <td  >
            <ng-template #tipContent class="">
              <p class="fw-bold text-left"> FAQs:</p>
              <p class="fw-bold text-left m-0">faq</p>



            </ng-template>
            <span class="d-flex     align-items-center gap-1" >
              <!-- <a class="info-table-bg mb-0 hover-text"  [ngbTooltip]="tipContent" placement="bottom">
                <img class="" src="../../assets/images/i-icon.svg" height="14px" />
              </a> -->
              <a class="text-primary hover-text ml-2 mb-0 pointer new-btn"
                (click)="gotoNext({redirectTo:'listView',viewname:'addressView','serviceDetails':item})"> <i
                  class="fa-solid fa-add"></i>
                New</a></span>
          </td>
        </tr>
      </tbody>
    </table>
</div>


    <!-- [dataSource]="dataSource" -->
    <!-- need to implement -->
    <!-- <th mat-header-cell *matHeaderCellDef mat-sort-header>
              <select id="serviceTypes" [(ngModel)]="selectedServiceType" (change)="onChange($event, data)">
                <option value="" selected>All Service Types:</option>
                <option *ngFor="let serviceType of caseTypeVal" [value]="serviceType.casetypeid">
                  {{ serviceType.content }}
                </option>
              </select>
            </th> -->

    <!-- <div class="mat-elevation-z8 card-p">
      <table mat-table [dataSource]="dataSource" matSort >

        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef style="width: 100px;"> # </th>
          <td mat-cell *matCellDef="let row;let i=index" style="width: 100px;"> {{ (paginatorRef.pageIndex *
            paginatorRef.pageSize) + (i + 1) }} </td>
        </ng-container>

        <ng-container matColumnDef="favourites">
          <th mat-header-cell *matHeaderCellDef style="width: 200px;" >Favourite</th>
          <td mat-cell *matCellDef="let row;let i=index" style="width: 200px;">
            <i *ngIf="!row.favourites" style="cursor:pointer;color:#E0E8EE" data-ng-hide="selected" id="star-result" (click)="togglefavourites(row)" matTooltip="Add to Favourite" class="fa-solid fa-star" aria-hidden="true"></i>
            <i *ngIf="row.favourites" style="cursor:pointer" data-ng-hide="selected" id="star-result" (click)="togglefavourites(row)" matTooltip="Remove From Favourite" class="fa-solid fa-star" ></i>
          </td>
        </ng-container>

        <ng-container matColumnDef="casetype">
          <th mat-header-cell *matHeaderCellDef mat-sort-header sortActionDescription="Sort by content">
            All Service Types
          </th>




          <td mat-cell *matCellDef="let row">
            <a class="text-primary "
              (click)="gotoNext({redirectTo:'listView',viewname:'addressView','serviceDetails':row})">
              <span class="">
                <img src="{{row.imageUrl}}" class="me-2"> <u>{{row.content}}</u>
              </span>
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="division">
          <th mat-header-cell *matHeaderCellDef> Division/Department </th>
          <td mat-cell *matCellDef="let row"> {{row.division.name}}
            <i class="bi bi-chevron-right" style="font-size: 1.0rem;"></i>
            <span class="depart-text">{{row.department.name}} </span>

          </td>
        </ng-container>
        <ng-container matColumnDef="resolution">
          <th mat-header-cell *matHeaderCellDef > Resolution Time </th>
          <td mat-cell *matCellDef="let row">{{row.estimatetime}}</td>
        </ng-container>
        <ng-container matColumnDef="actions">
          <th mat-header-cell *matHeaderCellDef style="width:140px !important;text-align:center !important;"> Actions
          </th>
          <td mat-cell *matCellDef="let row" style="width:140px !important;text-align:right !important;">

            <ng-template #tipContent class="">
              <p class="fw-bold text-left"> FAQs:</p>
              <p class="fw-bold text-left m-0"> Are potholes and cave-ins the same?</p>

              <p class=" text-left">No, a cave-in is a collapse in the pavement into a deep empty space without a solid
                bottom.</p>

              <P class="fw-bold text-left m-0"> What is a pothole is known as?</P>
              <p class=" text-left">A pothole is either a circular-shaped or a shallow hole or crack in the street
                surface.</p>

              <P class="fw-bold text-left m-0">Is the hole that is hollow or deep also a pothole?</P>
              <p class=" text-left"> This is considered a cave-in.</p>

            </ng-template>
            <a class="info-table-bg mb-0 hover-text" [ngbTooltip]="tipContent">

              <img class="" src="../../assets/images/i-icon.svg" height="14px" />



            </a>

            <a class="text-primary hover-text ml-2 mb-0 pointer new-btn"
              (click)="gotoNext({redirectTo:'listView',viewname:'addressView','serviceDetails':row})">
              New</a>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell p-2 text-center" colspan="4">No data matching the filter {{searchterms}}</td>
        </tr>
      </table>

      <mat-paginator fxFlex="100" #paginatorRef [pageSizeOptions]="pageSizeOptions"></mat-paginator>
      </div> -->
      <!-- <div class="row">
        <div class="col action-from text-center">
            <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto"  [routerLink]="['/']"><i class="bi bi-arrow-left"></i> Back</button>

        </div>
    </div> -->
    </div>


</div>

<!-- <mat-paginator style-paginator #paginatorRef showFirstLastButtons
            [showTotalPages]="3"
            [length]="dataSource.length"
            [pageSize]="3"
            [pageSizeOptions]="[3, 5, 10, 20]">
</mat-paginator> -->
<!-- <mat-paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPageSizeChange($event)"></mat-paginator> -->

<!-- <mat-paginator [pageSizeOptions]="pageSizeOptions" aria-label="Select page of users" class="mat-paginator-sticky"></mat-paginator> -->
