<div class="box p-4" >
   <div class="d-flex"> <img src="../../assets/images/chatt-logo.png" style="height: 40px"> <a class="ms-auto" (click)="onClose()"> <i class="bi bi-x fa-2x pointer"></i></a></div>
   <hr>

    <h4 class="text-center fw-bold">Sign In</h4>


    
    <form [formGroup]="loginUserInfoForm" (ngSubmit)="logIn()">
        <div class="row m-0">
          <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
              <input type="text" class="did-floating-input" id="username" formControlName="username"  >
              <label for="" class="did-floating-label">User Name</label>
          </div>
      </div>
      <div class="row m-0 mt-3">
          <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
              <input type="password" class="did-floating-input" id="password" formControlName="password" >
              <label for="" class="did-floating-label">Password</label>
          </div>
      </div>

      <div class="col action-from d-flex">
      <button type="submit" class="btn btn-primary m-auto mb-3 mt-2" [disabled]="!loginUserInfoForm.valid"> Sign In </button>
  </div>

  <p class="or-line"><span class="p-2 bg-white" style="z-index: 9;position: relative;">OR</span> </p>
  <p class="d-flex">
<button class="btn google-btn"> <i class="bi bi-google"></i><small class="fs-13"> Sign In with Google</small></button>
<button class="btn facebook-btn ms-auto"> <i class="bi bi-facebook"></i> <small  class="fs-13"> Sign In with Facebook</small></button>

</p>
  <p class="text-center mb-0 mt-4">Don’t have an account? 
    <a class="text-primary pointer" routerLink="/sign-up" (click)="onClose()"> Create an account</a></p>
</form>



  </div>

