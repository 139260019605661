<div class="d-flex all-section toggled">
    <div id="sidenav">
        <app-side-nav (isLoginCall)="isLoginCall($event)"></app-side-nav>
    </div>
    <!-- <div id="content-wrapper" class=" scroll-container container" (scroll)="onScroll($event)"> -->
        <div id="content-wrapper" class=" scroll-container container" >
        <app-marquee></app-marquee>

        <app-header [isLandingPage]="isLandingPage" [isLogin]="isLogin" ></app-header>
        <router-outlet ></router-outlet>
        <app-footer style="float: left; width: 100%; margin-top: 10px;"></app-footer>
    </div>
</div>

<!-- Chatbot     Start  -->
<app-saic></app-saic>
<!-- Chatbot     End  -->
