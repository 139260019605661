import { Component } from '@angular/core';
import { MarqueeServiceService } from '../../app/marquee/marquee-service.service';

@Component({
  selector: 'app-marquee',
  templateUrl: './marquee.component.html',
  styleUrls: ['./marquee.component.scss']
})
export class MarqueeComponent {
  responseData: any;

  constructor(private myService: MarqueeServiceService) { }

   ngOnInit(): void {
    this.myService.getData().subscribe((data: any) => {
      this.responseData = data;
      console.log('Response:', this.responseData);
    }, (error: any) => {
      console.error('Error:', error);
    });
};
}
