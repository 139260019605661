<div class="px-2">


  <div class="stepper hide-mobile">
    <ol class="mt-4 mb-2 p-0">
        <li class='success'><div><h2>1</h2><p>Select a Service Type</p></div><div class="line"> </div></li>
        <li class='success'><div><h2>2</h2><p>Provide Contact & Address Details</p></div><div class="line"> </div></li>
        <li class='active'><div><h2>3</h2><p>Provide Additional Information</p></div><div class="line"> </div></li>
        <li><div><h2>4</h2><p>Review & Submit Request</p></div></li>
      </ol>
  </div>
  <div class="stepper show-mobile mb-3">
    <div class="row">
      <div class="col-md-12">
        <ul class="progressbar">
          <li class="success">Contact</li>
          <li class="active">Address</li>
          <li>Script</li>
          <li>Review</li>
        </ul>
      </div>
    </div>
  </div>

<div class="container additional-details p-0 ">
    <div class="h6 mb-2 mt-3">Please provide additional information</div>
    <form [formGroup]="additionalInfoForm" (ngSubmit)="gotoNext({viewname:'reviewsubmit','serviceDetails':'next-btn'})">
        <div class="row">
            <div class="col-md-6  ">

                <div class="border h-100 p-3 bg-white card-p">
                    <div class=" m-0 mt-2">
                        <div class="did-floating-label-content">
                            <select class="did-floating-select" formControlName="nopotholeNumber">
                              <!-- <option [ngValue]="''"> How many potholes are there?</option> -->
                              <option [ngValue]="'Select 1'">Select 1</option>
                              <option [ngValue]="'Select 2'">Select 2</option>
                              <option [ngValue]="'Select 3'">Select 3</option>
                            </select>
                            <label class="did-floating-label">How many potholes are there? <span class="text-denger">*</span></label>
                          </div>
                    </div>

                    <div class=" m-0 mt-2">
                        <div class="did-floating-label-content">
                            <select class="did-floating-select" formControlName="pothole">
                              <!-- <option [ngValue]="''">Is it a true pothole or construction-related pothole?</option> -->
                              <option [ngValue]="'Select 1'">Select 1</option>
                              <option [ngValue]="'Select 2'">Select 2</option>
                              <option [ngValue]="'Select 3'">Select 3</option>
                            </select>
                            <label class="did-floating-label">Is it a true pothole or construction-related pothole? <span class="text-denger">*</span></label>
                          </div>
                    </div>



                    <div class="m-0 d-flex mt-3 mb-3" *ngIf="additionalInfoForm.value.pothole" >
                        <label class="me-2">Size of the Pothole:</label>
                        <div class="form-check form-check-inline ms-2">
                            <input class="form-check-input" type="radio" name="potholesize" formControlName="potholesize" id="Small" value="Small">
                            <label class="form-check-label" for="Small">Small</label>
                          </div>
                          <div class="form-check form-check-inline ms-2">
                            <input class="form-check-input" type="radio" name="potholesize" formControlName="potholesize" id="Medium" value="Medium">
                            <label class="form-check-label" for="Medium">Medium</label>
                          </div>
                          <div class="form-check form-check-inline ms-2">
                            <input class="form-check-input" type="radio" name="potholesize" formControlName="potholesize" id="Large" value="Large">
                            <label class="form-check-label" for="Large">Large</label>
                          </div>
                    </div>
                    <div class=" m-0" *ngIf="additionalInfoForm.value.potholesize">
                        <div class="did-floating-label-content">
                            <select class="did-floating-select" formControlName="locationdetails">
                                <!-- <option [ngValue]="''">Location Details</option> -->
                                <option [ngValue]="'Select 1'">Select 1</option>
                                <option [ngValue]="'Select 2'">Select 2</option>
                                <option [ngValue]="'Select 3'">Select 3</option>
                            </select>
                            <label class="did-floating-label">Location Details  <span class="text-denger">*</span></label>
                          </div>
                    </div>
                    <div class=" m-0">


                    <div class="did-floating-label-content  col-md-12 ps-0">
                        <textarea style="height: 60px;     padding-top: 10px;" formControlName="furtherdetails" class="did-floating-input" placeholder=" " row="3" width="100%"></textarea>
                        <label for="" class="did-floating-label">Further Details *</label>
                    </div>
                    </div>
                    <div class="m-0 d-flex mt-3 mb-3">
                        <label class="me-2 mt-1"> Add Photo:</label>

                        <div class="upload-btn-wrapper">
                            <input class="custom-file-input" type="file">
                          </div>

                    </div>


                </div>
            </div>
            <div class="col-md-6 ">
                <div class="border  bg-white d-flex card-p">
                 <app-faq></app-faq>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col action-from d-flex">
                <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto" (click)="gotoNext({viewname:'addressView','serviceDetails':'prv-btn'})"><i class="bi bi-arrow-left"></i> Back</button>
                <button type="submit" value="next-btn" class="btn btn-primary" [disabled]="!additionalInfoForm.valid" >
                    <i class="bi bi-arrow-right"></i> Next
                </button>
            </div>
</div>

    </form>
</div>
</div>
