<div id="main_list" class="container">
<div class="d-print">
<img src="../../assets/images/fort-wayne-logo-seal-dark.svg" height="60px">
<span class="h4"> SMART®  FAQ</span>
</div>
  <div class="boxed">
    <div class="container p-2">
      <div class="row">
      <div class="col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">
        <div class="row top10 m-0 breadcrumbs">
          <div class="col-sm-12 p-0">
            <ul class="fw-bc d-flex align-items-center gap-1">
              <li><a [routerLink]="['/']" class="tex-primary fw-bold fs-14 text-decoration-none ">Home</a></li>&NonBreakingSpace;
              <li> <i class="fa-solid fa-chevron-right"></i></li>&NonBreakingSpace;
              <li><a  class="tex-primary fw-bold fs-14 text-decoration-none" [routerLink]="['/answers/list']">SMART® Knowledge Base</a></li>&NonBreakingSpace;
              <li> <i class="fa-solid fa-chevron-right"></i></li>&NonBreakingSpace;
              <li>Details</li>&NonBreakingSpace;

            </ul>
          </div>
        </div>

        <div id="rn_PageTitle" class="rn_AnswerDetail">


          <div class="rn_Element row">
            <div class="col-md-12 col-xl-9">
             <div class=" ">
              <h2 class="head">{{ allfaq.summary }}</h2>
              <span class="createdtime">Updated: {{ allfaq.createdtime |date:'MMM dd, yyyy'}}</span>
            </div>
                <div [innerHTML]="stripHtmlAndEntities(allfaq.description)" class="fs-18 text-dark fw-500 mb-2"></div>


                <div id="rn_PageContent" class="rn_AnswerDetail mb-2">
                  <div id="rn_AnswerText">

             <div [innerHTML]="allfaq.solution"></div>


                  </div>
                 <div class="feedback-box" [class.feedback-done]="!feedbackgiven">
                  <div class="d-flex gap-4" *ngIf="feedbackgiven">
                  <img src="../../assets/images/feedback-star.svg" height="60px" title="feedback-star">
                  <div>
                  <p>Was this answer helpful?</p>
                  <div class="form-check form-check-inline">
                    <input  (click)="feedback(1);likeClick(allfaq.prodcatid)" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1">
                    <label class="form-check-label" for="inlineRadio1">Yes</label>
                  </div>
                  <div class="form-check form-check-inline">
                    <input  (click)="feedback(0);dislikeClick(allfaq.prodcatid)" class="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2">
                    <label class="form-check-label" for="inlineRadio2">No</label>
                  </div>
                </div>
                </div>
                <div class="d-flex gap-4" *ngIf="!feedbackgiven">
                  <img src="../../assets/images/feedback-star.svg" height="60px" title="feedback-star">
                  <div>
                  <p style="margin-top: 20px;">Thanks for the feedback</p>
                </div>
                </div>
                 </div>

                </div>

            </div>
            <div class="col-md-12 col-xl-3">

              <div class="quick-links">
                <h3> <img src="../../assets/images/linking.svg" height="20px"> Quick Links</h3>
                <ul type="disc">
                    <li *ngFor="let link of quickLinks">
                        <a (click)="answerdetail(link.id)">{{ link.summary }}</a>

                    </li>
                </ul>
            </div>

              <div class="share-info">
                <h3><img src="../../assets/images/reachout.svg" height="20px"> Reach out to us</h3>
                <div style="padding-left: 25px;">
                <p>
                  <strong class="d-block">Phone:</strong>
                  <span>260-427-8311 or 311</span>
                </p>
                <p>
                  <strong class="d-block">Street Address:</strong>
                  <span>Citizen Square / Solid Waste
                    200 E Berry St Suite 210
                    Fort Wayne In 46802</span>
                </p>
                <p>
                  <strong class="d-block">Office Hours:</strong>
                  <span>M-F 8:00am to 5:00pm</span>
                </p>
                <!-- <div class="si-icon d-flex flex-row"><i class="fa fa-envelope" (click)=" openDialog()"></i><i class="fa fa-wifi"></i></div> -->
              </div>
            </div>
<div class="d-flex gap-4">

      <button (click)="printPage()" class="share-info flex-fill p-3 btn btn-outline-primary" id="printPageButton"> <i class="fa fa-print  fs-18" ></i> </button>

        <button (click)=" openDialog()" class="share-info flex-fill p-3 btn btn-outline-primary" id="shareButton"> <i class="fa fa-envelope  fs-18" ></i></button>


</div>






            </div>
          </div>

        </div>
      </div>
    </div>
    </div>
  </div>
</div>
