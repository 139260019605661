import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-feedbacksurveypopup',
  templateUrl: './feedbacksurveypopup.component.html',
  styleUrls: ['./feedbacksurveypopup.component.scss']
})
export class FeedbacksurveypopupComponent {
  constructor(public dialogRef: MatDialogRef<FeedbacksurveypopupComponent>)
  {

  }
  onCloseClick(): void {
    this.dialogRef.close();

  }
}
