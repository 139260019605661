
<div class="container px-4 ">
    <div class=" mb-4 border-radius-20 bg-white     overflow-hidden pt-3 position-relative">
      <div class="row top10">
        <div class="col-sm-12">
          <ul class="fw-bc d-flex align-items-center gap-1 ms-3">
            <li><a [routerLink]="['/']" class="tex-primary fw-bold">Home</a></li>&NonBreakingSpace;
            <li> <i class="fa-solid fa-chevron-right"></i></li>&NonBreakingSpace;
            <li><a [routerLink]="['/track-sr']" class="tex-primary fw-bold">Track SR</a></li>&NonBreakingSpace;
            <li> <i class="fa-solid fa-chevron-right"></i></li>&NonBreakingSpace;
            <li>Details</li>
          </ul>
        </div>
      </div>
      <!-- <button  type="button" value="prv-btn" class="btn btn-outline-primary btn-sm border-0 ms-2     position-absolute" tabindex="0"
       [routerLink]="['/track-sr']">
        <i  class="fa fa-arrow-left fw-bold" style="font-size: 20px;"></i>
      </button> -->



<div class="card w-75-1 m-auto mb-3 border-0 p-3" style="background-color: #F4F6F8">

  <div class="d-flex align-items-center box-tracksr">
  <img  [src]="'../../assets/images/casetypeImage/'+refno.casetypeid+'.svg'"  width="60px" height="60px" style="border-radius: 60px; border: solid 1px #ccc;">
  <h2 class="mt-2">{{refno.casetype}}</h2>

<div >
<div class="row m-0 " *ngIf="refno.incidentstatus==='Unresolved'">
    <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2;">
    <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: #dc3545;"> </i>
  {{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Resolved'">
  <div class="status-badge  ms-auto" style="--bs-bg-opacity: .2;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: green; "> </i>
{{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Waiting'">
  <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: orange;"> </i>
{{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Reopen'">
  <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: aqua;"> </i>
{{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Close-Reclassify'">
  <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: lightpink;"> </i>
{{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Close-Disregard'">
  <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px; color: #dc3545;"> </i>
{{ refno.incidentstatus}} </div>
</div>
<div class="row m-0 " *ngIf="refno.incidentstatus==='Close-Resolved'">
  <div class="status-badge   ms-auto" style="--bs-bg-opacity: .2; color: red;">
  <i class="bi bi-circle-fill " style="font-size: 10px; margin-right: 10px;"> </i>
{{ refno.incidentstatus}} </div>
</div>
</div>

</div>



<div class="my-3" style="gap: 10px;display: flex;flex-direction: column;">



<div class="row m-0">
  <div class="col-md-6 col-6 text-end pe-0">SR #: </div>
  <div class="col-md-6 col-6 fw-bold text-start pe-0">{{ refno.refno }}</div>
  </div>
  <div class="row">
    <div class="col-md-6 col-6 text-end pe-0">Department: </div>
    <div class="col-md-6 col-6 fw-bold text-start">{{refno.department}}</div>
    </div>
  <div class="row">
    <div class="col-md-6 col-6 text-end pe-0">Service Type: </div>
    <div class="col-md-6 col-6 fw-bold text-start pe-0">{{refno.casetype}}</div>
    </div>
    <!-- <div class="row">
      <div class="col-md-6 text-end p-0">Queue: </div>
      <div class="col-md-6 fw-bold text-start">{{refno.queue}}</div>
      </div> -->
      <div class="row">
        <div class="col-md-6  col-6 text-end pe-0">Service Address: </div>
        <div class="col-md-6  col-6 fw-bold text-start pe-0">{{Nanaddress(refno.addressline1)}}</div>
        </div>
      <!-- <div class="row">
        <div class="col-md-6 text-end p-0">SLA (Service Level Agreement): </div>
        <div class="col-md-6 fw-bold text-start">{{refno.sla | date:"MMMM dd, yyyy h:mm a" }}</div>
        </div> -->
        <div class="row">
          <div class="col-md-6 col-6 text-end pe-0">Date Created: </div>
          <div class="col-md-6 col-6 fw-bold text-start pe-0">{{refno.createdtime | date:"MMMM dd, yyyy h:mm a" }}</div>
          </div>

</div>
<!-- <div class="row">
<div class=" col-md-6 " >
  <div class="bg-white p-2" style="gap: 10px;display: flex;flex-direction: column;">
    <h3 class="text-center " >Address Details</h3>

<div class="row">
<div class="col-md-6 text-end p-0">Address Location: </div>
<div class="col-md-6 fw-bold">{{refno.addressline1}}</div>
</div>
<div class="row">
  <div class="col-md-6 text-end p-0">City: </div>
  <div class="col-md-6 fw-bold">{{refno.city}}</div>
  </div>
  <div class="row">
    <div class="col-md-6 text-end p-0">State:</div>
    <div class="col-md-6 fw-bold">{{refno.state}}</div>
    </div>
    <div class="row">
      <div class="col-md-6 text-end p-0"> Postal / Zip: </div>
      <div class="col-md-6 fw-bold">{{refno.postal_code}}</div>
      </div>

          <div class="row">
            <div class="col-md-6 text-end p-0">Created time:</div>
            <div class="col-md-6 fw-bold">{{refno.createdtime | date: "MMMM dd, YYYY hh:mm aa " }}</div>
            </div>

</div>
</div>
<div class=" col-md-6 " >
  <div class="bg-white h-100 p-2" style="gap: 10px;display: flex;flex-direction: column;">
  <h3 class="text-center " > Customer Information</h3>

  <div class="row" >
    <div class="col-md-6 text-end p-0">Name:</div>
    <div class="col-md-6 fw-bold">{{refno.customer_name}}</div>
    </div>
    <div class="row">
      <div class="col-md-6 text-end p-0">Email Address:</div>
      <div class="col-md-6 fw-bold">{{refno.customer_email}}</div>
      </div>
      <div class="row">
        <div class="col-md-6 text-end p-0">Phone Number:</div>
        <div class="col-md-6 fw-bold">{{refno.customer_phone }}</div>
        </div>

</div>
</div>
</div> -->
</div>
</div>
</div>
