import { Component } from '@angular/core';

@Component({
  selector: 'app-about311',
  templateUrl: './about311.component.html',
  styleUrls: ['./about311.component.scss']
})
export class About311Component {
  ngOnInit()
{
  document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
}
}
