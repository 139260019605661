<div class="container p-2">
  <div class="col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">

    <div class="boxed" >
      <div class="row">
        <div class="col-sm-12">
          <h2 class="head">Contact Us</h2>
        </div>
      </div>

      <div class="row mt-4 g-45">
        
          <div class="col-md-4 ">
              <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                  <div class="mb-4">
                     <i class="contact-icon  bi bi-envelope-at-fill"></i>
                  </div>
                  <div>
                    <h4 class="fw-bold">Email Here</h4>
                      <ul class="list-unstyled p-0 m-0">
                          <li><a href="mailto:311@cityoffortwayne.org" >311@cityoffortwayne.org</a></li>
                          
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-md-4 ">
              <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                  <div class="mb-4">
                    <i class="contact-icon bi bi-pin-map-fill"></i>  
                  </div>
                  <div>
                    <h4 class="fw-bold">Mailing Address</h4>
                      <ul class="list-unstyled p-0 m-0">
                          <li>
                            <div>Citizens Square</div>
                            <div>200 East Berry Street, Suite 470</div>
                            <div>Fort Wayne, IN 46802</div>
                          </li>
                      </ul>
                  </div>
              </div>
          </div>
          <div class="col-md-4 ">
              <div class="contact-wrapper bg-light rounded position-relative h-100 px-4">
                  <div class="mb-4">
                   <i class="contact-icon bi bi-phone-vibrate"></i>
                  </div>
                  <div>
                      <h4 class="fw-bold">Call Here</h4>
                      <ul class="list-unstyled p-0 m-0">
                          <li><span  class="fw-500"> Dial </span> <a href="tel:+311">311 </a></li>
                         
                            <li class="fw-500">From outside Allen County <a href="tel:+2604278311"> (260) 427-8311</a></li>
                           

                      </ul>
                  </div>
              </div>
          </div>
      





        <!-- <div class="col-md-4">
          <div class="rt-joomla ">
            <div class="rt-article">
             
            
              <div><strong><span class="Apple-style-span">Phone:</span></strong></div>
              <div><span class="Apple-style-span" style="font-weight: normal;">In </span>Allen County call: 311 or
                260-427-8311</div>
              <div>Outside of Allen County call: 260-427-8311</div>
              <div><span class="Apple-style-span" style="font-weight: bold;"><br>Mailing address:</span></div>
              <div>Citizens Square</div>
              <div>200 East Berry Street, Suite 470</div>
              <div>Fort Wayne, IN 46802</div>
            </div>
          </div>

        </div>
        <div class="col-md-8">
          <img src="../../assets/images/RS_03.jpg"  width="400" height="265" class="img-responsive" >
        </div> -->
      </div>
    </div>
  </div>
</div>