import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { makeServiceRequestService } from '../make-service-request.service';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { FeedbacksurveypopupComponent } from '../feedbacksurveypopup/feedbacksurveypopup.component';
import { EmailsubmitpopupComponent } from '../emailsubmitpopup/emailsubmitpopup.component';

@Component({
  selector: 'app-emailpopup',
  templateUrl: './emailpopup.component.html',
  styleUrls: ['./emailpopup.component.scss']
})
export class EmailpopupComponent {
email:any;
emailForm: FormGroup;
  constructor(
    public dialogRef: MatDialogRef<EmailpopupComponent>,
    public DialogComponent: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: any,private makeservice:makeServiceRequestService,private fb: FormBuilder
  ) {

  }
  ngOnInit()
  {
    this.emailForm = this.fb.group({
      to: ['', [Validators.required,this.emailValidator()]],
    });
  }
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailPattern.test(email);

      if (email && !valid) {
        return { 'invalidEmailFormat': true };
      }
      return null;
    };
  }
  stripHtmlAndEntities(htmlString: string): string {
    if (!htmlString) return '';

    // Remove HTML tags
    const withoutTags = htmlString.replace(/<[^>]*>/g, '');
    const trimmedString = withoutTags.trim().replace(/^"/, '').replace(/"$/, '');
    // Remove &nbsp; entities
    const withoutEntities = trimmedString.replace(/&nbsp;/g, ' ');

    // Remove quotation marks
    const withoutQuotationMarks = withoutEntities.replace(/"/g, '');

    // Add space character
    const withSpace = withoutQuotationMarks + ' ';

    return withSpace;
}
sendemaildata:any;
closeDialog() {
  this.dialogRef.close();
}
openDialog(): void {

  const dialogRef = this.DialogComponent.open(EmailsubmitpopupComponent, {
    width: '450px',
    // data: { dataInPopup }
  });

  dialogRef.afterClosed().subscribe(() => {
    console.log('The dialog was closed');
    // this.router.navigateByUrl('/track-sr')
    // You can handle any actions after the dialog is closed
  });




}
 validateEmail(email: string): boolean {
  const emailPattern =/^[a-zA-Z0-9._%+-]+@[a-zA-Z.-]+\.[a-zA-Z]{2,}$/;
  return emailPattern.test(email);
}

onsubmit()
{
  // console.log("Form validity:", this.emailForm.valid);
  // console.log("Form:", this.emailForm);
  // // Rest of your code
console.log(this.emailForm.value.to)
  if (this.emailForm.valid) {
    const sendemaildata = {
      to: [this.emailForm.value.to],
      cc:[''],
      subject: this.stripHtmlAndEntities(this.data.subject),
      body_html: this.data.message
    };
  console.log(this.email)
    console.log(this.data.to)
  this.sendemaildata
    this.makeservice.sendEmailAttachment(sendemaildata).subscribe((data:any)=>
    {
      console.log(data);
      this.dialogRef.close();
      this.openDialog()
    })
    // Form is valid, proceed with submission
  } else {
    console.log("in ")
    // Form is invalid, handle accordingly
  }


}
}
