<div class="container p-2">


	<div class="row m-0">
	
	<div class=" col-md-9 mb-2 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-4">

		<div class="boxed border-radius-20 bg-white" id="printContent" >
			<div class="row">
				<div class="col-sm-12 d-flex">
					<h2 >About 311</h2>

					<div class="ms-auto">
						<a href="http://twitter.com/share" class="twitter-share-button" data-url="http://www.cityoffortwayne.org/about-311.html" data-text="About 311" data-count="vertical" data-via="" data-lang="en"> 
							<img src="../../assets/images/twitter2.svg" width="14px"> Share  </a>
						<script type="text/javascript" src="http://platform.twitter.com/widgets.js"></script>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					<div class="articleInfo">
						<div class="articleText">
							<!-- <span class="datePosted">
								05 October 2012
							</span> -->
						</div>
						<!-- <div class="articleIcons">
							<a href="http://www.cityoffortwayne.org/about-311.pdf?type=raw" target="_blank" title="PDF"><i class="fa fa-file-pdf-o">&nbsp;</i></a>
							<a href="http://www.cityoffortwayne.org/about-311.html?type=raw&tmpl=component&print=1&page=" onclick="window.open(this.href,'win2','status=no,toolbar=no,scrollbars=yes,titlebar=no,menubar=no,resizable=yes,width=640,height=480,directories=no,location=no'); return false;" title="Print"><i class="fa fa-print">&nbsp;</i></a>
						</div> -->
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-12">
					
					
					Welcome to the New Citizen Services website! The 311 Call Center has completed a new &nbsp; cloud based Oracle software installation.  It includes a robust website with social media offerings and our very first Fort Wayne 311 mobile application coming in the near future!  These new tools will make it even easier for you to access the city in the channel of your choice.<br /><br />

					The Citizen Services 311 Call Center is the front door to city services.   Feel free to call 311 if you have a question or need information about city services.  You can also call us to request a city service or report an issue to the City of Fort Wayne.   Please remember that we handle non-emergency city services only.  In the event of a life threatening situation, or in an emergency, always call 911.<br /><br />

					The Citizen Services department is the home of the 311 Call Center and the 311 city wide performance scorecard.  The aggregated data we receive from your calls assists city leaders in assessing and gauging city service delivery performance.  Through the 311 performance scorecard, your calls help drive strategic improvements within our city as we respond to your community voice.<br /><br />

					As you peruse our improved website and use our new tools, we hope that you will find it even easier to engage with the City of Fort Wayne in the future.  Please let us know what you think about these changes in the days ahead. <br /><br />
					<strong class="pull-right"> <small>— </small> Mayor Tom Henry</strong>
				</div>
			</div>

			<!-- <div class="socialShare">
				<h4>Share this article</h4>
				<div class="socialIcons">
					<a href="http://del.icio.us/post?url=http%3A%2F%2Fwww.cityoffortwayne.org%2Fabout-311.html&amp;title=About%20311" title="Submit to Delicious" target="_blank">
						<img src="http://www.cityoffortwayne.org/plugins/content/itpsocialbuttons/images/classy/delicious.png" alt="Submit to Delicious">
					</a>
					<a href="http://digg.com/submit?url=http%3A%2F%2Fwww.cityoffortwayne.org%2Fabout-311.html&amp;title=About%20311" title="Submit to Digg" target="_blank">
						<img src="http://www.cityoffortwayne.org/plugins/content/itpsocialbuttons/images/classy/digg.png" alt="Submit to Digg">
					</a>
					<a href="http://www.facebook.com/sharer.php?u=http%3A%2F%2Fwww.cityoffortwayne.org%2Fabout-311.html&amp;t=About%20311" title="Submit to Facebook" target="_blank">
						<img src="http://www.cityoffortwayne.org/plugins/content/itpsocialbuttons/images/classy/facebook.png" alt="Submit to Facebook">
					</a>
					<a href="http://www.stumbleupon.com/submit?url=http%3A%2F%2Fwww.cityoffortwayne.org%2Fabout-311.html&amp;title=About%20311" title="Submit to Stumbleupon" target="_blank">
						<img src="http://www.cityoffortwayne.org/plugins/content/itpsocialbuttons/images/classy/stumbleupon.png" alt="Submit to Stumbleupon">
					</a>
					<a href="http://twitter.com/share?text=About%20311&amp;url=http%3A%2F%2Fwww.cityoffortwayne.org%2Fabout-311.html" title="Submit to Twitter" target="_blank">
						<img src="http://www.cityoffortwayne.org/plugins/content/itpsocialbuttons/images/classy/twitter.png" alt="Submit to Twitter">
					</a>
				</div>
			</div> -->
		</div>
	</div>
	<div class="col-lg-3 col-md-3 col-sm-12">
		<div class="card mb-3 p-2">


		<h3 class="mb-2">Awards</h3>

		<div class="row" style="justify-content: space-between;flex-direction: row;display: flex;flex-wrap: wrap-reverse;align-items: center;
		vertical-align: middle;	">
		<div class="col-md-9"><img src="../../assets/images/10thAnniversary.jpg" alt="10th Anniversary" class="img-fluid" ></div>
		<div class="col-md-3"><img src="../../assets/images/awards1.png" alt="awards1" class="img-fluid"  ></div>
	</div>
		</div>
		<div class="row">
		<div class="col-lg-12">
	   <div class="twitter-timeline twitter-timeline-rendered w-100 mb-2 border-radius-20" ><iframe id="twitter-widget-0" scrolling="no" frameborder="0" allowtransparency="true" allowfullscreen="true" class="" title="Twitter Timeline" src="https://syndication.twitter.com/srv/timeline-profile/screen-name/MayorTomHenry?dnt=false&amp;embedId=twitter-widget-0&amp;features=eyJ0ZndfdGltZWxpbmVfbGlzdCI6eyJidWNrZXQiOltdLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X2ZvbGxvd2VyX2NvdW50X3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9iYWNrZW5kIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19yZWZzcmNfc2Vzc2lvbiI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfZm9zbnJfc29mdF9pbnRlcnZlbnRpb25zX2VuYWJsZWQiOnsiYnVja2V0Ijoib24iLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X21peGVkX21lZGlhXzE1ODk3Ijp7ImJ1Y2tldCI6InRyZWF0bWVudCIsInZlcnNpb24iOm51bGx9LCJ0ZndfZXhwZXJpbWVudHNfY29va2llX2V4cGlyYXRpb24iOnsiYnVja2V0IjoxMjA5NjAwLCJ2ZXJzaW9uIjpudWxsfSwidGZ3X3Nob3dfYmlyZHdhdGNoX3Bpdm90c19lbmFibGVkIjp7ImJ1Y2tldCI6Im9uIiwidmVyc2lvbiI6bnVsbH0sInRmd19kdXBsaWNhdGVfc2NyaWJlc190b19zZXR0aW5ncyI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdXNlX3Byb2ZpbGVfaW1hZ2Vfc2hhcGVfZW5hYmxlZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9LCJ0ZndfdmlkZW9faGxzX2R5bmFtaWNfbWFuaWZlc3RzXzE1MDgyIjp7ImJ1Y2tldCI6InRydWVfYml0cmF0ZSIsInZlcnNpb24iOm51bGx9LCJ0ZndfbGVnYWN5X3RpbWVsaW5lX3N1bnNldCI6eyJidWNrZXQiOnRydWUsInZlcnNpb24iOm51bGx9LCJ0ZndfdHdlZXRfZWRpdF9mcm9udGVuZCI6eyJidWNrZXQiOiJvbiIsInZlcnNpb24iOm51bGx9fQ%3D%3D&amp;frame=false&amp;hideBorder=false&amp;hideFooter=false&amp;hideHeader=false&amp;hideScrollBar=false&amp;lang=en&amp;maxHeight=600px&amp;origin=https%3A%2F%2Fcityoffortwayne--tst1.custhelp.com%2Fapp%2FFortwayne%2Fabout311&amp;sessionId=9ec4913012d8a7d162796bdcafe02e7e658f9106&amp;showHeader=true&amp;showReplies=false&amp;transparent=false&amp;widgetsVersion=2615f7e52b7e0%3A1702314776716" style="position: static; visibility: visible; width: 100%; height: 500px; display: block; flex-grow: 1;"></iframe></div>
<script>!function(d,s,id){var js,fjs=d.getElementsByTagName(s)[0],p=/^http:/.test(d.location)?'http':'https';if(!d.getElementById(id)){js=d.createElement(s);js.id=id;js.src=p+"://platform.twitter.com/widgets.js";fjs.parentNode.insertBefore(js,fjs);}}(document,"script","twitter-wjs");</script>
		</div>
		</div>
</div>
</div>
</div>


