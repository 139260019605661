<div class="col-lg-9 col-md-9 col-sm-12 top10 clearfix">
  <div class="row">
      <div class="col-sm-12">
          <br>
          <div class="text-center sereq" >A - Z Indexing</div>
          <br>
      </div>

      <div class="col-sm-12">
          <div class="ln-letters">
              <a class="a" (click)="displayall()">All</a>
              <ul style="list-style-type: none">
                  <li *ngFor="let x of testdata | keyvalue">
                      <a class="a" (click)="appendone(x.key);toggledisplay(x.key,'test',false)">{{x.key}}</a>
                  </li>
              </ul>
              <!-- <div >
              </div> -->
          </div>

          <div *ngIf="displayalldata || !display">
              <div style="margin-left:-15px">
                  <ul>
                      <li *ngFor="let x of testdata| keyvalue" style="list-style-type:none;" >
                          <b style="font-size: 17px;">{{x.key}}</b>{{appendone(x.key)}}
                          <hr>
                                  <div>
                                      <ul style="list-style-type: disc" [ngStyle]="{'padding-left':details? '0px' : '' }" >
                                          <li *ngFor="let y of secondarray| keyvalue" class="resp-tab-item"  [ngClass]="{'selected': (thirdarray === x.value && details),'view':details}" >
                                              <!-- toggledisplay() -->
                                              <a class="a"  (click)="appendtwo(y.value);toggledisplay(x.key,entiredata,true)">{{y.key}}</a>
                                          </li>
                                      </ul>
                              </div>

                              <!-- <div *ngIf="details" class="right"  style="padding-left:10px;border: 1px solid #c1c1c1;" >
                                      <ul class="resp-tabs-container">
                                          <li *ngFor="let x of thirdarray" style="margin-top: 5px;">
                                              <a style="color:black;" routerLink="detail/{{x.id}}">{{x.q}}</a>
                                          </li>
                                      </ul>
                              </div> -->
                      </li>
                  </ul>
          </div>
          </div>

          <!-- *ngIf="display && !displayalldata" -->
          <div style="margin-left: 5px;" *ngIf="display && !displayalldata">
              <b style="font-size: 17px;">{{displaytitle}}</b>
              <hr>

          <div class="container1">
              <div style="float:left" [ngStyle]="{'width':details? '30%' : '' }">
                      <ul [ngStyle]="{'padding-left':details? '0px' : '' }" >
                          <li *ngFor="let x of secondarray| keyvalue" class="resp-tab-item"  [ngClass]="{'selected': (thirdarray === x.value && details),'view':details}" >
                              <a class="a"  (click)="appendtwo(x.value)"   >{{x.key}}</a>
                          </li>
                      </ul>
              </div>

              <div *ngIf="details" class="right"  style="padding-left:10px;float:left;width:70%;border: 1px solid #c1c1c1;" >
                      <ul class="resp-tabs-container">
                          <li *ngFor="let x of thirdarray" style="margin-top: 5px;">
                              <a style="color:black;" routerLink="/details/{{x.id}}">{{x.q}}</a>
                          </li>
                      </ul>
              </div>
          </div>
          </div>
  </div>
</div>
