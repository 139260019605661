import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { KnowledgebaseService } from '../knowledgebase.service';
import { HttpClient } from '@angular/common/http';
import { MsalService } from '@azure/msal-angular';
import { Router } from '@angular/router';

@Component({
  selector: 'app-knowledgebase',
  templateUrl: './knowledgebase.component.html',
  styleUrls: ['./knowledgebase.component.scss']
})
export class KnowledgebaseComponent {
  isAdvancedSearchActive: boolean = false;
  checked:boolean=false;

  navigateToDetails(prodcatid: any) {
    this.knowledgeSearchService.searchterm=this.searchQuery;
console.log(this.knowledgeSearchService.searchterm)
    console.log(prodcatid)
    this.router.navigate(['/answers/details', prodcatid]);
  }
  // searchdatatablepagination() {


  //   $("#knowledgebasetablesearch_wrapper").remove();

  //   setTimeout(() => {
  //     ($('#knowledgebasetablesearch') as any).dataTable({

  //       // "language": {
  //       //   "paginate": {
  //       //     "previous": "",
  //       //     "next": ""
  //       //   },
  //       //   "lengthMenu": "Records per page _MENU_",
  //       // },
  //       pageLength: 10,
  //       // "order": [[2, "asc"]],

  //       "columnDefs": [
  //         { "orderable": false, "targets": 0 },
  //         { "orderable": false, "targets": 5 },
  //         // { 'orderable': false, 'targets': 6 }
  //       ],

  //       // "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
  //         fixedColumns: {
  //           start: 1,
  //           end: 1
  //       },
  //       paging: true,
  //       fixedHeader: true,
  //       scrollX: false,
  //       // scrollY: 200,
  //       destroy:true,
  //       // dom: 'frtlip',
  //       dom: 'rt',
  //       drawCallback: function (settings) {
  //         const api = this.api();
  //         const rows = api.rows({ page: 'current' }).nodes();
  //         let startIndex = api.page() * api.page.len() + 1;

  //         $(rows).each(function (index) {
  //           $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
  //           startIndex++;
  //         });
  //       },
  //     });
  //   }, 100);

  //   this.searchdatatableload=true
  // }
  datatablepagination() {

    // if ($.fn.DataTable?.isDataTable("#knowledgebasetable")) {
    //   // Destroy existing DataTable
      // $("#knowledgebasetable").DataTable().destroy();
  // }

    $("#knowledgebasetable_wrapper").remove();

    setTimeout(() => {
      const screenWidth = window.innerWidth;
      const isSmallScreen = screenWidth <= 768;
      ($('#knowledgebasetable') as any).dataTable({

        // "language": {
        //   "paginate": {
        //     "previous": "",
        //     "next": ""
        //   },
        //   "lengthMenu": "Records per page _MENU_",
        // },
        pageLength: this.pageSize,
        "order": [[1, "asc"]],
        // responsive:true,
        responsive: isSmallScreen ? false : false,

        "columnDefs": [
          { "orderable": false, "targets": 0 },
          { "orderable": false, "targets": 5 },
          // { 'orderable': false, 'targets': 6 }
        ],

        // "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        fixedColumns: {
            start:isSmallScreen ? 0 : 2,
            end: isSmallScreen ? 1 : 1,
        },
        paging: true,
        fixedHeader: true,
        // scrollX: true,
        scrollY: 450,
        destroy:true,
        // dom: 'frtlip',
        dom: 'rt',
        drawCallback: function (settings) {
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let startIndex = api.page() * api.page.len() + 1;

          $(rows).each(function (index) {
            $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
            startIndex++;
          });
        },
      });
    },100);

    this.datatableload=true
  }

  datatableload:any

  p: number = 1; // Current page
  resultsPerPage: number = 2; // Number of results per page
  totalResults!: number; // Total number of results
  @Input() kbSearch:any;
  @Output() searchEvent: EventEmitter<void> = new EventEmitter<void>();
  allfaq:any;
  calculateRange() {
    const start = (this.p - 1) * this.resultsPerPage + 1;
    let end = Math.min(this.p * this.resultsPerPage, this.totalResults);

    if (start === end && start === this.totalResults) {

      end = start;
    }

    return { start, end };
  }

    jsonData: any[] = [];

    dataArray:any;

    knowledgebasejsondata: string = 'assets/json/knowledgebase.json';

    // tcontainerbody = {
    //   height:(($(window).height() - $('.layout-navbar').height()) - 350) + 'px'
    // };
    constructor(private knowledgeSearchService:KnowledgebaseService,private router:Router, private http:HttpClient,private cdr: ChangeDetectorRef, private authService: MsalService) {}
    ngOnChanges(){
      console.log(this.kbSearch,'kbs');
      // if(this.kbSearch){
      //   this.searchterms = this.kbSearch;
      // this.onSearch();
      // }

     }
     stripHtmlAndEntities1(htmlString: string, keyword: string): string {

      if (!htmlString) return '';

      // Remove HTML tags
      const withoutTags = htmlString.replace(/<[^>]*>/g, '');

      // Remove &nbsp; entities
      const withoutEntities = withoutTags.replace(/&nbsp;/g, ' ');

      // Split content into words
      const words = withoutEntities.split(' ');

      // Check if keyword is present in any of the words
      const result = words.map(word => {
          if (word.toLowerCase().includes(keyword.toLowerCase())&& keyword!='') {
              return `<strong style="background-color: yellow;">${word}</strong>`;
          } else {
              return word;
          }
      }).join(' ');

      return result;
  }
     stripHtmlAndEntities(htmlString: string): string {
      if (!htmlString) return '';

      // Remove HTML tags
      const withoutTags = htmlString.replace(/<[^>]*>/g, '');
      const trimmedString = withoutTags.trim().replace(/^"/, '').replace(/"$/, '');
      // Remove &nbsp; entities
      const withoutEntities = trimmedString.replace(/&nbsp;/g, ' ');

      // Remove quotation marks
      const withoutQuotationMarks = withoutEntities.replace(/"/g, '');

      // Add space character
      const withSpace = withoutQuotationMarks + ' ';

      return withSpace;
  }

  searchQuery1:any;
  // searchPerformed: boolean = false;
  filteredResults: any[] = [];
// keyword:any;
initialPageResetDone= "search";
faqtitle:any;
showTable:boolean=false;
loadsmartFAQ=false;
loadFAQs(): void {
  this.loadsmartFAQ=false;
  this.searchQuery1=""
    this.checked=false;
    this.isAdvancedSearchActive=false;
    this.showTable = !this.isAdvancedSearchActive;
    this.datatableload=false
    this.faqtitle="All";
    console.log(this.currentPage)
    console.log(this.pageSize)
    this.knowledgeSearchService.getallfaq(this.currentPage,this.pageSize)
      .subscribe((data: any) => {
        console.log(data);
        this.allfaq = data.answerlist;
  this.answerlistlength=data.answerlist.length;
        this.totalpagesize=data.size;   ;
        console.log(this.allfaq);

        this.datatablepagination();
      });
  }
getpopularfaq()
{

this.loadsmartFAQ=false;
  this.searchQuery1=""
  this.checked=false;
  this.isAdvancedSearchActive=false;
  this.showTable = !this.isAdvancedSearchActive; // Show table only if isAdvancedSearchActive is false

  this.datatableload=false
  this.cdr.detectChanges();
  this.faqtitle="Popular";
  console.log(this.searchQuery)
  this.searchQuery='';
  console.log(this.searchQuery)
  this.knowledgeSearchService.getpopularfaq(this.currentPage,this.pageSize)
  .subscribe((data: any) => {
    console.log(data);
    this.allfaq = data.answerlist;
this.answerlistlength=data.answerlist.length;
    this.totalpagesize=data.size;   ;
    console.log(this.allfaq);

    this.datatablepagination()
    // this.searchdatatablepagination()
  });
}
getrecentfaq()
{
this.loadsmartFAQ=false;
this.searchQuery1=""
  this.checked=false;
  this.isAdvancedSearchActive=false;
  this.showTable = !this.isAdvancedSearchActive;

  this.datatableload=false
  this.faqtitle="Recent";
  this.knowledgeSearchService.getrecentanswers()
  .subscribe((data: any) => {
    console.log(data);
    this.allfaq = data?.answerlist;
this.answerlistlength=data?.answerlist.length;
    this.totalpagesize=data?.size?data?.size:0;
    console.log(this.totalpagesize)
    console.log(this.allfaq);
    this.datatablepagination()
  });
}
getsmartFAQ()
{
  this.loadsmartFAQ=true
  this.searchQuery1=""
  this.checked=false;
  this.isAdvancedSearchActive=false;
  this.showTable = !this.isAdvancedSearchActive; // Show table only if isAdvancedSearchActive is false

  this.datatableload=false
  this.cdr.detectChanges();
  this.faqtitle="smartFAQ";
  console.log(this.searchQuery)
  this.searchQuery='';
  console.log(this.searchQuery)
//   this.knowledgeSearchService.getpopularfaq(this.currentPage,this.pageSize)
//   .subscribe((data: any) => {
//     console.log(data);
//     this.allfaq = data.answerlist;
// this.answerlistlength=data.answerlist.length;
//     this.totalpagesize=data.size;   ;
//     console.log(this.allfaq);

//     this.datatablepagination()
//     // this.searchdatatablepagination()
//   });
}
// searchdatatableload:any
  onSearch1(query: string) {
    // this.searchdatatableload=false;
    this.datatableload=false
    this.faqtitle="All";
    console.log(this.faqtitle)
    console.log(query)
      // this.searchPerformed = true; // Set the flag to true to indicate that a search has been performed
      const searchResults: any[] = [];
      this.searchQuery = query;

      console.log(this.pageSize)
      console.log(this.initialPageResetDone)
      if(this.searchQuery=='')
      {
        this.loadFAQs()
        console.log("hyyy search")
        if(this.currentPage>0)
        {
          this.currentPage=0;
        }
      }
      if(this.initialPageResetDone=="search"){
      if(this.currentPage>0)
      {
        this.currentPage=0;
      }
    }
      console.log(this.currentPage);
      if(this.searchQuery!='')
        {
          this.knowledgeSearchService.getallkeyword(query,this.currentPage,this.pageSize).subscribe((data:any)=>
            {
              console.log(data);
              // this.filteredResults=data;
              this.allfaq=data.answerlist;
              // console.log(this.allfaq[0].answeridobject.summary)
              this.totalpagesize=data.size;

              this.datatablepagination()
              // this.searchdatatablepagination()
            })
        }


this.initialPageResetDone="search";
console.log(this.initialPageResetDone)

console.log(this.initialPageResetDone)
  }
keyword:any;
recentsearch:any;
getallrecentsearches(){
  this.knowledgeSearchService.getrecentsearch().subscribe((data:any)=>
  {
    console.log(data)
    this.recentsearch=data;

  })
}
page:any;
size=['10','25','50','100'];
currentPage: number = 0;
pageSize: number = 10;
totalpagesize:any;
totalItems: number = 50;
answerlistlength:any;

onpagesize(pagesize:any)
{
  console.log(pagesize)
  this.pageSize=pagesize;
  if(this.searchQuery=='' && this.faqtitle==="All")
  {
    console.log("search query in loadfaq",this.searchQuery)

    // this.goToPage(this.currentPage)
    this.getPageNumbers()
    this.loadFAQs();

    console.log(this.currentPage)
    console.log(this.pageCount)
    // this.datatablepagination();
    // this.getPageNumbers();
  }

  console.log(this.searchQuery)
  if(this.searchQuery!='')
  {
    console.log("search query in onsearch",this.searchQuery)
    this.getPageNumbers()
this.onSearch1(this.searchQuery)
  }
  if(this.faqtitle==="Popular")
    {
      console.log("in popular")
      this.getPageNumbers()
      this.getpopularfaq()
    }
    if(this.faqtitle==="Recent")
      {  this.getPageNumbers()
        this.getrecentfaq()

      }
}
nextPage2(): void {
  this.cdr.detectChanges();
  this.currentPage++;
console.log(this.faqtitle)
  if(this.searchQuery=='' && this.faqtitle==="All")
  {
    console.log("search query in loadfaq",this.searchQuery)
    this.loadFAQs();
  }

  console.log(this.searchQuery)
  if(this.searchQuery!='')
  {
    this.initialPageResetDone="next";
    console.log("search query in onsearch",this.searchQuery)
this.onSearch1(this.searchQuery)
  }
  if(this.faqtitle==="Popular")
    {
      // this.currentPage=0;
      this.searchQuery='';
      console.log("in popular")
      // this.getPageNumbers()
      this.getpopularfaq()
    }
    if(this.faqtitle==="Recent")
      {
        // this.getPageNumbers()
        this.getrecentfaq()

      }
}

previousPage2(): void {
  if (this.currentPage > 0) {
    this.currentPage--;
    if(this.searchQuery=='' && this.faqtitle==="All")
    {
      console.log("search query in loadfaq",this.searchQuery)
      this.loadFAQs();
    }
    if(this.searchQuery!='')
    {
      console.log("search query in onsearch",this.searchQuery)
  this.onSearch1(this.searchQuery)
    }
    if(this.faqtitle==="Popular")
      {
        console.log("in popular")
        // this.getPageNumbers()
        this.getpopularfaq()
      }
      if(this.faqtitle==="Recent")
        {
          // this.getPageNumbers()
          this.getrecentfaq()

        }
  }
}
pageCount:any;
endpage:any;
getPageNumbers(): number[] {
  // this.loadFAQs();
  if(this.searchQuery!='')
  {
    this.initialPageResetDone="next";
    // console.log("search query in onsearch",this.searchQuery)
// this.onSearch1(this.searchQuery)
  }
  const pageCount = Math.ceil(this.totalpagesize / this.pageSize);
  this.pageCount = pageCount;
  const pageNumbers = [];
  let currentPageIndex = this.currentPage;

  // Show first 5 page numbers initially
  let startPage = Math.max(0, currentPageIndex - 2);
  this.endpage = Math.min(pageCount - 1, startPage + 4);

  // Adjust startPage and endPage when total items are less than expected end page
  if (this.endpage - startPage < 4 && this.endpage < pageCount - 1) {
    startPage = Math.max(0, this.endpage - 4);
  }
if(this.currentPage>this.endpage)
{
  this.currentPage=this.endpage;
  console.log(this.currentPage)
  // this.loadFAQs()

}
  for (let i = startPage; i <= this.endpage; i++) {
// console.log(pageNumbers)
    pageNumbers.push(i);
  }

  return pageNumbers;
}


goToPage(pageNumber: number): void {
  this.currentPage = pageNumber;
  console.log(this.currentPage)
  if(this.searchQuery=='' && this.faqtitle==="All")
  {
    console.log("search query in loadfaq",this.searchQuery)
    this.loadFAQs();
  }

  console.log(this.searchQuery)
  if(this.searchQuery!='')
  {
    console.log("search query in onsearch",this.searchQuery)
this.onSearch1(this.searchQuery)
  }
  if(this.faqtitle==="Popular")
    {
      console.log("in popular")
      // this.getPageNumbers()
      this.getpopularfaq()
    }
    if(this.faqtitle==="Recent")
      {
        // this.getPageNumbers()
        this.getrecentfaq()

      }
}
getRecordsRange(): string {
  // console.log(this.totalpagesize)
  const startRecord =this.totalpagesize>0? this.currentPage * this.pageSize + 1:0;
  // console.log(startRecord)
  const endRecord = Math.min((this.currentPage + 1) * this.pageSize, this.totalpagesize);
  // console.log(endRecord)

  return `Showing ${startRecord} - ${endRecord} of ${this.totalpagesize}`;
}



logInfo:any;
    ngOnInit()
    {
      console.log(this.knowledgeSearchService.searchterm)
      if(this.knowledgeSearchService.searchterm!=undefined)
        {
          this.searchQuery1=this.knowledgeSearchService.searchterm;
          this.onSearch1(this.searchQuery1)
        }
        else{
          this.getPageNumbers();
          // this.fetchdata();
          // console.log(this.dataArray);

          // console.log(this.dataArray);
          this.loadFAQs();
        }
      // if(this.authService.instance.getAllAccounts().length > 0){
      //   this.logInfo = this.authService.instance.getAllAccounts()[0];
      //   console.log('test header info',this.logInfo);
      // }
      // this.getpopularfaq();
//       this.knowledgeSearchService.getallfaq(this.page,this.size).subscribe((data:any)=>
//       {
// console.log(data)
// this.allfaq=data;
//       })
      // this.knowledgeSearchService.getallautosuggest(this.keyword).subscribe((data:any)=>
      // {
      //   console.log(data)
      // })
      console.log(this.kbSearch,'kbsearch')
      // if(this.kbSearch){
      // this.searchterms = this.kbSearch;
      // this.onSearch();
      // }
      // this.getallrecentsearches();

      // this.knowledgeSearchService.getallautosuggest(this.searchterms);
    }

    paginateItems(items: any[], page: number, itemsPerPage: number): any[] {
      const startIndex = (page - 1) * itemsPerPage;
      return items.slice(startIndex, startIndex + itemsPerPage);
    }

    itemsPerPage = 2;
    currentPage1 = 1;



    getPaginatedItems() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      return this.jsonData.slice(startIndex, startIndex + this.itemsPerPage);
    }

    nextPage1() {
      if (this.currentPage < Math.ceil(this.jsonData.length / this.itemsPerPage)) {
        this.currentPage++;
      }
    }

    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    }

    filterDataone(query: string): any[] {
      // console.log(dataArray);
      console.log("query is " + query);

      query = query.toLowerCase();

      return this.dataArray.filter((department: any) => {
        return department.categories.some((category: any) => {
          return category.items.some((item: any) => {
            return (
              item.title.toLowerCase().includes(query) ||
              item.question.toLowerCase().includes(query) ||
              item.answer.toLowerCase().includes(query)
            );
          });
        });
      });
    }

    keyy:any;
    querydata:any;

    separateItems(data:any) {
      const separatedItems = [];

      for (const department of data) {
        for (const category of department.categories) {
          console.log(category)
          for (const item of category.items) {
            separatedItems.push({
              department: department.department,
              category: category.name,
              title: item.title,
              question: item.question,
              answer: item.answer,
              published: item.published,
              updated: item.updated,
              phone: item.phone,
              id:item.id,

              // Add more fields as needed
            });
          }
        }
      }

      return separatedItems;
    }


    separatedData:any;


    // Modify the filterData function to work with the separated data
    // filterData(query: string, selectedDepartment: string, selectedCategory: string) {

    //   let querydata = [...this.separatedData]; // Use the separated data

    //   if (query) {
    //     query = query.toLowerCase();
    //     querydata = querydata.filter((item) => (
    //       item.title.toLowerCase().includes(query) ||
    //       item.question.toLowerCase().includes(query) ||
    //       item.answer.toLowerCase().includes(query)
    //     ));
    //   }

    //   if (selectedDepartment) {
    //     selectedDepartment = selectedDepartment.toLowerCase();
    //     querydata = querydata.filter((item) => (
    //       item.department.toLowerCase().includes(selectedDepartment)
    //     ));
    //   }

    //   if (selectedCategory) {
    //     console.log(selectedCategory)
    //     selectedCategory = selectedCategory.toLowerCase();
    //     querydata = querydata.filter((item) => (
    //       item.category.toLowerCase().includes(selectedCategory)
    //     ));
    //   }


    //   return querydata;
    // }
    // filterData(query: string, selectedDepartment: string, selectedCategory: string): any[] {
    //   let querydata = [...this.separatedData];

    //   const uniqueTitles = new Set();

    //   if (query) {
    //     querydata = querydata.filter((item) => {
    //       const compositeKey = `${item.title.toLowerCase()}|${item.question.toLowerCase()}|${item.answer.toLowerCase()}`;

    //       if (!uniqueTitles.has(compositeKey)) {
    //         uniqueTitles.add(compositeKey);
    //         const matchesQuery = !query || compositeKey.includes(query.toLowerCase());
    //         const matchesDepartment = !selectedDepartment || item.department.toLowerCase().includes(selectedDepartment.toLowerCase());
    //         const matchesCategory = !selectedCategory || item.category.toLowerCase().includes(selectedCategory.toLowerCase());

    //         return matchesQuery && matchesDepartment && matchesCategory;

    //       }

    //       return false;
    //     });
    //   }

    //   if (selectedDepartment) {
    //     selectedDepartment = selectedDepartment.toLowerCase();
    //     querydata = querydata.filter((item) => (
    //       item.department.toLowerCase().includes(selectedDepartment)
    //     ));
    //   }

    //   if (selectedCategory) {
    //     selectedCategory = selectedCategory.toLowerCase();
    //     querydata = querydata.filter((item) => (
    //       item.category.toLowerCase().includes(selectedCategory)
    //     ));
    //   }

    //   return querydata;
    // }

    filterData(query: string, selectedDepartment: string, selectedCategory: string): any[] {

      let querydata = [...this.separatedData];
      this.p=1;
      const uniqueTitles = new Set();

      querydata = querydata.filter((item) => {
        const compositeKey = `${item.title.toLowerCase()}|${item.question.toLowerCase()}|${item.answer.toLowerCase()}`;

        if ((query || query=='') && (!selectedDepartment || !selectedCategory)) {
          // Apply uniqueTitles filter only when there is a query and either department or category is not selected
          if (!uniqueTitles.has(compositeKey)) {
            uniqueTitles.add(compositeKey);
            const matchesQuery = !query || compositeKey.includes(query.toLowerCase());

            return matchesQuery;
          }
        } else {
          // No query or both department and category are selected, no need for uniqueTitles filter

          const matchesQuery = !query || compositeKey.includes(query.toLowerCase());
          const matchesDepartment = !selectedDepartment || item.department.toLowerCase().includes(selectedDepartment.toLowerCase());
          const matchesCategory = !selectedCategory || item.category.toLowerCase().includes(selectedCategory.toLowerCase());

          return matchesQuery && matchesDepartment && matchesCategory;
        }

        return false;
      });

      if (selectedDepartment) {
        selectedDepartment = selectedDepartment.toLowerCase();
        querydata = querydata.filter((item) => (
          item.department.toLowerCase().includes(selectedDepartment)
        ));
      }

      if (selectedCategory) {
        console.log(selectedCategory)
        selectedCategory = selectedCategory.toLowerCase();
        querydata = querydata.filter((item) => (
          item.category.toLowerCase().includes(selectedCategory)
        ));
      }

      return querydata;
    }
    displayfilters:boolean=false;
    filteredData: any[] = [];
    selectedOption:any;
    categoryidselected:any;
    searchterms:any;
    selectedDepartment: any;
    selectedCategory: any;
    searchQuery: string = '';
  advancesearchpopup:boolean=false;
    categories:any;

    departments = [];
    updateCategories() {

      this.selectedCategory='';// Find the selected department based on its name
      const selectedDept = this.dataArray.find((dept:any) => dept.department === this.selectedDepartment);
  console.log(selectedDept)
      if (selectedDept) {
        this.categories = selectedDept.categories;
      } else {
        this.categories = []; // Clear categories if no department is selected
      }
    }
  // onSearch(): void {

  //   this.displayfilters=true;
  //   this.knowledgeSearchService.updateSearchQuery(this.searchterms, this.selectedDepartment, this.selectedCategory);

  // }
  catid:any;
  filtereddata:any;
  onSearch(): void {

    this.knowledgeSearchService.updateSearchQuery(this.searchterms, this.selectedDepartment, this.selectedCategory);
    // console.log(this.searchterms, this.selectedDepartment, this.selectedCategory,'1111')
    // this.searchEvent.emit();
    // this.knowledgeService.triggerSearchEvent();
  }

  advsearchpopup()
  {
    this.advancesearchpopup=true;
  }

  closepopup()
  {
    this.advancesearchpopup=false;
  }

  removecategoryfilter()
  {
    this.selectedCategory='';
    this.onSearch();
  }

  removeproductfilter()
  {
    this.selectedDepartment='';
    this.onSearch();
  }
    selectedItemId: any = null;

    showSeparatedData: boolean = false;
    selectedData: any = null;
    // Function to display separatedData based on ID
    displaySeparatedData(itemId: any) {
      this.selectedItemId = itemId;
      const selectedItem = this.separatedData.find((item: { id: any; }) => item.id === itemId);
      this.selectedData = selectedItem ? selectedItem : null;
      this.showSeparatedData = true;

      console.log(this.selectedItemId)
    }
    showJsonData() {
      this.showSeparatedData = false; // Set flag to show jsonData
    }

    // toggleAdvanceSearch(checked: boolean) {
    //   this.isAdvancedSearchActive = checked;
    //   console.log("toggleAdvanceSearch",this.isAdvancedSearchActive);
    //   if (checked) {
    //     console.log("AI Advance Search activated");
    //   } else {
    //     console.log("AI Advance Search deactivated");
    //   }
    // }

    // showTable: boolean = false; // Initialize variable to control table visibility

// togglePopular() {
//   this.showTable = !this.isAdvancedSearchActive;
// }

toggleAdvanceSearch(checked: boolean) {
  console.log(checked)
  if(!checked)
    {
      this.datatablepagination()
    }
  this.isAdvancedSearchActive = this.checked;
  if (!this.isAdvancedSearchActive) {
    this.showTable = false; // Hide table when isAdvancedSearchActive is false
  }else{
    this.showTable=true;
  }
}

}

