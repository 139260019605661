import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { AnyCatcher } from 'rxjs/internal/AnyCatcher';
import { MapService} from '../services/mapservice';
import { makeServiceRequestService } from '../make-service-request.service';

@Component({
  selector: 'app-google-map',
  templateUrl: './google-map.component.html',
  styleUrls: ['./google-map.component.scss'],
})
export class GoogleMapComponent implements OnInit {
  regionCode!: string;
  searchedPlaces: string[] = [];
  isLocRequired: boolean = false;
  // storedAddresses: string[] = [];
  storedAddresses: any[] = [];
  searchedAutoPlaces: any[] = [];
  @Input() isAddressRequired: any;
  @Input() isSearchNotRequired: any;
  @Input() isPopupShow!: Subject<any>;
  @Output() searchLocationEvent: EventEmitter<string> = new EventEmitter();
  @Output() serviceTypeDetailsEvent = new EventEmitter();


  locationInfo: any = localStorage.getItem('locationInfo');
  userListSubmit: any = localStorage.getItem('userListSubmit');
  userInfo: any = localStorage.getItem('loginUserInfoDetails');
  private map!: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  infoWindows: google.maps.InfoWindow[] = [];
  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow()

  constructor(private datePipe: DatePipe, private http:HttpClient, private mapService: MapService,private agentscriptservice:makeServiceRequestService) {
    this.storedAddresses = this.getStoredAddresses();
  }

  searchLocation:any
  ngOnInit(): void {
    this.searchLocation=this.agentscriptservice.addresstobesearched


    this.retrieveStoredAddresses();
    this.initializeMap();
    const map = new google.maps.Map(
      document.getElementById('map') as HTMLElement,
      {
        center: { lat: 35.0456, lng: -85.3097 },
        zoom: 12,
      }
    );

    const card = document.getElementById('pac-card') as HTMLElement;

    map.controls[google.maps.ControlPosition.TOP_LEFT].push(card);

    const center = { lat: 50.064192, lng: -130.605469 };
    const defaultBounds = {
      north: center.lat + 0.1,
      south: center.lat - 0.1,
      east: center.lng + 0.1,
      west: center.lng - 0.1,
    };
    const input = document.getElementById('pac-input') as HTMLInputElement;

    // const typedAddress = input.value;
    const options = {
      bounds: defaultBounds,
      types: ['geocode'],
      fields: ['address_components', 'geometry', 'icon', 'name'],
      strictBounds: true,
    };

    const autocomplete = new google.maps.places.Autocomplete(input);

    autocomplete.setOptions(options);
    const southwest = { lat: 5.6108, lng: 136.589326 };
    const northeast = { lat: 61.179287, lng: 2.64325 };
    const newBounds = new google.maps.LatLngBounds(southwest, northeast);

    autocomplete.setBounds(newBounds);

    const infowindow = new google.maps.InfoWindow();
    const infowindowContent = document.getElementById(
      'infowindow-content'
    ) as HTMLElement;

    infowindow.setContent(infowindowContent);

    const marker = new google.maps.Marker({
      map,
      anchorPoint: new google.maps.Point(0, -29),
    });

    autocomplete.addListener('place_changed', () => {
      infowindow.close();
      marker.setVisible(false);
      const place = autocomplete.getPlace();

      if (!place.geometry || !place.geometry.location) {
        window.alert("No details available for input: '" + place.name + "'");
        return;
      }
      let street = '';
      let city = '';
      let state = '';
      let country = '';
      let pincode = '';
      let county = '';
      const lat = place.geometry.location.lat();
      const lon = place.geometry.location.lng();

      const addressComponents: string[] = [];
      if (place.address_components && place.address_components.length > 0) {
        place.address_components.forEach((component) => {
          addressComponents.push(component.long_name || '');
        });
      }
      if (place.address_components && place.address_components.length > 0) {

        this.isLocRequired = true;
        place.address_components.forEach((component) => {
          if (component.types.includes('administrative_area_level_1')) {
            state = component.long_name || '';
          } else if (component.types.includes('route')) {
            street = component.long_name || '';
          } else if (component.types.includes('locality')) {
            city = component.long_name || '';
          } else if (component.types.includes('postal_code')) {
            pincode = component.long_name || '';
          } else if (component.types.includes('country')) {
            country = component.long_name || '';
          }
          else if (place.address_components) {
            place.address_components.forEach((component) => {
              if (component.types.includes('administrative_area_level_2')) {
                county = component.long_name || '';
              }
            });
          }
        });
      }
      marker.setTitle(addressComponents.join(', '));

      console.log('Address Components:', addressComponents.join(', '));
      console.log('street', street);
      console.log('state', state);
      console.log('city', city);
      console.log('pincode', pincode);
      console.log('country', country);

      const addressObj: any = {
        address: addressComponents.join(', '),
        city: city,
        state: state,
        country: country,
        postalcode: pincode,
        streetName: street,
        lat: lat,
        lon: lon,
        county: county
      };
      this.searchLocationEvent.emit(addressObj);
      let mapOptions = {}

      if (this.userListSubmit && this.isSearchNotRequired) {
        this.userListSubmit = JSON.parse(addressObj);
        mapOptions = {
          center: { lat: addressObj.lat, lng: addressObj.lon },
          zoom: 8
        };
      } else {
        mapOptions = {
          center: { lat: 0, lng: 0 },
          zoom: 2
        };
      }

      if (place.geometry.viewport) {
        map.fitBounds(place.geometry.viewport);
        console.log('1');
        map.setCenter(place.geometry.location);
        map.setZoom(17);
      } else if (place.geometry.location) {
        map.setCenter(place.geometry.location);
        console.log('2');

        map.setZoom(24);
      }

      marker.setPosition(place.geometry.location);
      marker.setVisible(true);

      let address = '';

      if (place.address_components) {
        address = [
          (place.address_components[0] &&
            place.address_components[0].long_name) ||
          '',
          (place.address_components[1] &&
            place.address_components[1].long_name) ||
          '',
          (place.address_components[2] &&
            place.address_components[2].long_name) ||
          '',
        ].join(' ');
      }

      var locStorage: any = localStorage.getItem('searchedPlaces');

      // console.log('locStorage', locStorage);

      if (locStorage) {
        let allSearchedPlaces:[]= JSON.parse(locStorage);
        this.searchedPlaces = allSearchedPlaces.filter((item:any) => !item.isAutoLoc);
      }

      this.searchedPlaces.push(addressObj);

      localStorage.setItem(
        'searchedPlaces',
        JSON.stringify(this.searchedPlaces)
      );

      this.storedAddresses = this.getStoredAddresses();
    });

    function setupClickListener(id: string, countries: string[] | string) {
      const radioButton = document.getElementById(
        id
      ) as unknown as HTMLInputElement;

      if (radioButton) {
        radioButton.addEventListener('click', () => {
          autocomplete.setComponentRestrictions({ country: countries });
        });
      }
    }

    // setupClickListener('changecountry-andhra-pradesh', ['IN-AP']); // For Andhra Pradesh
    if (this.isAddressRequired) {
      this.addMarkersForSearchedPlaces(map);
      if (this.markers && this.markers.length) {
        this.isPopupShow.subscribe(addressDtls => {
          this.showPopupForDefaultAddress(addressDtls);
        });
      }

    }
    // this.filterSuggestions();

  }

  mapElement!: HTMLElement;
  showStreetView1() {
    this.mapElement = document.getElementById('map') as HTMLElement;
    console.log(this.storedAddresses)
    const lastSearchedAddress = this.storedAddresses
    console.log(lastSearchedAddress)
    event?.preventDefault();
    console.log(this.mapElement)
    if (!this.mapElement) {
        console.log('Map element with ID "map" not found in the DOM.');
        return;
    }

    console.log(this.agentscriptservice.locationcoordinates)
    let centerLatLng;
    if (this.agentscriptservice.locationcoordinates && this.agentscriptservice.locationcoordinates.lng && this.agentscriptservice.locationcoordinates.lat) {
        centerLatLng = { lng: +this.agentscriptservice.locationcoordinates.lng, lat: +this.agentscriptservice.locationcoordinates.lat };
    } else {
        // Use Fort Wayne's coordinates as default
        centerLatLng = { lng: -85.1205, lat: 41.1373 };
    }

    const mapOptions: google.maps.MapOptions = {
        center: centerLatLng,
        zoom: 15
    };

    console.log(mapOptions)
    const map = new google.maps.Map(this.mapElement, mapOptions);
    const streetViewService = new google.maps.StreetViewService();

    if (mapOptions.center) {
        streetViewService.getPanorama(
            {
                location: mapOptions.center,
                radius: 50
            },
            (data, status) => {
                if (status === 'OK') {
                    if (data && data.location) {
                        const panorama = new google.maps.StreetViewPanorama(
                            this.mapElement,
                            { position: data.location.latLng }
                        );
                        map.setStreetView(panorama);
                    } else {
                        console.log('Street View data not found for this location');
                    }
                } else {
                    console.log('Street View data not found for this location');
                }
            }
        );
    } else {
        console.log('Map center is not defined.');
    }
}



  retrieveStoredAddresses() {
    // alert()
    // this.storedAddresses = this.getStoredAddresses();
    this.showStreetView1()
  }

  getStoredAddresses(): string[] {
    const storedAddressesJSON = localStorage.getItem('searchedPlaces');
    if (storedAddressesJSON) {
      return JSON.parse(storedAddressesJSON) as string[];
    } else {
      return [];
    }
  }

  cityError!: boolean;
  filterSuggestions() {
    this.cityError = false;
    setTimeout(() => {
      $('body')
        .find('.pac-container .pac-item')
        .each(function (item) {
          // console.log("item",$(this).text().toLowerCase());
          let keyWorkd = $(this).text().toLowerCase();
          if (keyWorkd.indexOf('chattanooga') == -1) {
            $(this).remove();
          }
          $('.pac-item').css('display', 'block');
        });
    }, 300);
  }
  getSuggestion() { }

  addMarkersForSearchedPlaces(map: google.maps.Map) {
    let userListSubmit: any = localStorage.getItem('userListSubmit');
    let storedPlaces:any = [];
    if (userListSubmit) {
      userListSubmit = JSON.parse(userListSubmit);
      userListSubmit.forEach((place:any) => {
        if(place.locationInfo){
          storedPlaces.push(place.locationInfo);
        }
      });
      storedPlaces.forEach((place:any) => {
        if (typeof place === 'object' && place.lat && place.lon) {
          const lat = parseFloat(place.lat);
          const lon = parseFloat(place.lon);
          if (!isNaN(lat) && !isNaN(lon)) {
           const location = new google.maps.LatLng(lat, lon);
          //const location:any = {lat:lat, lon:lon};
            const marker = new google.maps.Marker({
              map,
              position: location,
              title: place.address,
            });
            //  const _self = this;
            //  if (this.markers.length > 0) {
            //   for (let i = 0; i < this.markers.length; i++) {
            //     this.markers[i].setMap(null);
            //   }
            //   // this.markers = [];
            // }

            const infoWindow = new google.maps.InfoWindow({
              content: `
                <div>
                  <strong>State:</strong> ${place.state || 'N/A'}<br>
                  <strong>City:</strong> ${place.city || 'N/A'}<br>
                  <strong>Country:</strong> ${place.country || 'N/A'}
                </div>
              `,
            });
            this.markers.push(marker);

            this.infoWindows.push(infoWindow);

            marker.addListener('click', () => {

              this.infoWindows.forEach((info, i) => {
                if (i !== this.markers.indexOf(marker)) {
                  info.close();
                }
              });

              infoWindow.open(map, marker);
            });
          }
        }
      });
    }
  }

  private initializeMap() {
    // Create the map instance
    if (!this.isAddressRequired && navigator.geolocation  && this.userInfo) {
      var lng: any;
      var lat: any;
      navigator.geolocation.getCurrentPosition((position) => {
        lat = position.coords.latitude;
        lng = position.coords.longitude;
        this.map = new google.maps.Map(
          document.getElementById('map') as HTMLElement,
          {
            center: { lat: lat, lng: lng },
            zoom: 12,
          }
        );
        //this.getAddressFromCoords(lat, lng);
        this.getLocationDtlsByLatLan(lat,lng);
      });

    } else {

      this.map = new google.maps.Map(
        document.getElementById('map') as HTMLElement,
        {
          center: { lat: 0, lng: 0 },
          zoom: 12,
        }
      );
    }
  }

  openInfoWindowForMarker(index: number) {
    console.log('Clicked on address with index:', index);

    const map = this.map;
    const markers = this.markers;
    const infoWindows = this.infoWindows;
    console.log('Markers:', this.markers);
    console.log('InfoWindows:', this.infoWindows);
    if (map && markers && infoWindows) {
      if (index >= 0 && index < markers.length && index < infoWindows.length) {
        infoWindows.forEach((infoWindow, i) => {
          if (i !== index) {
            infoWindow.close();
          }
        });

        infoWindows[index].open(map, markers[index]);
      }
    }
  }
  showPopupForDefaultAddress(address: any) {
    let datePipeString = this.datePipe.transform(address.srReportDate, 'MM/dd/yy, hh:mm a');
    this.infoWindow.close();
    const marker = this.markers[address.index];
    this.infoWindow.setContent(`<div>
  <strong>Case Type:</strong> ${address.content}<br />
  <strong>Address:</strong> ${address.serviceAddress}<br />
  <strong>Sr Report Date:</strong> ${datePipeString}<br />
  <strong>Sr Status:</strong> ${address.srStatus}<br />
</div>`);
    this.infoWindow.open(this.map, marker);
  }

  addMarkersForSearchedPlacesNew(map: google.maps.Map) {
    const storedPlaces: any[] = this.searchedAutoPlaces;
    storedPlaces.forEach((place) => {
      if (typeof place === 'object' && place.lat && place.lon) {
        const lat = parseFloat(place.lat);
        const lon = parseFloat(place.lon);

        if (!isNaN(lat) && !isNaN(lon)) {
          const location = new google.maps.LatLng(lat, lon);
          const marker = new google.maps.Marker({
            map,
            position: location,
            title: place.address,
          });

          const infoWindow = new google.maps.InfoWindow({
            content: `
              <div>
                <strong>State:</strong> ${place.state || 'N/A'}<br>
                <strong>City:</strong> ${place.city || 'N/A'}<br>
                <strong>Country:</strong> ${place.country || 'N/A'}
              </div>
            `,
          });
          this.markers.push(marker);

          this.infoWindows.push(infoWindow);

          marker.addListener('click', () => {

            this.infoWindows.forEach((info, i) => {
              if (i !== this.markers.indexOf(marker)) {
                info.close();
              }
            });

            infoWindow.open(map, marker);
          });
        }
      }
    });
  }
  getLocationDtlsByLatLan(lat:any,lon:any){
    this.mapService.getAddress(lat,lon).subscribe((data: any) => {
      if (data.status === 'OK' && data.results[0]) {
          let street = '';
          let city = '';
          let state = '';
          let country = '';
          let pincode = '';
          let county = '';
          let streetList:any = [];
          const place = data.results[0];

          const addressComponents: string[] = [];
          if (place.address_components && place.address_components.length > 0) {
            place.address_components.forEach((component:any) => {
              addressComponents.push(component.long_name || '');
            });
          }
          if (place.address_components && place.address_components.length > 0) {

            this.isLocRequired = true;
            place.address_components.forEach((component:any) => {
              if (component.types.includes('administrative_area_level_1')) {
                state = component.long_name || '';
              } else if ((component.types.includes('route') || component.types.includes("premise")  || component.types.includes("sublocality"))&& component.long_name) {
                streetList.push(component.long_name)
              } else if (component.types.includes('locality')) {
                city = component.long_name || '';
              } else if (component.types.includes('postal_code')) {
                pincode = component.long_name || '';
              } else if (component.types.includes('country')) {
                country = component.long_name || '';
              }else if (place.address_components) {
                place.address_components.forEach((component:any) => {
                  if (component.types.includes('administrative_area_level_2')) {
                    county = component.long_name || '';
                  }
                });
              }
            });
          }

          console.log('Address Components:', addressComponents.join(', '));
          console.log('street', street);
          console.log('state', streetList);
          console.log('city', city);
          console.log('pincode', pincode);
          console.log('country', country);

          const addressObj: any = {
            address: addressComponents.join(', '),
            city: city,
            state: state,
            country: country,
            postalcode: pincode,
            streetName: streetList.join(', '),
            lat: lat,
            lon: lon,
            county: county,
            isAutoLoc:true
          };
          this.searchLocationEvent.emit(addressObj);
          var locStorage: any = localStorage.getItem('searchedPlaces');

        // console.log('locStorage', locStorage);
        let searchedAutoPlaces:any=[];
        this.searchedAutoPlaces.push(addressObj);
        if (locStorage) {
          let allSearchedPlaces:[] = JSON.parse(locStorage);
          searchedAutoPlaces = allSearchedPlaces.filter((item:any) => !item.isAutoLoc);
        }
          searchedAutoPlaces.push(addressObj);

          localStorage.setItem(
            'searchedPlaces',
            JSON.stringify(searchedAutoPlaces)
          );
          this.addMarkersForSearchedPlacesNew(this.map);


      } else {
        console.error('Geocoding failed:', data.status);
      }
    });
  }
  ngOnDestroy() {
    if (this.isAddressRequired && this.markers && this.markers.length) {
      this.isPopupShow.unsubscribe();
    }
  }
}
function p(p: any, arg1: (any: any) => any) {
  throw new Error('Function not implemented.');
}


