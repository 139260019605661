import { Component } from '@angular/core';

@Component({
  selector: 'app-waystocontact',
  templateUrl: './waystocontact.component.html',
  styleUrls: ['./waystocontact.component.scss']
})
export class WaystocontactComponent {
  ngOnInit()
{
  document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
}
}
