<div *ngIf="!isSignupRoute()">


  <ng-container *ngIf="isLandingPage; then someContent else otherContent"></ng-container>
  <ng-template #someContent>

    <div id="header" class="header  scroll-up-sticky  " >
  <section class="header-top ">
  <div class="d-992 comrob">
    <div class="logo-container  ">
      <div class="logo-con text-start">
        <a href="#" style="display: inline-block; margin: 0;">
          <img src="../../assets/images/FW-311-Logo-Citizen-Portal.svg" alt="fort wayne logo seal" class="logo-seel">
          <img src="../../assets/images/FW-311-Logo-Citizen-Portal.svg" alt="fort wayne logo seal" class="logo-seel" >
        </a>
      </div>
    </div>

    <div class="action-btn-header invisible ">
      <div class="header-action-r " >
        <app-notification *ngIf="isLogin" class=""></app-notification>
        <div class="dropdown "  *ngIf="!isLogin">
          <img src="../../assets/images/Notifications.svg" alt="Notifications" width="20px" class="opacity-1" >  </div>
        </div>
          <div class="header-action-r" >
        <!-- settings -->
        <!-- <div class="dropdown ">
          <a href="#" class="hover-text dropdown-toggle m-0" title="settings" data-bs-toggle="dropdown" aria-expanded="false">
          <img src="../../assets/images/setting.svg" alt="settings" width="20px" >
          </a>


        </div> -->
  </div>
        <div class="header-action-r " >
        <!-- login -->
        <div class="dropdown">

          <a [routerLink]="['/login']" class="hover-text m-0" title="Login" *ngIf="!isLogin">
            <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >
            </a>

            <a href="javascript:void(0)" id="logoutUser" class="hover-text dropdown-toggle" title="Account" *ngIf="isLogin"  data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >

            </a>
              <ul *ngIf="isLogin" class="dropdown-menu " aria-labelledby="logoutUser" >
                <li class="ps-2 d-flex" style="flex-direction: column;align-items: center;padding: 20px;">
                  <!-- <p class="text-center">{{logInfo.username}}</p> -->
                  <div>
                  <img src="../../assets/images/download.png" alt="settings" width="80px" height="80px" class="avatar-xl rounded-circle img-thumbnail">
                  </div>
                  <div class="ms-2">
                  <!-- <h4 class="mb-0 mt-2">Hi, {{logInfo.name}}</h4> -->
                  <!-- <p><small class="text-muted">Administrator</small> </p>  -->
                </div>
                </li>

                <li class="border-top bg-white text-center">
                  <a class="dropdown-item py-2" href="javascript:void(0)" (click)="logout()">

                  <svg height="22" viewBox="0 0 24 24" width="22" focusable="false" >
                    <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
                    <path d="M0 0h24v24H0z" fill="none"></path>
                  </svg>
                  Logout</a></li>
              </ul>
            </div>


      </div>
    </div>
  </div>

        <div class="secondary-inner ">
          <div class="logo-container dn-768 ">

            <div class="logo-con text-start">
              <a href="#" style="display: inline-block; margin: 0;">

                <img src="../../assets/images/FW-311-Logo-Citizen-Portal.svg" alt="fort wayne logo seal" class="logo-seel">
                <img src="../../assets/images/fort-wayne-logo-seal-dark.svg" alt="fort wayne logo seal" class="logo-seel" >

              </a>
            </div>
           </div>

  <div class="search-section">

    <div class="">
      <form class="navbar-form navbar-left d-flex justify-content-center gap-3" role="search">
         <div class="d-block search-container" >
          <img src="../../assets/images/search-black.svg" alt="search" class="searchline">
          <input type="text" autocomplete="off" name="kws" (keyup)="filterlist()" class="h-search dropdown-toggle" placeholder="Missed Garbage, Dead Animal etc." [(ngModel)]="searchQuery" data-bs-toggle="dropdown" aria-expanded="false" >

          <div class="dropdown-menu dropdown">
            <div class="rn-dp-container">
            <span *ngFor="let item of caseTypeVal" class="dropdown-item lpage-search-dropdown d-flex" (click)="onSelect(item)">
            <i class="fa-solid fa-clock-rotate-left ms-1 me-3" style="color: #5D5C5D;"></i>
            <span (click)="gotoNext({redirectTo:'home',viewname:'addressView','serviceDetails':item})" style="overflow: hidden;
            text-overflow: ellipsis;"> {{item.content}} </span>

            <span  class="text-primary hover-text ms-auto mb-0 pointer new-btn"
              (click)="gotoNext({redirectTo:'home',viewname:'addressView','serviceDetails':item})">
              <i  class="fa-solid fa-add"></i> </span>
            </span>

            </div>
          </div>


  <!--
          <button type="submit" class="searchClk" (click)="onSearch()" [routerLink]="['/knowledge-base']"><i
              class="bi bi-search"></i></button>
           <button type="submit" class="searchClk mic"><i class="bi bi-mic-fill" (click)="onSearch()"
              [routerLink]="['/knowledge-base']"></i></button> -->


        </div>
        <!-- <button class="a2z button-2" routerLink="/a2z">A-Z</button> -->
      </form>
    </div>



    <div class="cs-btnBox dn-991">
      <a class="button-2" role="button" routerLink="/create-sr">
        <span><img src="../../assets/images/NewSRdefult.svg" alt="New Service Request">  </span>
        <span class="dn-1200">New Service Request</span> <span class="d-1200">New SR</span></a>
      <a class="button-2" routerLink="/track-sr" role="button">
        <span><img src="../../assets/images/track-solid-line.svg" alt="Track Service Request"></span>
        <span class="dn-1200">Track a Service Request </span> <span class="d-1200">Track a SR</span></a>
      <a class="button-2 text-center" role="button" routerLink="/answers/list">
        <span><img src="../../assets/images/faq-solid-line.svg" class="track"></span>
        <span class="dn-1200">SMART® Knowledge Base </span> <span class="d-1200">SMART® Knowledge Base</span>

        </a>
    </div>

  </div>


  <div class="action-btn-header  dn-768 invisible">
          <div class="header-action-r" >
            <app-notification *ngIf="isLogin" class=""></app-notification>
            <div class="dropdown "  *ngIf="!isLogin">
              <img src="../../assets/images/Notifications.svg" alt="Notifications" width="18px" class="opacity-1">  </div>
            </div>
              <!-- <div class="header-action-r" >

            <div class="dropdown ">
              <a href="#" class="hover-text dropdown-toggle m-0" title="settings" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../assets/images/setting.svg" alt="settings" width="20px" >
              </a>


            </div>
  </div> -->
            <div class="header-action-r invisible" >
            <!-- login -->
            <div class="dropdown">

              <a [routerLink]="['/login']" class="hover-text m-0" title="Login" *ngIf="!isLogin">
                <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >
                </a>

                <a href="javascript:void(0)" id="logoutUser" class="hover-text dropdown-toggle" title="Account" *ngIf="isLogin"  data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >

                </a>
                  <!-- <ul *ngIf="isLogin" class="dropdown-menu " aria-labelledby="logoutUser" >
                    <li class="ps-2 d-flex" style="flex-direction: column;align-items: center;padding: 20px;">
                      <p class="text-center">{{logInfo.username}}</p>
                      <div>
                      <img src="../../assets/images/download.png" alt="settings" width="80px" height="80px" class="avatar-xl rounded-circle img-thumbnail">
                      </div>
                      <div class="ms-2">
                      <h4 class="mb-0 mt-2">Hi, {{logInfo.name}}</h4>
                    </div>
                    </li>

                    <li class="border-top bg-white text-center">
                      <a class="dropdown-item py-2" href="javascript:void(0)" (click)="logout()">

                      <svg height="22" viewBox="0 0 24 24" width="22" focusable="false" >
                        <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                      Logout</a></li>
                  </ul> -->
                </div>


          </div>
  </div>
  </div>
        <div class="cs-btnBox d-991">
          <a class="button-2" role="button" routerLink="/create-sr">
            <span><img src="../../assets/images/NewSRdefult.svg" alt="New Service Request">  </span>
            <span class="dn-1200">New Service Request</span> <span class="d-1200">New SR</span></a>
          <a class="button-2" routerLink="/track-sr" role="button">
            <span><img src="../../assets/images/track-solid-line.svg" alt="Track Service Request"></span>
            <span class="dn-1200">Track a Service Reques </span> <span class="d-1200">Track a SR</span></a>
          <a class="button-2 text-center" role="button" routerLink="/answers/list">
        <span><img src="../../assets/images/faq-solid-line.svg" class="track"></span>SMART® Knowledge Base</a>
        </div>


      </section>

    </div>
  </ng-template>


  <ng-template #otherContent>

    <div id="header2" class=" scroll-effect">
      <section class="header-top  bg-white ">
        <div class="secondary-inner d-flex">
          <div class="logo-container ">

            <div class="logo-con d-flex">
              <a routerLink="/" class="mt-2">
                <img src="../../assets/images/fort-wayne-logo-seal-dark.svg" alt="fort-wayne-logo-seal" class="logo-seel2 ot-page" height="50px" >

              </a>
              <!-- <div  class="d-block search-container"><input  type="text"
                  placeholder="Search" class="h-search" [(ngModel)]="searchQuery" ><button  class="searchClk"  (click)="onSearch()"  [routerLink]="['/knowledge-base']" ><i
                     class="bi bi-search" ></i></button><button
                  class="searchClk mic"><i  class="bi bi-mic-fill"></i></button></div> -->


            </div>
            <!-- <form class="navbar-form navbar-left" role="search">
              <a class="a2z" routerLink="/a2z" style="color: black;">A-Z</a>
        <div class="d-block search-container" >
          <input type="text" name="kws"  class="h-search" placeholder="Search" [(ngModel)]="searchQuery" style="margin-right: 780px">


       <button type="submit" class="searchClk"  (click)="onSearch()"  [routerLink]="['/knowledge-base']"><i class="bi bi-search"></i></button>
       <button type="submit" class="searchClk mic"><i class="bi bi-mic-fill" (click)="onSearch()" [routerLink]="['/knowledge-base']"></i></button>

      </div>
       </form> -->


          </div>
          <div class="header-action-r invisible">

            <div class="dropdown " >
              <!-- <button (click)="toggleSearchBox()" class="no-style-button" [class.open]="isSearchBoxOpen" >
                <img src="../../assets/images/search.svg">
                <i class="fa-solid fa-xmark" style="font-size: 22px;color: #275a8a;"></i>
              </button> -->

              <div *ngIf="isSearchBoxOpen"  class="searchtoggle">
                <input type="text" placeholder="Search..." class="form-control">
              </div>

            </div>
            <app-notification *ngIf="isLogin" class=""></app-notification>

            <div class="dropdown " *ngIf="!isLogin">
              <img src="../../assets/images/Notifications.svg" alt="Notifications" width="18px" class="opacity-1">


              <ul class="dropdown-menu " aria-labelledby="logoutUser" style="     padding-bottom: 0;   background: #f0f0f0;width: 200px;overflow: hidden; margin-top: 5px !important;">
                <li class="ps-2 d-flex" style="flex-direction: column;align-items: center;padding: 20px;">
                  <p class="text-center"> </p>

                  <div class="ms-2">
                  <h4 class="mb-0"> </h4>

                </div>
                </li>

                <li class="border-top bg-white">   <a class="dropdown-item py-2" >  Logout</a></li>
              </ul>
            </div>

            <!-- settings -->
            <div class="dropdown disabled">
              <!-- <a href="#" class="hover-text dropdown-toggle m-0" title="settings" data-bs-toggle="dropdown" aria-expanded="false">
              <img src="../../assets/images/setting.svg" alt="settings" width="20px" class="opacity-1">
              </a> -->
              <!-- <ul class="dropdown-menu " aria-labelledby="logoutUser" style="     padding-bottom: 0;   background: #f0f0f0;width: 200px;overflow: hidden; margin-top: 5px !important;">
                <li class="ps-2 d-flex" style="flex-direction: column;align-items: center;padding: 20px;">
                  <p class="text-center"> </p>

                  <div class="ms-2">
                  <h4 class="mb-0"> </h4>

                </div>
                </li>

                <li class="border-top bg-white">   <a class="dropdown-item py-2" >  Logout</a></li>
              </ul> -->

            </div>


            <!-- login -->
            <div class="dropdown">

              <a [routerLink]="['/login']" class="hover-text  m-0" title="Login" *ngIf="!isLogin">
                <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >

              </a>

                <a href="javascript:void(0)" id="logoutUser" class="hover-text dropdown-toggle m-0" title="Account" *ngIf="isLogin"  data-bs-toggle="dropdown" aria-expanded="false">
                  <img src="../../assets/images/user-r.svg" alt="settings"  style="height: 20px;width: 18px;" >


                </a>
                  <ul *ngIf="isLogin" class="dropdown-menu " aria-labelledby="logoutUser" style="     padding-bottom: 0;   background: #f0f0f0;width: 300px;overflow: hidden; margin-top: 5px !important;">
                    <li class="ps-2 d-flex" style="flex-direction: column;align-items: center;padding: 20px;">
                      <!-- <p class="text-center">{{logInfo.username}}</p> -->
                      <div>
                      <img src="../../assets/images/download.png" alt="settings" width="80px" height="80px" class="rounded-circle">
                      </div>
                      <div class="ms-2">
                      <!-- <h4 class="mb-0">Hi, {{logInfo.name}}</h4> -->
                      <!-- <p><small class="text-muted">Administrator</small> </p>  -->
                    </div>
                    </li>

                    <li class="border-top bg-white">
                      <a class="dropdown-item py-2" href="javascript:void(0)" (click)="logout()">

                      <svg height="22" viewBox="0 0 24 24" width="22" focusable="false" >
                        <path d="M17 7l-1.41 1.41L18.17 11H8v2h10.17l-2.58 2.58L17 17l5-5zM4 5h8V3H4c-1.1 0-2 .9-2 2v14c0 1.1.9 2 2 2h8v-2H4V5z"></path>
                        <path d="M0 0h24v24H0z" fill="none"></path>
                      </svg>
                      Logout</a></li>
                  </ul>
                </div>


          </div>



        </div>
      </section>

    </div>
  </ng-template>
  </div>
