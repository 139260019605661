import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';

@Component({
  selector: 'app-a2z',
  templateUrl: './a2z.component.html',
  styleUrls: ['./a2z.component.scss']
})
export class A2zComponent {
  display:boolean=false;

  constructor(private http:HttpClient){
    this.displayall();
    this.fetchdata();
    console.log(this.completedata)
    // console.log(this.completedata[0])
    // console.log(this.completedata[0].a)
    // this.testdata=this.completedata[0]
    // console.log(this.testdata.a.ACC)
    // console.log(this.testdata)
    // console.log(this.testdata.a['ACC'][0])
    // this.entiredata=this.testdata;
    // console.log(this.entiredata)
    // console.log(this.entiredata['0-9']['211'][0])

  }

  completedataone:any[] = [];

  a2zjsondata: string = 'assets/json/a2zjsondata.json';

  fetchdata() {
    this.http.get(this.a2zjsondata).subscribe(data => {
      this.completedata = data;
      console.log(data);
      this.testdata=this.completedata[0];
    });
  }

  // peddaarray:any;
  entiredata:any;
  // peddapeddaarray:any;
  completedata:any;
  testdata:any

  secondarray:any;
  details:boolean=false;
  displaytitle='';
  displayalldata:boolean=true;
  displayall()
  {
    this.displayalldata=true;
  }

  toggledisplay(id:any,array:any,change:boolean)
  {
    this.displaytitle=id;
    console.log(id)
    console.log(array)
    if(change)
    {
      // console.log(this.testdata[id])
      this.secondarray=this.testdata[id]
    }
    this.displayalldata=false;

  }
  appendone(id:any)
  {
    // console.log("appendone")
    this.displaytitle=id;
    this.details=false;
    // console.log(id)
    this.secondarray=this.testdata[id]
    this.display=true;
    // console.log(this.secondarray)
  }

  thirdarray:any;
  appendtwo(id:any)
  {
    // console.log("appendtwo")
    this.details=true;
    // console.log(id);
    // console.log(this.secondarray)
    this.thirdarray=id;
    // console.log(this.thirdarray)
  }

  // bigappendtwo(id:any)
  // {
  //   // console.log("bigappendtwo")
  //   this.details=true;
  //   console.log(id);
  //   console.log(this.secondarray)
  //   this.thirdarray=id;
  //   console.log(this.thirdarray)
  // }

//   fetchdata()
//   {

//    this.completedata=
//    [
//     {
//     "0-9": {
//       "211 - United Way": [
//         {"id": "126", "q": "United Way - First call for help"},
//         {"id": "128", "q": "Urban League (Fort Wayne)"},
//         {"id": "212", "q": "Legal Services"},
//         {"id": "249", "q": "Scan"},
//         {"id": "284", "q": "Tax Help (free)"},
//         {"id": "298", "q": "Homeless Shelters Encampments"},
//         {"id": "314", "q": "Super Shot Clinic Information"}
//       ],
//       "311 - City Call Center": [
//         {"id": "567", "q": "311 Mobile App"}
//       ],
//       "411 - Directory Assistance": [
//         {"id": "74", "q": "Citi link Bus Transportation"},
//         {"id": "145", "q": "Time & Temperature"},
//         {"id": "186", "q": "Transportation (Citilink)"},
//         {"id": "221", "q": "Trolley (Holly Trolley)"},
//         {"id": "454", "q": "Unites States Postal Service Holiday Hours"}
//       ],
//       "511 - INDOT (State Highways In Indiana)": [
//         {"id": "196", "q": "INDOT (State Highways in Indiana)"}
//       ],
//       "611 - Comcast - Land Line Only": [
//         {"id": "455", "q": "Utilities Fort Wayne"}
//       ],
//       "711 - State Relay Center": [
//         {"id": "588", "q": "711, Telecommunications Relay Services (TRS), TTY Telephone System Text Telephone (TTY)"}
//       ],
//       "811 - Call Before You Dig - Utility Locates": [
//         {"id": "5", "q": "AEP Outages"},
//         {"id": "57", "q": "Cable Service Xfinity"},
//         {"id": "58", "q": "Call Before you Dig"},
//         {"id": "69", "q": "Electric Company I&M"},
//         {"id": "123", "q": "Underground Utilities"},
//         {"id": "160", "q": "Gas Leaks"},
//         {"id": "222", "q": "Lights Out"}
//       ],
//       "911 - Fire Police EMS": [
//         {"id": "5", "q": "AEP Outages"},
//         {"id": "76", "q": "EMERGENCY 9-1-1"},
//         {"id": "160", "q": "Gas Leaks"},
//         {"id": "271", "q": "Sheriff's Department"},
//         {"id": "291", "q": "Power Lines Down"},
//         {"id": "534", "q": "Swift911 Fort Wayne, Allen County emergency alert system"}
//       ]
//     },
//     "a": {
//       "A Week": [
//         {"id": "359", "q": "Recycling Compliance Information & Calendar"}
//       ],
//       "ACC": [
//         {"id": "362", "q": "Perfect Paws Rescue"},
//         {"id": "435", "q": "Relinquish Your Pet"}
//       ],
//       "ADA": [
//         {"id": "570", "q": "ADA Regulations handicapped parking spots"}
//       ],
//       "ADA Complaints": [
//         {"id": "14", "q": "American Disability Act"}
//       ],
//       "ADA Service Animals": [
//         {"id": "604", "q": "ADA Service Animals, service dogs"}
//       ],
//       "AEP": [
//         {"id": "20", "q": "Appliances with Freon"},
//         {"id": "69", "q": "Electric Company I&M"},
//         {"id": "222", "q": "Lights Out"},
//         {"id": "291", "q": "Power Lines Down"},
//         {"id": "455", "q": "Utilities Fort Wayne"}
//       ],
//       "AIDS Walk": [
//         {"id": "7", "q": "Aids Task Force"}
//       ],
//       "AM Legal": [
//         {"id": "15", "q": "AMLEGAL"},
//         {"id": "79", "q": "City Code Ordinances"}
//       ],
//       "Abandoned": [
//         {"id": "1", "q": "Abandoned Vehicles on Street"}
//       ],
//       "Abandoned Vehicle": [
//         {"id": "363", "q": "Parking Control Enforcement"}
//       ],
//       "Abatement": [
//         {"id": "3", "q": "Abatement  CED-Redevelopment"}
//       ],
//       "Absentee Ballot": [
//         {"id": "4", "q": "Absentee Ballots Election Board"}
//       ],
//       "Absentee Voting": [
//         {"id": "4", "q": "Absentee Ballots Election Board"}
//       ],
//       "Abuse": [
//         {"id": "66", "q": "Child Protective Service"}
//       ],
//       "Accdc": [
//         {"id": "509", "q": "ACCDC County owned properties"}
//       ],
//       "Accdc Sales": [
//         {"id": "487", "q": "property sales"}
//       ],
//       "Access Public Records": [
//         {"id": "255", "q": "Public Information Request"}
//       ],
//       "Accident": [
//         {"id": "76", "q": "EMERGENCY 9-1-1"}
//       ],
//       "Active Shooters": [
//         {"id": "534", "q": "Swift911 Fort Wayne, Allen County emergency alert system"}
//       ],
//       "Additional Fee": [
//         {"id": "231", "q": "Lost Animals- pets"}
//       ],
//       "Administers Of Solid Waste": [
//         {"id": "538", "q": "Solid Waste Management"}
//       ],
//       "Adoption": [
//         {"id": "388", "q": "ASPCA-American Society for the Prevention of Cruelty to Animals"}
//       ],
//       "Adoption Cost": [
//         {"id": "383", "q": "Animal Adoptions, payments"}
//       ],
//       "Adult Literacy": [
//         {"id": "6", "q": "Adult Literacy"}
//       ],
//       "Adult Tutoring": [
//         {"id": "6", "q": "Adult Literacy"}
//       ],
//       "Adults": [
//         {"id": "314", "q": "Super Shot Clinic Information"}
//       ],
//       "Advance Disposal": [
//         {"id": "175", "q": "Landfill Locations"}
//       ],
//       "Advise": [
//         {"id": "212", "q": "Legal Services"}
//       ],
//       "Aep": [
//         {"id": "522", "q": "City of Fort Wayne City Utilities"}
//       ],
//       "Affidavits": [
//         {"id": "275", "q": "Recorders Office"}
//       ]
//     }
// }
//    ]

//   }
}
