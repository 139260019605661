<div class="dialog">

  <div class="dialog-header">
    <img src="../../assets/images/fort-wayne-logo-seal-dark.svg" class="me-auto mb-2" height="42px">
    <button type="button" class="btn-close" data-bs-dismiss="dialog" (click)="onCloseClick()"
      aria-label="Close"></button>
  </div>
  <div class="dialog-body text-center">
    <p class="text-center mt-3"> <img src="../../assets/images/verified.svg" width="58px"> </p>
    <div class="row">
      <div class="col-md-12 text-center">A New Service Request is submitted successfully</div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col-md-6 col-5 text-end-r">Service Type:</div>
      <div class="col-md-6 col-7 fw-500 text-start-r ps-0">{{this.makeService.casetype}} <i
          class="bi bi-chevron-right"></i> {{this.makeService.department}}</div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col-md-6 col-5 text-end-r">SR #:</div>
      <div class="col-md-6 col-7 fw-500 text-start-r ps-0">
        <a class="" [routerLink]="['/track-sr/ref_no/' + allrequireddata.payLoad.refno]" (click)="onCloseClick()"
          style="text-decoration: underline;">
          <span>{{allrequireddata.payLoad.refno}}</span>
          <i class="fas fa-copy ms-2" style="cursor: pointer;" (click)="copyRefNo($event,allrequireddata.payLoad.refno)"
            title="Copy SR ID"></i>
        </a>
      </div>
    </div>
    <div class="row mt-2 mx-0">
      <div class="col-md-6 col-5 text-end-r">Date Created:</div>
      <div class="col-md-6 col-7 fw-500 text-start-r ps-0"> {{allrequireddata.payLoad.createdtime |date:"MMMM dd, yyyy
        h:mm a"}}</div>
    </div>

    <div class="row mt-2 mx-0">
      <div class="col-md-6 col-5 text-end-r">Status:</div>
      <div class="col-md-6 col-7 fw-500 text-start-r ps-0"><span class="badge status-box">
          <i class="bi bi-circle-fill" style="color: #F14621; font-size: 8px;"></i> Unresolved </span> </div>
    </div>

    <!-- <div class=" row mt-2">

          <span class="bg-ntiok"> <img src="../../assets/images/noti-sand.png" height="14px">  Estimated resolution time:
            <span class="text-primary fw-500">{{allrequireddata.estimatedtime}}</span></span>

          </div> -->
  </div>
  <br>
  <div class="dialog-footer text-center action-from">

    <button type="button" class="btn btn-primary m-0"
      [routerLink]="['/track-sr/ref_no/' + allrequireddata.payLoad.refno]" (click)="onCloseClick()">OK</button>


  </div>

</div>