
import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import{environment} from 'src/environments/environment';


export const httpGetOptions = {
  headers: new HttpHeaders({

  })
};
export const httpJsonPostOptions = {
  headers: new HttpHeaders({
      'Content-Type':  'application/json',

  })
};

export const httpFormPostOptions = {
  headers: new HttpHeaders({
      'Content-Type':  'application/x-www-form-urlencoded'

  })
};
@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  constructor(private http: HttpClient) { }
  sendMessage(payload:any,usermsg:any){
    const headers = new HttpHeaders({
      'Authorization': payload,  // Using payload as the authorization token
      'Content-Type': 'application/json',
    });
    console.log(usermsg);
    console.log(payload);

    return this.http.post(environment.chatbotUrl + 'api/v1/generate', usermsg, { headers});
  }
  submitFeedback(payload:any):Observable<any> {
    return this.http.post(environment.backendurl+"ChatFeedback", payload);
  }
}
