
<div class="dialog-header">
  <img src="../../assets/images/fort-wayne-logo-seal-dark.svg" class="me-auto mb-2" height="50px" style="    position: relative;
  top: 6px;">
<button type="button" class="btn-close" (click)="closeDialog()"  style="position: absolute;
right: 18px;
top: 10px;"></button>
</div>
<hr>
<!-- <h2 mat-dialog-title>Email</h2> -->
<mat-dialog-content  style="width: 80vw;">

  <form (ngSubmit)="onsubmit()" [formGroup]="emailForm">
    <div class="form-group">
      <label for="to">To</label>
      <input type="email" class="form-control" id="to" formControlName="to" placeholder="Enter email" required>
      <div *ngIf="emailForm.get('to')?.invalid && (emailForm.get('to')?.dirty || emailForm.get('to')?.touched)" class="text-danger">
        <div *ngIf="emailForm.get('to')?.errors?.required">
          Email is required.
        </div>
        <!-- <div *ngIf="!validateEmail(emailForm.get('to')?.value) && emailForm.get('to')?.errors?.email && (emailForm.get('to')?.dirty || emailForm.get('to')?.touched)" class="text-danger">
          Please enter a valid email.
        </div> -->
        <div *ngIf="emailForm.get('to').errors.invalidEmailFormat">Invalid email format.</div>

      </div>
    </div>
    <div class="form-group">
      <label for="subject">Subject</label>
      <input type="text" class="form-control" id="subject" [value]="stripHtmlAndEntities(data.subject)"  placeholder="Subject"  >
    </div>
    <div class="form-group">
      <label for="message">Message</label>
      <textarea class="form-control" id="message" placeholder="Message" [value]="stripHtmlAndEntities(data.message)"  rows="15"></textarea>
    </div>
  </form>
</mat-dialog-content>
<mat-dialog-actions>
  <!-- <button mat-button color="primary" (click)="closeDialog()">Close</button> -->
  <!-- <button mat-button color="primary" (click)="onsubmit()" >Submit</button> -->
  <div class="action-from col d-flex" >
  <button   type="submit"  class="btn btn-primary m-auto"   [disabled]="emailForm.invalid"   (click)="onsubmit()"><i class="bi bi-save mr-1"></i> Submit</button>
</div>
</mat-dialog-actions>
