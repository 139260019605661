<div class="hi-icon-wrap chatbot-effect" *ngIf="!isSignupRoute()" [class.active]="isChatwindow">
  <img src="../assets/images/chat-icon2.png" width="84px" (click)="openChat()" [class.d-none]="isChatwindow" class="chatLogo">
  <div *ngIf="isChatwindow" class="box-chat">
      <form (ngSubmit)="OnSubmit(f)" #f="ngForm" name="chatBot">
          <div class="pop-chat" *ngIf="showPopchat&& isAgreed"
              style="position: absolute;top: 0;left: 0;z-index: 999;background: #000000a8;width: 100%;height: 100%;">
              <div
                  style="background-color: white; margin: 50px; border-radius: 10px; padding: 20px; margin-top: calc(50% - 120px)">
                  <!-- <P> "Thank you for chatting with us! If you have any more questions in the future, don't hesitate to
                      reach out. Have a great day!"</P> -->
                      <P>Would you like to exit?</P>
                  <div class="action-from d-flex gap-2 justify-content-center" *ngIf="!countdownInProgress">
                      <button class="btn btn-primary py-1" (click)="clearChat()"> Yes </button>
                      <button class="btn btn-outline-primary py-1 " (click)="closeBox()"> No </button>
                  </div>
                  <div class="action-from d-flex gap-2 justify-content-center" *ngIf="countdownInProgress">
                      <div class="">
                          <h3 class="timer-value text-center">{{ countdownValue }}</h3>
                          <button class="btn btn-outline-primary py-1" (click)="stayChat()">Stay Here</button>
                          <button class="btn btn-outline-primary py-1" (click)="closeChat()">Close</button>
                      </div>
                  </div>
              </div>
          </div>
          <div class="feedback-popup" *ngIf="showFeedbackPopup"
              style="position: absolute;top: 0;left: 0;z-index: 999;background: #000000a8;width: 100%;height: 100%;">
              <div
                  style="background-color: white; margin: 50px; border-radius: 10px; padding: 20px; margin-top: calc(50% - 120px)">
                  <h2>Feedback</h2>
                  <form [formGroup]="feedbackForm" (submit)="submitFeedback(false)">
                      <!-- <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                          <input type="text" class="did-floating-input" id="name" formControlName="name">
                          <label for="name" class="did-floating-label">Name</label>
                          <div *ngIf="feedbackForm.get('name')?.hasError('required') && feedbackForm.get('name')?.touched" class="text-danger">
                             Name is required.
                            </div>
                      </div> -->
                      <div class="did-floating-label-content  col-md-12 ps-0 pe-0">
                          <textarea type="text" class="did-floating-input feedback" id="feedback" formControlName="feedback"></textarea>
                          <label for="feedback" class="did-floating-label">Feedback</label>
                          <!-- <div *ngIf="feedbackForm.get('feedback')?.hasError('required') && feedbackForm.get('feedback')?.touched" class="text-danger">
                              Feedback is required.
                             </div> -->
                      </div>
                      <button type="submit" value="submit-btn" class="btn btn-primary ms-2"
                          [disabled]="feedbackForm.invalid">
                          Submit
                      </button>
                      <button type="button" value="cancel-btn" class="btn btn-primary ms-2"
                          (click)="closeFeedbackPopup()">
                          Cancel
                      </button>
                  </form>
              </div>
          </div>

          <div class="chat-header d-flex ">
              <div class="logo d-flex align-items-center text-start gap-2">
                  <img src="../assets/images/chat-icon.png" width="48px">
                  <span>
                      <strong class="text-left">SAIC</strong>
                  </span>
              </div>

              <div class="action gap-3 d-flex mt-2">
                  <a class="text-white pointer" (click)="openChat()" style="font-size: 18px;"> <i
                          class="bi bi-chevron-down" [ngClass]="isChatwindow ? 'bi-chevron-down' : 'bi-chevron-up'"
                          ngbTooltip="Minimize" tooltipClass="custom-tooltip-class"></i>
                  </a>
                  <a class="text-white pointer me-2" (click)="openPopup()"> <i class="bi bi-x-lg" ngbTooltip="Close"
                          tooltipClass="custom-tooltip-class"></i></a>
              </div>
          </div>
          <div class="chat-body p-3">
              <div class="customer-row text-start w-100" *ngIf="conversationId">
                  <div class="d-flex text-start align-items-start  gap-2">
                      <img src="../assets/images/chat-icon.png" width="36px">
                      <markdown class="bg-white agent-row-box"> Welcome! I am SAIC - Fortwayne 311 Chatbot. <br>

                          Get started using our quick links or feel free to type in the below box to ask your query. You can visit
                          <a href="https://www.cityoffortwayne.org/"> https://www.cityoffortwayne.org/</a> or contact the city's helpline by dialing 311 for assistance.
                      </markdown>
                  </div>
                  <p class="time-text text-start" style="padding-left: 50px;"><span>{{initialDateTime}}</span></p>
              </div>
              <div class="d-flex flex-wrap gap-2 mb-3" *ngIf="conversationId">
                  <div class="suggestion-chip" routerLink="/create-sr" (click)="openChat()"> Create a New Service Request <svg
                          xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                          <path
                              d="M4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h4.938V4.5H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h10.5q.083 0 .167-.083.083-.084.083-.167v-4.938h1.083v4.938q0 .562-.385.948-.386.385-.948.385Zm3.333-3.895-.771-.771L14.729 4.5H12V3.417h4.583V8H15.5V5.271Z">
                          </path>
                      </svg> </div>
                  <div class="suggestion-chip" routerLink="/track-sr" (click)="openChat()">Track a Service Request <svg
                          xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                          <path
                              d="M4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h4.938V4.5H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h10.5q.083 0 .167-.083.083-.084.083-.167v-4.938h1.083v4.938q0 .562-.385.948-.386.385-.948.385Zm3.333-3.895-.771-.771L14.729 4.5H12V3.417h4.583V8H15.5V5.271Z">
                          </path>
                      </svg> </div>
                  <div class="suggestion-chip" routerLink="/answers/list" (click)="openChat()"> Search knowledge Article <svg
                          xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                          <path
                              d="M4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h4.938V4.5H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h10.5q.083 0 .167-.083.083-.084.083-.167v-4.938h1.083v4.938q0 .562-.385.948-.386.385-.948.385Zm3.333-3.895-.771-.771L14.729 4.5H12V3.417h4.583V8H15.5V5.271Z">
                          </path>
                      </svg> </div>
                      <div class="suggestion-chip" routerLink="/faq" (click)="openChat()"> Smart FAQ <svg
                          xmlns="http://www.w3.org/2000/svg" height="20" width="20">
                          <path
                              d="M4.75 16.583q-.562 0-.948-.385-.385-.386-.385-.948V4.75q0-.562.385-.948.386-.385.948-.385h4.938V4.5H4.75q-.083 0-.167.083-.083.084-.083.167v10.5q0 .083.083.167.084.083.167.083h10.5q.083 0 .167-.083.083-.084.083-.167v-4.938h1.083v4.938q0 .562-.385.948-.386.385-.948.385Zm3.333-3.895-.771-.771L14.729 4.5H12V3.417h4.583V8H15.5V5.271Z">
                          </path>
                      </svg> </div>
              </div>


              <!-- agent-row end  -->
              <div *ngFor="let message of user.allmessages; let i = index;">
                  <div class="agent-row d-flex text-start align-items-end">

                      <div class="bg-primary  agent-row-box ms-auto" [innerHTML]="message.query">
                      </div>
                      <!-- <img src="../assets/images/profile.png" width="36px"> -->
                  </div>
                  <p class="time-text text-end" style="padding-right: 34px; font-weight: 500; font-size: 11px;">
                      <span>{{message.timestamp | date:'MMM dd, yyyy hh:mm a'}}</span> </p>

                  <!-- agent-row end  -->
                  <div class="customer-row text-start w-100">
                      <div class="d-flex  text-start align-items-start  gap-2">
                          <img src="../assets/images/chat-icon.png" width="36px">
                          <markdown class="bg-white  agent-row-box variable-binding" [data]="message.response">
                          </markdown>

                      </div>
                      <p class="time-text text-start" style="padding-left: 50px; font-weight: 500; font-size: 11px;">
                          <span>{{message.timestamp | date:'MMM dd, yyyy hh:mm a'}}</span> </p>
                  </div>


                  <div class="customer-row text-start w-100" *ngIf="i == user.allmessages.length - 1 && showFeedbackQuestion && !likeStatus">
                      <div class="d-flex  text-start align-items-start  gap-2">
                          <img src="../assets/images/chat-icon.png" width="36px">
                          <div class="bg-white  agent-row-box variable-binding" >
                              <p class="p-2">Was I able to answer your question?</p>
                          <div class="d-flex gap-2">
                              <button class="btn-thumb flex-fill " type="button" (click)="submitFeedback(true)">
                                 <img src="../assets/images/thumb-up.svg" height="24px">
                              </button>

                              <button class="btn-thumb flex-fill" type="button" (click)="openFeedbackPopup()">
                                  <img src="../assets/images/thumb-down.svg" height="24px">

                              </button>
                          </div>
                          </div>

                      </div>
                      <p class="time-text text-start" style="padding-left: 50px; font-weight: 500; font-size: 11px;">
                          <span>{{message.timestamp | date:'MMM dd, yyyy hh:mm a'}}</span> </p>
                  </div>



              </div>
          </div>

          <div class="chat-footer align-items-center d-flex">
            <input [(ngModel)]="user.sendMsg" placeholder="Ask me anything" class="area-text"
                [ngModelOptions]="{standalone: true}" #selectControl="ngModel"
                (input)="checkMaxLength()" [maxlength]="251" (keyup.enter)="onSendMessage()">
            <button [disabled]="isInputEmpty() || loading" (click)="onSendMessage()">
                <i class="bi bi-send-fill send-msg" style="font-size: 18px;margin-right: 10px;"></i>
            </button>
        </div>
          <div *ngIf="isMaxLengthExceeded"  class="error-message text-danger" style="font-size: 11px;text-align: left;padding: 5px 5px 5px 20px;margin-top: -14px;">
              The input cannot exceed 250 characters.
          </div>
          <p style="display: flex;padding: 0 10px;">
              <!-- <img src="../assets/images/warning.svg" width="10px"> -->
              <span style="font-size: 10px;line-height: 12px; text-align: left; margin-left: 5px;"> Powered by NebuLogic. This chatbot is experimental and may provide sometimes less relevant responses. User discretion is advised. </span>
          </p>

          <div class="chat-noti" *ngIf="!conversationId">

              <small class="mt-auto border-top p-2">
                  I agree to the Privacy Policy and I hereby confirm that I am either above 16 years of age or have
                  obtained consent
                  from my parents (or guardians) to use SAIC AI Chatbot.
              </small>
              <div class="action-from d-flex align-items-center my-2">
                  <a class="btn btn-primary ms-2" (click)="agreeContinue()"> Agree & Continue </a>
              </div>
          </div>




      </form>
  </div>


</div>
