import { NgModule, Component } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LayoutsComponent } from './layouts/layouts.component';
import { TrackSRComponent } from './track-sr/track-sr.component';
import { SelectServiceTypeComponent } from './select-service-type/select-service-type.component';
import { FaqComponent } from './faq/faq.component';
import { AddressDetailsComponent } from './address-details/address-details.component';
import { CreateNewSRComponent } from './create-new-sr/create-new-sr.component';
import { MyServiceRequestComponent } from './my-service-request/my-service-request.component';
import { GoogleMapComponent } from './google-map/google-map.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { AgentScriptComponent } from './agent-script/agent-script.component';
import { PreviewComponent } from './preview/preview.component'
import { DetailsComponent } from './details/details.component';
import { A2zComponent } from './a2z/a2z.component';
import { KnowledgebaseComponent } from './knowledgebase/knowledgebase.component';
import { KnowledgebaseAiComponent } from './knowledgebaseai/knowledgebaseai.component';
import { About311Component } from './about311/about311.component';
import { NewsComponent } from './news/news.component';
import { WaystocontactComponent } from './waystocontact/waystocontact.component';
import { FeedbacksurveyComponent } from './feedbacksurvey/feedbacksurvey.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './login/login.component';
import { ServicerequestconfirmationComponent } from './servicerequestconfirmation/servicerequestconfirmation.component';

const routes: Routes = [
  {path: '', component: LayoutsComponent },
  {path: 'create-sr',component:CreateNewSRComponent},

  { path: 'track-sr', component: TrackSRComponent },
  {path: 'new-sr',component:SelectServiceTypeComponent},
  {path: 'address-dls',component:AddressDetailsComponent},
  {path: 'preview',component:PreviewComponent},

  {path: 'faq',component:FaqComponent},
  {path: 'my-service-request',component:MyServiceRequestComponent},
  {path: 'google-map',component:GoogleMapComponent},
  // {path: 'sign-up',component:SignUpComponent},
  // {path: 'thanks',component:ThankYouComponent},
  { path: 'agent-script/:deptId/:casetypeId/:qid/:divId/:fileName', component: AgentScriptComponent},
  {path:'answers/list',component:KnowledgebaseComponent},
  {path:'answers/ailist',component:KnowledgebaseAiComponent},
{path:'about311',component:About311Component},
  {path:'answers/details/:id',component:DetailsComponent},
  {path:'a2z',component:A2zComponent},
  {path:'news',component:NewsComponent},
  {path:'waystocontactus',component:WaystocontactComponent},
  {path:'feedbacksurvey',component:FeedbacksurveyComponent},
  // {path:'login',component:LoginComponent,canActivate: [MsalGuard]},
  {path:'track-sr/ref_no/:id',component:ServicerequestconfirmationComponent},
// {path:'signup',component:SignUpComponent}

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
