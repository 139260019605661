import { makeServiceRequestService } from './../make-service-request.service';
import { Component, ElementRef, EventEmitter, Input, Output, SimpleChanges, ViewChild } from '@angular/core';
import { loadModules } from 'esri-loader';
import { setDefaultOptions } from 'esri-loader';
import esri = __esri;
import { add } from 'esri/views/3d/externalRenderers';
@Component({
  selector: 'app-home-esrimap',
  templateUrl: './home-esrimap.component.html',
  styleUrls: ['./home-esrimap.component.scss']
})
export class HomeEsrimapComponent {
  @Input() recentIncidents: any;
  searchAddress!: string;
  latitude!: number;
  longitude!: number;
  getrecentincidents:any;

  @Output() mapLoaded = new EventEmitter<boolean>();
  @Input()  searchLocation:any;
  @Output() childComponentValue = new EventEmitter<any>();
  @ViewChild('mapViewNode', { static: true })

  private mapViewEl!: ElementRef;

  /**
   * @private _zoom sets map zoom
   * @private _center sets map center
   * @private _basemap sets type of map
   */
  private _zoom: number =12 ;
  private _center: Array<number> = [-85.1394, 41.0793]; // Approximate center coordinates for Fort Wayne
  public _basemap: string = 'streets-vector';
  EsriMapView: any;
  location: any;

  @Input()
  set zoom(zoom: number) {
      this._zoom = zoom;
  }

  get zoom(): number {
      return this._zoom;
  }

  @Input()
  set center(center: Array<number>) {
      this._center = center;
  }

  get center(): Array<number> {
      return this._center;
  }

  @Input()
  set basemap(basemap: string) {
      this._basemap = 'streets-vector';
  }

  get basemap(): string {
      return this._basemap;
  }

  constructor(private makeServiceRequestService:makeServiceRequestService) {
    this.getrecentincidents = this.recentIncidents;
    console.log(this.recentIncidents)
   }


   async initializeMap() {
    try {
      const [
        EsriMap,
        EsriMapView,
        Graphic,
        GraphicsLayer,
        SimpleMarkerSymbol,
        PopupTemplate,
        BasemapToggle,
      ] = await loadModules([
        'esri/Map',
        'esri/views/MapView',
        'esri/Graphic',
        'esri/layers/GraphicsLayer',
        'esri/symbols/SimpleMarkerSymbol',
        'esri/PopupTemplate',
        'esri/widgets/BasemapToggle',
      ]);

      const map = new EsriMap({
        basemap: this._basemap
      });

      const view = new EsriMapView({
        container: this.mapViewEl.nativeElement,
        center: this._center,
        zoom: this._zoom,
        map: map
      });

      view.when(() => {
        const graphicsLayer = new GraphicsLayer();
        map.add(graphicsLayer);

        // Define a mapping between departments and marker symbols

        const departmentMarkerSymbols = {
          'Streets': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/streets1.png',
              width: '30px',
              height: '40px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/streets1.png',
              width: '30px',
              height: '40px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/streetssolved.png',
              width: '30px',
              height: '40px'
            }
          },
          'Solid Waste': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/solid-waste1.png',
              width: '30px',
              height: '40px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/solid-waste1.png',
              width: '30px',
              height: '40px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/solidwasteresolved.png',
              width: '30px',
              height: '40px'
            }
          },
          'Street Lighting': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/street-lighting1.png',
              width: '30px',
              height: '40px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/street-lighting1.png',
              width: '30px',
              height: '40px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/streetslightsresolved.png',
              width: '30px',
              height: '40px'
            }
          },
          'Neighborhood Code Compliance': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/nccunresolved.png',
              width: '30px',
              height: '40px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/nccunresolved.png',
              width: '30px',
              height: '40px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/NCCresolved.png',
              width: '30px',
              height: '40px'
            }
          },
          'Water Maintenance': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/water-maintenanceunresolved.png',
              width: '30px',
              height: '40px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/water-maintenanceunresolved.png',
              width: '30px',
              height: '40px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/water-maintenanceunresolved.png',
              width: '30px',
              height: '40px'
            }
          },
          'Right of Way': {
            'Unresolved': {
              type: 'picture-marker',
              url: '../../assets/images/right-way.png',
              width: '28px',
              height: '50px'
            },
            'Waiting': {
              type: 'picture-marker',
              url: '../../assets/images/right-way.png',
              width: '28px',
              height: '50px'
            },
            'Resolved': {
              type: 'picture-marker',
              url: '../../assets/images/right-way-done.png',
              width: '28px',
              height: '50px'
            }
          },
          //
          //
          //

          // Add more departments and their corresponding marker symbols here
        };


        this.recentIncidents.forEach((incident) => {
          if (incident.latitude && incident.longitude) {
            const point = {
              type: 'point',
              longitude: parseFloat(incident.longitude),
              latitude: parseFloat(incident.latitude)
            };

            // Get the marker symbol based on the department
            const departmentSymbol = departmentMarkerSymbols[incident.department][incident.incidentstatus];
            if (departmentSymbol) {
              const popupTemplate = new PopupTemplate({
                title: incident.casetype,
                content: [
                  {
                    type: 'fields',
                    fieldInfos: [
                      { fieldName: 'addressline1', label: 'Address' },
                      { fieldName: 'casetype', label: 'casetype' },
                      { fieldName: 'incidentstatus', label: 'Incident Status' },
                      { fieldName: 'department', label: 'Department' },
                      { fieldName: 'createdtime', label: 'Date Created'},
                    ]
                  }
                ]
              });

              const graphic = new Graphic({
                geometry: point,
                symbol: departmentSymbol.symbol,
                attributes: incident,
                popupTemplate: popupTemplate
              });

              graphicsLayer.add(graphic);
            }
          }
        });
      });


      const toggle = new BasemapToggle({
        view: view,
        nextBasemap: 'satellite' // Set the default basemap for the toggle
      });

      // Add the BasemapToggle widget to the view
      view.ui.add(toggle, 'bottom-right');
      this.mapLoaded.emit(true);

    } catch (error) {
      console.error('We have an error: ', error);
    }
  }




  toggleBasemap() {
    console.log("toggle")
    if (this._basemap === 'satellite') {
      console.log(this._basemap)
      this._basemap = 'streets-vector';
    } else {
      console.log(this._basemap)
      this._basemap = 'satellite';
    }
    this.initializeMap();
  }
  @Input() selectedDepartment: string = '';
  incident:any
  ngOnInit() {
    this.getrecentincidents = this.recentIncidents;
    console.log("in home-esri",this.getrecentincidents)
// this. showIncidentLocation(this.incident);
      // this.initializeMap();

      // this.loadMapToAddress(this._searchLocation,this.selectedDepartment);
      // setTimeout(()=>{
        // this.initializeMapWithPopup();
        this.loadMapToAddress(this._searchLocation,this.selecteddepartment)
          // },500);
      // this.geocodeAndCenter(this._searchLocation)
    //  this.loadMapToAddress
  }
  ngOnChanges(changes: SimpleChanges) {
    this.getrecentincidents=this.recentIncidents;
    console.log(this.recentIncidents)
    console.log(this.incident)

    // this._searchLocation = this.searchLocation;
    // console.log("searchloaction", this._searchLocation)
    // You can use this._searchLocation to access search location anywhere in the component
    setTimeout(()=>{
    this.loadMapToAddress(this._searchLocation,this.selecteddepartment)
      },300);
    // this.initializeMapWithPopup(this.recentIncidents);
    // this.onDepartmentChange(this.selectedDepartment)
  }
  _searchLocation: any;

  selecteddepartment:any;
  private geocodedLocation: Array<number> | null = null;
  geocodeAndCenter(address: string) {
    console.log("loadmap address", address);
    if (address) {

      this.makeServiceRequestService.geocodeAddress(address).subscribe(async (response) => {
        console.log(address)
        console.log(response)
        if (response && response.candidates && response.candidates.length > 0) {
          const candidate = response.candidates[0];
          const location = candidate.location;
          console.log(location)
          if (location && location.x && location.y) {
            const [
              EsriMap,
              EsriMapView,
              Graphic,
              GraphicsLayer,
              SimpleMarkerSymbol,
              PopupTemplate
            ] = await loadModules([
              'esri/Map',
              'esri/views/MapView',
              'esri/Graphic',
              'esri/layers/GraphicsLayer',
              'esri/symbols/SimpleMarkerSymbol',
              'esri/PopupTemplate'
            ]);

            const map = new EsriMap({
              basemap: this._basemap
            });

            const view = new EsriMapView({
              container: this.mapViewEl.nativeElement,
              center: this._center,
              zoom: this._zoom,
              map: map
            });
            // Use location.x for longitude and location.y for latitude
            this.longitude = location.x;
            this.latitude = location.y;
            this.geocodedLocation = [this.longitude, this.latitude];
            console.log(this.geocodedLocation)
            // Set the center of the map to the provided address
            // Assuming 'view' is your MapView or SceneView object
            view.center = [this.longitude, this.latitude];
            view.zoom = 15;
          }
        }
      }, (error) => {
        console.error('Error geocoding address:', error);
      });
    }
}''
filteredIncidents:any;
loadMapToAddress(searchAddress: string, selectedDepartment: string) {
console.log(searchAddress)
console.log(selectedDepartment)
  this._searchLocation = searchAddress;
  this.selecteddepartment = selectedDepartment;

  if ((this.selectedDepartment == 'Solid Waste' ||
    this.selectedDepartment == 'Neighborhood Code Compliance' ||
    this.selectedDepartment == 'Street Lighting' ||
    this.selectedDepartment == 'Streets' ||
    this.selectedDepartment === 'Right of Way' ||
    this.selectedDepartment == 'Water Maintenance') && this._searchLocation) {

    const streetAddress = this._searchLocation.split(',')[0].trim();
    const streetAddress1= streetAddress.replace(/\d+/g, '')
    const filteredIncidents = this.recentIncidents.filter((incident: any) =>
      incident.addressline1.toLowerCase().includes(streetAddress1.toLowerCase()) &&
      incident.department === selectedDepartment
    );
    this.filteredIncidents = filteredIncidents;
console.log(this.filteredIncidents)
    if (this.filteredIncidents.length > 0) {

      this.initializeMapWithPopup(this.filteredIncidents);
      // this.selectedDepartment='';

      // this.filteredIncidents=null;
      // this.count=1;
    }
    else {

      this.location=this._searchLocation;

      // this.count=2;
      // this.geocodeAndCenter(searchAddress);
    }

  }
  else if (this._searchLocation && !(this.selectedDepartment === 'Solid Waste' ||
    this.selectedDepartment === 'Neighborhood Code Compliance' ||
    this.selectedDepartment === 'Street Lighting' ||
    this.selectedDepartment === 'Streets' ||
    this.selectedDepartment === 'Water Maintenance' ||
    this.selectedDepartment === 'Right of Way' ||
    this.selectedDepartment === 'All')) {
console.log(this._searchLocation)
    const streetAddress = this._searchLocation.split(',')[0].trim();
    console.log(streetAddress)
    const streetAddress1= streetAddress.replace(/\d+/g, '')
    console.log(streetAddress1)
    console.log(this.recentIncidents)
    const filteredIncidents = this.recentIncidents.filter((incident: any) =>
      incident.addressline1.toLowerCase().includes(streetAddress1.toLowerCase())
    );
    console.log(filteredIncidents)
    this.filteredIncidents = filteredIncidents;
console.log(this.filteredIncidents)
    if (this.filteredIncidents.length > 0) {

      this.initializeMapWithPopup(this.filteredIncidents);
      this.selectedDepartment='';
      // this.filteredIncidents=null;
      // this.count=3;
    } else {

      this.location=this._searchLocation;
      // this.count=4;
      // this.geocodeAndCenter(searchAddress);
    }

  }
  else if ((this.selectedDepartment === 'Solid Waste' ||
    this.selectedDepartment === 'Neighborhood Code Compliance' ||
    this.selectedDepartment === 'Street Lighting' ||
    this.selectedDepartment === 'Streets' ||
    this.selectedDepartment === 'Right of Way' ||
    this.selectedDepartment === 'Water Maintenance') && !this._searchLocation) {
    const filteredIncidents = this.recentIncidents.filter((incident: any) =>
      incident.department === selectedDepartment


    );

    this.filteredIncidents = filteredIncidents;
    console.log(filteredIncidents)
    this.initializeMapWithPopup(this.filteredIncidents);
    this.selectedDepartment='';
    // this.filteredIncidents=null;
    // this.count=5;
  }
  else if (this.selectedDepartment == 'All' && !this._searchLocation) {

    this.filteredIncidents = this.recentIncidents;
    this.initializeMapWithPopup(this.filteredIncidents);
    this.selectedDepartment='';
    // this.filteredIncidents=null;
    // this.count=6;
  }
  else if (this.selectedDepartment == 'All' && this._searchLocation) {
    const streetAddress = this._searchLocation.split(',')[0].trim();
    const streetAddress1= streetAddress.replace(/\d+/g, '')
    const filteredIncidents = this.recentIncidents.filter((incident: any) =>
      incident.addressline1.toLowerCase().includes(streetAddress1.toLowerCase())
    );
    this.filteredIncidents = filteredIncidents;

    if (this.filteredIncidents.length > 0) {


      this.initializeMapWithPopup(this.filteredIncidents);
      this.selectedDepartment='';
      // this.filteredIncidents=null;
      // this.count=7;
    }
    else {

      this.location=this._searchLocation
      // this.count=8;
      // Use this._searchLocation instead of searchAddress
      // this.geocodeAndCenter(this._searchLocation); // Corrected parameter
    }

  }

  else {
    console.log("if neither search address nor department is provided");
    this.initializeMapWithPopup(this.recentIncidents);
// this.count=9;
  }


  if(this.location!='')
  {
    this.geocodeAndCenter(this.location)
    this.location='';
  }

}



  @Output() showIncidentLocationEvent = new EventEmitter<any>();

  // Function to handle the emitted event from PageContentComponent
showIncidentLocation(incident: any[]) {
  console.log(incident)
  // Set the incident property to the provided incident
  this.incident = incident;
console.log(this.incident);
console.log(this._searchLocation)
console.log(this.selecteddepartment)
// console.log("this.filteredIncidents",this.filteredIncidents)
// console.log("this.filteredIncidentslength",this.filteredIncidents.length)

// this.initializeMapWithPopup();
// if(this._searchLocation!=null)
// {
//   console.log("gc bhjm",this._searchLocation)
//   console.log([this.incident])
//   this.initializeMapWithPopup([this.incident]);
// }
// else {
//   console.log("is null",this._searchLocation)
//   this.initializeMapWithPopup();
// }
if (this._searchLocation!=null || this.selecteddepartment!=null) {
  this.initializeMapWithPopup(this.filteredIncidents);
}
 else {
  // Otherwise, pass recent incidents
  this.initializeMapWithPopup();
}
  // Check if the incident has latitude and longitude

}


initializeMapWithPopup(incidents: any[] | undefined = this.recentIncidents || []) {
  if (!incidents) {
    console.error('No incidents provided.');
    return;
  }

  console.log(incidents);
  loadModules([
    'esri/Map',
    'esri/views/MapView',
    'esri/Graphic',
    'esri/layers/GraphicsLayer',
    'esri/symbols/SimpleMarkerSymbol',
    'esri/PopupTemplate',
    'esri/widgets/BasemapToggle',
  ]).then(([EsriMap, EsriMapView, Graphic, GraphicsLayer, SimpleMarkerSymbol, PopupTemplate, BasemapToggle]) => {
    const map = new EsriMap({
      basemap: this._basemap,
    });
console.log(this.recentIncidents)

    const view = new EsriMapView({
      container: this.mapViewEl.nativeElement,
      center: this._center,
      zoom: this._zoom,
      map: map,
      // constraints: {
      //   maxZoom: 15 // Adjust this value to set the maximum zoom level
      // }
    });
//  setTimeout(()=>{
    view.when(() => {
      const graphicsLayer = new GraphicsLayer();
      map.add(graphicsLayer);

      // Clear existing graphics from the graphics layer
      graphicsLayer.removeAll();

      const departmentMarkerSymbols = {
        'Streets': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/streetssolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/streetssolved.png',
            width: '28px',
            height: '50px'
          }
        },
        'Right of Way': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/right-way.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/right-way.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/right-way.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/right-way.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/right-way.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/right-way-done.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/right-way-done.png',
            width: '28px',
            height: '50px'
          }
        },
        'Solid Waste': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/solid-waste1.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/solid-waste1.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/solid-waste1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/solid-waste1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/solid-waste1.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/solidwasteresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/solidwasteresolved.png',
            width: '28px',
            height: '50px'
          }
        },
        'Street Lighting': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/street-lighting1.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/street-lighting1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/street-lighting1.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/street-lighting1.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/streetslightsresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/streetslightsresolved.png',
            width: '28px',
            height: '50px'
          }
        },
        'Neighborhood Code Compliance': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/nccunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/nccunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/nccunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/nccunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/NCCresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/NCCresolved.png',
            width: '28px',
            height: '50px'
          }
        },
        'Water Maintenance': {
          'Unresolved': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Reopen': {
            type: 'picture-marker',
            url: '../../assets/images/streets1.png',
            width: '28px',
            height: '50px'
          },
          'Waiting': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Reclassify': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Disregard': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          },
          'Close-Resolved': {
            type: 'picture-marker',
            url: '../../assets/images/water-maintenanceunresolved.png',
            width: '28px',
            height: '50px'
          }
        },
        //
        //
        //

        // Add more departments and their corresponding marker symbols here
      };

      incidents.forEach((incident: any,index:any) => {
        if (incident.latitude && incident.longitude && incident.department) {
          const adjustedLatitude = parseFloat(incident.latitude);
          // parseFloat(incident.latitude) + (index * 0.000001)
          const point = {
            type: 'point',
            longitude: parseFloat(incident.longitude),
            latitude: adjustedLatitude // Use adjusted latitude
          };

          const departmentSymbol = departmentMarkerSymbols[incident.department][incident.incidentstatus];
          if (incident.addressline1) {
            incident.addressline1 = incident.addressline1.replace(/\d+/g, ''); // Remove numericals
          }
          const createdTime = new Date(incident.createdtime);
          const formattedCreatedTime = createdTime.toLocaleString('en-US', { month: 'short', day: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true });
          // console.log(formattedCreatedTime)
          incident.createdtime = formattedCreatedTime;
// console.log("working")
          if (departmentSymbol) {
            const markerSymbol = {
              type: departmentSymbol.type,
              url: departmentSymbol.url,
              width: departmentSymbol.width,
              height: departmentSymbol.height
            };

            const popupTemplate = new PopupTemplate({
              title: "SR#: " + incident.refno,

              content: [
                {
                  type: 'fields',
                  fieldInfos: [
                    { fieldName: 'addressline1', label: 'Service Address' },
                    { fieldName: 'casetype', label: 'Service Type' },
                    { fieldName: 'incidentstatus', label: 'SR Status' },
                    { fieldName: 'department', label: 'Department' },
                    { fieldName: 'createdtime', label: 'Date Created'},
                  ]
                }
              ]
            });

            const graphic = new Graphic({
              geometry: point,
              symbol: markerSymbol,
              attributes: incident,
              popupTemplate: popupTemplate
            });

            graphicsLayer.add(graphic);

            // If this.incident is set, check if the current incident matches it
            // If it does, open a popup for it
            if (this.incident && this.incident.refno === incident.refno) {
              view.popup.open({
                features: [graphic],
                location: point
              });
            }
          }
        }
      });

      if (incidents.length > 0 && incidents[0].latitude && incidents[0].longitude) {
        const firstIncidentPoint = {
          type: 'point',
          longitude: parseFloat(incidents[0].longitude),
          latitude: parseFloat(incidents[0].latitude)
        };
        // console.log(incident)
console.log(incidents[0].longitude, incidents[0].latitude)
console.log(firstIncidentPoint)
        // view.goTo(
        //   {
        //   center: [incidents[0].longitude, incidents[0].latitude],
        //   zoom: 50,
        //   map: map
        // });
      }
      // console.log(this.incident)
      // console.log(this.incident.length)
      if(this.incident!=undefined)
        {
          view.center = [this.incident.longitude, this.incident.latitude];
          view.zoom = 15;

        }

    });

    const toggle = new BasemapToggle({
      view: view,
      nextBasemap: 'satellite' // Set the default basemap for the toggle
    });

    // Add the BasemapToggle widget to the view
    view.ui.add(toggle, 'bottom-right');
    // Emit map loaded event
    this.mapLoaded.emit(true);
      //  },2);
  });
}




onDepartmentChange(selectedDepartment: string) {
  this.selectedDepartment = selectedDepartment;
  const filteredIncidents = this.recentIncidents.filter((incident: any) => incident.department === this.selectedDepartment);
  console.log(filteredIncidents)
  this.initializeMapWithPopup(filteredIncidents);
}




}
