

import { makeServiceRequestService } from '../make-service-request.service';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
@Component({
  selector: 'app-review-dialog',
  templateUrl: './review-dialog.component.html',
  styleUrls: ['./review-dialog.component.scss']
})
export class ReviewDialogComponent implements OnInit {
  constructor(public dialogRef: MatDialogRef<ReviewDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public makeService: makeServiceRequestService) { }
  onCloseClick(): void {
    // console.log(this.refno)
    this.makeService.referrence_number = this.allrequireddata.payLoad.refno;
    this.dialogRef.close();
  }

  // refno: any
  createdtime:any;
  formattedTime:any;
  allrequireddata:any
  ngOnInit(): void {
    console.log(this.data)
    // this.transform(1714650013)
    console.log(this.data['dataInPopup'])
    this.allrequireddata=this.data['dataInPopup']
    console.log(this.makeService)
    // this.refno = this.data['dataInPopup'].allrequireddata.payLoad.refno;
    this.createdtime=this.data['dataInPopup'].createdtime;
    console.log(this.createdtime)
    const milliseconds = this.createdtime;
    const date = new Date(milliseconds);

    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const monthIndex = date.getMonth();
    const month = months[monthIndex];
    const day = ("0" + date.getDate()).slice(-2);
    const year = date.getFullYear();
    let hours = date.getHours();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // Handle midnight (0 hours)
    const minutes = ("0" + date.getMinutes()).slice(-2);

    this.formattedTime = `${month} ${day}, ${year} ${hours}:${minutes} ${ampm}`;
    console.log(this.formattedTime);

console.log(this.formattedTime);
    this.makeService.referrence_number = this.allrequireddata.payLoad.refno
  }

  transform(epoch: number): string {
    // console.log(epoch)
    const date = new Date(epoch * 1000); // Convert seconds to milliseconds
    // console.log(date.toISOString())
    return date.toISOString(); // You can format the date as per your requirements
  }

  referencenum: any;
  copyRefNo(event: Event, refno: any) {
    this.dialogRef.close();
    event.preventDefault();

    const dummyInput = document.createElement('input');
    document.body.appendChild(dummyInput);
    dummyInput.setAttribute('value', refno);
    dummyInput.select();
    document.execCommand('copy');
    document.body.removeChild(dummyInput);
    this.referencenum = dummyInput;
    console.log(this.referencenum.value);
    // this.makeService.referrence_number = this.referencenum.value;
  }
}
