
import { Component, ViewChild, Output, EventEmitter } from '@angular/core';

import { DomSanitizer, Title, Meta } from '@angular/platform-browser';
import { MapService } from '../services/mapservice';
import { Subject, filter } from 'rxjs';
import { httpJsonPostOptions, makeServiceRequestService } from '../make-service-request.service';
import { HomeEsrimapComponent } from '../home-esrimap/home-esrimap.component';
import { loadModules } from 'esri-loader';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { KnowledgebaseService } from '../knowledgebase.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { MatTabChangeEvent } from '@angular/material/tabs';
import { MsalService } from '@azure/msal-angular';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';

declare var $: any;
@Component({
  selector: 'app-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],

})
export class PageContentComponent {
  showSubscribePopup: boolean;

  tabChanged(event: MatTabChangeEvent): void {
    console.log('Tab changed:', event.tab.textLabel);
    // alert()
    if (event.tab.textLabel == 'Trending Service Requests') {
      this.datatablepaginations();
    }
    if (event.tab.textLabel == 'My Service Requests') {
      this.datatablepaginationmyrequest();
    }
    // Call your function here
    // For example: this.yourFunction();
  }

  currDiv: string = 'mainView';
  locationInfo: any = localStorage.getItem("locationInfo");
  storedAddressesJSON: any = localStorage.getItem('userListSubmit');
  lattitude: any;
  longitude: any;
  storedAddresses: any;
  isSearchNotRequired: any = true;
  isAddressRequired: any = true;
  URL: any;
  track_with_refno: any = ''
  recentIncidents: any;
  recentincidents: any = [];
  updatedrecentincidents: any = []
  @ViewChild(HomeEsrimapComponent) homeEsrimapComponent!: HomeEsrimapComponent;
  constructor(private formBuilder: FormBuilder, private knowledgeSearchService: KnowledgebaseService, private router: Router, public sanitizer: DomSanitizer, private mapService: MapService, private makeservicerequest: makeServiceRequestService, private http: HttpClient, private datePipe: DatePipe, private authService: MsalService) {

  }

  mapCenter = [-85.1392, 41.0804];
  basemapType = 'satellite';
  mapZoomLevel = 11;
  mapLoadedEvent(status: boolean) {
    console.log('The map has loaded: ' + status);
  }
  searchLocation: any;
  caseTypeVal: any = [];
  newcaseTypeVal: any = [];
  departmentItems: any;
  departmentlist: string[] = [];
  selectedDepartment: any = 'All';
  searchterms: any;
  mysrlist: boolean = true;
  getImageFromjson(incidentid: any) {

    const item = this.casetype_image.find((obj: any) => obj.casetype === incidentid)

    // console.log(item)

    if (item) {
      return item.imageUrl
    }
    // ../../assets/images/abandoned-vehicle.png

    return "../../assets/images/abandoned-vehicle.png"
  }

  casetype_image: any;
  logInfo: any;
  loggedinemail: any
  incidenttobesubscribed: any

  openSubscribePopup(incident: any) {
    this.incidenttobesubscribed = incident
    this.showSubscribePopup = true;
    // clearTimeout(this.sessionTimer);
  }


  closeSubscribePopup() {
    this.showSubscribePopup = false;
    this.subscribeForm.reset();
  }
  Nanaddress(address: any) {
    return address.replace(/\d+/g, '');

  }
  subscribeForm: FormGroup;
  ngOnInit() {

    this.subscribeForm = this.formBuilder.group({
      Subscribe: ['', [Validators.required,this.emailValidator()]]
    });


    this.makeservicerequest.loggedin = false;
    // if (this.authService.instance.getAllAccounts().length > 0) {
    //   this.logInfo = this.authService.instance.getAllAccounts()[0];
    //   console.log('test header info', this.logInfo);
    //   this.loggedinemail = this.logInfo.username
    //   this.makeservicerequest.loggedinemail = this.logInfo.username
    //   this.makeservicerequest.loggedin = true
    //   // this.subscribeForm.get('Subscribe')?.setValue(this.logInfo.username)
    // }
    // this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
    //   this.departmentItems = data;
    //   this.departmentItems.forEach((obj: any) => {
    //     obj.caseType.forEach((ele: any) => {
    //       this.caseTypeVal.push(ele);
    //     });
    //   })
    //   console.log(this.caseTypeVal)

    //   this.newcaseTypeVal = this.caseTypeVal
    //   this.newcaseTypeVal = this.newcaseTypeVal.filter((x: any) => x.filteringvalues && x.filteringvalues.includes('Trending'))

    //   console.log(this.newcaseTypeVal)

    // // this.datatablepaginations();
    // });


    // this.http.get<any>('/assets/json/casetype_image.json').subscribe((data) => {
    //   this.casetype_image = data;
    //   console.log(this.casetype_image)
    //   console.log(this.casetype_image)
    // });


    this.storedAddresses = (this.storedAddressesJSON) ? JSON.parse(this.storedAddressesJSON) : [];
    if (this.locationInfo) {
      this.locationInfo = JSON.parse(this.locationInfo);
      this.lattitude = this.locationInfo.lattitude
      this.longitude = this.locationInfo.longitude
      this.URL = "https://maps.google.com/maps?q=" + this.lattitude + "," + this.longitude + "&hl=es&z=14&amp;output=embed";

    }
    this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
      this.departmentItems = data;
      this.departmentItems.forEach((obj: any) => {
        // Check if the 'caseType' property exists and is an array
        if (Array.isArray(obj.caseType)) {
          // Concatenate the 'caseType' array with 'allEleObjects'
          this.caseTypeVal = this.caseTypeVal.concat(obj.caseType);
          // console.log(this.caseTypeVal)
        }
        // console.log(this.caseTypeVal)
      });
      let departments: string[] = [];

      // Check if 'this.caseTypeVal' is defined and it's an array
      if (Array.isArray(this.caseTypeVal)) {
        // Create a Set to store unique department names
        let uniqueDepartments = new Set<string>();

        // Loop through each object in the array
        this.caseTypeVal.forEach((item: any) => {
          // Access the 'department' property and add its 'name' value to the Set
          if (item && item.department && item.department.name) {
            uniqueDepartments.add(item.department.name);
          }
        });

        // Convert the Set back to an array to match the format you want
        this.departmentlist = Array.from(uniqueDepartments);

        // console.log(this.departmentlist);
      }



    });

    this.recentIncidents;
    this.getRecentIncidents();
    // this.getByreferrenceId();
    this.applyFilters();
    this.datatablepaginations();
    console.log(this.recentIncidents)
    this.getuserIncidents();
    this.gettrendingdata();
    this.getusersubscription();
  }
  loaddatatable() {
    this.datatablepaginations();
    console.log("helloooooooooooo")
  }
  ngAfterViewInit() {

    this.datatablepaginations();
  }
  formatDate(date: Date): any {
    return this.datePipe.transform(date, 'MMM dd, yyyy hh:mm a');
  }
  createdagoformatDate(date: Date): any {
    return this.datePipe.transform(date, 'MMM dd, yyyy hh:mm a');
  }
  gotoNext(data: any) {
    console.log(data)

    this.knowledgeSearchService.opentab(data);

    this.router.navigate(['/create-sr'])

  }
  showlist: boolean = true;
  datatablepaginations() {
    console.log("in datatable");

    // Check if DataTable is initialized on the table
    // if ($.fn.DataTable.isDataTable("#listviewtables")) {
    //   // Destroy existing DataTable
    //   $("#listviewtables").DataTable().destroy();
    // }

    // Initialize DataTable after a delay

    const screenWidth = window.innerWidth;
    const isSmallScreen = screenWidth <= 768;
    setTimeout(() => {
      ($('#listviewtables') as any).dataTable({
        "language": {
          "paginate": {
            "previous": "",
            "next": ""
          },
          "lengthMenu": "Records per page _MENU_",
        },
        pageLength: 10,
        "order": [[1, ""]],

        "columnDefs": [
          { "orderable": false, "targets": 0 },
          { 'orderable': false, 'targets': 3 },
          // { 'orderable': false, 'targets': 4 }
        ],

        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        fixedColumns: {
          start:isSmallScreen ? 0 : 1,
          end: isSmallScreen ? 1 : 1,
      },
        paging: true,
        fixedHeader: true,
        // scrollX: false,
        scrollY: 450,
        destroy: true,
        dom: 'frtlip',
        drawCallback: function (settings) {
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let startIndex = api.page() * api.page.len() + 1;

          $(rows).each(function (index) {
            $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
            startIndex++;
          });
        },
      });
    }, 200);
  }


  onAddressChange() {
    console.log(this.searchAddress)
    this.applyFilters()
    // Pass the search address and selected department to HomeEsrimapComponent
    this.homeEsrimapComponent.loadMapToAddress(this.searchAddress, this.selectedDepartment);
  }

  onDepartmentChange(selectedDepartment: string) {
    this.selectedDepartment = selectedDepartment;
    if (selectedDepartment === '') {
      this.selectedDepartment = 'All';
    }
    // console.log(this.selectedDepartment)
    this.applyFilters()
    // Pass the search address and selected department to HomeEsrimapComponent
    this.homeEsrimapComponent.loadMapToAddress(this.searchAddress, this.selectedDepartment);
  }
  createdago(createdago: string): string {
    // Check if the string contains "Created on"
    if (createdago.includes("Created on")) {
      // Extract the date part from the string
      const dateString = createdago.replace("Created on ", "");
      const date = new Date(dateString);

      // Check if the extracted date is valid
      if (!isNaN(date.getTime())) {
        // Format the date to "MMM dd, yyyy hh:mm a"
        return 'Created on ' + this.createdagoformatDate(date);      } else {
        // If the date is not valid, return the original string
        return createdago;
      }
    } else {
      // If the string does not contain "Created on", return it as is
      return createdago;
    }
  }
  @Output() showIncident = new EventEmitter<any>();
  showIncidentLocation(incident: any) {
    console.log(incident)
    this.searchAddress;
    console.log("search", this.searchAddress)
    this.applyFilters()
    // Call the showIncidentLocation method of HomeEsrimapComponent
    this.homeEsrimapComponent.showIncidentLocation(incident);
  }
  createdAgoList:any;
  getRecentIncidents() {
    this.makeservicerequest.getrecentIncidents().subscribe(
      (data) => {
        this.recentIncidents = data;
        console.log(this.recentIncidents)
        const createdAgoList = this.recentIncidents.map(x => x.createdago);

        console.log(createdAgoList);
               console.log(this.recentIncidents.createdago)
        this.applyFilters()

        // console.log(data);
      },
      (error) => {

        console.error('Error fetching recent incidents:', error);
      }
    );
  }

  ShowDiv(divVal: string) {
    this.currDiv = divVal;
  }

  showview() {
    this.showlist = !this.showlist;
    this.datatablepaginations();
  }
  destroyDataTable() {
    if ($.fn.DataTable.isDataTable("#listviewtables")) {
      $("#listviewtables").DataTable().destroy();
    }
  }
  destroymysrDataTable() {
    if ($.fn.DataTable.isDataTable("#myservicerequest")) {
      $("#myservicerequest").DataTable().destroy();
    }
  }
  toggleListView() {
    this.showlist = !this.showlist;
console.log(this.showlist)
    // Reinitialize DataTable when switching between list and grid view
    if (this.showlist == false) {
      this.datatablepaginations();
    } else {
      this.destroyDataTable();
    }
  }
  togglemysrListView() {
    this.mysrlist = !this.mysrlist;

    // Reinitialize DataTable when switching between list and grid view
    if (this.mysrlist == true) {
      this.datatablepaginationmyrequest();
    } else {
      this.destroymysrDataTable();
    }
  }
  ListView() {
    this.showlist = !this.showlist;

    // Reinitialize DataTable when switching between list and grid view
    if (this.showlist == true) {
      this.datatablepaginations();
    } else {
      this.destroyDataTable();
    }
  }
  // showgridview()
  // {
  //   this.showlist=false;
  // }
  markers: google.maps.Marker[] = [];
  infoWindow: google.maps.InfoWindow = new google.maps.InfoWindow()

  clickAddress: Subject<any> = new Subject();
  showPopup(data: any, index: any) {
    data["index"] = index;
    this.clickAddress.next(data);
  }
  showDropdown: boolean = false;
  addressSuggestions: any;
  searchAddress: any;
  onOptionClick(selectedValue: string) {
    this.searchAddress = selectedValue;
    // Optionally, close the option box after selecting a value
    this.showDropdown = false;
  }

  onDropdownChange(selectedValue: string) {
    console.log("selectedvalueeeeeeeeeeee", selectedValue);
    this.searchAddress = selectedValue;
    //  this.onSearch();
  }
  suggestionSearch(event: any) {
    console.log(event);
    this.performAddressSearch1(event);
  }
  entiredata: any;
  async performAddressSearch1(searchTerm: any) {
    console.log(searchTerm)
    this.makeservicerequest.mapsuggestions(searchTerm).subscribe((response: any) => {
      console.log(response.suggestions);
      this.entiredata = response.suggestions
      this.addressSuggestions = response.suggestions.map((suggestion: { text: any; }) => suggestion.text);
      console.log(this.addressSuggestions);
    });

  }
  fetchaddressdetails: any;
  assignAddress(item: any) {
    console.log(item)
    this.addressSuggestions = []
    var streetAddress = item.split(',')[0].trim();
    console.log(item)
    this.searchAddress = item;
    this.entiredata=[];
    // this.selectedmap=$('.pane:visible [data-basemap-id]').attr('data-basemap-id')
    // this.togglemap=
    // console.log(this.selectedmap)
    // console.log($('[data-basemap-id]').attr('data-basemap-id'))
    // this.addressvalue = item
    // this.suggestionSearch()

    //   var k=this.agentscriptservice.fetchData(this.tab_title,'casetypedata')
    // let casetypeid = k.serviceDetails.casetypeid;
    //   console.log(k.serviceDetails.casetypeid)
    this.makeservicerequest.fetchaddressdetails(streetAddress, 'Address', '').subscribe((data: any) => {
      this.fetchaddressdetails = data
      console.log(data)

      // this.searchAddress=data.returned_address;
      // console.log(this.searchAddress)
      // this.makeservicerequest.updateOrInsertArray(this.tab_title, 'mapdata', this.fetchaddressdetails)
      // console.log(this.fetchaddressdetails)
      //     if (this.fetchaddressdetails.msg) {
      //       alert("please enter valid address");
      //       // this.signupForm.get('addressline1')?.setValue('');

      // // console.log(item)
      //     }
      //     else {
      // console.log(this.fetchaddressdetails)
      //       this.addressSuggestions=[]
      //       this.address=item;
      //       console.log(this.address)
      //       this.signupForm.get('addressline1')?.setValue(this.address)


      //       // this.geocodeAndCenter(streetAddress)
      //     }




    })

  }

  // onAddressChange() {

  //   // Pass the address to HomeEsrimapComponent
  //   this.searchLocation = this.searchAddress;
  //   console.log(this.searchLocation)
  // }
  filteredIncidents: any=[];
  applyFilters() {
    // console.log(this.applyFilters)
    console.log(this.searchAddress)

    console.log(this.filteredIncidents)
    console.log(this.selectedDepartment)
    if ((this.selectedDepartment === 'Solid Waste' ||
      this.selectedDepartment === 'Neighborhood Code Compliance' ||
      this.selectedDepartment === 'Street Lighting' ||
      this.selectedDepartment === 'Streets' ||
      this.selectedDepartment === 'Right of Way' ||
      this.selectedDepartment === 'Water Maintenance') && this.searchAddress) {
      const streetAddress = this.searchAddress.split(',')[0].trim().toLowerCase();
      const streetAddress1 = streetAddress.replace(/\d+/g, '')
      // Extract street portion
      this.filteredIncidents = this.recentIncidents.filter((incident: any) =>
        incident.addressline1.toLowerCase().includes(streetAddress1.toLowerCase()) &&
        incident?.department?.toLowerCase() === this.selectedDepartment.toLowerCase()
      );
      // Filter by both selected department and search address

    } else if ((this.selectedDepartment === 'Solid Waste' ||
      this.selectedDepartment === 'Neighborhood Code Compliance' ||
      this.selectedDepartment === 'Street Lighting' ||
      this.selectedDepartment === 'Streets' ||
      this.selectedDepartment === 'Right of Way' ||
      this.selectedDepartment === 'Water Maintenance') && !this.searchAddress) {
      // Filter only by selected department
      this.filteredIncidents = this.recentIncidents.filter((incident: any) =>
        incident.department === this.selectedDepartment);
    } else if (this.searchAddress && !(this.selectedDepartment === 'Solid Waste' ||
      this.selectedDepartment === 'Neighborhood Code Compliance' ||
      this.selectedDepartment === 'Street Lighting' ||
      this.selectedDepartment === 'Streets' ||
      this.selectedDepartment === 'Right of Way' ||
      this.selectedDepartment === 'Water Maintenance' ||
      this.selectedDepartment === 'All')) {
      // Filter only by search address (ignoring case)
      const streetAddress = this.searchAddress.split(',')[0].trim().toLowerCase();
      const streetAddress1 = streetAddress.replace(/\d+/g, '') // Extract street portion and convert to lowercase
      this.filteredIncidents = this.recentIncidents.filter((incident: any) =>
        incident.addressline1.toLowerCase().includes(streetAddress1)
      );
    } else if (this.selectedDepartment == 'All' && !this.searchAddress) {


      this.filteredIncidents = this.recentIncidents;
    }
    else if (this.selectedDepartment == 'All' && this.searchAddress) {

      const streetAddress = this.searchAddress.split(',')[0].trim().toLowerCase();
      console.log(streetAddress)
      const streetAddress1 = streetAddress.replace(/\d+/g, '')
      // Extract street portion
      this.filteredIncidents = this.recentIncidents.filter((incident: any) =>
        incident.addressline1.toLowerCase().includes(streetAddress1)

      );
    }
    else {
      // No filters applied, show all recent incidents
      this.filteredIncidents = this.recentIncidents;
    }
    // console.log(this.filteredIncidents)
  }
  getImagePath(department: string, incidentStatus: string): string {
    let imagePath = '../../assets/images/'; // Base path to your images folder

    // Logic to determine image path based on department
    if ((department === 'Solid Waste' && incidentStatus === 'Unresolved') || (department === 'Solid Waste' && incidentStatus === 'Waiting')||(department === 'Solid Waste' && incidentStatus === 'Reopen')||(department === 'Solid Waste' && incidentStatus === 'Close-Reclassify')||(department === 'Solid Waste' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'solid-waste1.png';

    }
    else if ((department === 'Solid Waste' && incidentStatus === 'Resolved')||(department === 'Solid Waste' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'solidwasteresolved.png';
    }
    else if ((department === 'Neighborhood Code Compliance' && incidentStatus === 'Unresolved') || (department === 'Neighborhood Code Compliance' && incidentStatus === 'Waiting')||(department === 'Neighborhood Code Compliance' && incidentStatus === 'Reopen')||(department === 'Neighborhood Code Compliance' && incidentStatus === 'Close-Reclassify')||(department === 'Neighborhood Code Compliance' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'nccunresolved.png';
    }
    else if ((department === 'Neighborhood Code Compliance' && incidentStatus === 'Resolved')||(department === 'Neighborhood Code Compliance' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'NCCresolved.png';
    }
    else if ((department === 'Street Lighting' && incidentStatus === 'Unresolved') || (department === 'Street Lighting' && incidentStatus === 'Waiting')||(department === 'Street Lighting' && incidentStatus === 'Reopen')||(department === 'Street Lighting' && incidentStatus === 'Close-Reclassify')||(department === 'Street Lighting' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'street-lighting1.png';
    }
    else if ((department === 'Street Lighting' && incidentStatus === 'Resolved')||(department === 'Street Lighting' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'streetslightsresolved.png';
    }
    else if ((department === 'Streets' && incidentStatus === 'Unresolved') || (department === 'Streets' && incidentStatus === 'Waiting')||(department === 'Streets' && incidentStatus === 'Reopen')|| (department === 'Streets' && incidentStatus === 'Close-Reclassify')||(department === 'Streets' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'streets1.png';
    }
    else if ((department === 'Streets' && incidentStatus === 'Resolved')||(department === 'Streets' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'streetssolved.png';
    }
    else if ((department === 'Water Maintenance' && incidentStatus === 'Unresolved') || (department === 'Water Maintenance' && incidentStatus === 'Waiting')||(department === 'Water Maintenance' && incidentStatus === 'Reopen')||(department === 'Water Maintenance' && incidentStatus === 'Close-Reclassify')||(department === 'Water Maintenance' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'water-maintenanceunresolved.png';
    }
    else if ((department === 'Water Maintenance' && incidentStatus === 'Resolved')||(department === 'Water Maintenance' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'water-maintenanceunresolved.png';
    }
    else if ((department === 'Right of Way' && incidentStatus === 'Unresolved') || (department === 'Right of Way' && incidentStatus === 'Waiting')||(department === 'Right of Way' && incidentStatus === 'Reopen')||(department === 'Right of Way' && incidentStatus === 'Close-Reclassify')||(department === 'Right of Way' && incidentStatus === 'Close-Disregard')) {
      imagePath += 'right-way.png';
    }
    else if ((department === 'Right of Way' && incidentStatus === 'Resolved')||(department === 'Right of Way' && incidentStatus === 'Close-Resolved')) {
      imagePath += 'right-way-done.png';
    }
    else {
      imagePath += 'default.png'; // Default image if no specific image is found
    }

    return imagePath;
  }


  async performAddressSearch(searchTerm: string) {
    console.log(this.searchAddress)
    console.log(searchTerm)
    try {
      // Load required modules
      const [Search] = await loadModules(['esri/widgets/Search']);

      // Create a new search widget
      const searchWidget = new Search();

      // Perform address suggestion search with the input value
      const suggestions = await searchWidget.suggest(searchTerm);
      console.log('Autocomplete Suggestions:', suggestions.results[0].results.length);
      if (suggestions.results[0].results.length > 1) {
        // Show the dropdown
        this.showDropdown = true;
        // Assign suggestions to a property for binding in the template
        this.addressSuggestions = suggestions.results[0].results;
        console.log(this.addressSuggestions);
      } else {
        // Hide the dropdown
        this.showDropdown = false;
      }
      // Handle suggestions here, such as displaying them to the user
    } catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
  }




  getByreferrenceId() {
    // console.log("hi")
    // console.log(this.track_with_refno.length)
    // if (true) {
    this.http.post(environment.backendurl + "getByIncidentByRefno/" + this.track_with_refno, httpJsonPostOptions).subscribe((data: any) => {
      console.log(data)
      // console.log(data['refno'])
      console.log(data)
      this.recentincidents = []
      if (Object.keys(data).length === 0 && data.constructor === Object) {
        this.recentincidents = []
      }
      else {
        this.recentincidents = []
        console.log(this.recentincidents)
        this.recentincidents.push(data)
      }

      console.log(this.recentincidents)
      // this.datatablepagination()
    })
    // }
    // else {
    //   this.recentincidents = this.updatedrecentincidents
    //   // this.datatablepagination()
    // }

    // alert(this.track_with_refno.value.length)

  }
  myservicerequest: any;
  getuserIncidents() {
    // this.makeservicerequest.getuserIncidents().subscribe((data: any) => {
    //   console.log(data);
    //   this.myservicerequest = data;
    //   console.log("this.myservicerequest", this.myservicerequest)
    //   this.datatablepaginationmyrequest();
    // })
  }
  datatablepaginationmyrequest() {
    console.log("in datatable");

    // Check if DataTable is initialized on the table
    if ($.fn.DataTable.isDataTable("#myservicerequest")) {
      // Destroy existing DataTable
      $("#myservicerequest").DataTable().destroy();
    }

    // Initialize DataTable after a delay
    setTimeout(() => {
      $('#myservicerequest').DataTable({

        "language": {
          "paginate": {
            "previous": "",
            "next": ""
          },
          "lengthMenu": "Records per page _MENU_",
        },
        pageLength: 10,
        "order": [[1, ""]],

        "columnDefs": [
          { "orderable": false, "targets": 0 },
          { 'orderable': false, 'targets': 7 }
        ],

        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        fixedColumns: {
          start: 1,
          end: 1
        },
        paging: true,
        fixedHeader: true,
        // scrollX: false,
        scrollY: 450,
        destroy: true,
        dom: 'frtlip',
        drawCallback: function (settings) {
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let startIndex = api.page() * api.page.len() + 1;

          $(rows).each(function (index) {
            $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
            startIndex++;
          });
        },
      });
    }, 200);
  }
  gettrendingdata() {
    this.makeservicerequest.getTrendingdata().subscribe((data: any) => {
      console.log(data)
      this.newcaseTypeVal = data;
    })
  }
  getIfSubscribed(incidentid: any) {
    const item = this.subscriptiondata?.find(item => item.incidentid == incidentid);
    if (item) {
      return true
    }
    return false
  }
  subscriptiondata: any
  getusersubscription() {
    this.makeservicerequest.getusersubscription().subscribe((data: any) => {
      console.log(data)
      this.subscriptiondata = data
    })
  }
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailPattern.test(email);

      if (email && !valid) {
        return { 'invalidEmailFormat': true };
      }
      return null;
    };
  }
  submitSubscribe() {
    var subscribedmail = this.subscribeForm?.get('Subscribe')?.value
    if (!(this.makeservicerequest.loggedinemail)) {
      var emailsubscribed = subscribedmail
    }
    else{
      var emailsubscribed = this.makeservicerequest.loggedinemail
    }
    this.savesubscription(emailsubscribed, this.incidenttobesubscribed)
    this.subscribeForm.reset();
  }
  savesubscription(loggedinemail: any, incidentid: any) {
    //  this.swalfireevent()
    this.makeservicerequest.savesubscription(loggedinemail, incidentid).subscribe((data: any) => {
      console.log(data);
      this.showSubscribePopup = false
      this.getusersubscription();
    })
  }

  unsubscribe(incidentId: any) {
    this.makeservicerequest.unsubscribe(incidentId).subscribe((data: any) => {
      console.log(data);
      this.getusersubscription();
    });
  }

}
function openSubscribePopup() {
  throw new Error('Function not implemented.');
}

