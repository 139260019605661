export const environment = {
    production: true,
    apiFileURL:'https://tst-mahelpline-upload.thesmartice.com/cc-fileuploads/',
    backendurl:'https://fortwayne-citizen-services.thesmartcity311.com/',
    chatbotUrl:'https://fortwayne-smart-chat.thesmartcity311.com/',
    JavaUrlEndPoint:'',
    localhost:window.location.protocol+"//"+window.location.hostname+":"+window.location.port,
    language:'en',
    loglevel: 'info',
    clientId:'b1fb15e8-9106-46e7-8734-207ddc2e34f0',
    msalAuthority:'https://fortwayne311.b2clogin.com/fortwayne311.onmicrosoft.com/B2C_1_signincustomer',
    pwresetmsalAuthority:'https://fortwayne311.b2clogin.com/fortwayne311.onmicrosoft.com/B2C_1_pwdreset',
    silentAPI:"https://fortwayne311.b2clogin.com/fortwayne311.onmicrosoft.com/api/user_impersonation",
    dev_mode: "FALSE"
  };


