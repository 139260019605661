import { Component, Input } from '@angular/core';
import * as jq from 'jquery';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Router, ResolveStart, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PopupComponent } from '../popup/popup.component';
import { KnowledgebaseService } from '../knowledgebase.service';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  caseTypeVal: any = [];
  newcaseTypeVal: any = [];
  @Input() isLandingPage:any;
  @Input() isLogin:any;

  logInfo:any;
  dialog: any;
  departmentItems: any;
  isSearchBoxOpen: any;
  //isUserLoggedIn;
  constructor(private knowledgeSearchService:KnowledgebaseService,private authService: MsalService, private router: Router, private http: HttpClient){

  }
   activeDropdown: string | null = null;
   ngOnChanges(){

    // console.log('test header',this.authService.instance.getAllAccounts());
    // if(this.authService.instance.getAllAccounts().length > 0){
    //   this.logInfo = this.authService.instance.getAllAccounts()[0];
    //   console.log('test header info',this.logInfo);
    // }
    // this.isLogin = this.isLogin? true:false
   }

   toggleSearchBox() {
    this.isSearchBoxOpen = !this.isSearchBoxOpen;
  }
  ngOnInit(){

    // this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
      this.http.get<any>(environment.backendurl+'getDeptcasedefToCustomer').subscribe((data) => {
      this.departmentItems = data;
      this.departmentItems.forEach((obj: { caseType: any[]; }) => {
        obj.caseType.forEach((ele: any) => {
          this.caseTypeVal.push(ele);
        });

      })

      this.caseTypeVal.sort((a: { content: string; }, b: { content: string; }) => {
        const contentA = a.content.toLowerCase();
        const contentB = b.content.toLowerCase();

        if (contentA < contentB) return -1;
        if (contentA > contentB) return 1;
        return 0;
    });

      console.log(this.caseTypeVal)
      this.newcaseTypeVal = this.caseTypeVal
    });
    console.log(this.isLandingPage);
    //this.isLogin = this.logInfo? true:false

    //this.isUserLoggedIn = this.authService.instance.getAllAccounts().length
  }
  isSignupRoute(): boolean {
    return this.router.url.includes('signup');
  }
  filterlist() {
    console.log(this.caseTypeVal)
    this.caseTypeVal = this.newcaseTypeVal
    this.caseTypeVal = [...this.caseTypeVal.filter((x: any) => x.content.toLowerCase().includes(this.searchQuery.trim().toLowerCase()))]
    console.log(this.caseTypeVal)
  }
  gotoNext(data: object, rowInfo?: Object) {
    // console.log(data,rowInfo)

    this.knowledgeSearchService.opentab(data);

    this.router.navigate(['/create-sr'])

    // this.serviceTypeDetailsOutputEvent.emit(data)
  }
  openDropdown(dropdownId: string) {
    this.activeDropdown = dropdownId;
  }

  closeDropdown(dropdownId: string) {
    this.activeDropdown = null;
  }
  onSearch(): void {

    this.knowledgeSearchService.updateSearchQuery(this.searchQuery,'','');
    // console.log(this.searchterms, this.selectedDepartment, this.selectedCategory,'1111')
    // this.searchEvent.emit();
    // this.knowledgeService.triggerSearchEvent();
  }
  searchQuery: string = '';
  // mobile menu
  menuOpen:boolean = false;
 clickHamburgerMenu(){
  this.menuOpen = !this.menuOpen;

  if(this.menuOpen){
   jq(".side-nav").addClass('sidenav-open');
  }else{
   jq(".side-nav").removeClass('sidenav-open');
  }
 }


 // mobile menu

 openPopup(){
  this.dialog.open(PopupComponent, {
    width: '440px'
  });
}
 logout(){
  this.authService.logout();
  localStorage.clear()
  sessionStorage.clear()
 }
 onSelect(item: any) {
  // console.log(item)
}
}
