import { Component, ElementRef, ViewChild, HostListener, Input, Output, EventEmitter, ChangeDetectorRef } from '@angular/core';
import {
  FormGroup,
  FormControl,
  FormArray,
  FormBuilder,
  Validators,
  NgForm,
  AbstractControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { makeServiceRequestService } from '../make-service-request.service';
import { MatDialog } from '@angular/material/dialog';
import { ReviewDialogComponent } from '../review-dialog/review-dialog.component';
import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'app-agent-script',
  templateUrl: './agent-script.component.html',
  styleUrls: ['./agent-script.component.scss']
})
export class AgentScriptComponent {

  // isMobile: boolean = window.innerWidth <= 768;
  // screenWidth:any;
  // @HostListener('window:resize', ['$event'])
  // onResize(event: Event): void {
  //   this.screenWidth = window.innerWidth;
  //   console.log('Window width:', this.screenWidth);
  // }
  object_heading!: string;

  receivedData!: string;
  isChecked: boolean = true;

  iscontinueButtonVisible: boolean = true;

  selectControl: any;
  addressObj = {
    address: '',
    address2: '',
    address_t: '',
    msr_lat: '',
    msr_lng: '',
    msr_city: '',
    msr_state: '',
    msr_zip: '',
  };

  receiveData(data: any) {
    this.receivedData = data;
    this.addressObj.address = data.address;
    this.addressObj.address2 = data.address2;
    this.addressObj.address_t = data.address_t;
    this.addressObj.msr_lat = data.msr_lat;
    this.addressObj.msr_lng = data.msr_lng;
    this.addressObj.msr_city = data.msr_city;
    this.addressObj.msr_state = data.msr_state;
    this.addressObj.msr_zip = data.msr_zip;
  }

  showNewSR: boolean = true;
  showTrackSR: boolean = false;
  selectAccordian: any = 0;
  selectCartList: any = [];
  isAnonymous: boolean = false;
  contactDetailsForm!: FormGroup;
  contactnumber = false;
  aph = false;
  serviceRequestArray: any;
  dataarray: any;
  agentScriptArray: any;
  selectCartQst: any = [];
  selectQuestionCartItems: any = [];
  subGroupItem: any = {};
  mapCenter = [-85.1392, 41.0804];
  basemapType = 'satellite';
  mapZoomLevel = 11;
  formData: any = {};
  ContactPayload: any = {};

  mapLoadedEvent(status: boolean) {
    console.log('The map has loaded: ' + status);
  }
  private selectedValue!: string;
  deptname: string = '';
  casetype: string = '';
  filename: any;
  qid: string = '';
  deptId: any;
  casetypeId: string = '';
  divId: string = '';
  constructor(public DialogComponent: MatDialog,
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    private makeSerReqService: makeServiceRequestService, private cdRef: ChangeDetectorRef
  ) {


  }

  // complex code
  lengthofvehicles!: number;
  formDataArray: any[] = [];
  iteration_id: number = 0;
  json_id!: number;
  displayArray: { id: number, arraydisplay: any[] }[] = [];

  checkArray(id: any, index: any) {
    // setTimeout(() => {
    const item = this.displayArray.find(obj => obj.id === id);

    if (item) {
      if (index >= 0 && index < item.arraydisplay.length) {
        this.initialValues = "yes";
        return item.arraydisplay[index];
      }
      this.initialValues = "No";
      return false;
    }
    // },0)
    // console.log(this.initialValues)

  }

  // checkArrayindex(id: any, value: boolean): number | null {
  //   const item = this.displayArray.find(obj => obj.id === id);

  //   if (item) {
  //     const index = item.arraydisplay.indexOf(value);
  //     return index !== -1 ? index : null;
  //   }

  //   return null;
  // }

  // updateValue(1, 'rv_1_cm_reason', '5')
  updateValue(index: number, key: string, newValue: any): void {
    // Check if the index is valid and the object at that index has the specified key
    if (index >= 0 && index < this.formDataArray.length && this.formDataArray[index].hasOwnProperty(key)) {
      // Update the value of the specified key
      this.formDataArray[index][key] = newValue;
    } else {
      // Handle the case where the key doesn't exist or the index is out of range
      console.log('Invalid index or key does not exist.');
    }
  }

  visibletopublic: any;
  onmultiChange(event: any, item: any, iterationid: number, jsonid: number) {
    console.log(this.formDataArray)
    console.log("on multi change")
    console.log(event.target.value)
    console.log(item);
    // console.log(iterationid+1);
    this.iteration_id = iterationid;
    // alert(this.iteration_id)
    this.json_id = jsonid;
    console.log(jsonid)
    // this.addNewEntry(jsonid,Array(this.formDataArray.length).fill(false))
    item.subq.forEach((element: any) => {
      if (element.id == jsonid) {
        console.log(element)
        // console.log(element.rules.length)
        if (element.hasOwnProperty("rules")) {
          element.rules.forEach((x: any) => {
            this.executeCondition(event, x, item);
          })
        }
        // console.log(element.question)
        // console.log(element.rules[0])

      }
    })
  }

  createForms(selectedValue: any) {
    this.displayArray = []
    this.checkArray1 = []
    console.log("in create forms")
    this.lengthofvehicles = selectedValue;
    console.log(selectedValue)
    // this.iteration_id=0;
    this.formDataArray = [];
    this.formDataArray = Array.from({ length: selectedValue }, () => ({}));
    // this.insertOrUpdate(Array(this.formDataArray.length),false);
    this.checkArray1 = Array.from({ length: selectedValue }, (_, i) => ({ id: i, bool: 'true' }));
    // this.addNewEntry(1, Array(this.formDataArray.length).fill(false));
    // this.addNewEntry(1,Array(this.formDataArray.length).fill(false))
    console.log(this.displayArray)
    console.log(this.checkArray1)
    this.enablesubmitbutton()
  }

  // updateOrCreateArray(id: any, index: number, value: boolean): void {
  //   const item = this.displayArray.find(obj => obj.id === id);
  // console.log(id,index,value)
  // console.log(item)
  //   if (item) {
  //     console.log(item.arraydisplay.length)

  //     if (index >= 0 && index < item.arraydisplay.length) {
  //       item.arraydisplay[index] = value;
  //     } else {
  //       console.error(`Index ${index} is out of bounds for ID ${id}`);
  //     }
  //   } else {
  //     this.addNewEntry(id, Array(this.formDataArray.length).fill(false));
  //     const item =this.displayArray.find(obj => obj.id === id);
  //     if (item) {
  //       if (index >= 0 && index < item.arraydisplay.length) {
  //         item.arraydisplay[index] = value;
  //       } else {
  //         console.error(`Index ${index} is out of bounds for ID ${id}`);
  //       }
  //     }
  //     // this.displayArray.push({ id, arraydisplay: [value] });
  //   }
  // }

  updateOrCreateArray(id: any, index: number, value: boolean): void {
    let item = this.displayArray.find(obj => obj.id === id) ?? undefined;

    console.log(id, index, value);
    console.log(item);

    if (!item) {
      // If the item is not found, add a new entry with a default array
      this.addNewEntry(id, Array(this.formDataArray.length).fill(false));
      item = this.displayArray.find(obj => obj.id === id) as { id: any, arraydisplay: boolean[] }; // Type assertion
    }
    console.log(item.arraydisplay)
    console.log(this.displayArray)
    console.log(item.arraydisplay.length);

    if (index >= 0 && index < item.arraydisplay.length) {
      // If the index is within bounds, update the array
      item.arraydisplay[index] = value;
    } else {
      // If the index is out of bounds, dynamically increase the array size
      const newArray = Array(index + 1).fill(false);

      // Copy existing values to the new array
      for (let i = 0; i < item.arraydisplay.length; i++) {
        newArray[i] = item.arraydisplay[i];
      }

      // Update the array with the new size and set the value at the specified index
      item.arraydisplay = newArray;
      item.arraydisplay[index] = value;

      console.error(`Index ${index} is out of bounds for ID ${id}. Array size increased.`);
    }
  }

  addNewEntry(id: any, initialArray: any[]): void {
    if (!this.displayArray.some(obj => obj.id === id)) {
      this.displayArray.push({ id, arraydisplay: initialArray });
    } else {
      console.error(`Object with id ${id} already exists`);
    }
  }
  VehiclePayload: any = {}
  responsivevehiclepayload(form: any) {
    console.log(form)
    this.lengthofvehicles = form.value.how_may_vehicles;
    this.reviewsubmitpayload['How many vehicles are being reported?'] = this.lengthofvehicles;
    this.VehiclePayload["Addfields.numberofvehicles"] = this.lengthofvehicles;
    while (this.lengthofvehicles > 0) {

      var k = this.lengthofvehicles - 1;

      // if(this.payload_incidentid)
      // VehiclePayload["incidentid"] = this.payload_incidentid;
      this.VehiclePayload["Addfields.vehiclelocation" + this.lengthofvehicles] = form.value['rv_' + k + '_location'];
      this.VehiclePayload["Addfields.vehicleparked" + this.lengthofvehicles] = form.value['rv_' + k + '_parked'];
      this.VehiclePayload["Addfields.vehicletype" + this.lengthofvehicles] = form.value['rv_' + k + '_type'];
      this.VehiclePayload["Addfields.vehicle" + this.lengthofvehicles + "color"] = form.value['rv_' + k + '_color'];
      this.VehiclePayload["Addfields.vehicle" + this.lengthofvehicles + "makemodel"] = form.value['rv_' + k + '_model'];
      console.log(k)
      console.log(form.value['rv_' + k + '_location'])
      this.reviewpayload1["vehicle " + this.lengthofvehicles + " location"] = this.getcolumnvalue(this.agentScriptArray, 'location', form.value['rv_' + k + '_location']);
      var p = this.getcolumnvalue(this.agentScriptArray, 'location', form.value['rv_' + k + '_location']);
      console.log(p)
      this.reviewpayload1["vehicle " + this.lengthofvehicles + " parked"] = this.getcolumnvalue(this.agentScriptArray, 'parked', form.value['rv_' + k + '_parked']);
      this.reviewpayload1["vehicle " + this.lengthofvehicles + " type"] = this.getcolumnvalue(this.agentScriptArray, 'type', form.value['rv_' + k + '_type']);
      // this.reviewsubmitpayload["vehicle "+this.lengthofvehicles+" type"] = this.getcolumnvalue(this.agentScriptArray,'type',form.value['rv_' + k + '_type']);
      this.reviewpayload1["vehicle " + this.lengthofvehicles + " color"] = form.value['rv_' + k + '_color']
      this.reviewpayload1["vehicle " + this.lengthofvehicles + " makemodel"] = form.value['rv_' + k + '_model'];

      console.log(this.reviewpayload1)
      console.log(this.VehiclePayload)
      this.lengthofvehicles--;

      var pk = k + 1;
      this.reviewsubmitpayload["Vehicle " + pk + " Details"] = this.reviewpayload1
      this.reviewpayload1 = {}

    }
    this.reviewsubmitpayload['further details'] = form.value.further_details;
    console.log(this.reviewsubmitpayload)
    console.log(this.VehiclePayload)

    // if (this.casetypeId == '211') {
      this.selectVehicle("Vehicle 1 Details");

    // }

  }

  saveVehiclePayload() {
    let vehicleload = [
      {
        "entityName": "Addfields",
        "payLoad": this.VehiclePayload
      }
    ]

    console.log(this.VehiclePayload)
    this.makeSerReqService.onSaveMakeSr(vehicleload).subscribe((res: any) => {
      console.log("vehicle payload is submitted")
      console.log(res);

    });
  }
  CartPayload: any = {}
  agentScriptSaveData: any;
  vehiclenumber: any
  responsivecartpayload(form: any) {
    this.reviewsubmitpayload = {}
    this.reviewsubmitpayload1 = {}
    this.VehiclePayload={}
    console.log(form.value.cm_how_many)
    this.lengthofvehicles = form.value.cm_how_many
    var p=form.value.cm_how_many
    // console.log(this.getBoolById(0))
    // console.log(this.lengthofvehicles > 0)
    console.log(this.lengthofvehicles)
    this.vehiclenumber = 0
    // while (this.lengthofvehicles > 0) {
    //   // this.reviewsubmitpayload=[]
    //   var k = this.lengthofvehicles - 1;

    //   console.log(this.lengthofvehicles +"and k is "+k)
    //   console.log(k+"cfd"+this.getBoolById(k))
    //   console.log(k+"hgfd"+!this.getBoolById(k))
    //   this.VehiclePayload["Cart.numberofbarrels"] = this.lengthofvehicles
    //   // this.getBoolById(k)
    //   if (this.getBoolById(k)!='false') {
    //     console.log("cart is ok for"+k+"item and length of vehicle is "+this.lengthofvehicles)

    //     this.VehiclePayload["Cart.garbagerecyling" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_cart_type']
    //     this.VehiclePayload["Cart.requestreason" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_reason']
    //     this.VehiclePayload["Cart.sizeofcart" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_cart_size']

    //     this.reviewsubmitpayload["Type of Cart" + this.lengthofvehicles] = this.getcolumnvalue(this.agentScriptArray, 'cm_cart_type', form.value['rv_' + k + '_cm_cart_type'])
    //     this.reviewsubmitpayload["Size of Cart" + this.lengthofvehicles] = this.getcolumnvalue(this.agentScriptArray, 'cm_cart_size', form.value['rv_' + k + '_cm_cart_size'])
    //     this.reviewsubmitpayload["Reason for Request" + this.lengthofvehicles] = this.getcolumnvalue(this.agentScriptArray, 'cm_reason', form.value['rv_' + k + '_cm_reason'])

    //     if (form.value['rv_' + k + '_cm_cart_type'] == 1 && form.value['rv_' + k + '_cm_reason'] == 3) {
    //       if (form.value['rv_' + k + '_cm_two_dollars'] == 'Yes') {
    //         this.VehiclePayload["Cart.hitorsign" + this.lengthofvehicles] = 1;
    //         this.reviewsubmitpayload["There is a fee per month with a 1-year minimum rental. Would you like to proceed?" + this.lengthofvehicles] = 'Yes'

    //         if (form.value['rv_' + k + '_cm_ac_holder'] == 'Yes') {
    //           this.VehiclePayload["Cart.blockingcitycrews" + this.lengthofvehicles] = 1;
    //           this.reviewsubmitpayload["Are you the City Utilites account holder?" + this.lengthofvehicles] = 'Yes'
    //         }
    //         else {
    //           this.VehiclePayload["Cart.blockingcitycrews" + this.lengthofvehicles] = 0;
    //           this.reviewsubmitpayload["Are you the City Utilites account holder?" + this.lengthofvehicles] = 'No'
    //         }

    //       }
    //       else {
    //         this.VehiclePayload["Cart.hitorsign" + this.lengthofvehicles] = 0;
    //         this.reviewsubmitpayload["There is a fee per month with a 1-year minimum rental. Would you like to proceed?" + this.lengthofvehicles] = 'No'

    //       }
    //     }


    //     if (form.value['rv_' + k + '_cm_reason'] == 7) {
    //       this.VehiclePayload["Cart.listreasonforpickup" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_pickup_reason']
    //       this.reviewsubmitpayload["List reason for pick up" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_pickup_reason']
    //     }


    //     let arraytest: string[] = ['1', '6', '7']
    //     if (form.value['rv_' + k + '_cm_reason'] && form.value['rv_' + k + '_cm_reason'].includes(arraytest)) {
    //       // $street_missed = "street_missed".$validCarts;
    //       // if($formData['cm_city_seal_'.$loop] == 'Yes'){
    //       // $street_missed = "street_missed".$validCarts;
    //       if (form.value['rv_' + k + '_cm_city_seal'] == 'Yes') {

    //         this.VehiclePayload["Cart.streetmissed" + this.lengthofvehicles] = 1

    //         this.reviewsubmitpayload["Does the cart have the City Seal on the side?" + this.lengthofvehicles] = 'Yes'


    //       } else {
    //         this.VehiclePayload["Cart.streetmissed" + this.lengthofvehicles] = 0

    //         this.reviewsubmitpayload["Does the cart have the City Seal on the side?" + this.lengthofvehicles] = 'No'
    //       }

    //       // $cart_empty_at_the_curb = "cart_empty_at_the_curb".$validCarts;
    //       if (form.value['rv_' + k + '_cm_cart_empty'] == 'Yes') {
    //         this.VehiclePayload["Cart.cartemptyatthecurb" + this.lengthofvehicles] = 1;

    //         this.reviewsubmitpayload["Is the cart empty and at the curb?" + this.lengthofvehicles] = 'Yes'

    //       } else {
    //         this.VehiclePayload["Cart.cartemptyatthecurb" + this.lengthofvehicles] = 0;

    //         this.reviewsubmitpayload["Is the cart empty and at the curb?" + this.lengthofvehicles] = 'No'

    //       }
    //     }


    //     if (form.value['rv_' + k + '_cm_reason'] == 1) {
    //       this.VehiclePayload["Cart.itemtype" + this.lengthofvehicles] = form.value['rv_' + k + '_cm_what_repair']


    //       if (form.value['rv_' + k + '_cm_what_repair'] == 1) {
    //         this.VehiclePayload["Cart.brokenmeter" + this.lengthofvehicles] = 1;
    //         this.reviewsubmitpayload["What needs repaired?" + this.lengthofvehicles] = "Lid"
    //       }
    //       else {
    //         this.VehiclePayload["Cart.brokenmeter" + this.lengthofvehicles] = 0;
    //         // this.reviewsubmitpayload["What needs repaired?" + this.lengthofvehicles] = "No"

    //       }
    //     }

    //     if (form.value['rv_' + k + '_cm_reason'] == 5) {
    //       if (form.value['rv_' + k + '_cm_nsa_taken'] == 'Yes') {
    //         this.VehiclePayload["Cart.witnessoption" + this.lengthofvehicles] = 1
    //       } else {
    //         this.VehiclePayload["Cart.witnessoption" + this.lengthofvehicles] = 0
    //       }
    //     }

    //     if (form.value['rv_' + k + '_cm_reason'] == 2) {
    //       if (form.value['rv_' + k + '_cm_looked_around_'] == 'Yes') {

    //         this.VehiclePayload["Cart.lookedproperty" + this.lengthofvehicles] = 1

    //       }
    //       else {
    //         this.VehiclePayload["Cart.lookedproperty" + this.lengthofvehicles] = 0

    //       }
    //     }


    //   }

    //   this.lengthofvehicles--;
    //   console.log(this.VehiclePayload)
    //   console.log(this.reviewsubmitpayload)

    //   var pk = k +1;
    //   this.reviewsubmitpayload1["Cart " + pk + " Details"] = this.reviewsubmitpayload
    //   this.reviewsubmitpayload={}
    //   console.log(this.reviewsubmitpayload1)
    // }

    while (this.lengthofvehicles > 0) {

      // this.reviewsubmitpayload=[]
      var k= p - this.lengthofvehicles

      console.log(this.lengthofvehicles + "and k is " + k)
      console.log(k + "cfd" + this.getBoolById(k))
      console.log(k + "hgfd" + !this.getBoolById(k))
      // this.getBoolById(k)
      if (this.getBoolById(k) != 'false') {
        this.vehiclenumber++;
        console.log("cart is ok for" + k + "item and length of vehicle is " + this.lengthofvehicles)

        this.VehiclePayload["Cart.garbagerecyling" + this.vehiclenumber] = form.value['rv_' + k + '_cm_cart_type']
        this.VehiclePayload["Cart.requestreason" + this.vehiclenumber] = form.value['rv_' + k + '_cm_reason']
        this.VehiclePayload["Cart.sizeofcart" + this.vehiclenumber] = form.value['rv_' + k + '_cm_cart_size']

        this.reviewsubmitpayload["Type of Cart" + this.vehiclenumber] = this.getcolumnvalue(this.agentScriptArray, 'cm_cart_type', form.value['rv_' + k + '_cm_cart_type'])
        this.reviewsubmitpayload["Size of Cart" + this.vehiclenumber] = this.getcolumnvalue(this.agentScriptArray, 'cm_cart_size', form.value['rv_' + k + '_cm_cart_size'])
        this.reviewsubmitpayload["Reason for Request" + this.vehiclenumber] = this.getcolumnvalue(this.agentScriptArray, 'cm_reason', form.value['rv_' + k + '_cm_reason'])

        if (Number(form.value['rv_' + k + '_cm_cart_type']) == 1 && Number(form.value['rv_' + k + '_cm_reason']) == 3) {
          if (form.value['rv_' + k + '_cm_two_dollars'] == 'Yes') {
            this.VehiclePayload["Cart.hitorsign" + this.vehiclenumber] = 1;
            this.reviewsubmitpayload["There is a fee per month with a 1-year minimum rental. Would you like to proceed?" + this.vehiclenumber] = 'Yes'

            if (form.value['rv_' + k + '_cm_ac_holder'] == 'Yes') {
              this.VehiclePayload["Cart.blockingcitycrews" + this.vehiclenumber] = 1;
              this.reviewsubmitpayload["Are you the City Utilites account holder" + this.vehiclenumber +" ?"] = 'Yes'
            }
            else {
              this.VehiclePayload["Cart.blockingcitycrews" + this.vehiclenumber] = 0;
              this.reviewsubmitpayload["Are you the City Utilites account holder" + this.vehiclenumber + " ?"] = 'No'
            }

          }
          else {
            this.VehiclePayload["Cart.hitorsign" + this.vehiclenumber] = 0;
            this.reviewsubmitpayload["There is a fee per month with a 1-year minimum rental. Would you like to proceed" + this.vehiclenumber+" ?"] = 'No'

          }
        }


        if (Number(form.value['rv_' + k + '_cm_reason']) == 7) {
          this.VehiclePayload["Cart.listreasonforpickup" + this.vehiclenumber] = form.value['rv_' + k + '_cm_pickup_reason']
          this.reviewsubmitpayload["List reason for pick up" + this.vehiclenumber] = form.value['rv_' + k + '_cm_pickup_reason']
        }


        let arraytest: any[] = [1, 6, 7]
        let reasonValue=form.value['rv_' + k + '_cm_reason'];
        console.log(form.value['rv_' + k + '_cm_reason'])
        console.log(arraytest)
        console.log(arraytest.includes(form.value['rv_' + k + '_cm_reason']))
        console.log(arraytest.includes(Number(reasonValue)));
        if ((form.value['rv_' + k + '_cm_reason']) && (arraytest.includes(Number(reasonValue)))) {
          // $street_missed = "street_missed".$validCarts;
          // if($formData['cm_city_seal_'.$loop] == 'Yes'){
          // $street_missed = "street_missed".$validCarts;
          if (form.value['rv_' + k + '_cm_city_seal'] == 'Yes') {

            this.VehiclePayload["Cart.streetmissed" + this.vehiclenumber] = 1

            this.reviewsubmitpayload["Does the cart have the City Seal on the side" + this.vehiclenumber+" ?"] = 'Yes'


          } else {
            this.VehiclePayload["Cart.streetmissed" + this.vehiclenumber] = 0

            this.reviewsubmitpayload["Does the cart have the City Seal on the side" + this.vehiclenumber+" ?"] = 'No'
          }

          // $cart_empty_at_the_curb = "cart_empty_at_the_curb".$validCarts;
          if (form.value['rv_' + k + '_cm_cart_empty'] == 'Yes') {
            this.VehiclePayload["Cart.cartemptyatthecurb" + this.vehiclenumber] = 1;

            this.reviewsubmitpayload["Is the cart empty and at the curb" + this.vehiclenumber+" ?"] = 'Yes'

          } else {
            this.VehiclePayload["Cart.cartemptyatthecurb" + this.vehiclenumber] = 0;

            this.reviewsubmitpayload["Is the cart empty and at the curb" + this.vehiclenumber+" ?"] = 'No'

          }
        }


        if (Number(form.value['rv_' + k + '_cm_reason'] )== 1) {
          this.VehiclePayload["Cart.itemtype" + this.vehiclenumber] = form.value['rv_' + k + '_cm_what_repair']

          if (Number(form.value['rv_' + k + '_cm_what_repair'] )== 1)
            {
              this.reviewsubmitpayload["What needs repaired" + this.vehiclenumber+" ?"] = "Lid"




            if (form.value['rv_' + k + '_cm_boken_handle'] == 'Yes') {
              this.VehiclePayload["Cart.brokenmeter" + this.vehiclenumber] = 1;
              this.reviewsubmitpayload["Is the handle broken also" + this.vehiclenumber+" ?"] = "Yes"

            }
            else {
              this.VehiclePayload["Cart.brokenmeter" + this.vehiclenumber] = 0;
              this.reviewsubmitpayload["Is the handle broken also" + this.vehiclenumber+" ?"] = "No"

            }



            }
            else if(Number(form.value['rv_' + k + '_cm_what_repair'] )== 2)
              {
                this.reviewsubmitpayload["What needs repaired" + this.vehiclenumber+" ?"] = "Wheels"

              }
              else if(Number(form.value['rv_' + k + '_cm_what_repair'] )== 3)
                {
                  this.reviewsubmitpayload["What needs repaired" + this.vehiclenumber+" ?"] = "Body and/or Handle"

                }



        }

        if (Number(form.value['rv_' + k + '_cm_reason']) == 5) {
          if (form.value['rv_' + k + '_cm_nsa_taken'] == 'Yes') {
            this.VehiclePayload["Cart.witnessoption" + this.vehiclenumber] = 1
            this.reviewsubmitpayload["Did you witness GFL take your bin" + this.vehiclenumber +" ?"] = "Yes"
          } else {
            this.VehiclePayload["Cart.witnessoption" + this.vehiclenumber] = 0

            this.reviewsubmitpayload["Did you witness GFL take your bin" + this.vehiclenumber+" ?"] = "No"

          }
        }

        if (Number(form.value['rv_' + k + '_cm_reason']) == 2) {
          if (form.value['rv_' + k + '_cm_looked_around'] == 'Yes') {

            this.VehiclePayload["Cart.lookedproperty" + this.vehiclenumber] = 1

            this.reviewsubmitpayload["Have you looked around the property for a cart" + this.vehiclenumber+" ?"] = "Yes"

          }
          else {
            this.VehiclePayload["Cart.lookedproperty" + this.vehiclenumber] = 0

            this.reviewsubmitpayload["Have you looked around the property for a cart" + this.vehiclenumber+" ?"] = "No"


          }
        }


      }

      this.lengthofvehicles--;
      console.log(this.VehiclePayload)
      console.log(this.reviewsubmitpayload)

      // var pk = k +1;
      if (!(Object.keys(this.reviewsubmitpayload).length === 0))
        this.reviewsubmitpayload1["Cart " + this.vehiclenumber + " Details"] = this.reviewsubmitpayload
      this.reviewsubmitpayload = {}
      console.log(this.reviewsubmitpayload1)
    }
    console.log(this.reviewsubmitpayload1)
    this.reviewsubmitpayload = this.reviewsubmitpayload1
    this.reviewsubmitpayload['further details'] = form.value.further_details;
    this.reviewsubmitpayload['How many Carts'] = this.vehiclenumber;
    this.VehiclePayload["Cart.numberofbarrels"] = this.vehiclenumber


    // if (this.casetypeId == '293') {
      this.selectVehicle("Cart 1 Details");

    // }

  }


  reviewsubmitpayload1 = {};
  saveCartPayload() {
    let cartload = [
      {
        "entityName": "Cart",
        "payLoad": this.CartPayload
      }
    ]

    console.log(this.CartPayload)
    this.makeSerReqService.onSaveMakeSr(cartload).subscribe((res: any) => {
      console.log("cart payload is submitted")
      console.log(res);

    });
  }

  initialValues: any; // Object to store initial values
  updateinitialarray: { id: number, arraydisplay: any[] }[] = [];

  updateInitialValue(id: any, index: any) {
    // const id =
    // const index =

    // Call checkArray and update ngModel
    // const newValue = this.checkArrayindex(id, index, value);
    // this.initialValues[key] = newValue;

    const item = this.displayArray.find(obj => obj.id === id);

    if (item) {
      if (index >= 0 && index < item.arraydisplay.length) {
        this.initialValues = "yes";
      }
      this.initialValues = "No";
    }
    console.log(this.initialValues)
  }

  ////////////////

  checkArray1: { id: number; bool: string }[] = [];


  insertOrUpdate(id: number, bool: any): void {
    const existingItemIndex = this.checkArray1.findIndex(item => item.id === id);

    if (existingItemIndex !== -1) {
      // Update existing item
      this.checkArray1[existingItemIndex].bool = bool;
    } else {
      // Insert new item
      this.checkArray1.push({ id, bool });
    }
    console.log(this.checkArray1)
    console.log(this.getBoolById(1));
    console.log(this.getBoolById(2));
    console.log(this.getBoolById(0));
  }

  enablesubmitbutton() {
    // console.log(this.checkArray1)
    if (this.casetypeId == '293' && this.checkArray1.length > 0) {
      // console.log(this.lengthofvehicles)
      // console.log(this.checkArray1)

      const hasTrueBool = this.checkArray1.find(item => item.bool == "true");
      // console.log("will",hasTrueBool)
      if (hasTrueBool) {
        // console.log("true")
        this.shouldHideContinueButton = true
        return true
      }
      else {
        // console.log("false")
        return false;
      }
    }
    // this.shouldHideContinueButton = true
    return true
  }

  getBoolById(id: any): string {
    // console.log(id)
    const item = this.checkArray1.find(item => item.id === id);
    this.initialValues = item?.bool;
    // console.log(item?.bool)
    // console.log(item ? item.bool : null)
    return item ? item.bool : '';
  }



  // end of complex code
  email_em: any;
  confirmEmailValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const emailControl = this.contactDetailsForm?.get('em');

    if (!emailControl) {
      // Handle the case where the form control is not found
      return null;
    }

    // const email = emailControl.value;
    const confirmEmail = control.value;
    // console.log(this.email_em)
    // console.log(confirmEmail)

    if (this.email_em !== confirmEmail) {
      return { 'emailMismatch': true };
    }

    return null;
  }
  validatePhoneNumberFormat(control: AbstractControl): ValidationErrors | null {
    const phoneNumber = control.value;
    if (phoneNumber && !(/^\(\d{3}\)-\d{3}-\d{4}$/).test(phoneNumber)) {
      return { 'invalidPhoneNumber': true };
    }
    return null;
  }

  getObjectKeys(obj: any): string[] {

    if (obj) {

      return Object.keys(obj);
    }
    return [];
  }
  getValueFromInnerObject(innerObject: any, key: string): any {

    return innerObject[key];
  }
  gotoNext(data: any, type: any) {
    console.log("back")

    if (data.viewname == 'home') {
      data.viewname = 'gridView'
    }
    this.showscript = false;
    // this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
    // this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;

    data.serviceDetails = this.serviceTypeInfo.serviceDetails;
    this.makeSerReqService.servicedetails = data.serviceDetails;
    console.log(this.makeSerReqService.servicedetails)
    this.serviceTypeDetailsOutputEvent.emit(data);
  }
  @Input() serviceTypeInfo: any;
  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  agentScriptInfo: any;
  colwidth: any = '';
  showscript: boolean = true;
  contactForm: FormGroup;
  ngOnInit() {

    this.route.params.subscribe((params) => {
      // console.log(params);
      // this.filename = params['fileName'];
      // this.deptname = params['deptName'];
      // this.casetype = params['label'];
      // this.divId = params['divId'];
      // this.deptId = params['deptId'];
      // this.casetypeId = params['casetypeId'];
      // this.qid = params['qid'];
      // this.filename=params['fileName']

      // console.log(this.divId,this.deptId,this.qid,this.filename)

      // console.log('Filename from URL:', this.filename);
      // console.log('deptname', this.deptname);
      // console.log('casetype', this.casetype);
      // console.log('deptId', this.deptId);
      // console.log('qid', this.qid);
      // console.log('divId', this.divId);
      // console.log('casetypeId', this.casetypeId);
      console.log(this.serviceTypeInfo)
      this.makeSerReqService.casetype = this.serviceTypeInfo.serviceDetails.content
      this.makeSerReqService.department = this.serviceTypeInfo.serviceDetails.department.name
      this.agentScriptInfo = this.serviceTypeInfo.serviceDetails;
      this.casetypeId = this.agentScriptInfo.casetypeid
      if (this.casetypeId == '211')
        this.object_heading = "Vehicle"
      else
        this.object_heading = "Cart"

      this.divId = this.agentScriptInfo.division.id;
      this.deptId = this.agentScriptInfo.department.id
      this.qid = this.agentScriptInfo.qid
      console.log(this.agentScriptInfo)
      this.casetypeId = this.agentScriptInfo.casetypeid
      this.http.get<any>('/assets/json/' + this.agentScriptInfo.filename).subscribe((data) => {
        this.agentScriptArray = data;
        // console.log(data);

      });
    });
    // this.http.get<any>('/assets/json' + this.filename).subscribe((data) => {
    //   this.agentScriptArray = data;
    //   console.log(data);
    //   console.log('agentScriptArray111', this.agentScriptArray);
    // });


    this.contactDetailsForm = this.fb.group({
      anonymous: [{ value: this.deptId == 199, disabled: this.deptId == 199 }],
      private_or_public: [''],
      til: ['', [Validators.required]],
      fn: ['', [Validators.required]],
      ln: ['', [Validators.required]],
      em: ['', [Validators.required, Validators.email]],
      emc: ['', [Validators.required, Validators.email, this.confirmEmailValidator.bind(this)]],
      ph: [''],
      aph: [''],
    });
    setTimeout(() => {
      //alert($(".row-agent").length);
      $(".row-agent").each((index, ele) => {
        if ((index % 2) == 0) {
          //alert('even')
          $(ele).removeClass('odd').addClass('even');
        } else {
          $(ele).removeClass('even').addClass('odd');
        }
      })
    }, 500)
    // this.http.get<any>('/assets/json/servicerequestarray.json').subscribe((data) => {
    //   // console.log(data);
    //   this.serviceRequestArray = data;
    // });

    this.makeSerReqService.subGroupItemQuery$.subscribe((subGroupItemVal: any) => {
      this.subGroupItem = subGroupItemVal;
    });



    console.log(this.serviceTypeInfo.serviceDetails)
    this.agentScriptInfo = this.serviceTypeInfo.serviceDetails;
    this.showscript = true;
    // console.log( this.showscript)
    // console.log(this.reviewsubmitpayload)
  }
  moveNext() {
    if (this.addressObj.msr_lat && this.addressObj.msr_lng) {
      this.selectAccordian++;
    } else {
      // alert('Please Enter Address');
    }
  }
  movePrevious() {
    this.selectAccordian--;
  }

  selectedCheckboxes: any;
  selectedValuesMap: { [colName: string]: string } = {};
  selectedValues: any;

  isControlTouched(controlName: string): boolean {
    return this.f.controls[controlName] && this.f.controls[controlName].touched;
  }
  items: any;
  onChange(event: any, item: any) {

    setTimeout(() => {
      //alert($(".row-agent").length);
      $(".row-agent").each((index, ele) => {
        //alert(index);
        if ((index % 2) == 0) {
          //alert('even')
          $(ele).removeClass('odd').addClass('even');
        } else {
          $(ele).removeClass('even').addClass('odd');
        }
      })
    }, 200)

    console.log(event, item)
    if (item.type !== 'checkbox') {
      return this.executerule(event, item);
    }

    console.log(item);
    this.items = item;
    const selectedOptions = item.options.filter((opt: { checked: any }) => opt.checked);
    console.log(selectedOptions);

    this.selectedValues = selectedOptions.map((opt: { valueen: any }) => opt.valueen);
    console.log(this.selectedValues, 'sv')


    if (item.colName) {
      this.selectedValuesMap[item.colName] = this.selectedValues.join(', ');
      console.log(`Selected values for ${item.colName}:`,
        this.selectedValuesMap[item.colName]
      );
    }

    this.cdRef.detectChanges();
    this.executerule(event, item);

  }

  // resetValues(item: any) {
  //   // Assuming this.agentScriptArray contains your objects
  //   this.agentScriptArray.forEach((obj: any) => {
  //     if (Array.isArray(item.id)) {
  //       if (item.id.indexOf(obj.id) !== -1) {
  //         obj.visible = false;
  //         if (obj.hasOwnProperty('value')) {
  //           obj.value = null; // Reset value to null or appropriate default value
  //         }
  //       }
  //     }
  //   });
  // }
  executerule(e: any, item: any) {
    console.log(e, item)
    if (item.rules?.length > 0) {
      console.log(item)
      item.rules.forEach((element: any) => {
        console.log(element)
        this.executeCondition(e, element, item);
      });
    }
  }
  executeCondition(e: any, item: any, obj: any) {
    console.log(e, item, obj)
    console.log("In execute condition")
    if (Object.keys(item).length === 0) {
      return;
    }


    console.log(this.selectedValue, 'selectedvalue');
    // if(item.)
    if (item.condition.operator == 'equalsin') {
      console.log(item.condition.value)
      console.log(e)
      if ((item.condition.value.includes(e)) || (item.condition.value.includes(e.target?.value))) {
        this.executeThen(e, item, obj);

      } else {
        this.executeElse(e, item, obj);
      }

    }

    else if (item.condition.operator == 'equals') {
      console.log("equals condition")
      console.log(e)
      console.log(item)
      console.log(item.condition.value)
      console.log(item.condition)
      if ((item.condition.value == e) || (item.condition.value == e.target?.value)) {
        console.log("jitrdhjmh")
        this.executeThen(e, item, obj);
      } else {
        console.log("90876543")
        this.executeElse(e, item, obj);
      }
    }

    else if (item.condition.operator == "multiqs") {
      console.log("in multiqs")
      item.condition.value.forEach((ele1: any) => {
        this.agentScriptArray.forEach((ele: any) => {

          if (ele.hasOwnProperty('subq')) {
            ele.subq.forEach((subqele: any) => {
              if (ele1.checkid == subqele.id) {
                if (ele1.qvalue.includes(this.getColumnValueOfCart('rv_' + this.iteration_id + '_' + subqele.colName, this.iteration_id)) && (e?.target?.value == ele1.equals || e == ele1.equals)) {
                  this.executeThen(e, item, obj);
                }
              }
            })
          }
          else {
            if (ele1.checkid == ele.id) {
              if (ele1.qvalue.includes(ele.value) && (e?.target?.value == ele1.equals || e == ele1.equals)) {
                this.executeThen(e, item, obj);
              }
            }
          }

        })
      })
    }
    else if (item.condition.operator == "equalsor") {
      console.log(this.selectedValues, 'selectedValues')
      console.log(item.condition.value, 'item.condition.value')

      if (this.selectedValues.includes(item.condition.value)) {
        this.executeThen(e, item, obj);
      } else {
        this.executeElse(e, item, obj);
      }

    }
  }

  getColumnValueOfCart(columnValue: any, index: any): any {
    if (index >= 0 && index < this.formDataArray.length && this.formDataArray[index] && this.formDataArray[index].hasOwnProperty(columnValue)) {
      return this.formDataArray[index][columnValue];
    } else {
      return null; // or return a default value as per your requirement
    }
  }

  executeThen(e: any, item: any, obj: any) {
    console.log("executethen")
    console.log(item);
    item.then.forEach((res: any) => {
      if (res.action == 'show') {
        this.ifShow(e, res, this.agentScriptArray);
      }
      if (res.action == 'hide') {
        this.ifHide(e, res, obj);
      }
      if (res.action == 'setvalue') {
        this.setvalue(e, res, obj);
      }
    });
  }

  setvalue(e: any, item: any, obj: any) {
    this.agentScriptArray.forEach((obj: any) => {
      if (item.id.includes(obj.id)) {
        console.log(item);
        console.log(obj.options);
        console.log(e);
        // obj.value='';
        if (obj.type === 'checkbox') {
          console.log("checking check box")
          if (item.value === '') {
            console.log("in item")
            // obj.checked=false;
            obj.options.forEach((i: any) => {
              i.checked = false;
              console.log(i)

            })
          }
        }
      }
    });
  }
  executeElse(e: any, item: any, obj: any) {
    console.log("executeelse")
    console.log(item)
    item.else?.forEach((res: any) => {
      if (res.action == 'hide') {
        console.log("executeelse hide")
        this.elseHide(e, res, obj);
      }
      if (res.action == 'show') {
        console.log("executeelse show")
        this.elseShow(e, res, obj);
      }
    });
  }
  shouldHideContinueButton: boolean = true;

  subq_haschild!: boolean;
  ifShow(e: any, item: any, obj: any) {

    console.log("in if show")
    console.log(obj)
    console.log(item);

    if (item?.id[0] == 'continue-button') {
      console.log("in true")
      this.shouldHideContinueButton = true
    }

    // this.shouldHideContinueButton=true;
    // console.log("shouldHideContinueButton","true")
    if (true) {
      obj.forEach((obj: any) => {
        console.log(obj)
        console.log(obj.hasOwnProperty('subq'))
        if (obj.hasOwnProperty('subq')) {
          console.log("It has subq")
          this.ifShow(e, item, obj.subq)
        }
        console.log(obj)

        if (item.id.includes(obj.id)) {

          this.subq_haschild = true;
          console.log("matched")
          console.log(obj)
          if (this.subq_haschild && obj.extra) {
            this.updateOrCreateArray(obj.id, this.iteration_id, true);
            console.log(this.displayArray)
            this.subq_haschild = false;
          }

          obj.value = '';
          if (obj.type === 'checkbox') {
            obj.checked = false;
          }
          obj.visible = true;

          if (item.id.includes("continue-button")) {
            console.log(item)
            console.log("It has continue-button")
            this.shouldHideContinueButton = true;
            console.log("shouldHideContinueButton", "false")
            if (this.casetypeId == '293') {
              console.log(item.id[0], this.iteration_id)
              this.insertOrUpdate(this.iteration_id, 'true')
              this.enablesubmitbutton()
              // this.checkAndInsert(item.id[0],this.iteration_id)
              // console.log(this.checkArrayindex(item.id[0],true))
            }
          } else {
            // this.shouldHideContinueButton = true;
            // console.log("shouldHideContinueButton", "true")
          }
        }
        // this.agentScriptArray.forEach((obj: any) => {
        //   if (item.id.includes(obj.id)) {
        //     obj.value='';
        //     if(obj.type==='checkbox')
        //   {
        //     obj.checked=false;
        //   }
        //     obj.visible = true;
        //     if (item.id.includes("continue-button")) {
        //       this.shouldHideContinueButton = false;
        //       console.log("false")
        //     } else {
        //       this.shouldHideContinueButton = true;
        //       console.log("true")
        //     }
        //   }
        // });

      });
    }
    this.enablesubmitbutton()
  }
  elseShow(e: any, item: any, obj: any) {


    console.log("elseshow")

    if (item?.id[0] == 'continue-button') {
      console.log("in false")
      this.shouldHideContinueButton = true
    }

    if (obj.hasOwnProperty('subq')) {

      this.agentScriptArray.forEach((obj: any) => {
        if (obj.type == 'multifields') {
          obj.subq.forEach((ele: any) => {
            if (item.id.includes(ele.id)) {
              console.log("both matched");
              console.log(ele)
              ele.visible = true;
              this.updateOrCreateArray(ele.id, this.iteration_id, true);
              console.log(this.displayArray)
            }
          })
        }
      });

    } else {
      this.agentScriptArray.forEach((obj: any) => {
        if (item.id.includes(obj.id)) {
          console.log('elseShow sub', obj.visible);
          obj.visible = true;
        }
      });
    }

    this.enablesubmitbutton()
  }
  ifHide(e: any, item: any, obj: any) {
    console.log("if hide")
    console.log(obj);
    console.log(item);
    if (obj.hasOwnProperty('subq')) {

      this.agentScriptArray.forEach((obj: any) => {
        if (obj.type == 'multifields') {
          obj.subq.forEach((ele: any) => {
            if (item.id.includes(ele.id)) {
              console.log("both matched");
              console.log(ele)
              if (item.id.includes("continue-button")) {
                if (this.casetypeId == '293') {
                  console.log(item.id[0], this.iteration_id)
                  this.insertOrUpdate(this.iteration_id, 'false')
                  this.enablesubmitbutton()
                  // this.checkAndInsert(item.id[0],this.iteration_id)
                }
                this.shouldHideContinueButton = false;
                console.log("shouldHideContinueButton", "true")
              }
              ele.visible = false;
              this.updateValue(this.iteration_id, 'rv_' + this.iteration_id + '_' + ele.colName, '')
              this.updateOrCreateArray(ele.id, this.iteration_id, false);
              console.log(this.displayArray)
            }
          })
        }
      });


    } else {
      this.agentScriptArray.forEach((obj: any) => {
        console.log(obj)

        console.log(item.id)
        if (item?.id[0] == 'continue-button') {
          console.log("in false")
          this.shouldHideContinueButton = false
        }
        if (item.id.includes(obj.id)) {
          obj.value = '';
          console.log("matched")
          console.log(obj)
          if (obj.type == 'checkbox') {
            console.log("hide is checkbox")
            console.log(obj)
            // obj.checked=false;
            obj.options.forEach((x: any) => {
              x.checked = false;
            })
          }
          if (item.id.includes("continue-button")) {
            if (this.casetypeId == '293') {
              console.log(item.id[0], this.iteration_id)
            }
            this.shouldHideContinueButton = false;
            console.log("shouldHideContinueButton", "true")
          }
          obj.visible = false;
        }
      });
    }

    this.enablesubmitbutton()
  }
  elseHide(e: any, item: any, obj: any) {
    console.log("else hide")

    if (item?.id[0] == 'continue-button') {
      console.log("in true")
      this.shouldHideContinueButton = false
    }
    // if (obj.hasOwnProperty('subq')) {
    //   this.agentScriptArray.forEach((obj: any) => {
    //     if(obj.type=='multifields')
    //     {
    //      obj.subq.forEach((ele:any)=>
    //      {
    //        if(item.id.includes(ele.id))
    //        {
    //          console.log("both matched");
    //          console.log(ele)
    //          ele.visible=false;
    //         this.updateOrCreateArray(ele.id,this.iteration_id,false);
    //         console.log(this.displayArray);
    //        }
    //      })
    //     }
    //    });

    // } else

    if (true) {
      this.agentScriptArray.forEach((obj: any) => {
        if (obj.type == 'multifields') {
          console.log(obj.subq)
          // obj.forEach((ele:any)=>{
          obj.subq.forEach((ele_subq: any) => {
            if (ele_subq.hasOwnProperty('haschild')) {
              console.log("line number 638")
              console.log(ele_subq)
              ele_subq?.subq?.forEach((haschild: any) => {
                console.log(haschild)
                if (item.id.includes(haschild.id)) {
                  console.log("both matched")
                  console.log(haschild)
                  // haschild.visible=true;
                  this.updateOrCreateArray(haschild.id, this.iteration_id, false);
                  console.log(this.displayArray)
                }
              })
            }
            else {
              console.log("dont have inner children")
              if (item.id.includes(ele_subq.id)) {
                ele_subq.visible = true;
              }
            }
          })
        }
        if (item.id.includes(obj.id)) {
          if (item.id.includes("continue-button")) {
            this.shouldHideContinueButton = false;
            console.log("shouldHideContinueButton", "true")
          }
          obj.visible = false;
        }
      });
    }

    this.enablesubmitbutton()
  }

  selectCart(event: any, cartItems: any) {
    this.selectCartList = [];
    if (event.target.value) {
      for (let i = 0; i < event.target.value; i++) {
        this.selectCartList.push({
          reasonForRequest: '',
          cartItems: cartItems,
        });
      }
    }
  }
  selectedRequestReason(index: any, event: any) {
    this.selectCartList[index].reasonForRequest = event.target.value
      ? event.target.value
      : '';
  }
  selectCartItem(event: any, cartItem: any, index: any) {
    console.log(
      'caartitemmmmmmmmmlistttttttt',
      event.target.value,
      cartItem,
      index
    );
    this.selectCartQst = [];
    const convertedArr = Array.from(cartItem.options);
    console.log(Array.from(cartItem.options), 'converting array');
    convertedArr.forEach((element: any) => {
      if (event.target.value == element.value) {
        console.log('elementtttt', element);
        if (element.hasOwnProperty('questions')) {
          element.questions.forEach((ele: any) => {
            this.selectCartQst.push(ele);
          });
        }
      }
      for (let i = 0; i < event.target.value; i++) {
        this.selectCartQst.push({
          reasonForRequest: '',
          cartItems: this.selectCartQst,
        });
      }
    });
  }
  selectQuestionCart(event: any, qstItems: any) {
    console.log(
      'selectQuestionCart itemsssssssssssssssssssssss',
      event.target.value,
      qstItems.noteMsg
    );
    this.selectQuestionCartItems = [];
    const convertedArr = Array.from(qstItems.options);
    console.log(Array.from(qstItems.options), 'converting arrayquestions');
    convertedArr.forEach((element: any) => {
      console.log('sel111111111ectQuestionCart', element);
      if (element.value == 'No') {
        this.selectQuestionCartItems.push(element.noteMsg);
      }
    });
    for (let i = 0; i < event.target.value; i++) {
      this.selectQuestionCartItems.push({
        reasonForRequest: '',
        cartItems: this.selectQuestionCartItems,
      });
    }
  }
  toggleVisibility(e: any) {
    this.isAnonymous = e.target.checked;
    if (!this.isAnonymous) {
      this.contactDetailsForm.reset();
    }
  }
  toggleContactNumber(event: any) {
    this.aph = event.target.checked;
  }

  // markAllControlsAsTouched(formGroup: NgForm | any) {
  //   Object.keys(formGroup.controls).forEach(controlName => {
  //     const control = formGroup.controls[controlName];
  //     control.markAsTouched();

  //     if (control.controls) {
  //       this.markAllControlsAsTouched(control);
  //     }
  //   });
  // }
  // isControlInvalid(controlName: string, form: NgForm): boolean {
  //   const control = form.controls[controlName];
  //   return control.invalid && (control.dirty || control.touched);
  // }
  // f: NgForm;

  // ContactPayload : any = {};
  // Referrence_ID:any;
  submitForm() {
    // 306619313467
    // 231030-000001
    // this.Referrence_ID = Math.floor((Math.random() * 1000000000000));
    // console.log(this.Referrence_ID)
    let ContactPayload: any = {};
    console.log('Form is valid:', this.contactDetailsForm.valid);
    console.log('til validity:', this.contactDetailsForm.get('til')!.valid);
    console.log('fn validity:', this.contactDetailsForm.get('fn')!.valid);
    this.contactDetailsFormsubmit = true;
    console.log(this.casetypeId)
    if (this.isAnonymous || this.deptId == '199') {
      console.log("In anonymous")
      ContactPayload['Contact.title'] = "1";
      ContactPayload['Contact.firstname'] = "Anonymous";
      ContactPayload['Contact.lastname'] = "Anonymous";
      ContactPayload['Contact.email'] = "anonymous@thesmartcity311.com";
      ContactPayload['Contact.emailalt1'] = "anonymous@thesmartcity311.com";
      ContactPayload['Contact.phonenumber'] = "";
      ContactPayload['Contact.alternateph'] = "";
    }
    else {
      console.log(this.contactDetailsForm)
      ContactPayload = {
        'Contact.title': this.contactDetailsForm.value.til,
        'Contact.firstname': this.contactDetailsForm.value.fn,
        'Contact.lastname': this.contactDetailsForm.value.ln,
        'Contact.email': this.contactDetailsForm.value.em,
        'Contact.emailalt1': this.contactDetailsForm.value.emc,
        'Contact.phonenumber': this.contactDetailsForm.value.ph,
        'Contact.alternateph': this.contactDetailsForm.value.aph,
      };
    }

    let payload = [
      { entityName: 'Incident', payLoad: this.IncidentPayload },
      { entityName: 'Contact', payLoad: ContactPayload },
    ];
    let incident_id: number;

    this.ContactPayload = ContactPayload;
    console.log(' payloaddpayloaddpayloaddpayloadd', payload);
    console.log("payload working")
    this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any[]) => {
      console.log(res);
      res.forEach((ele: any) => {
        if (ele.entityName == 'Incident')
          incident_id = ele.payLoad.id;
        this.makeSerReqService.incident_id = ele.payLoad.id;
        if (this.casetypeId == '211') {
          this.VehiclePayload["Addfields.incidentid"] = ele.payLoad.id
          this.saveVehiclePayload();
          console.log(this.VehiclePayload);
        }
        else if (this.casetypeId == '293') {
          this.CartPayload["Addfields.incidentid"] = ele.payLoad.id
          this.saveCartPayload();
          console.log(this.VehiclePayload);
        }

        // this.makeSerReqService.fetchdata(ele.payLoad.id);
      })
      console.log('incident id is' + incident_id);

      // this.makeSerReqService.makeservicereq_payload=res;
      this.router.navigateByUrl('/service-request-confirmation');
      // console.log(res[1].payLoad.id)
    });

  }

  contactDetailsFormsubmit = false;
  hasError(controlName: any, errorType: any) {
    const control = this.contactDetailsForm.get(controlName);
    return this.contactDetailsFormsubmit && control?.hasError(errorType);

  }

  IncidentPayload: any = {}; // Declare IncidentPayload at the class level
  payload: any;
  reviewpayload: any = {};
  reviewpayload1: any = {};
  reviewsubmitpayload: any = {}
  // setDepartmentValue(serviceCategoryId: string): void {
  //   const selectedServiceCategory = this.findItemById(
  //     serviceCategoryId,
  //     this.serviceRequestArray
  //   );
  //   if (selectedServiceCategory) {
  //     // Use the label or any other property to set the department value
  //     this.IncidentPayload['Incident.department'] =
  //       selectedServiceCategory.label;
  //   }
  // }

  formSubmitted = false;
  countInvalidFields(form: NgForm): number {
    let count = 0;
    const controls = form.controls;

    Object.keys(controls).forEach(controlName => {
      const control = controls[controlName];

      if (control.invalid && (control.dirty || control.touched)) {
        count++;
      }
    });

    return count;
  }
  caseTypeData: any;
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailPattern.test(email);

      if (email && !valid) {
        return { 'invalidEmailFormat': true };
      }
      return null;
    };
  }
  addressinformation: any;
  onSubmit(form: any) {
    console.log(this.agentScriptSaveData?.reviewsubmitdata)
    console.log(this.selectedValuesMap)
    const formValues = form.value;
    console.log('Form Values:', formValues);

    // Or access individual controls by name
    // const nameControl = form.value('name');

    // if (nameControl) {
    //   const nameValue = nameControl.value;
    //   console.log('Name Value:', nameValue);
    // }

    // Iterate over all controls
    Object.keys(form.controls).forEach(controlName => {
      const controlValue = form.controls[controlName].value;
      console.log(`Control ${controlName} Value:`, controlValue);
    });



    console.log(form)
    console.log(form.value)
    console.log(this.serviceTypeInfo.serviceDetails)
    // console.log(this.caseTypeData)
    // console.log(this.caseTypeData.length)
    // if (this.caseTypeData.length != 0) {
    // console.log("yess")
    // this.serviceTypeInfo.serviceDetails = this.serviceTypeInfo.serviceDetails?.caseTypeData
    // }
    this.caseTypeData = []

    // console.log(this.serviceTypeInfo.serviceDetails)
    // this.serviceTypeInfo.serviceDetails=this.serviceTypeInfo.serviceDetails?.caseTypeData

    this.caseTypeData = this.serviceTypeInfo.serviceDetails;
    console.log(this.caseTypeData)
    this.contactForm = this.fb.group({
      firstname: [this.caseTypeData.contactDtls?.firstname || '', Validators.required],
      lastname: [this.caseTypeData.contactDtls?.lastname || '', Validators.required],
      email: [this.caseTypeData.contactDtls?.emailaddress || '', [Validators.required, this.emailValidator()]],
      phonenumber: [this.caseTypeData.contactDtls?.phonenumber1 || ''],
      addressline1: [''], // Add other address fields as needed
      city: [''],
      state: [''],
      postalcode: ['']
    });
    this.addressinformation = this.fb.group({
      addressline1: [this.caseTypeData.locationDtls.addressline1 || '', Validators.required], // Add other address fields as needed
    })


    // console.log(this.caseTypeData)
    if (this.casetypeId == '211')
      this.responsivevehiclepayload(form);

    if (this.casetypeId == '293')
      this.responsivecartpayload(form);




    // console.log(form.value.department, 'dept');
    // console.log(form.value)
    // console.log(form.value.deptId,'deptId');
    // console.log(form.value, 'form');

    let currentDate = new Date();
    let formValue: any = {};
    let IncidentPayload: any = {};
    let reviewpayload: any = {};
    let AddressPayload: any = {
      firstname: 'test',
      lastname: 'lname',
      email: 'test@gmail.com',
      phonenumber: '9999999999',
    };
    console.log(this.serviceTypeInfo)
    console.log(this.serviceTypeInfo.serviceDetails.locationDtls)
    IncidentPayload['Incident.division'] = this.divId;
    IncidentPayload['Incident.department'] = this.deptId;
    IncidentPayload['Incident.casetype'] = this.casetypeId;
    IncidentPayload['Incident.postalcode'] = this.serviceTypeInfo.serviceDetails.locationDtls.postalcode;
    IncidentPayload['Incident.latitude'] = this.serviceTypeInfo.serviceDetails.locationDtls.latitude;
    IncidentPayload['Incident.longitude'] = this.serviceTypeInfo.serviceDetails.locationDtls.longitude;;
    IncidentPayload['Incident.addressline1'] = this.caseTypeData.locationDtls?.addressline1;
    IncidentPayload['Incident.addressline2'] = this.serviceTypeInfo.serviceDetails.locationDtls.addressline2;
    IncidentPayload['Incident.city'] = this.serviceTypeInfo.serviceDetails.locationDtls.city;
    IncidentPayload['Incident.state'] = this.serviceTypeInfo.serviceDetails.locationDtls.state;
    IncidentPayload['Incident.furtherdetails'] = form.value.further_details;
    IncidentPayload['Incident.visiblepublic'] = ''
    IncidentPayload['Incident.channelid'] = '';
    IncidentPayload['Incident.collectionday'] = this.serviceTypeInfo.serviceDetails.locationDtls?.collectionday
    IncidentPayload['Incident.withinlimits'] = this.serviceTypeInfo.serviceDetails.locationDtls?.withinlimits
    IncidentPayload['Incident.recycleweek'] = this.serviceTypeInfo.serviceDetails.locationDtls?.recycleweek
    IncidentPayload['Incident.zoning'] = this.serviceTypeInfo.serviceDetails.locationDtls?.zoning
    IncidentPayload['Incident.jurisdiction'] = this.serviceTypeInfo.serviceDetails.locationDtls?.jurisdiction
    IncidentPayload['Incident.createdby'] = "Customer portal"
    IncidentPayload['Incident.subject']=(this.serviceTypeInfo.serviceDetails.division.name?this.serviceTypeInfo.serviceDetails.division.name+"-":'')+(this.serviceTypeInfo.serviceDetails.department.name?this.serviceTypeInfo.serviceDetails.department.name+"-":'')+(this.serviceTypeInfo.serviceDetails.content?this.serviceTypeInfo.serviceDetails.content:'')
console.log((this.serviceTypeInfo.serviceDetails.division.name?this.serviceTypeInfo.serviceDetails.division.name+"-":'')+(this.serviceTypeInfo.serviceDetails.department.name?this.serviceTypeInfo.serviceDetails.department.name+"-":'')+(this.serviceTypeInfo.serviceDetails.content?this.serviceTypeInfo.serviceDetails.content:''))
    // IncidentPayload['Incident.subject'] = this.agentScriptInfo.content+




    //   {
    //     "addresstype": "1363",
    //     "addressline1": "200 E BERRY ST",
    //     "city": "Fort Wayne",
    //     "state": "IN",
    //     "postalcode": "46802",
    //     "jurisdiction": "",
    //     "zoning": "",
    //     "recycleweek": 1361,
    //     "next_collection_day": "Bulk Trash Pickup is scheduled on: Wednesday June 5, 2024"
    // }

    if(this.serviceTypeInfo.serviceDetails.contactDtls.anonymous && this.serviceTypeInfo.serviceDetails.contactDtls.anonymous==true)
    {
      console.log("contact is anonymous")
      IncidentPayload['Incident.contactid'] = 10;
    }


    if (form.valid) {
      reviewpayload={}
      if (!(this.casetypeId == '211' || this.casetypeId == '293'))
        reviewpayload['Incident.furtherdetails'] = 'further_details'
      // console.log(form.value.further_details)
      if (this.addressObj.address_t == 'INTERSECTION') {
        IncidentPayload['Incident.addresstype'] = 1363;
      } else {
        IncidentPayload['Incident.addresstype'] = 1362;
      }

      this.formSubmitted = true;
      for (let i in form.value) {
        // console.log(i);
        if (form.value[i]) {
          // console.log(form.value.open_accessing);
          IncidentPayload['Incident.incidentstatus'] = 1;
          IncidentPayload['Incident.servicerequestsource'] = 1354;

          if (this.deptId == '207') { // SOLID WASTE => Public Works (Division)
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;

            // Cart Maintenance

            // if(this.casetypeId == '293'){
            //   // $incident->Category = RNCPHP\ServiceCategory::fetch($case_type);
            //   // $subject_part_label = "Cart Maintenance";
            //   const number_of_carts =form.value.cm_how_many;
            //   // $cartObject = new RNCPHP\Fort_Wayne\Cart();

            //   // $loop = 1;
            //   // $validCarts = 0;
            //   while(number_of_carts > 0){

            //     if($formData['cart_insert_'.$loop] == 'Yes'){
            //       $validCarts++;
            //       $garbage_recyling = "garbage_recyling".$validCarts;
            //       $cartObject->{$garbage_recyling} = RNCPHP\Fort_Wayne\Garbage_Recycling::fetch($formData['cm_cart_type_'.$loop]);

            //       $size_of_cart = "size_of_cart".$validCarts;
            //       $cartObject->{$size_of_cart} = RNCPHP\Fort_Wayne\Size_of_Cart::fetch($formData['cm_cart_size_'.$loop]);

            //       $request_reason = "request_reason".$validCarts;
            //       $cartObject->{$request_reason} = RNCPHP\Fort_Wayne\Request_Reason::fetch($formData['cm_reason_'.$loop]);


            //       // if garbage and additional as reason
            //       if($formData['cm_cart_type_'.$loop] == 1 && $formData['cm_reason_'.$loop] == 3){
            //         $hit_or_sign = "hit_or_sign".$validCarts;
            //         if($formData['cm_two_dollars_'.$loop] == 'Yes'){
            //           $cartObject->{$hit_or_sign} = 1;
            //           // city account holder
            //           $blocking_citycrews = "blocking_citycrews".$validCarts;
            //           if($formData['cm_ac_holder_'.$loop] == 'Yes'){
            //             $cartObject->{$blocking_citycrews} = 1;
            //           } else {
            //             $cartObject->{$blocking_citycrews} = 0;
            //           }
            //         } else {
            //           $cartObject->{$hit_or_sign} = 0;
            //         }
            //       }


            //       // reason for pickup
            //       if($formData['cm_reason_'.$loop] == 7){
            //         $list_reason_for_pick_up = "list_reason_for_pick_up".$validCarts;
            //         $cartObject->{$list_reason_for_pick_up} = $formData['cm_pickup_reason_'.$loop];
            //       }

            //       // repair, exchange or pickup selection
            //       if(in_array($formData['cm_reason_'.$loop], array(1, 6, 7))){
            //         $street_missed = "street_missed".$validCarts;
            //         if($formData['cm_city_seal_'.$loop] == 'Yes'){
            //           $cartObject->{$street_missed} = 1;
            //         } else {
            //           $cartObject->{$street_missed} = 0;
            //         }

            //         $cart_empty_at_the_curb = "cart_empty_at_the_curb".$validCarts;
            //         if($formData['cm_cart_empty_'.$loop] == 'Yes'){
            //           $cartObject->{$cart_empty_at_the_curb} = 1;
            //         } else {
            //           $cartObject->{$cart_empty_at_the_curb} = 0;
            //         }
            //       }

            //       // repair selected, so what needs repaired
            //       if($formData['cm_reason_'.$loop] == 1){
            //         $item_type = "item_type".$validCarts;
            //         $cartObject->{$item_type} = RNCPHP\Fort_Wayne\Item_Type::fetch($formData['cm_what_repair_'.$loop]);

            //         // if lid, handle is broken
            //         if($formData['cm_what_repair_'.$loop] == 1){
            //           $broken_meter = "broken_meter".$validCarts;
            //           if($formData['cm_boken_handle_'.$loop] == 'Yes'){
            //             $cartObject->{$broken_meter} = 1;
            //           } else {
            //             $cartObject->{$broken_meter} = 0;
            //           }
            //         }
            //       }

            //       // reason is NSA, so did you see NSA take
            //       if($formData['cm_reason_'.$loop] == 5){
            //         $witness_option = "witness_option".$validCarts;
            //         if($formData['cm_nsa_taken_'.$loop] == 'Yes'){
            //           $cartObject->{$witness_option} = 1;
            //         } else {
            //           $cartObject->{$witness_option} = 0;
            //         }
            //       }

            //       // reason is move in, so have you looked around
            //       if($formData['cm_reason_'.$loop] == 2){
            //         $looked_property = "looked_property".$validCarts;
            //         if($formData['cm_looked_around_'.$loop] == 'Yes'){
            //           $cartObject->{$looked_property} = 1;
            //         } else {
            //           $cartObject->{$looked_property} = 0;
            //         }
            //       }
            //     }
            //     $loop++;
            //     $number_of_carts--;
            //   }
            //   unset($loop);
            //   $cartObject->number_of_barrels = (int) $validCarts;
            //   $cartObject->save();
            //   $incident->CustomFields->Fort_Wayne->Cart = $cartObject;
            //   $incident->CustomFields->c->sla = strtotime('+5 days');
            // }

            // Cart Maintenance

            if (this.casetypeId == '293') {
              // $incident->Category = RNCPHP\ServiceCategory::fetch($case_type);
              // IncidentPayload['Incident.catid']=this.casetypeId
              // $subject_part_label = "Cart Maintenance";

              // const number_of_carts =  form.value.cm_how_many
              // $cartObject = new RNCPHP\Fort_Wayne\Cart();

              // let loop = 0;
              // let validCarts = 0;
              // while(number_of_carts > 0){
              //   if(!this.getBoolById(loop)){
              //     validCarts++;
              // let vaidcarts_tostring=validCarts.toString
              // let garbage_recyling = "garbage_recyling".concat(vaidcarts_tostring);
              // $cartObject->{$garbage_recyling} = RNCPHP\Fort_Wayne\Garbage_Recycling::fetch($formData['cm_cart_type_'.$loop]);

              // $size_of_cart = "size_of_cart".$validCarts;
              // $cartObject->{$size_of_cart} = RNCPHP\Fort_Wayne\Size_of_Cart::fetch($formData['cm_cart_size_'.$loop]);

              // $request_reason = "request_reason".$validCarts;
              // $cartObject->{$request_reason} = RNCPHP\Fort_Wayne\Request_Reason::fetch($formData['cm_reason_'.$loop]);


              // if garbage and additional as reason
              // if($formData['cm_cart_type_'.$loop] == 1 && $formData['cm_reason_'.$loop] == 3){
              //   $hit_or_sign = "hit_or_sign".$validCarts;
              //   if($formData['cm_two_dollars_'.$loop] == 'Yes'){
              //     $cartObject->{$hit_or_sign} = 1;
              //     // city account holder
              //     $blocking_citycrews = "blocking_citycrews".$validCarts;
              //     if($formData['cm_ac_holder_'.$loop] == 'Yes'){
              //       $cartObject->{$blocking_citycrews} = 1;
              //     } else {
              //       $cartObject->{$blocking_citycrews} = 0;
              //     }
              //   } else {
              //     $cartObject->{$hit_or_sign} = 0;
              //   }
              // }


              // reason for pickup
              // if($formData['cm_reason_'.$loop] == 7){
              //   $list_reason_for_pick_up = "list_reason_for_pick_up".$validCarts;
              //   $cartObject->{$list_reason_for_pick_up} = $formData['cm_pickup_reason_'.$loop];
              // }

              // repair, exchange or pickup selection
              // if(in_array($for/$cart_empty_at_the_curb} = 0;
              //   }
              // }

              // repair selected, so what needs repaired
              // if($formData['cm_reason_'.$loop] == 1){
              //   $item_type = "item_type".$validCarts;
              //   $cartObject->{$item_type} = RNCPHP\Fort_Wayne\Item_Type::fetch($formData['cm_what_repair_'.$loop]);

              // if lid, handle is broken
              //   if($formData['cm_what_repair_'.$loop] == 1){
              //     $broken_meter = "broken_meter".$validCarts;
              //     if($formData['cm_boken_handle_'.$loop] == 'Yes'){
              //       $cartObject->{$broken_meter} = 1;
              //     } else {
              //       $cartObject->{$broken_meter} = 0;
              //     }
              //   }
              // }

              // reason is NSA, so did you see NSA take
              // if($formData['cm_reason_'.$loop] == 5){
              //   $witness_option = "witness_option".$validCarts;
              //   if($formData['cm_nsa_taken_'.$loop] == 'Yes'){
              //     $cartObject->{$witness_option} = 1;
              //   } else {
              //     $cartObject->{$witness_option} = 0;
              //   }
              // }

              // reason is move in, so have you looked around
              //   if($formData['cm_reason_'.$loop] == 2){
              //     $looked_property = "looked_property".$validCarts;
              //     if($formData['cm_looked_around_'.$loop] == 'Yes'){
              //       $cartObject->{$looked_property} = 1;
              //     } else {
              //       $cartObject->{$looked_property} = 0;
              //     }
              //   }
              // }
              // $loop++;
              // $number_of_carts--;
              // }
              // unset($loop);
              // $cartObject->number_of_barrels = (int) $validCarts;
              // $cartObject->save();
              // $incident->CustomFields->Fort_Wayne->Cart = $cartObject;
              // $incident->CustomFields->c->sla = strtotime('+5 days');
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 5);

            }

            // Bulk Trash Pickup
            if (this.casetypeId == '367') {
              //     $AgentMap = new AgentMap();
              //     $collectionDayandWeek = $AgentMap->getCollectionDay($getcollectionday_address, $getcollectionday_addr_type);

              // IncidentPayload['Incident.trash_location']=form.value.bts_collectionday
              IncidentPayload['Incident.locationdetails'] = form.value.bt_collection_location;
              IncidentPayload['Incident.tagdescription'] = form.value.bt_typeofitem
              IncidentPayload['Incident.specifictime'] = form.value.bt_number_of_bulkitem

              var secondPart
              if (this.serviceTypeInfo.serviceDetails?.nextcollectionday && this.serviceTypeInfo.serviceDetails?.nextcollectionday.includes(':')) {
                const parts = this.serviceTypeInfo.serviceDetails?.nextcollectionday.split(':');
                // const firstPart = parts[0].trim();
                secondPart = parts[1].trim();
              }
              else {
                secondPart = this.serviceTypeInfo.serviceDetails?.nextcollectionday;
              }
              // alert(this.serviceTypeInfo.serviceDetails.locationDtls?.next_collection_day_date)
// alert(secondPart)
console.log(this.serviceTypeInfo.serviceDetails);
              IncidentPayload['Incident.trashlocation'] = secondPart
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 4);

              // IncidentPayload['Incident.associationid'] = form.value.association_tax;
              // reviewpayload['Incident.associationid']='association_tax'

              reviewpayload['Incident.locationdetails'] = 'bt_collection_location';
              reviewpayload['Incident.tagdescription'] = 'bt_typeofitem'
              reviewpayload['Incident.specifictime'] = 'bt_number_of_bulkitem'
            }


            // Missed Garbage

            if (this.casetypeId == '233') {

              //     $AgentMap = new AgentMap();
              // $collectionDayandWeek = $AgentMap->getCollectionDay($getcollectionday_address, $getcollectionday_addr_type);
              // if($collectionDayandWeek['collection_day'] != null){
              //  $incident->CustomFields->c->collection_day->ID = $collectionDayandWeek['collection_day'];
              // }
              // if($collectionDayandWeek['recycle_week_ID'] != null){
              //  $incident->CustomFields->c->recycle_week->ID = $collectionDayandWeek['recycle_week_ID'];
              // }


              //  $incident->Category = RNCPHP\ServiceCategory::fetch(233);
              // IncidentPayload["Incident.streetmissed"] = 233;
              let subject_part_label = "Missed Garbage";

              IncidentPayload['Incident.casesubtype'] = 583;

              if (form.value.mg_anyone_missed == 'Yes') {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['mg_anyone_missed', 'yes']
              } else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['mg_anyone_missed', 'no']

              }
              let were_items_tagged = form.value.mg_items_tagged; // menu
              IncidentPayload['Incident.booldesc'] = were_items_tagged;
              reviewpayload['Incident.booldesc'] = 'mg_items_tagged'

              let wereitemstaggedarray: string[] = ['1058', '1059', '1060'];
              if (wereitemstaggedarray.includes(were_items_tagged)) {
                IncidentPayload['Incident.tagdescription'] = form.value.mg_tag_say;
                reviewpayload['Incident.tagdescription'] = 'mg_tag_say'

              }

              // console.log(were_items_tagged, 'mg_what_collected')
              let mgwhatcollected: string[] = ['1087', '1088', '1090', '1091', '1092', '1093'];

              IncidentPayload['Incident.collectionoptions'] = form.value.mg_what_collected;
              reviewpayload['Incident.collectionoptions'] = 'mg_what_collected'

              let mgwhatcollected1 = form.value.mg_what_collected;
              if (mgwhatcollected.includes(mgwhatcollected1)) {
                // console.log(mgwhatcollected, 'mgwhatcollected');
                // console.log(form.value.mg_what_collected, 'form.value.mgwhatcollected');
                if (form.value.mg_any_bulk_items == 'Yes') {
                  IncidentPayload['Incident.citytree'] = 1;
                  reviewpayload['Incident.citytree'] = ['mg_any_bulk_items', 'yes']

                } else {
                  IncidentPayload['Incident.citytree'] = 0;
                  reviewpayload['Incident.citytree'] = ['mg_any_bulk_items', 'no']

                }
              }
              // console.log(form.value.mg_collection_location)
              IncidentPayload['Incident.locationdetails'] = form.value.mg_collection_location;
              reviewpayload['Incident.locationdetails'] = 'mg_collection_location'
              if (form.value.mg_collection_location == '1045') {
                IncidentPayload['Incident.trashlocation'] = form.value.mg_where_place;
                reviewpayload['Incident.trashlocation'] = 'mg_where_place'
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 4);
            }

            // Missed Recycling
            if (this.casetypeId == '257') {

              //     $AgentMap = new AgentMap();
              // $collectionDayandWeek = $AgentMap->getCollectionDay($getcollectionday_address, $getcollectionday_addr_type);
              // if($collectionDayandWeek['collection_day'] != null){
              //  $incident->CustomFields->c->collection_day->ID = $collectionDayandWeek['collection_day'];
              // }
              // if($collectionDayandWeek['recycle_week_ID'] != null){
              //  $incident->CustomFields->c->recycle_week->ID = $collectionDayandWeek['recycle_week_ID'];
              // }


              //  $incident->Category = RNCPHP\ServiceCategory::fetch(233);
              // IncidentPayload["Incident.streetmissed"] = 233;

              let subject_part_label = "Missed Recycle";

              IncidentPayload['Incident.casesubtype'] = 341;
              if (form.value.mr_anyone_missed == 'Yes') {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['mr_anyone_missed', 'yes']
                // console.log(IncidentPayload['Incident.streetmissed'])
              } else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['mr_anyone_missed', 'no']
                // console.log(IncidentPayload['Incident.streetmissed'])
              }

              let were_items_tagged = form.value.mr_items_tagged; // menu
              IncidentPayload['Incident.booldesc'] = were_items_tagged;
              reviewpayload['Incident.booldesc'] = 'mr_items_tagged'
              // console.log(IncidentPayload['Incident.booldesc'])

              let wereitemstaggedarray: string[] = ['1058', '1059', '1060'];
              if (wereitemstaggedarray.includes(were_items_tagged)) {
                IncidentPayload['Incident.tagdescription'] = form.value.mr_tag_say;
                reviewpayload['Incident.tagdescription'] = 'mr_tag_say'
                // console.log(IncidentPayload['Incident.tagdescription'])
              }

              IncidentPayload['Incident.collectionoptions'] = form.value.mr_what_collected;
              reviewpayload['Incident.collectionoptions'] = 'mr_what_collected'
              // console.log(IncidentPayload['Incident.collectionoptions'])
              IncidentPayload['Incident.locationdetails'] = form.value.mr_collection_location;
              reviewpayload['Incident.locationdetails'] = 'mr_collection_location'
              // console.log(IncidentPayload['Incident.locationdetails'])
              if (form.value.mr_collection_location == '1045') {
                IncidentPayload['Incident.trashlocation'] = form.value.mr_where_place;
                reviewpayload['Incident.trashlocation'] = 'mr_where_place'
                // console.log(IncidentPayload['Incident.trashlocation'])
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 4);
              // console.log(IncidentPayload['Incident.sla'])

            }

            // SERVICE COMPLAINT
            else if (this.casetypeId == '265') {
              // $incident->Category = RNCPHP\ServiceCategory::fetch($categoryID);
              let subject_part_label = "Service Complaint";
              let sc_date = form.value.sc_date;
              let sc_time = form.value.sc_time;
              IncidentPayload['Incident.specifictime'] = sc_date + ' ' + sc_time;
              IncidentPayload['Incident.garbagerecycling'] = form.value.sc_type;
              IncidentPayload['Incident.violationtype'] = form.value.sc_complaint_type;
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 3);

              reviewpayload['Incident.specifictime'] = 'sc_date' + ' ' + 'sc_time';
              reviewpayload['Incident.garbagerecycling'] = 'sc_type';
              reviewpayload['Incident.violationtype'] = 'sc_complaint_type';
            }
          }

          else if (this.deptId == '199') {  // NEIGHBORHOOD CODE ENFORCEMENT => Community Development (Division)
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;


            // open structure
            if (this.casetypeId == '249') {
              if (form.value.open_accessing == 'Yes') {
                IncidentPayload['Incident.witnessoption'] = 693;
                reviewpayload['Incident.witnessoption'] = ['open_accessing', 'yes'];
              } else {
                IncidentPayload['Incident.witnessoption'] = 694;
                reviewpayload['Incident.witnessoption'] = ['open_accessing', 'no'];
              }

              IncidentPayload['Incident.propertyoccupied'] = form.value.open_vacant;
              reviewpayload['Incident.propertyoccupied'] = 'open_vacant';
              if (form.value.open_violation_seen == 'Yes') {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['open_violation_seen', 'yes'];
              }
              else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['open_violation_seen', 'no'];
                if (form.value.open_permission_to_view == 'Yes') {
                  IncidentPayload['Incident.permissionproperty'] = 1;
                  reviewpayload['Incident.permissionproperty'] = ['open_permission_to_view', 'yes'];
                  IncidentPayload['Incident.contactinfo'] = form.value.open_view_address;
                  reviewpayload['Incident.contactinfo'] = 'open_view_address';
                  if (form.value.open_view_call == 'Yes') {
                    IncidentPayload['Incident.callback'] = 1;
                    reviewpayload['Incident.callback'] = ['open_view_call', 'yes'];
                  } else {
                    IncidentPayload['Incident.callback'] = 0;
                    reviewpayload['Incident.callback'] = ['open_view_call', 'no'];
                  }
                }
                else {
                  IncidentPayload['Incident.permissionproperty'] = 0;
                  reviewpayload['Incident.permissionproperty'] = ['open_permission_to_view', 'no'];
                }
              }

              if ('open_structure_secure[]') {

                IncidentPayload['Incident.componentfield'] = this.selectedValuesMap['open_structure_secure[]'];
                reviewpayload['Incident.componentfield'] = 'open_structure_secure[]'
                // console.log(IncidentPayload['Incident.componentfield'])
              }
              IncidentPayload['Incident.openingssecured'] = form.value.openings_secure;
              reviewpayload['Incident.openingssecured'] = 'openings_secure';
              if ('opening_location[]') {
                IncidentPayload['Incident.componentfieldopenstructure'] = this.selectedValuesMap['opening_location[]'];
                reviewpayload['Incident.componentfieldopenstructure'] = 'opening_location[]'
              }
              if (form.value.open_other_violations == 'Yes') {
                IncidentPayload['Incident.reportingviolations'] = 1;
                reviewpayload['Incident.reportingviolations'] = 'open_other_violations';
              }
              else {
                IncidentPayload['Incident.reportingviolations'] = 0;
                reviewpayload['Incident.reportingviolations'] = 'open_other_violations';
                console.log(" IncidentPayload['Incident.reportingviolations'] in else", IncidentPayload['Incident.reportingviolations'])
              }
              if (form.value.open_multiple_orders == 'Yes') {
                IncidentPayload['Incident.multipleorders'] = 1;
                IncidentPayload['Incident.confirmationnumbers'] = form.value.open_conf_nos;


                reviewpayload['Incident.multipleorders'] = 'open_multiple_orders';
                reviewpayload['Incident.confirmationnumbers'] = 'open_conf_nos';
              }
              else {
                IncidentPayload['Incident.multipleorders'] = 0;
                reviewpayload['Incident.confirmationnumbers'] = 'open_conf_nos';
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 30);
            }

            if (this.casetypeId == '283') //tall grass
            {

              IncidentPayload['Incident.propertyoccupied'] = form.value.tg_vacant;
              reviewpayload['Incident.propertyoccupied'] = ['tg_vacant','yes'];

              if (form.value.tg_violation_seen == 'Yes') {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['tg_violation_seen','yes'];
              }
              else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['tg_violation_seen','no'];
                if (form.value.tg_permission_to_view == 'Yes') {
                  IncidentPayload['Incident.permissionproperty'] = 1;
                  reviewpayload['Incident.permissionproperty'] = ['tg_permission_to_view','Yes'];
                  IncidentPayload['Incident.contactinfo'] = form.value.tg_view_address;
                  reviewpayload['Incident.contactinfo'] = 'tg_view_address';

                  if (form.value.tg_view_call == 'Yes') {
                    IncidentPayload['Incident.callback'] = 1;
                    reviewpayload['Incident.callback'] = ['tg_view_call','yes'];

                  } else {
                    IncidentPayload['Incident.callback'] = 0;
                    reviewpayload['Incident.callback'] = ['tg_view_call','no'];
                  }
                } else {
                  IncidentPayload['Incident.permissionproperty'] = 0;
                  reviewpayload['Incident.permissionproperty'] = ['tg_permission_to_view','No'];

                }
              }
              if (form.value.tg_invasive_weeds == 'Yes') {
                console.log(form.value.tg_invasive_weeds)
                IncidentPayload['Incident.invasiveweeds'] = 1;
                reviewpayload['Incident.invasiveweeds'] = ['tg_invasive_weeds','yes'];
                IncidentPayload['Incident.componentfieldtypeofinvasi'] = this.selectedValuesMap['tg_invasive_weed_type[]'];
                reviewpayload['Incident.componentfieldtypeofinvasi'] = 'tg_invasive_weed_type[]';

              }
              else {
                console.log(form.value.tg_invasive_weeds)
                IncidentPayload['Incident.invasiveweeds'] = 0;
                reviewpayload['Incident.invasiveweeds'] = ['tg_invasive_weeds','no'];
              }
              IncidentPayload['Incident.booldesc'] = form.value.tg_overgrown;
              reviewpayload['Incident.booldesc'] = ['tg_overgrown','yes'];
              IncidentPayload['Incident.animaltype'] = form.value.tg_safety_concern;
              reviewpayload['Incident.animaltype'] = ['tg_safety_concern','yes'];
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 30);

            }
            if (this.casetypeId == '211') { // Abandoned Vehicles
              IncidentPayload['Incident.Propertytype'] = form.value.property_type;

              IncidentPayload['Incident.longreporting'] = form.value.how_long;

              this.reviewsubmitpayload['Residential or Commercial Property?'] = this.getcolumnvalue(this.agentScriptArray, 'property_type', form.value.property_type);

              this.reviewsubmitpayload['How long has vehicle been on the property?'] = form.value.how_long

              if ( i == 'property_type' && form.value[i] == '679') {
                if (form.value.parked_on_grass == 'Yes') {
                  IncidentPayload['Incident.citytree'] = 1;
                  this.reviewsubmitpayload['Is the vehicle parked on the grass?'] = 'yes'

                } else {
                  IncidentPayload['Incident.citytree'] = 0;
                  this.reviewsubmitpayload['Is the vehicle parked on the grass?'] = 'no'
                }

              }

              IncidentPayload['Incident.propertyoccupied'] = form.value.vacant_or_occupied;
              this.reviewsubmitpayload['Is the property vacant or occupied?'] = this.getcolumnvalue(this.agentScriptArray, 'vacant_or_occupied', form.value.vacant_or_occupied);

              if (form.value.violation_seen == 'No') {
                // $incident->CustomFields->c->street_missed = 0;
                IncidentPayload['Incident.streetmissed'] = 0
                this.reviewsubmitpayload['Can the violation be seen from the Right of Way?'] = form.value.violation_seen
                if (form.value.permission_to_view == 'Yes') {
                  IncidentPayload['Incident.permissionproperty'] = 1;
                  this.reviewsubmitpayload['Do we have permission to go onto your property to view it?'] = form.value.permission_to_view
                  IncidentPayload['Incident.contactinfo'] = form.value.view_address
                  this.reviewsubmitpayload['Please provide your address and phone number:'] = form.value.view_address
                  if (form.value.view_call == 'Yes') {
                    IncidentPayload['Incident.callback'] = 1
                  } else {
                    IncidentPayload['Incident.callback'] = 0
                  }
                  this.reviewsubmitpayload['If necessary, can we call you?'] = form.value.view_call

                } else {
                  IncidentPayload['Incident.permissionproperty'] = 0;
                  this.reviewsubmitpayload['Do we have permission to go onto your property to view it?'] = form.value.permission_to_view
                }

              } else {
                IncidentPayload['Incident.streetmissed'] = 1;
                this.reviewsubmitpayload['Can the violation be seen from the Right of Way?'] = form.value.violation_seen
              }

              const no_of_vehicles = form.value.how_may_vehicles;

              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 30);

            }


            // No Utilities
            if (this.casetypeId == '246') {
              if (form.value.uti_vacant == "Yes") {
                IncidentPayload['Incident.structurevacant'] = 1;
                reviewpayload['Incident.structurevacant'] = ['uti_vacant', 'yes'];
              }
              else {
                IncidentPayload['Incident.structurevacant'] = 0;
                reviewpayload['Incident.structurevacant'] = ['uti_vacant', 'no'];
              }
              if (form.value.uti_children == "Yes") {
                IncidentPayload['Incident.childrenliving'] = 1;
                reviewpayload['Incident.childrenliving'] = ['uti_children', 'yes'];
              }
              else {
                IncidentPayload['Incident.childrenliving'] = 0;
                reviewpayload['Incident.childrenliving'] = ['uti_children', 'no'];
              }
              IncidentPayload['Incident.propertytype'] = form.value.uti_property_type;
              reviewpayload['Incident.propertytype'] = 'uti_property_type';
              if (form.value.uti_disconnected) {
                IncidentPayload['Incident.componentfield5'] = this.selectedValuesMap["uti_disconnected"];
                reviewpayload['Incident.componentfield5'] = 'uti_disconnected';
              }
              if (form.value.uti_other_violations == "Yes") {
                IncidentPayload['Incident.reportingviolations'] = 1;
                reviewpayload['Incident.reportingviolations'] = 'uti_other_violations';
              }
              else {
                IncidentPayload['Incident.reportingviolations'] = 0;
                reviewpayload['Incident.reportingviolations'] = 'uti_other_violations';
              }
              if (form.value.uti_multiple_orders == "Yes") {
                IncidentPayload['Incident.multipleorders'] = 1;
                IncidentPayload['Incident.confirmationnumbers'] = form.value.deb_conf_nos;


                reviewpayload['Incident.multipleorders'] = 'uti_multiple_orders';
                reviewpayload['Incident.confirmationnumbers'] = 'deb_conf_nos';
              }
              else {
                IncidentPayload['Incident.multipleorders'] = 0;
                reviewpayload['Incident.multipleorders'] = 'uti_multiple_orders';
              }
            }

            // Minimum Housing / Commercial Standards
            if (this.casetypeId == '301') {
              let subject_part_label = "Minimum Housing / Commercial Standards";

              IncidentPayload['Incident.specialrequest'] = form.value.mhcs_internal_exterior;
              IncidentPayload['Incident.propertytype'] = form.value.mhcs_property_type;
              IncidentPayload['Incident.propertyoccupied'] = form.value.mhcs_vacancy;


              reviewpayload['Incident.specialrequest'] = 'mhcs_internal_exterior'
              reviewpayload['Incident.propertytype'] = 'mhcs_property_type'
              reviewpayload['Incident.propertyoccupied'] = 'mhcs_vacancy'

              if (form.value.mhcs_violation_seen == "Yes") {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['mhcs_violation_seen', 'yes'];
              }
              else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['mhcs_violation_seen', 'no'];
                if (form.value.mhcs_permission_to_view == "Yes") {
                  IncidentPayload['Incident.permissionproperty'] = 1;
                  reviewpayload['Incident.permissionproperty'] = ['mhcs_permission_to_view', 'yes']
                  IncidentPayload['Incident.contactinfo'] = form.value.mhcs_view_address;
                  reviewpayload['Incident.contactinfo'] = 'mhcs_view_address'
                  if (form.value.mhcs_view_call == "Yes") {
                    IncidentPayload['Incident.callback'] = 1;
                    reviewpayload['Incident.callback'] = ['mhcs_view_call', 'yes']
                  }
                  else {
                    IncidentPayload['Incident.callback'] = 0;
                    reviewpayload['Incident.callback'] = ['mhcs_view_call', 'no']
                  }
                }
                else {
                  IncidentPayload['Incident.permissionproperty'] = 0;
                  reviewpayload['Incident.permissionproperty'] = ['mhcs_permission_to_view', 'no']
                }
              }
              if ('mhcs_structure_type[]') {
                IncidentPayload['Incident.componentfield'] = this.selectedValuesMap['mhcs_structure_type[]'];
                reviewpayload['Incident.componentfield'] = 'mhcs_structure_type[]'

              }
              if ('mhcs_complaint[]') {
                IncidentPayload['Incident.componentfield3'] = this.selectedValuesMap['mhcs_complaint[]'];
                reviewpayload['Incident.componentfield3'] = 'mhcs_complaint[]'

              }
              let window = 'Window/Door';
              if ('mhcs_complaint[]')
                {
                  console.log("in if ")
                if (form.value.mhcs_window_open == 'Yes') {
                  console.log("yes")
                  IncidentPayload['Incident.recentlymovedout'] = 1;
                  reviewpayload['Incident.recentlymovedout'] = ['mhcs_window_open','yes']
                } else if(form.value.mhcs_window_open == 'No'){
                  console.log("No")
                  IncidentPayload['Incident.recentlymovedout'] = 0;
                  reviewpayload['Incident.recentlymovedout'] = ['mhcs_window_open','no']
                }
              }
              if (form.value.mhcs_other_violations == 'Yes') {
                IncidentPayload['Incident.reportingviolations'] = 1;
                reviewpayload['Incident.reportingviolations'] = ['mhcs_other_violations', 'yes']
              } else {
                IncidentPayload['Incident.reportingviolations'] = 0;
                reviewpayload['Incident.reportingviolations'] = ['mhcs_other_violations', 'no']
              }

              if (form.value.mhcs_multiple_orders == 'Yes') {
                IncidentPayload['Incident.multipleorders'] = 1;
                IncidentPayload['Incident.confirmationnumbers'] = form.value.mhcs_conf_nos;

                reviewpayload['Incident.multipleorders'] = 'mhcs_multiple_orders';
                reviewpayload['Incident.confirmationnumbers'] = 'mhcs_conf_nos';

              } else {
                IncidentPayload['Incident.multipleorders'] = 0;
                reviewpayload['Incident.multipleorders'] = 'mhcs_multiple_orders';
              }
            }

            else if (this.casetypeId == '302') {   // Debris / Cistern
              let subject_part_label = "Debris / Cistern";
              // $incident->CustomFields->c->debris_type->ID = $_POST['deb_complaint'];

              IncidentPayload['Incident.debristype'] = form.value.deb_complaint;
              reviewpayload['Incident.debristype'] = 'deb_complaint';


              if (form.value.deb_complaint == 1278 && form.value.deb_quantity == 'Yes') {
                IncidentPayload['Incident.spilldrain'] = 1;
                IncidentPayload['Incident.speakto'] = form.value.deb_how_long;

                reviewpayload['Incident.spilldrain'] = ['deb_quantity', 'yes'];
                reviewpayload['Incident.speakto'] = ['deb_how_long','yes']
                // reviewpayload['Incident.reportingviolations'] = ['mhcs_other_violations', 'yes']

              } else {
                IncidentPayload['Incident.spilldrain'] = 0;
                reviewpayload['Incident.spilldrain'] = ['deb_quantity', 'no'];

              }
              console.log(this.selectedValuesMap['deb_location[]'])
              if ('deb_location[]') {
                IncidentPayload['Incident.componentfield6'] = this.selectedValuesMap['deb_location[]'];
                reviewpayload['Incident.componentfield6'] = 'deb_location[]';
              }
              IncidentPayload['Incident.propertytype'] = form.value.deb_property_type;
              IncidentPayload['Incident.propertyoccupied'] = form.value.deb_vacant_or_occupied;

              reviewpayload['Incident.propertytype'] = 'deb_property_type';
              reviewpayload['Incident.propertyoccupied'] = 'deb_vacant_or_occupied';

              if (form.value.deb_violation_seen == 'Yes') {
                IncidentPayload['Incident.streetmissed'] = 1;
                reviewpayload['Incident.streetmissed'] = ['deb_violation_seen', 'yes'];
              } else {
                IncidentPayload['Incident.streetmissed'] = 0;
                reviewpayload['Incident.streetmissed'] = ['deb_violation_seen', 'no'];

                if (form.value.deb_permission_to_view == 'Yes') {
                  IncidentPayload['Incident.permissionproperty'] = 1;
                  IncidentPayload['Incident.contactinfo'] = form.value.deb_view_address;

                  reviewpayload['Incident.permissionproperty'] = ['deb_permission_to_view', 'yes'];
                  reviewpayload['Incident.contactinfo'] = 'deb_view_address';

                  if (form.value.deb_view_call == 'Yes') {
                    IncidentPayload['Incident.callback'] = 1;
                    reviewpayload['Incident.callback'] = ['deb_view_call', 'yes'];
                  } else {
                    IncidentPayload['Incident.callback'] = 0;
                    reviewpayload['Incident.callback'] = ['deb_view_call', 'no'];
                  }
                } else {
                  IncidentPayload['Incident.permissionproperty'] = 0;
                  reviewpayload['Incident.permissionproperty'] = ['deb_permission_to_view', 'no'];
                }
              }
              if (form.value.deb_complaint == 1278 && 'deb_type[]') {
                console.log(this.selectedValuesMap['deb_location[]'])
                console.log(this.selectedValuesMap['deb_type[]'])
                IncidentPayload['Incident.componentfield2'] = this.selectedValuesMap['deb_type[]'];
                reviewpayload['Incident.componentfield2'] = 'deb_type[]';
              }

              let debtype = form.value['deb_type[]'];
              let appliances = 'Appliances - Refrigerator';
              console.log(this.selectedValuesMap['deb_type[]'])
              if (this.selectedValuesMap['deb_type[]']?.includes(appliances)) {
                IncidentPayload['Incident.refrigeratordoor'] = form.value.fridge_door;
                reviewpayload['Incident.refrigeratordoor'] = 'fridge_door';
              }
              if (form.value.deb_other_violations == 'Yes') {
                IncidentPayload['Incident.reportingviolations'] = 1;
                // reviewpayload['Incident.reportingviolations'] = 'deb_other_violations';
              } else {
                IncidentPayload['Incident.reportingviolations'] = 0;
                // reviewpayload['Incident.reportingviolations'] = ['deb_other_violations','no'];
              }
              if (form.value.deb_multiple_orders == 'Yes') {
                IncidentPayload['Incident.multipleorders'] = 1;
                IncidentPayload['Incident.confirmationnumbers'] = form.value.deb_conf_nos;

                reviewpayload['Incident.multipleorders'] = 'deb_multiple_orders';
                reviewpayload['Incident.confirmationnumbers'] = 'deb_conf_nos';
              } else {
                IncidentPayload['Incident.multipleorders'] = 0;
                reviewpayload['Incident.confirmationnumbers'] = 'deb_multiple_orders';

              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 45);

            }

          }
          if(this.deptId == '203')
          {
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;
            if (this.casetypeId == '226') {
              let subject_part_label = "Cutback";
              console.log("in cutback if condition");
              if (form.value.traffic_hazard == 'Yes') {
                console.log("in cutback if condition");
                IncidentPayload['Incident.traffichazard'] = 1;
                reviewpayload['Incident.traffichazard'] = ['traffic_hazard', 'yes'];
              } else {
                IncidentPayload['Incident.traffichazard'] = 0;
                reviewpayload['Incident.traffichazard'] = ['traffic_hazard', 'no'];
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 5);
            }
          }
          if (this.deptId == '209') {  // STREETS => Public Works (Division)
            console.log(this.qid, 'qid')
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;
            // IncidentPayload['Incident.incidentstatus']= 1;
            // IncidentPayload['Incident.servicerequestsource']= 1354;

            //cutback
            // if (this.casetypeId == '226') {
            //   let subject_part_label = "Cutback";
            //   console.log("in cutback if condition");
            //   if (form.value.traffic_hazard == 'Yes') {
            //     console.log("in cutback if condition");
            //     IncidentPayload['Incident.traffichazard'] = 1;
            //     reviewpayload['Incident.traffichazard'] = ['traffic_hazard', 'yes'];
            //   } else {
            //     IncidentPayload['Incident.traffichazard'] = 0;
            //     reviewpayload['Incident.traffichazard'] = ['traffic_hazard', 'no'];
            //   }
            //   // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 5);
            // }
            // Pothole in Alley
            if (this.casetypeId == '254') {
              let subject_part_label = "Pothole in Alley";
              IncidentPayload['Incident.alleylocation'] = form.value.alley_location;
              reviewpayload['Incident.alleylocation'] = 'alley_location';
              if ("alley_type[]") {
                IncidentPayload['Incident.componentfield14'] = this.selectedValuesMap["alley_type[]"];
                reviewpayload['Incident.componentfield14'] = 'alley_type[]';
                // console.log(IncidentPayload['Incident.componentfield14'])
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 5);
            }
            //  Pothole in Street
            if (this.casetypeId == '255') {
              let subject_part_label = "Pothole in Street";
              IncidentPayload['Incident.locationdetails'] = form.value.plothole_location;
              reviewpayload['Incident.locationdetails'] = 'plothole_location';
              // IncidentPayload['Incident.sla'] = currentDate.setTime(currentDate.getTime() + 4 * 60 * 60 * 1000);
            }

            //dead animal
            if (this.casetypeId == '229') {
              let subject_part_label = "Dead Animal";
              IncidentPayload['Incident.animaltype'] = form.value.animal_kind;
              reviewpayload['Incident.animaltype'] = 'animal_kind';
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 2);
            }

            // Debris
            if (this.casetypeId == '230') {
              let subject_part_label = "Debris";

              IncidentPayload['Incident.locationdetails'] = form.value.debris_location;
              IncidentPayload['Incident.debristype'] = form.value.debris_type;

              reviewpayload['Incident.locationdetails'] = 'debris_location';
              reviewpayload['Incident.debristype'] = 'debris_type';
              if (form.value.debris_type == '1144') {
                IncidentPayload['Incident.alleylocation'] = form.value.tree_location;
                reviewpayload['Incident.alleylocation'] = 'tree_location';
              }
              if (form.value.debris_type == '1141') {
                IncidentPayload['Incident.itemtype'] = form.value.liquid_type;
                reviewpayload['Incident.itemtype'] = 'liquid_type';
              }
              if (form.value.deb_traffic_hazard == 'Yes') {
                IncidentPayload['Incident.traffichazard'] = 1;
                reviewpayload['Incident.traffichazard'] = ['deb_traffic_hazard', 'yes'];

              } else {
                IncidentPayload['Incident.traffichazard'] = 0;
                reviewpayload['Incident.traffichazard'] = ['deb_traffic_hazard', 'no'];
              }
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 1);
            }

            // Guardrail
            if (this.casetypeId == '235') {
              IncidentPayload['Incident.reasonrequest'] = form.value.request_reason;
              reviewpayload['Incident.reasonrequest'] = 'request_reason';
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 28);
            }
            // Gravel Alley Maintenance
            if (this.casetypeId == '234') {
              IncidentPayload['Incident.reasonrequest'] = form.value.st_reason;
              IncidentPayload['Incident.alleylocation'] = form.value.st_alley_location;

              reviewpayload['Incident.reasonrequest'] = 'st_reason';
              reviewpayload['Incident.alleylocation'] = 'st_alley_location';

              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 14);
            }
            // Leaf Bag Pickup
            if (this.casetypeId == '238') {
              let subject_part_label = "Leaf Bag Pickup";
              // Number of Bags
              IncidentPayload['Incident.numberhydrants'] = form.value.no_of_bags;
              reviewpayload['Incident.numberhydrants'] = 'no_of_bags';
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 2);
            }
          }

          if (this.deptId == '208') { //  STREET LIGHTING => Public Works (Division)
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;

            if (this.casetypeId == '311') { //  Street Lights Out
              {
                let subject_part_label = "Street Lights Out";
                IncidentPayload['Incident.locationdetails'] = form.value.street_light_location;
                reviewpayload['Incident.locationdetails'] = 'street_light_location';
                if (i == 'street_light_location' && form.value[i] == '1290') {
                  IncidentPayload['Incident.mobilehomepark'] = form.value.home_park;
                  reviewpayload['Incident.mobilehomepark'] = 'home_park';
                }
                if (i == 'street_light_issue') {
                  IncidentPayload['Incident.poleissue'] = form.value.street_light_issue;
                  reviewpayload['Incident.poleissue'] = 'street_light_issue';
                }
                IncidentPayload['Incident.numberhydrants'] = form.value.pole_numbers;
                reviewpayload['Incident.numberhydrants'] = 'pole_numbers';
                if (i == 'poweroutagemenu' && form.value[i] == '1471') {
                  IncidentPayload['Incident.poweroutagemenu'] = 1471;
                  reviewpayload['Incident.poweroutagemenu'] = 'poweroutagemenu';
                } else {
                  IncidentPayload['Incident.poweroutagemenu'] = 1472;
                  reviewpayload['Incident.poweroutagemenu'] = 'poweroutagemenu';
                }

                if (i == 'more_than_one_out' && form.value[i] == 'Yes') {
                  // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 30);
                } else {
                  // IncidentPayload['Incident.sla'] = currentDate.setHours(currentDate.getHours() + 88);
                }
              }
            }
          }
          if (this.deptId == '210') { //  WATER MAINTENANCE => City Utilities (Division)
            IncidentPayload['Incident.incidentqueue'] = this.qid;
            IncidentPayload['Incident.division'] = this.divId;
            //curbbox
            if (this.casetypeId == '224') {
              let subject_part_label = "Curb Box";
              IncidentPayload['Incident.locationdetails'] = form.value.curb_location;
              IncidentPayload['Incident.curbboxissue'] = form.value.curb_box_issue;

              reviewpayload['Incident.locationdetails'] = 'curb_location';
              reviewpayload['Incident.curbboxissue'] = 'curb_box_issue';

              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 30);
            }
            //   HYDRANT PAINTING
            if (this.casetypeId == '237') {
              let subject_part_label = "Hydrant Painting";
              IncidentPayload['Incident.numberhydrants'] = form.value.no_of_hydrants;
              // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 15);
              IncidentPayload['Incident.associationid'] = form.value.association_tax;
              IncidentPayload['Incident.associationname'] = form.value.association_name


              reviewpayload['Incident.associationid'] = 'association_tax'
              reviewpayload['Incident.numberhydrants'] = 'no_of_hydrants'
              reviewpayload['Incident.associationname'] = 'association_name'

              // reviewpayload[form.value.association_tax]='Incident.sla'
              // console.log(reviewpayload)
            }

          }
        }

        // if (true) {
        //   let subject_part_label = "Hydrant Painting";
        //   IncidentPayload['Incident.associationid'] = form.value.association_tax;
        //   IncidentPayload['Incident.numberhydrants'] = form.value.no_of_hydrants;
        // IncidentPayload['Incident.sla'] = currentDate.setDate(currentDate.getDate() + 15);
        //   reviewpayload['Incident.associationid']='association_tax'
        //   reviewpayload['Incident.numberhydrants']='no_of_hydrants'
        //   // reviewpayload[form.value.association_tax]='Incident.sla'

        // }

        // console.log(this.IncidentPayload)
        // this.reviewdata;


        if (!(this.casetypeId == '211' || this.casetypeId == '293')) {
          console.log("not cart not vehicles")
          this.reviewpayload = reviewpayload;
        }


        // console.log(this.IncidentPayload)

        // this.reviewpayload=reviewpayload;

        // console.log(this.reviewpayload)
        // console.log(IncidentPayload)

        this.IncidentPayload = IncidentPayload
        // console.log(reviewpayload)
        // console.log(this.reviewpayload)

        // console.log(this.IncidentPayload.split("."))
        // if(!(this.casetypeId=='211' || this.casetypeId=='293')){
        for (const key of Object.keys(this.IncidentPayload)) {
          const value = this.IncidentPayload[key];

          // console.log(this.reviewpayload[key])

          if (this.reviewpayload[key]) {
            let value1 = `${value}`
            // console.log(value1)
            // console.log(`${this.reviewpayload[key]}`);


            this.agentScriptArray.forEach((item: any) => {
              // console.log("item is "+item)
              // console.log("colname is "+item.colName)
              // console.log(this.reviewpayload[key])
              // console.log(this.reviewpayload[key])
              if (item.colName?.includes(this.reviewpayload[key]) || (!(typeof this.reviewpayload[key] == 'number') && (this.reviewpayload[key])?.includes(item.colName))) {
                console.log("matched with colname " + this.reviewpayload[key])
                // console.log(item.question)
                if (item.type == 'radio') {
                  item.options.forEach((options: any) => {
                    // console.log(value1)
                    // console.log(this.reviewpayload[key]+" options.valueen is "+options.valueen)
                    // console.log(options.valueen.toLowerCase())
                    // console.log((this.reviewpayload[key]))
                    if (value1 == options.valueen || (this.reviewpayload[key]).includes((options.valueen).toLowerCase())) {
                      console.log("Question is " + item.question + " answer is " + options.label)
                      this.reviewsubmitpayload[item.question] = options.label
                    }
                  })
                }
                else {
                  console.log("Question is " + item.question + " answer is " + value1)
                  this.reviewsubmitpayload[item.question] = value1
                }
              }
            })
          }
        }
        // }

        // console.log("Review page data is ")
        // console.log(this.reviewsubmitpayload)
      }
      // console.log(this.serviceTypeInfo?.serviceDetails?.locationDtls)
      // console.log(this.IncidentPayload);

      console.log(this.serviceTypeInfo.serviceDetails.contactDtls)

      this.ContactPayload = {
        'Contact.firstname': this.caseTypeData.contactDtls?.firstname ? this.caseTypeData.contactDtls.firstname : "",
        'Contact.lastname': this.caseTypeData.contactDtls?.lastname ? this.caseTypeData.contactDtls.lastname : "",
        'Contact.email': this.caseTypeData.contactDtls?.emailaddress ? this.caseTypeData.contactDtls.emailaddress : "",
        'Contact.phonenumber': this.caseTypeData.contactDtls?.phonenumber1 ? this.caseTypeData.contactDtls.phonenumber1 : "",
        'Contact.alternateph': this.caseTypeData.contactDtls?.phonenumber2 ? this.caseTypeData.contactDtls.phonenumber2 : "",
        'Contact.addressid': this.caseTypeData.contactDtls?.mailingadd ? this.caseTypeData.contactDtls.mailingadd : "",
        // 'Contact.id':(this.serviceTypeInfo.serviceDetails.contactDtls.anonymous && this.serviceTypeInfo.serviceDetails.contactDtls.anonymous==true)?10:null
      };
      if(this.serviceTypeInfo.serviceDetails.contactDtls.anonymous && this.serviceTypeInfo.serviceDetails.contactDtls.anonymous==true)
        {
          // console.log("contact is anonymous")
          this.ContactPayload['Contact.id'] = 10;
        }

      // IncidentPayload['Incident.addressline1'] = this.caseTypeData.locationDtls?.addressline1 ? this.caseTypeData.locationDtls.addressline1 : "",
      console.log(this.caseTypeData)
      console.log(this.caseTypeData.locationDtls?.addressline1)
      console.log(IncidentPayload['Incident.addressline1'])


      console.log(this.ContactPayload)
      console.log(IncidentPayload)

      this.payload = [
        {
          "entityName": "Incident",
          "payLoad": IncidentPayload
        },
        {
          "entityName": "Contact",
          "payLoad": this.ContactPayload
        }
      ]

      this.agentScriptSaveData = {
        redirectTo: 'agentscriptView',
        caseTypeData: this.caseTypeData,
        reviewsubmitdata: this.reviewsubmitpayload,
        payload: this.payload,
        additionalpayload: this.VehiclePayload,
        // addfields: this.AddfieldsPayload,
        // cart: this.cartfieldsPayload,
        // tab_id:$(".custom-tabs li.active").find("a").attr('data-obj-id'),
        formScriptData: Object.assign(this.IncidentPayload)
      }
      this.IncidentPayload = IncidentPayload;
      console.log(this.IncidentPayload)
      console.log(this.agentScriptSaveData.reviewsubmitdata)
      console.log(this.agentScriptSaveData.caseTypeData.locationDtls.city)
      this.moveNext();
      this.showscript = false
      // this.gotoNext({ viewname: 'reviewsubmit', 'serviceDetails': this.agentScriptSaveData, isSubmit: true }, 0);
      // this.router.navigateByUrl('/preview')
    }

    else {
      this.formSubmitted = true;
      this.markAllControlsAsTouched(form)
    }


  }


  getcolumnvalue(obj: any, colName: any, valueen: any) {
    // // this.labelvalue=''
    // console.log(obj)
    // console.log(colName, valueen)
    obj.forEach((item: any) => {
      // console.log(item)
      if (item.hasOwnProperty('subq')) {
        // console.log("It has subq")
        // console.log(item)
        // console.log(item.subq)
        this.getcolumnvalue(item.subq, colName, valueen)
      }
      else {
        // console.log("It do not have subq")
        // console.log(item.colName)
        if (colName.includes(item.colName)) {
          // console.log("Colname is " + colName + "item.colName is " + item.colName)
          item.options.forEach((optionlabel: any) => {
            // console.log(optionlabel)
            if (optionlabel.valueen == valueen) {
              // console.log(optionlabel.valueen)
              // console.log(optionlabel.label)
              const label = optionlabel['label']
              // console.log(optionlabel['label'])
              // console.log(label)
              this.labelvalue = label;
              // return label;
            }
          })
        }
      }
    })
    // console.log(this.labelvalue)

    // setTimeout(()=>
    // {
    return this.labelvalue;
    // })

  }

  labelvalue: any

  markAllControlsAsTouched(formGroup: NgForm | any) {
    Object.keys(formGroup.controls).forEach(controlName => {
      const control = formGroup.controls[controlName];
      control.markAsTouched();

      if (control.controls) {
        this.markAllControlsAsTouched(control);
      }
    });
  }
  isControlInvalid(controlName: string, form: NgForm): boolean {
    const control = form.controls[controlName];
    return control.invalid && (control.dirty || control.touched);
  }
  f!: NgForm;
  findItemById(id: string, itemList: any[]): any {
    for (const item of itemList) {
      if (item.id === id) {
        return item;
      } else if (item.subgroup) {
        const subItem = this.findItemById(id, item.subgroup);
        if (subItem) {
          return subItem;
        }
      }
    }
    return null;
  }

  isInvalid(control: any): boolean {
    return control?.touched && !control?.valid;
  }
  resetForm(formId: any) {
    const form = document.getElementById(formId);
    console.log(form)
    if (form instanceof HTMLFormElement) {
      console.log(form);
      form.reset();
      console.log(form.reset()) // This resets the form
    } else {
      console.error("Form not found with ID:", formId);
      // Handle the case when the form with the provided ID is not found
    }
  }
  areAnyCheckboxesChecked(options: any[]): boolean {

    return options.some(option => option.checked);
  }
  // resetDependentFields(parentId: number) {
  //   // Find the dependent fields and reset their values
  //   this.agentScriptArray.forEach((field: { id: number; rules: any[]; visible: boolean; value: string; subq: string | any[]; }) => {
  //     if (field.id !== parentId && field.rules) {
  //       field.rules.forEach((rule) => {
  //         if (rule.condition.operator === 'equals' && rule.condition.value === '') {
  //           // Hide the field


  //           // Reset the value
  //           field.value = '';

  //           // If it has subquestions, reset their values recursively
  //           if (field.subq && field.subq.length > 0) {
  //             this.resetDependentFields(field.id);
  //           }
  //         }
  //       });
  //     }
  //   });
  // }

  // @Input() serviceTypeInfo: any;
  // @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  //   isSelected(value: any){
  //     console.log(value)
  // this.selectedValues=value;
  // return this.selectedValues;
  //   }
  checkedItems: any;
  suggestionSearch(value: string) {


    this.addressvalue = value;
    console.log(this.addressvalue)

    // console.log(this.addressvalue)
    this.performAddressSearch(this.addressvalue)
  }
  entiredata: any;
  addressSuggestions: any;
  async performAddressSearch(searchTerm: any) {
    console.log(searchTerm)
    this.makeSerReqService.mapsuggestions(searchTerm).subscribe((response: any) => {
      console.log(response.suggestions);
      this.entiredata = response.suggestions
      this.addressSuggestions = response.suggestions.map((suggestion: { text: any; }) => suggestion.text);
      console.log(this.addressSuggestions);
    });
  }

  addressvalue: any;
  fetchaddressdetails: any;
  assignAddress(item: any) {
    this.addressSuggestions = []
    var streetAddress = item.split(',')[0].trim();
    this.makeSerReqService.fetchaddressdetails(streetAddress, 'Address', '').subscribe((data: any) => {
      this.fetchaddressdetails = data
      // this.makeSerReqService.updateOrInsertArray(this.tab_title, 'mapdata', this.fetchaddressdetails)
      console.log(this.fetchaddressdetails)
      if (this.fetchaddressdetails.msg) {
        // alert("please enter valid address")
        Swal.fire({
          title: '<div class="popup-content"> <div class="text-left w-100"></div><div class="popup-text"><img src="../../assets/images/warning.svg" class="animated-check"> Given address is either not in Fort Wayne City limits or not a valid address</div></div>',
          imageUrl: "https://fortwayne.thesmartcity311.com/assets/images/fort-wayne-logo-seal-dark.svg",
          imageHeight: 45,
          showCloseButton: true,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'OK',
        })
        this.caseTypeData.locationDtls.addressline1 = '';
        this.caseTypeData.locationDtls.city = '';
        this.caseTypeData.locationDtls.state = '';
        this.caseTypeData.locationDtls.postalcode = '';
      }
      else {
        this.makeSerReqService.locationcoordinates = this.fetchaddressdetails

        const coords = {
          // msr_addresstype:this.fetchaddressdetails?.address_type,
          // msr_serviceaddress:this.fetchaddressdetails?.returned_address,
          msr_city: this.fetchaddressdetails?.personal_data.property_address_city ? this.fetchaddressdetails?.personal_data.property_address_city : 'Fort Wayne',
          msr_state: this.fetchaddressdetails?.personal_data.mailing_state ? this.fetchaddressdetails?.personal_data.mailing_state : 'IN',
          msr_zip: this.fetchaddressdetails?.personal_data.property_address_zip ? this.fetchaddressdetails?.personal_data.property_address_zip : '46802',
          msr_serviceaddress: this.fetchaddressdetails?.returned_address,

        };
        console.log(coords)
        console.log(this.caseTypeData.locationDtls?.addressline1)
        console.log(this.IncidentPayload['Incident.city'])
        this.caseTypeData.locationDtls.addressline1 = coords.msr_serviceaddress;
        this.caseTypeData.locationDtls.city = coords.msr_city;
        this.caseTypeData.locationDtls.state = coords.msr_state;
        this.caseTypeData.locationDtls.postalcode = coords.msr_zip;
        console.log(this.caseTypeData.locationDtls.addressline1)

      }




    })

  }
  updateContactPayload(field: string, value: string) {
    this.ContactPayload[field] = value;

  }
  goback() {

    this.showscript = true;
    this.reviewsubmitpayload = [];
    this.caseTypeData.contactDtls.firstname = this.ContactPayload['Contact.firstname'];
    this.caseTypeData.contactDtls.lastname = this.ContactPayload['Contact.lastname'];
    this.caseTypeData.contactDtls.emailaddress = this.ContactPayload['Contact.email'];
    this.caseTypeData.contactDtls.phonenumber1 = this.ContactPayload['Contact.phonenumber'];
    console.log(this.caseTypeData.contactDtls.firstname)
    // this.ContactPayload['Contact.firstname'] = this.caseTypeData.contactDtls?.firstname;
    // this.ContactPayload['Contact.lastname'] = this.caseTypeData.contactDtls?.lastname;
    // this.ContactPayload['Contact.email'] = this.caseTypeData.contactDtls?.emailaddress;
    // this.ContactPayload['Contact.phonenumber'] = this.caseTypeData.contactDtls.phonenumber1 ? this.caseTypeData.contactDtls.phonenumber1 : "";
    // this.ContactPayload['Contact.alternateph'] = this.caseTypeData.contactDtls.phonenumber2 ? this.caseTypeData.contactDtls.phonenumber2 : "";

    console.log(this.ContactPayload);
    console.log(this.ContactPayload['Contact.firstname'])
    // this.agentScriptSaveData.reviewsubmitdata=[];
    // console.log(this.agentScriptSaveData.reviewsubmitdata)


  }
  selectedVehicle: any;
  selectVehicle(vehicleKey: any): void {
    console.log("Selected Vehicle:", vehicleKey);
    this.selectedVehicle = vehicleKey;
  }
  isSelectedVehicle(vehicleKey: any): boolean {
    return this.selectedVehicle === vehicleKey;
  }

  getObjectProperties(obj: any): { key: any, value: any }[] {
    if (obj && typeof obj === 'object') {

      return Object.keys(obj).map(key => ({ key, value: obj[key] }));
    } else {
      return [];
    }
  }
  isObject(value: any): boolean {
    return value !== null && typeof value === 'object' && !Array.isArray(value);
  }
  editMode: boolean = false;
  toggleEditMode() {
    this.editMode = true;
  }
  addresseditmode: boolean = false
  toggleEditaddressmode() {
    this.addresseditmode = true
  }
  getSortedObjectProperties(obj: any): { key: any, value: any }[] {
    if (obj && typeof obj === 'object') {
      const keys = Object.keys(obj).sort((a, b) => {
        const aNum = a.includes('Details') ? 0 : 1;
        const bNum = b.includes('Details') ? 0 : 1;


        // If both keys contain 'Details', or both don't, compare them as strings
        if (aNum === bNum) {

          return a.localeCompare(b);
        }

        // Otherwise, prioritize the one containing 'Details'
        return aNum - bNum;
      });

      return keys.map(key => ({ key, value: obj[key] }));
    } else {
      return [];
    }
  }

  openDialog(dataInPopup: any): void {

    const dialogRef = this.DialogComponent.open(ReviewDialogComponent, {
      width: '450px',
      maxWidth: '92vw',
      data: { dataInPopup }
    });

    dialogRef.afterClosed().subscribe(() => {
      console.log('The dialog was closed' + this.makeSerReqService.referrence_number);
      this.router.navigateByUrl('/track-sr/ref_no/' + this.makeSerReqService.referrence_number)
      // You can handle any actions after the dialog is closed
    });


    // let :any;
    // let incident_id:number
    console.log(this.agentScriptSaveData.payload)
    // this.makeSerReqService.onSaveMakeSr(this.agentScriptSaveData.payload).subscribe((res: any) => {
    //   console.log("payload is submitted")
    //   console.log(res);
    //   this.incident_payload = res
    //   console.log(this.agentScriptSaveData.caseTypeData.casetypeid)
    //   // if (this.agentScriptInfo.caseTypeData.casetypeid == '211') {

    //     let additionalpayload = this.agentScriptSaveData.additionalpayload;

    //     this.incident_payload.forEach((ele: any) => {
    //       if (ele.entityName == 'Incident')
    //         this.incident_id = ele.payLoad.id;
    //       this.makeSerReqService.incident_id = ele.payLoad.id;
    //     })

    //     if (this.agentScriptSaveData.caseTypeData.casetypeid == '211') {
    //       console.log("211")
    //       additionalpayload["Addfields.incidentid"] = this.incident_id
    //       let payload = [
    //         { entityName: 'Addfields', payLoad: additionalpayload }
    //       ]
    //       console.log(payload)
    //         this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
    //           console.log("addfields payload is submitted")
    //           console.log(res);
    //         });
    //     }
    //     else if(this.agentScriptSaveData.caseTypeData.casetypeid == '293'){
    //       console.log("293")
    //       additionalpayload["Cart.incidentid"] = this.incident_id
    //       let payload = [
    //         { entityName: 'Cart', payLoad: additionalpayload }
    //       ]
    //       console.log(payload)
    //         this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
    //           console.log("Cart payload is submitted")
    //           console.log(res);
    //         });
    //     }



    //     // })

    //   // }


    // });

  }
  incident_payload: any
  incident_id: any
  submitclicked = false;
  isSaveDisabled(): boolean {
    return !this.contactForm.valid || !this.addressinformation.valid || !this.visibletopublic;
  }

  customcreate() {

    this.submitclicked = true;
    // IncidentPayload['Incident.visiblepublic']=this.visibletopublic?1:0;
    console.log(this.visibletopublic)
    console.log(this.visibletopublic ? 1 : 0)
    console.log(this.visibletopublic ? 0 : 1)

    if (this.visibletopublic == 'true') {
      this.visibletopublic = 1
    }
    else {
      this.visibletopublic = 0
    }

    // let :any;
    // let incident_id:number
    console.log(this.agentScriptSaveData.payload)
    console.log(this.caseTypeData)
    this.makeSerReqService.incidentdetails = this.caseTypeData;
    this.agentScriptSaveData.payload.forEach((entity: any) => {
      if (entity.entityName == 'Incident') {
        console.log(entity)
        entity.payLoad["Incident.visiblepublic"] = this.visibletopublic
        entity.payLoad["Incident.channelid"] = 5;
        entity.payLoad["Incident.addressline1"] = this.caseTypeData.locationDtls?.addressline1;
        entity.payLoad["Incident.postalcode"] = this.caseTypeData.locationDtls?.postalcode;
        entity.payLoad["Incident.city"] = "Fort Wayne";
        entity.payLoad["Incident.state"] = "IN";

        if(entity.payLoad["Incident.addressline1"].includes(' & ') || entity.payLoad["Incident.addressline1"].includes(' and '))
          {
            var kk = entity.payLoad["Incident.addressline1"].replace(' and ',' & ')
            var replaced=kk.replace(' and ',' & ')
            var fields = replaced.split(' & ');

            var addline1=fields[0]
            var addline2=fields[1]
            entity.payLoad["Incident.addressline1"] = fields[0]
            entity.payLoad["Incident.addressline2"] = fields[1]
            entity.payLoad["Incident.addresstype"] = 1363


            // this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue(fields[0])
            // this.addressDetailsForm.get(['locationDtls', 'addressline2'])?.setValue(fields[1])

          }
        console.log(this.agentScriptSaveData.payload)
      }



    })

    console.log(this.agentScriptSaveData.payload)
    this.makeSerReqService.onSaveMakeSr(this.agentScriptSaveData.payload).subscribe((res: any) => {
      console.log("payload is submitted")
      console.log(res);
      this.incident_payload = res;
      // this.makeSerReqService.incidentdetails=res;
      // this.makeSerReqService.servicedetails=res;
      console.log(this.agentScriptSaveData.caseTypeData.casetypeid)
      // if (this.agentScriptInfo.caseTypeData.casetypeid == '211') {

      let additionalpayload = this.agentScriptSaveData.additionalpayload;

      this.incident_payload.forEach((ele: any) => {
        if (ele.entityName == 'Incident') {
          console.log(ele.payLoad);
          this.incident_id = ele.payLoad.id;
          this.makeSerReqService.incident_id = ele.payLoad.id;
          this.openDialog(ele)
        }

      })

      if (this.agentScriptSaveData.caseTypeData.casetypeid == '211') {
        console.log("211")
        additionalpayload["Addfields.incidentid"] = this.incident_id
        let payload = [
          { entityName: 'Addfields', payLoad: additionalpayload }
        ]
        console.log(payload)
        this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
          console.log("addfields payload is submitted")
          console.log(res);
        });
      }
      else if (this.agentScriptSaveData.caseTypeData.casetypeid == '293') {
        console.log("293")
        additionalpayload["Cart.incidentid"] = this.incident_id
        let payload = [
          { entityName: 'Cart', payLoad: additionalpayload }
        ]
        console.log(payload)
        this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
          console.log("Cart payload is submitted")
          console.log(res);
        });
      }

      // this.visibletopublic='';

      // })

      // }


    });

  }


}
