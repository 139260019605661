<!--
<div class="px-2">

    <div class=" mb-3">
        <div class="stepper ">
            <ol class="mt-4 mb-2 p-0">
                <li class='success'><div><h2>1</h2><p>Select a Service Type</p></div><div class="line"> </div></li>
                <li class='success'><div><h2>2</h2><p>Provide Contact & Address Details</p></div><div class="line"> </div></li>
                <li class='success'><div><h2>3</h2><p>Provide Additional Information</p></div><div class="line"> </div></li>
                <li class='active'><div><h2>4</h2><p>Review & Submit</p></div></li>
              </ol>
          </div>




        <div class="container review-submit p-0">
            <p class="h6 mb-2 mt-3">Please review and submit</p>
            <form [formGroup]="reviewForm">
                <div class="row">
                    <div class="col-md-3 ">

                        <div class="border h-100 p-3 bg-white  card-p">
                            <div class="d-flex ">
                                <div class="h6 mb-3"><i class="bi bi-person-lines-fill opacity-75me-2"></i> Contact Details</div>
                            </div>
                            <table class="table table-borderless">
                                <tbody>
                                  <tr>
                                    <td>First Name</td>
                                    <td>{{userInfo.addressDetails.firstname}}</td>
                                  </tr>
                                  <tr>
                                   <td>Last Name</td>
                                    <td>{{userInfo.addressDetails.lastname}}</td>
                                  </tr>

                                  <tr>
                                    <td>Email Address</td>
                                    <td>{{userInfo.addressDetails.emailaddress}}</td>
                                  </tr>
                                  <tr>
                                    <td>Phone Number</td>
                                    <td>{{userInfo.addressDetails.phonenumber}}</td>
                                  </tr>

                                </tbody>
                              </table>


                        </div>

                    </div>
                    <div class="col-md-3 ">

                        <div class="border h-100 p-3 bg-white  card-p">

                            <div class="d-flex mt-1">
                                <div class="h6 mb-3"><i class="bi bi-pin-map-fill opacity-75 me-2"></i> Service Address Details</div>
                            </div>

                            <table class="table table-borderless">
                                <tbody>
                                  <tr>
                                    <td>Street Address </td>
                                    <td>{{userInfo.addressDetails.streetaddress}}</td>
                                  </tr>
                                  <tr>
                                   <td>City</td>
                                    <td>{{userInfo.addressDetails.city}}</td>
                                  </tr>
                                  <tr>
                                    <td>State</td>
                                    <td>{{userInfo.addressDetails.state}}</td>
                                  </tr>
                                  <tr>


                                    <td>County</td>
                                    <td>{{userInfo.addressDetails.county}}</td>
                                  </tr>
                                  <tr>
                                    <td>Zip Code</td>
                                    <td>{{userInfo.addressDetails.zipcode}}</td>
                                  </tr>

                                </tbody>
                              </table>
                        </div>

                    </div>
                    <div class="col-md-6 ">

                        <div class="border h-100 p-3 bg-white card-p ">
                            <div class="h6 mb-3"><i class="bi bi-gear-fill opacity-75 me-2"></i> Service Request Details</div>

                            <table class="table table-borderless">
                                <tbody>
                                    <tr>
                                        <td>How many potholes are there?</td>
                                         <td>{{this.userInfo.additionalInfoDetails.nopotholeNumber}}</td>
                                       </tr>
                                  <tr>
                                    <td>Is it a true pothole or construction-related pothole? </td>
                                    <td>{{userInfo.additionalInfoDetails.pothole}}</td>
                                  </tr>

                                  <tr>
                                   <td>Size of the Pothole</td>
                                    <td>{{userInfo.additionalInfoDetails.potholesize}}</td>
                                  </tr>
                                  <tr>
                                    <td>Further Details</td>
                                    <td>{{userInfo.additionalInfoDetails.furtherdetails}}</td>
                                  </tr>
                                  <tr>
                                    <td>Add / update photo</td>
                                    <td>Pothole.png</td>
                                  </tr>
                                </tbody>
                              </table>
                        </div>

                    </div>
                </div>
                <div class="row">
                    <div class="col action-from d-flex">
                        <button type="button" value-text="prv-btn" class="btn btn-outline-primary ms-auto"
                            (click)="gotoNext({viewname:'additionalinfoView','serviceDetails':'prv-btn'})"><i
                                class="bi bi-arrow-left"></i> Back</button>
                        <button type="submit" value-text="next-btn" class="btn btn-primary" (click)="reviewSubmit()">
                            <i class="bi bi-arrow-right"></i> Submit
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
</div>
 -->

 <div class="col-sm-12 p-0">
  <div id="printMe" class="rn_serviceRequestConfirmation ">
    <!-- <input type="hidden" name="c_id" id="c_id" readonly="readonly" value="0"> -->
    <!-- <div>
      <label>
        <input type="radio" [(ngModel)]="option" [value]="true" (ngModelChange)="optionChanged()"> Yes
      </label>
      <label>
        <input type="radio" [(ngModel)]="option" [value]="false" (ngModelChange)="optionChanged()"> No
      </label>
    </div> -->
    <div class="row ">
      <div class="col-sm-12">
        <!-- <table class="table table-condensed">
                  <tbody>
                      <tr>
                          <td><strong>Reference Number: <span style="color:#0367A6;">231030-000001</span></strong>
                          </td>
                          <td><b>Status: <span style="color:red">Unresolved</span></b></td>
                      </tr>
                      <tr>
                          <td width="50%"><b>Division: </b>{{this.agentScriptInfo?.division}}</td>
                          <td width="50%"><b>Department: </b>{{this.agentScriptInfo?.department}}</td>
                      </tr>
                      <tr>
                          <td width="50%"><b>Case Type: </b>{{this.agentScriptInfo?.casetype}}</td>
                          <td width="50%"><b>Case Sub Type: </b>{{this.agentScriptInfo?.casesubtype}}</td>
                      </tr>
                      <tr>
                          <td width="50%"><b>Queue: </b>{{this.agentScriptInfo?.queue}}</td>
                          <td width="50%"><b>Created By: </b>{{this.agentScriptInfo?.createdby}}</td>
                      </tr>
                      <tr>
                          <td colspan="2"><b>SLA (Service Level Agreement): </b>{{this.agentScriptInfo?.sla}}</td>

                      </tr>
                  </tbody>
              </table> -->



        <!-- <div class="pageHeading text-center">Contact Details</div>
        <table class="table table-condensed">
          <tbody>
            <tr>
              <td><strong>First Name: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.firstname}}</td>
              <td><strong>Last Name: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.lastname}}</td>
              <td><strong>Email ID: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.emailaddress}}</td>
              <td><strong>Phone: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.mailingadd}}</td>
            </tr>
          </tbody>
        </table>

        <div class="pageHeading text-center">Address Details</div>
        <table class="table table-condensed">
          <tbody>
            <tr>
              <td><strong>Street Address: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.addressline1}}</td>
              <td ><strong>City: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.city}}</td>
              <td><strong>State: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.state}}</td>
              <td><strong>Country: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.postalcode}}</td>
            </tr>
            <tr>
              <td width="50%"><strong>Zipcode: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.latitude}}</td>
            </tr>

          </tbody>
        </table>

        <div class="pageHeading text-center">Additional Information</div>
        <table class="table table-condensed">
          <tbody>
            <tr>
              <div class="review-data">
                <div class="review-item" *ngFor="let item of this.agentScriptInfo?.reviewsubmitdata | keyvalue">
                  <label>{{ item.key }}</label>
                  <input type="text" disabled [name]="item.key" [value]="item.value">
                </div>
              </div>
            </tr>

          </tbody>
        </table>

        <table class="table table-condensed">
          <tbody>
            <tr>
              <td><button class="btn btn-primary">Back</button><button class="btn btn-primary" (click)="customcreate()">Submit</button></td>
            </tr>

          </tbody>
        </table>


      </div>
    </div>

  </div>
</div> -->




<div class="accordion" id="accordionPanelsStayOpenExample">


  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Contact Details
      </button>
    </h2>
    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
        <table class="table table-condensed">
          <tbody>
            <tr>
              <td><strong>First Name: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.firstname}}</td>
              <td><strong>Last Name: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.lastname}}</td>
              <td><strong>Email ID: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.emailaddress}}</td>
              <td><strong>Phone: </strong>{{this.agentScriptInfo?.caseTypeData.contactDtls?.mailingadd}}</td>
            </tr>
          </tbody>
        </table> </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Address Details
      </button>
    </h2>
    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
        <table class="table table-condensed">
          <tbody>
            <tr>
              <td><strong>Street Address: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.addressline1}}</td>
              <td ><strong>City: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.city}}</td>
              <td><strong>State: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.state}}</td>
              <td><strong>Country: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.postalcode}}</td>
            </tr>
            <tr>
              <td width="50%"><strong>Zipcode: </strong>{{this.agentScriptInfo?.caseTypeData.locationDtls?.latitude}}</td>
            </tr>

          </tbody>
        </table>      </div>
    </div>
  </div>

  <div class="accordion-item">
    <h2 class="accordion-header" id="panelsStayOpen-headingOne">
      <button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
        Additional Information
      </button>
    </h2>
    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
      <div class="accordion-body">
        <table class="table table-condensed">
          <tbody>
            <tr>
            <div class="review-data">

              <tabset (select)="selectVehicle($event)">
                <div *ngFor="let keyValuePair of agentScriptInfo?.reviewsubmitdata | keyvalue">
                  <div  *ngIf="isObject(keyValuePair.value)">
                    <tab>
                      <ng-template tabHeading>
                        <div (click)="selectVehicle(keyValuePair.key)">
                          {{ keyValuePair.key }}
                        </div>
                      </ng-template >
                      <div class="nested-box"*ngIf="isSelectedVehicle(keyValuePair.key)">
                        <div *ngFor="let item of getObjectProperties(keyValuePair.value)">
                          <div class="form-group" >
                            <label>{{ item.key }}</label>
                            <input type="text" class="form-control form-control-sm" [name]="item.key" [value]="item.value" disabled>
                          </div>
                        </div>
                      </div>
                    </tab>
                  </div>

                </div>
              </tabset>














  <div *ngFor="let keyValuePair of getSortedObjectProperties(agentScriptInfo?.reviewsubmitdata)">
    <!-- <div class="nested-box" *ngIf="isObject(keyValuePair.value)">
      <h3>{{ keyValuePair.key }}</h3>
      <div *ngFor="let item of getObjectProperties(keyValuePair.value)">
        <div class="form-group">
          <label>{{ item.key }}</label>
          <input type="text" class="form-control form-control-sm" disabled [name]="item.key" [value]="item.value">
        </div>
      </div>
    </div> -->
    <div *ngIf="!isObject(keyValuePair.value)">
      <div class="form-group">
        <label>{{ keyValuePair.key }}</label>
        <input type="text" class="form-control form-control-sm" disabled [name]="keyValuePair.key" [value]="keyValuePair.value">
      </div>
    </div>
  </div>
</div>
            </tr>

          </tbody>
        </table>      </div>
    </div>
  </div>
  <button class="btn btn-primary" (click)="goback({'viewname':agentScriptInfo.redirectTo,'serviceDetails':agentScriptInfo.caseTypeData},1)">Back</button><button class="btn btn-primary" (click)="customcreate()">Submit</button>
</div>
