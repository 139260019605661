import { AfterViewInit, Component, ViewChild, Output, EventEmitter } from '@angular/core';
import * as jq from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { KnowledgebaseService } from '../knowledgebase.service';
import { makeServiceRequestService } from '../make-service-request.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';

export const httpJsonPostOptions = {

};

@Component({
  selector: 'app-track-sr',
  templateUrl: './track-sr.component.html',
  styleUrls: ['./track-sr.component.scss']
})


export class TrackSRComponent {
  track_with_refno: any = ''
  userInfoList: any = localStorage.getItem('userListSubmit');
  submitSrDetails: any;
  // dataSource = new MatTableDataSource<any>([
  //   { id: 1, srID: '24207-000450', department: 'Animal care and Control', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '123 Main St' },
  //   { id: 2, srID: '24207-000451', department: 'Forestry', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '456 Elm St' },
  //   { id: 3, srID: '24207-000452', department: 'Animal care and Control', srReportDate: new Date(), srStatus: 'Completed', serviceAddress: '200 e berry st' },
  //   { id: 4, srID: '24207-000453', department: 'Filtration', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '456 Elm St' },
  //   { id: 5, srID: '24207-000454', department: 'Forestry', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '456 Elm St' },
  //   { id: 6, srID: '24207-000455', department: 'Parking Control', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '200 e berry st' },
  //   // Add more static data entries here
  // ]);
  displayedColumns: string[] = ['id', 'srID', 'department', 'srReportDate', 'srStatus', 'serviceAddress','open'];
  datatableload: any;


  constructor(public dialog: MatDialog, private knowledgeSearchService: KnowledgebaseService, private makeservicerequest: makeServiceRequestService, private http: HttpClient,private datePipe: DatePipe) {

  }
  formatDate(date: Date): any {
    return this.datePipe.transform(date, 'MMM dd, yyyy');
  }
  ngOnInit() {
    this.getRecentIncidents();
    console.log(this.makeservicerequest.referrence_number)
    this.makeservicerequest.referrence_number='';
    if(this.makeservicerequest.referrence_number!=0)
    {
      this.track_with_refno=this.makeservicerequest.referrence_number
      this.getByreferrenceId()
        // this.makeservicerequest.referrence_number='';
    }

  }
  ngOnChanges()
{
  this.makeservicerequest.referrence_number='';
}
  // datatablepagination() {
  //   console.log("in datatablepagination")
  //   setTimeout(() => {
  //     ($('#trackservicetable') as any).dataTable({
  //       "language": {
  //         "paginate": {
  //           "previous": "«",
  //           "next": "»"
  //         }
  //       },
  //       order: [],
  //       "retrieve": true,
  //       "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
  //       "bPaginate": true,
  //     });
  //   }, 200);
  // }

  datatablepagination() {

    $("#trackservicetable_wrapper").remove();

    // ($('#trackservicetable') as any).DataTable().destroy();


    setTimeout(() => {

      const screenWidth = window.innerWidth;
      const isSmallScreen = screenWidth <= 768;

      ($('#trackservicetable') as any).dataTable({
        "language": {
          "paginate": {
            "previous": "",
            "next": ""
          },
          "lengthMenu": "Records per page _MENU_",
        },
        // responsive: isSmallScreen ? true : false,

        pageLength: 10,
        // "order": [[ 1, "asc" ]],

        "columnDefs": [
          { "orderable": false, "targets": 0 },
          { 'orderable': false, 'targets': 7 },
         ],

        "lengthMenu": [[10, 25, 50, -1], [10, 25, 50, "All"]],
        fixedColumns: {
          start:isSmallScreen ? 0 : 2,
          end: isSmallScreen ? 1 : 1,
      },
        paging: true,
        fixedHeader: true,
        // scrollX: false,
        scrollY: 450,
        destroy:true,
        dom: 'frtlip',

        drawCallback: function (settings: any) {
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let startIndex = api.page() * api.page.len() + 1;

          $(rows).each(function (index) {
            $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
            startIndex++;
          });
        },
      });
      document.body.scrollTop = 0; // For Safari
      document.documentElement.scrollTop = 0;
    }, 200);
    this.datatableload=true


  }

  checkIfEmpty(event:Event) {
    console.log("keyup")
    console.log(this.makeservicerequest.referrence_number)
    console.log(this.track_with_refno.length)
    console.log(this.track_with_refno)
    if (this.track_with_refno == '' || this.track_with_refno.length<1) {
      this.clearsearch()
    }
    else
    {
      console.log(this.track_with_refno)
      $('.dt-search input').val(this.track_with_refno).keyup();

    }
    // if(this.makeservicerequest.referrence_number==0)
    //   {

        // this.track_with_refno=this.makeservicerequest.referrence_number
        this.getByreferrenceId()
      // }
  }


  clearsearch() {
    this.datatableload=false
    this.track_with_refno = ''
    this.recentincidents=[]
    this.recentincidents = this.updatedrecentincidents
    setTimeout(() => {
    this.datatablepagination()
    }, 100);
  }

  getByreferrenceId() {

    // console.log(this.track_with_refno)
    // console.log(this.track_with_refno.length)
    // if (true) {
this.makeservicerequest.getByIncidentByRefno(this.track_with_refno).subscribe((data: any) => {
      console.log(data)
      // console.log(data['refno'])
      // console.log(data)
      this.recentincidents = []
      if (Object.keys(data).length === 0 && data.constructor === Object) {
        console.log(data)
        this.recentincidents = []
      }
      else {
        this.datatableload=false;
        this.recentincidents = []
        console.log(this.recentincidents)
        this.recentincidents.push(data);
      }

      console.log(this.recentincidents)
      setTimeout(() => {

      this.datatablepagination()
      }, 100);
    })
    // }
    // else {
    //   this.recentincidents = this.updatedrecentincidents
    //   // this.datatablepagination()
    // }

    // alert(this.track_with_refno.value.length)

  }
  Nanaddress(address: any) {
    return address.replace(/\d+/g, '');

  }
  recentincidents: any = [];
  updatedrecentincidents: any = []
  ShowDiv()
  {

  }
  getRecentIncidents() {
    this.datatableload=false
    this.makeservicerequest.getrecentIncidents().subscribe(
      (data) => {
        this.recentincidents = data;
        console.log(data);
        console.log(this.recentincidents)
        this.updatedrecentincidents = this.recentincidents

        if (this.makeservicerequest.referrence_number != 0) {
          console.log("undefined")
          this.track_with_refno = this.makeservicerequest.referrence_number;
          console.log(this.track_with_refno)
          this.getByreferrenceId()
          this.makeservicerequest.referrence_number=0
        }
          this.datatablepagination()
      },
      (error) => {
        console.error('Error fetching recent incidents:', error);
      }
    );
  }
  refno: any;
  ngAfterViewInit() {
    // this.makeservicerequest.referencenum;
    // this.refno = this.makeservicerequest.referencenum;

    // if (this.refno !== undefined) {

    //   this.track_with_refno = this.refno;

    //   this.getByreferrenceId();

    // }

    // this.dataSource.paginator = this.paginator;
    // this.dataSource.sort = this.sort;
  }
  // openPopup() {

  //   this.dialog.open(PopupComponent, {
  //     width: '440px'
  //   });
  // }

  trackOpen: boolean = false;
  serviceRequest: any;
  //   clickTrack(){
  //     if(this.userInfoList){
  //       let userList=JSON.parse(this.userInfoList);
  //       let selectedList = userList.filter((item:any) => item.srID == this.serviceRequest);
  //       this.submitSrDetails = selectedList.length ?selectedList[0]:"";
  //       this.trackOpen = this.submitSrDetails?true:false;

  //       if(this.trackOpen){
  //       jq(".l-seaech").parents().find('#track-div').addClass('tracktable');
  //       }else{
  //       jq(".l-seaech").parents().find('#track-div').removeClass('tracktable');
  //       }
  //     }

  //  }
  onSearch(): void {

    this.knowledgeSearchService.updateSearchQuery(this.searchQuery, '', '');
    console.log("search");
    console.log(this.searchQuery)
    // console.log(this.searchterms, this.selectedDepartment, this.selectedCategory,'1111')
    // this.searchEvent.emit();
    // this.knowledgeService.triggerSearchEvent();
  }
  searchQuery: string = '';
  dataSourcee: any[] = [
    { id: 1, srID: '24207-000450', department: 'Animal care and Control', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '123 Main St' },
    { id: 2, srID: '24207-000451', department: 'Forestry', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '456 Elm St' },
    { id: 3, srID: '24207-000452', department: 'Animal care and Control', srReportDate: new Date(), srStatus: 'pending', serviceAddress: '200 e berry st' },
    { id: 4, srID: '24207-000453', department: 'Filtration', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '456 Elm St' },
    { id: 5, srID: '24207-000454', department: 'Forestry', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '456 Elm St' },
    // { id: 6, srID: '24207-000455', department: 'Parking Control', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '200 e berry st' },
    // { id: 7, srID: '24207-000457', department: 'Property Management', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '456 Elm St' },
    // { id: 8, srID: '24207-000458', department: 'Filtration', srReportDate: new Date(), srStatus: 'Pending', serviceAddress: '456 Elm St' },

    // { id: 9, srID: '24207-000459', department: 'Parking Control', srReportDate: new Date(), srStatus: 'In Progress', serviceAddress: '200 e berry st' },

    // Add more dummy data entries here
    // Repeat this pattern for 10 entries
  ];

  //  applyFilter(event: Event) {
  //   const filterValue = (event.target as HTMLInputElement).value;
  //   this.dataSource.filter = filterValue.trim().toLowerCase();
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }

}
