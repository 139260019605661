import { Component } from '@angular/core';
import { makeServiceRequestService } from '../make-service-request.service';

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent {
  constructor(public makeservicerequest:makeServiceRequestService)
  {

  }
  dropdownOpen = false;
  notifications: string[] = ['Notification 1', 'Notification 2', 'Notification 3','Notification 1', 'Notification 2', 'Notification 3'];
  toggleDropdown() {
    this.dropdownOpen = !this.dropdownOpen;
  }
  ngOnInit()
  {
    this.makeservicerequest.getallnotifications();
  }
  // getallnotifications()
  // {
  //   this.makeservicerequest.getallnotifications().subscribe((data:any)=>
  //   {
  //     console.log(data)
  //   })
  // }
  usernotifications:any;
  filteredNotifications:any;
  unreadCount:any;
  // getallnotifications()
  // {
  //   this.makeservicerequest.getallnotifications().subscribe((data:any)=>
  //   {
  //     this.usernotifications = data;
  //     console.log(this.usernotifications)
  //     // Initially show all notifications
  //     if(this.usernotifications!=null)
  //       {
  //         this.filteredNotifications = this.usernotifications;
  //         this.unreadCount =this.filteredNotifications.filter((notification:any) => !notification.readmode).length;
  //         console.log(this.unreadCount)
  //         console.log(data)
  //       }

  //   })
  // }
  toggleShowUnread(checked: any) {
    console.log(checked)
    if (checked.target.checked) {
      // alert(checked)
      // Filter unread notifications
      this.makeservicerequest.filteredNotifications = this.makeservicerequest.usernotifications.filter((notification:any) => !notification.readmode);
      console.log(this.makeservicerequest.filteredNotifications.length)
    } else {
      // Show all notifications
      this.makeservicerequest.filteredNotifications = this.makeservicerequest.usernotifications;
    }
  }
  updatemode(data:any)
  {

    console.log(data.id,data.readmode)
    this.makeservicerequest.updatenotification(data.id,!data.readmode).subscribe((data:any)=>
    {
console.log(data)
data.readmode=data.readmode;
console.log(data.readmode)
this.makeservicerequest.getallnotifications();
    })
    // console.log(index)
    // this.http.put(this.backendUrl+'updateMode?notifyid='+notifyid.id,{}).subscribe((data:any)=>
    // {
    //   console.log(data)
    //   if(data)
    //   notifyid.readmode=true
    // })
    // this.getrefreshuserlist();


    // const unread = document.querySelector('input[type="checkbox"][id="unreadnotification"]');
    //       console.log(unread)
    //       if (unread) {
    //         (unread as HTMLInputElement).checked = false;
    //       }


  }

}
