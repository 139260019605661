<div class="row m-0 input_box">
  <div class=" col-md-12 ps-0 pe-0 mb-4 position-relative search-loc" ngbDropdown >

    <input type="text" class="form-control search-rwq" placeholder="Service Address" [(ngModel)]="addressvalue"
      (input)="suggestionSearch()" (change)="onDropdownChange()"  style="padding-right: 40px;" id="asriSearch" ngbDropdownToggle autocomplete="new-password">


      <div class="dropdown_check" ngbDropdownMenu aria-labelledby="asriSearch">
        <p *ngFor="let item of addressSuggestions" class="dropdown-item mb-1">
            <span style="font-size: 13px; margin-left: 20px;" style="cursor: pointer;"
                (click)="assignAddress(item)">{{item}} </span></p>
    </div>


    <img src="../../assets/images/search-line.png" height="20px" style="position: absolute;right: 14px;top: 8px;z-index: 9999;">

    <!-- <label class="">Service Address</label> -->
  </div>
</div>



<div style="height: 20px;" #mapViewNode></div>
