<div id="wrapper" *ngIf="!isSignupRoute()">

   <!-- <aside id="sidebar-wrapper" clickOutside (clickOutside)="clickedOutside()"> -->
      <aside id="sidebar-wrapper" >
      <div class="d-flex mob-champ"  (click)="clickNavbarMenu()" >
         <img class="track cursor " src="../../assets/images/menu.svg"
           width="22px" title="menu"  [ngClass]="{'change':menuOpen}" />
      </div>
      <ul class="sidebar-nav scrollbox">
         <li> <a class="hover-text" (click)="isActiveRouteCall('/')" routerLink="/" routerLinkActive="active"
               [routerLinkActiveOptions]="{exact: true}"> <span class="tooltip-text tip-right" id="right">311 Home
                  </span> <img class="track" src="../../../assets/images/home.png" />

                  <img class="track" src="../../assets/images/data-analytics.svg" /> <span class="tittle">Home</span>
               </a>
               </li>


         <li><a class="hover-text" (click)="isActiveRouteCall('/create-sr')" routerLink="/create-sr"
               routerLinkActive="active">
               <span class="tooltip-text tip-right">New Service Request</span>
               <img class="track" src="../../../assets/images/NewSRdefult.svg" />
               <img class="track" src="../../../assets/images/NewSRactive.svg" />
               <span  class="tittle">New SR</span></a></li>

         <li><a class="hover-text" (click)="isActiveRouteCall('/track-sr')" routerLink="/track-sr"
               routerLinkActive="active"> <span class="tooltip-text tip-right">Track a Service Request</span>
               <img class="track" src="../../assets/images/track-sr-line.png" />
                      <img class="track" src="../../assets/images/track-sr.svg" />
                  <span  class="tittle">Track SR</span> </a></li>

         <!-- <li><a class="hover-text" (click)="openPopup()" [ngClass]="{'active':isActiveRouter == '/my-service-request'}">
               <span class="tooltip-text tip-right">My Service Requests</span>
               <i
                  class="bi bi-clipboard-pulse mr-i"></i>

                  <span>My Service Requests</span> </a></li> -->
                  <!-- <li><a href="#" class="hover-text" routerLink="/faq" routerLinkActive="active"> <span
                     class="tooltip-text tip-right">SMART® FAQs</span>
                     <img class="track" src="../../assets/images/faq-line.png" />
                     <img class="track" src="../../assets/images/faq.svg" />

                     <span  class="tittle">SMART® FAQs</span></a></li> -->
         <!-- <li><a class="hover-text"> <span class="tooltip-text tip-right">Recently Viewed</span>
            <img class="" src="../../assets/images/recent-line.png" />

                      <img class="" src="../../assets/images/recent.svg" />

            <span>Recently Viewed</span></a></li> -->
         <li>
            <hr>
         </li>

         <li class="kbase"><a  href="#" [routerLink]="['/answers/list']"  (click)="isActiveRouteCall('/knowledge-base')" class="hover-text" routerLinkActive="active">
          <span class="tooltip-text tip-right">Smart® Knowledge Base AI(BETA)</span>
          <img class="track" src="../../assets/images/knowledgeBase-solid.png" />
          <img class="track" src="../../assets/images/knowledgeBase.png" />
            <span  class="tittle">SMART® Knowledge Base AI(BETA)</span>
             </a>
       </li>
         <!-- <li><a   href="#" routerLink="/about311"class="hover-text" routerLinkActive="active"> <span
          class="tooltip-text tip-right">About 311</span>
          <img class="track" src="../../assets/images/about-311-solid.png" />
    <img class="track" src="../../assets/images/about-311.png" />

          <span  class="tittle">About 311</span></a>
         </li> -->

 <li><a href="#" routerLink="/news"  class="hover-text" routerLinkActive="active">
    <span class="tooltip-text tip-right">News</span>
    <img class="track" src="../../assets/images/news-solid.png" />
    <img class="track" src="../../assets/images/news.png" />
     <span  class="tittle">News</span></a>
 </li>

 <!-- <li><a href="#" routerLink="/feedbacksurvey" class="hover-text" routerLinkActive="active"> -->
   <li><a href="#" routerLink="/feedbacksurvey"  class="hover-text" >
    <span class="tooltip-text tip-right">Feedback </span>
    <img class="track" src="../../assets/images/feedback-solid.png" />
    <img class="track" src="../../assets/images/feedback.png" />

    <span  class="tittle">Feedback</span></a>
 </li>
 <li><a  href="#" routerLink="/waystocontactus" class="hover-text" routerLinkActive="active">
    <span class="tooltip-text tip-right">Ways to Contact US</span>
    <img class="track" src="../../assets/images/way-contact-solid.png" />
    <img class="track" src="../../assets/images/way-contact.png" />
      <span  class="tittle">Contact Us</span>
       </a>
 </li>




         <!-- <li><a href="#" class="hover-text" routerLink="/feedbacksurvey"> <span class="tooltip-text tip-right">Feedback
                  survey</span><i class="bi bi-question-circle"></i><span>Feedback survey</span></a></li> -->

         <li>
            <hr>
         </li>




         <!-- <p class=" sidenav-htext">Main</p> -->
<!--
         <li><a href="javascript:void(0)" class="hover-text" routerLinkActive="active" > <span
          class="tooltip-text tip-right">Residents</span>
          <img class="track" src="../../assets/images/resident-solid.png" />
              <img class="track" src="../../assets/images/resident.png" />
         <span  class="tittle">Residents</span></a></li>


 <li><a href="javascript:void(0)" class="hover-text"  routerLinkActive="active">
    <span class="tooltip-text tip-right">Business</span>
    <img class="track" src="../../assets/images/business-solid.png" />
    <img class="track" src="../../assets/images/business.png" />
    <span  class="tittle">Business</span>
 </a>
 </li>


 <li><a href="javascript:void(0)" class="hover-text" routerLinkActive="active">
    <span class="tooltip-text tip-right">Visitors</span>
    <img class="track" src="../../assets/images/visitors-solid.png" />
    <img class="track" src="../../assets/images/visitors.png" />

          <span  class="tittle">Visitors</span>
       </a>
    </li> -->

    <li>
<a href="https://www.cityoffortwayne.org/" class="hover-text" routerLinkActive="active">
    <span class="tooltip-text tip-right">City Home</span>
    <img class="track" src="../../assets/images/cityHome-solid.png" />
    <!-- <img class="track" src="../../assets/images/cityHome.png" /> -->

          <span  class="tittle">City Home</span>
       </a>
    </li>


      </ul>
      <!-- <div class="app-sidebar-mobile-backdrop"><a href="#" data-dismiss="app-sidebar-mobile" class="stretched-link"></a></div> -->

   </aside>
</div>
