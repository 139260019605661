import { makeServiceRequestService } from './../make-service-request.service';
import { Component, Output, Input, EventEmitter, ViewChild, SimpleChanges, ElementRef, Renderer2, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl } from '@angular/forms'
import { Router } from '@angular/router';
import { loadModules } from 'esri-loader';
import { EsriMapComponent } from '../esri-map/esri-map.component';
import { GoogleMapComponent } from '../google-map/google-map.component';
import { MsalService } from '@azure/msal-angular';

declare var $: any;
@Component({
  selector: 'app-address-details',
  templateUrl: './address-details.component.html',
  styleUrls: ['./address-details.component.scss']
})
export class AddressDetailsComponent {
  @Input() serviceTypeInfo: any;
  @ViewChild(EsriMapComponent) EsriMapComponent: EsriMapComponent;
  @ViewChild(GoogleMapComponent) GoogleMapComponent: GoogleMapComponent;

  searchAddress: any;
  nextcollectiondate: any;
  receiveData(data: any) {
    // alert()
    if (data.msr_serviceaddress != undefined) {
      this.isGisVerified = true;
    }
    else {
      this.isGisVerified = false;
    }
    console.log(data)
    // this.agentscriptservice.locationcoordinates=data
    // if(data.msr_addresstype.includes("Intersection")){
    //   this.addressDetailsForm.get(['locationDtls','addresstype'])?.setValue("1362")
    //   this.addressDetailsForm.get(['locationDtls','addressline2'])?.setValue(data.msr_)
    // }else{
    //   this.addressDetailsForm.get(['locationDtls','addresstype'])?.setValue("1363")
    //   this.addressDetailsForm.get(['locationDtls','addressline2'])?.setValue(data.msr_)
    // }
    this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue(data.msr_serviceaddress)
    console.log(data.msr_lat)
    if (data.msr_serviceaddress != undefined && data.msr_lat != '') {
      this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue("Fort Wayne")
      this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue("IN")
    }
    else {
      this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue("")
      this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue("")
    }
    if (this.serviceTypeInfo.serviceDetails.casetypeid == 367) {
      this.addressDetailsForm.get(['locationDtls', 'next_collection_day'])?.setValue(data.msr_next_collection_day)
    }

    this.addressDetailsForm.get(['locationDtls', 'postalcode'])?.setValue(data.msr_zip)
    this.addressDetailsForm.get(['locationDtls', 'longitude'])?.setValue(data.msr_lng)
    this.addressDetailsForm.get(['locationDtls', 'latitude'])?.setValue(data.msr_lat)
    this.addressDetailsForm.get(['locationDtls', 'withinlimits'])?.setValue(data.msr_withinlimits)
    this.addressDetailsForm.get(['locationDtls', 'jurisdiction'])?.setValue(data.msr_jurisdiction)
    this.addressDetailsForm.get(['locationDtls', 'zoning'])?.setValue(data.msr_zoning)
    this.addressDetailsForm.get(['locationDtls', 'recycleweek'])?.setValue(data.msr_recycleweek)
    this.addressDetailsForm.get(['locationDtls', 'collectionday'])?.setValue(data.msr_collectionday)
    this.addressDetailsForm.get(['locationDtls', 'parcelnumber'])?.setValue(data.msr_parcelnumber)
    this.nextcollectiondate = data.msr_next_collection_date;
    if (this.isGisVerified && this.addressDetailsForm.valid) {

      this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
      this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;
      // this.serviceTypeInfo.serviceDetails=this.nextcollectiondate;
      if (this.serviceTypeInfo.serviceDetails.casetypeid == 367) {
        this.serviceTypeInfo.serviceDetails.nextcollectionday = this.nextcollectiondate;
      }
      //   this.nextdata={'redirectTo':this.serviceTypeInfo.redirectTo,viewname:'agentscriptView','serviceDetails':'next-btn'}
      // this.nextdata.serviceDetails = this.serviceTypeInfo.serviceDetails;
      //   console.log(this.nextdata)
      //       this.serviceTypeDetailsOutputEvent.emit(this.nextdata);
    }

    // this.addressDetailsForm.updateValueAndValidity();
    // if(this.addressDetailsForm.value.contactDtls!='')
    //   {
    //     console.log(this.addressDetailsForm.value.contactDtls)
    //     alert(this.addressDetailsForm.valid)
    //     console.log(this.addressDetailsForm)
    //   }
    // this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;

    // this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.servicedetails.contactDtls;
    // console.log( this.serviceTypeInfo.serviceDetails["contactDtls"])
    // this.ngOnInit();

  }

  addressDetailsForm: FormGroup;
  isLoginInfo: boolean = false;
  isAnonymous: boolean = false;
  exampleCheck1: boolean = false;
  marked: boolean = false;
  searchLocation: any;
  //esri map
  mapCenter = [-85.1392, 41.0804];
  basemapType = 'satellite';
  mapZoomLevel = 11;
  mapLoadedEvent(status: boolean) {
    console.log('The map has loaded: ' + status);
  }
  tcontainer = {
    'height': ($(window).height() - $('.layout-navbar').height() - 290) + 'px',
    'overflow': 'hidden',
    'overflow-y': 'auto'
  };

  dropdownOpen: boolean = false;

  openDropdown(event: any) {
    if (event.keyCode !== 27) { // Check if the key is not the escape key
      this.dropdownOpen = true;
    }
  }

  //   openDropdown(event: any) {
  //     if (event.keyCode !== 27) { // Check if the key is not escape key
  //         const dropdownToggle = document.querySelector('[data-toggle="dropdown"]');
  //         if (dropdownToggle) {
  //             dropdownToggle.dispatchEvent(new Event('click', { bubbles: true }));
  //         }
  //     }
  // }
  showesri() {
    // alert("this.displayesri=true")
    this.displayesri = true

    // const addressline1Control = this.addressDetailsForm.get(['locationDtls', 'addressline1']);
    // if (!(addressline1Control.value == null || addressline1Control.value == '' || addressline1Control.value == undefined))
    //   {
    //     // this.agentscriptservice.itemtext
    //     // this.agentscriptservice.emitAddress(addressline1Control.value);
    //     this.assignaddress(this.agentscriptservice.totalitemtext)
    //   }

    event.preventDefault()
  }
  showgoogle() {
    this.displayesri = false
    this.agentscriptservice.searchAddress = this.searchAddress;
    this.GoogleMapComponent.retrieveStoredAddresses()
    event.preventDefault()
  }
  checkPhoneNumbers() {
    const phonenumber1 = this.addressDetailsForm.get(['contactDtls', 'phonenumber1']).value;
    const phonenumber2Control = this.addressDetailsForm.get(['contactDtls', 'phonenumber2'])

    // console.log(phonenumber2Control?.value.length);

    if (phonenumber2Control?.value && phonenumber2Control?.value.length < 14) {
      console.log("min length error");
      phonenumber2Control?.setErrors({ minlength: true });
    } else {
      if (phonenumber1 && phonenumber2Control && phonenumber2Control.value) {
        // const phonenumber2 = phonenumber2Control.value;
        console.log(phonenumber2Control.value)
        if (phonenumber1 == phonenumber2Control.value) {
          console.log("same numbers error");
          phonenumber2Control.setErrors({ sameNumbers: true });
        } else {
          phonenumber2Control.setErrors(null);
        }
      }
    }

    console.log(phonenumber2Control.value)


    console.log(phonenumber2Control?.errors);
  }

  displayesri: any;
  logInfo: any;
  formsubmitted:any
  ngOnInit() {
    this.formsubmitted=false
    this.displayesri = true
    this.showin3dbutton = false;
    console.log(this.serviceTypeInfo.serviceDetails)
    console.log(this.agentscriptservice.incidentdetails)
    //   if (this.agentscriptservice.incidentdetails !== undefined) {
    //     if (this.serviceTypeInfo.serviceDetails.department.id !== 199) {
    //         this.serviceTypeInfo.serviceDetails["locationDtls"] = this.agentscriptservice.incidentdetails.locationDtls;
    //     }
    //     if (this.serviceTypeInfo.serviceDetails.casetypeid === 367) {
    //         const contactDtls = this.agentscriptservice.incidentdetails.contactDtls;
    //         console.log(contactDtls);

    //         const hasAnonymous = (value: string) => value && value.toLowerCase().includes('anonymous');
    //         console.log(hasAnonymous);
    //         console.log(hasAnonymous(contactDtls.firstname))

    //         if (hasAnonymous(contactDtls.firstname) || hasAnonymous(contactDtls.lastname) || hasAnonymous(contactDtls.emailaddress)) {
    //           console.log("in anonymous")
    //             this.serviceTypeInfo.serviceDetails["contactDtls"] = {};
    //         }
    //         else {
    //             this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.incidentdetails.contactDtls;
    //         }
    //     } else {
    //       console.log(this.agentscriptservice.incidentdetails.contactDtls)
    //         this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.incidentdetails.contactDtls;
    //         console.log(this.serviceTypeInfo.serviceDetails["contactDtls"])

    //     }
    // }
    // console.log(this.agentscriptservice.incidentdetails.locationDtls)

    if (this.agentscriptservice.incidentdetails != undefined) {
      console.log(this.serviceTypeInfo.serviceDetails.casetypeid)
      if (this.serviceTypeInfo.serviceDetails.department.id != 199) {

        this.serviceTypeInfo.serviceDetails["locationDtls"] = this.agentscriptservice.incidentdetails.locationDtls;
        this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.incidentdetails.contactDtls;
        console.log(this.serviceTypeInfo.serviceDetails["locationDtls"])
        console.log(this.serviceTypeInfo.serviceDetails["contactDtls"])
      }
      if (this.serviceTypeInfo.serviceDetails.casetypeid === 367 && this.serviceTypeInfo.serviceDetails.department.id === 207) {
        const contactDtls = this.agentscriptservice.incidentdetails.contactDtls;
        const hasAnonymous = (value: string) => value && value.toLowerCase().includes('anonymous');
        console.log(hasAnonymous);
        console.log(hasAnonymous(contactDtls.firstname))

        if (hasAnonymous(contactDtls.firstname) || hasAnonymous(contactDtls.lastname) || hasAnonymous(contactDtls.emailaddress)) {
          console.log(hasAnonymous(contactDtls.firstname))
          this.serviceTypeInfo.serviceDetails["contactDtls"] = {};
          console.log(this.serviceTypeInfo.serviceDetails["contactDtls"])
        } else {
          console.log(this.agentscriptservice.incidentdetails.contactDtls)
          this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.incidentdetails.contactDtls;
          ;
        }
      }
      if (this.serviceTypeInfo.serviceDetails.department.id == 199) {
        this.serviceTypeInfo.serviceDetails["locationDtls"] = this.agentscriptservice.incidentdetails.locationDtls;
      }
      // this.serviceTypeInfo.serviceDetails["contactDtls"] =this.agentscriptservice.incidentdetails.contactDtls;
    }


    console.log('Department ID:', this.serviceTypeInfo.serviceDetails.department.id);
    //  if(this.authService.instance.getAllAccounts().length > 0 && this.serviceTypeInfo.serviceDetails.department.id !== 199){
    //   this.logInfo = this.authService.instance.getAllAccounts()[0];
    //   console.log('test header info',this.logInfo);
    //   this.addressDetailsForm.get(['contactDtls', 'firstname'])?.setValue(this.logInfo.name);
    //   // this.addressDetailsForm.get(['contactDtls', 'lastname'])?.setValue(userInfo.username); // Set both first and last name to username, change as needed
    //   this.addressDetailsForm.get(['contactDtls', 'emailaddress'])?.setValue(this.logInfo.username);
    // }
    console.log(this.agentscriptservice.servicedetails)
    if (this.agentscriptservice.servicedetails !== undefined) {
      console.log("in if");

      // Default assignment of locationDtls
      this.serviceTypeInfo.serviceDetails["locationDtls"] = this.agentscriptservice.servicedetails.locationDtls;

      // Check for department ID 199
      if (this.serviceTypeInfo.serviceDetails.department.id !== 199) {
        console.log("in ifff");
        this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.servicedetails.contactDtls;
      }

      // Check for department ID 207 and "anonymous" in firstname, lastname, or emailaddress
      if (this.serviceTypeInfo.serviceDetails.casetypeid === 367) {
        const contactDtls = this.agentscriptservice.servicedetails.contactDtls;
        const hasAnonymous = (value: string) => value && value.toLowerCase().includes('anonymous');

        if (hasAnonymous(contactDtls.firstname) || hasAnonymous(contactDtls.lastname) || hasAnonymous(contactDtls.emailaddress)) {
          this.serviceTypeInfo.serviceDetails["contactDtls"] = {};
        } else {
          this.serviceTypeInfo.serviceDetails["contactDtls"] = contactDtls;
        }
      }
      // else {
      //   this.serviceTypeInfo.serviceDetails["contactDtls"] = this.agentscriptservice.servicedetails.contactDtls;
      // }

      console.log(this.serviceTypeInfo.serviceDetails["contactDtls"]);
    }



    console.log(this.addressDetailsForm.value.contactDtls)
    // this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
    // console.log(this.serviceTypeInfo.serviceDetails["contactDtls"])
    // this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0;
    console.log(this.serviceTypeInfo)
    if (this.serviceTypeInfo.serviceDetails.contactDtls?.anonymous == true) {
      this.isAnonymous = true;
    }
    if (this.serviceTypeInfo.serviceDetails.department.id === 199) {

      this.isAnonymous = true;
      this.addressDetailsForm.get(['contactDtls', 'anonymous'])?.setValue(true);
      this.addressDetailsForm.get(['contactDtls', 'contact'])?.setValue("Anonymous Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'firstname'])?.setValue("Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'lastname'])?.setValue("Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'emailaddress'])?.setValue("anonymous@thesmartcity311.com");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber1'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber2'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'mailingadd'])?.setValue("United States (US)");

      console.log(this.isAnonymous)
    }
    // console.log(this.serviceTypeInfo.serviceDetails.locationDtls.addressline1)
    if ((this.serviceTypeInfo.serviceDetails.locationDtls?.addressline1 && this.serviceTypeInfo.serviceDetails.locationDtls?.addressline1 != '' && this.serviceTypeInfo.serviceDetails.locationDtls?.state && this.serviceTypeInfo.serviceDetails.locationDtls?.state != '' && this.serviceTypeInfo.serviceDetails.locationDtls?.city && this.serviceTypeInfo.serviceDetails.locationDtls?.city != '') && (this.serviceTypeInfo.serviceDetails.locationDtls?.addressline1 != undefined)) {
      this.isGisVerified = true;
    }
    if (this.serviceTypeInfo.serviceDetails.casetypeid == 367) {
      this.isGisVerified = false;
    }
    // this.clearForm();
    if (this.serviceTypeInfo && this.serviceTypeInfo.serviceDetails && this.serviceTypeInfo.serviceDetails.contactDtls) {
      this.addressDetailsForm.get(['contactDtls', 'anonymous'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.anonymous);
      this.addressDetailsForm.get(['contactDtls', 'contact'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.contact);
      this.addressDetailsForm.get(['contactDtls', 'firstname'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.firstname);
      this.addressDetailsForm.get(['contactDtls', 'lastname'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.lastname);
      this.addressDetailsForm.get(['contactDtls', 'emailaddress'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.emailaddress);
      this.addressDetailsForm.get(['contactDtls', 'phonenumber1'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.phonenumber1);
      this.addressDetailsForm.get(['contactDtls', 'phonenumber2'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.phonenumber2);
      this.addressDetailsForm.get(['contactDtls', 'mailingadd'])?.setValue(this.serviceTypeInfo.serviceDetails.contactDtls.mailingadd);
    }
    if (this.serviceTypeInfo && this.serviceTypeInfo.serviceDetails && this.serviceTypeInfo.serviceDetails.locationDtls) {
      if (this.serviceTypeInfo.serviceDetails.casetypeid != 367) {
        this.addressDetailsForm.get(['locationDtls', 'addresstype'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addresstype);
        this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addressline1);
        this.addressDetailsForm.get(['locationDtls', 'addressline2'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addressline2);
        this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.city);
        this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.state);
        this.addressDetailsForm.get(['locationDtls', 'longitude'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.longitude);
        this.addressDetailsForm.get(['locationDtls', 'latitude'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.latitude);
        this.addressDetailsForm.get(['locationDtls', 'postalcode'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.postalcode);
        this.addressDetailsForm.get(['locationDtls', 'withinlimits'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.withinlimits);
        this.addressDetailsForm.get(['locationDtls', 'jurisdiction'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.jurisdiction);
        this.addressDetailsForm.get(['locationDtls', 'zoning'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.zoning);
        this.addressDetailsForm.get(['locationDtls', 'recycleweek'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.recycleweek);
        this.addressDetailsForm.get(['locationDtls', 'collectionday'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.collectionday);
        this.addressDetailsForm.get(['locationDtls', 'parcelnumber'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.parcelnumber);
        this.addressDetailsForm.get(['locationDtls', 'next_collection_day'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls?.next_collection_day)
      }
      else {
        // this.addressDetailsForm.get(['locationDtls','addresstype'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addresstype);
        this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addressline1);
        this.serviceTypeInfo.serviceDetails.casetypeid = 367
        // this.addressDetailsForm.get(['locationDtls','addressline2'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.addressline2);
        setTimeout(() => {
          this.keyupenter()
        }, 100);
      }

      // this.addressDetailsForm.get(['locationDtls','sla'])?.setValue(this.serviceTypeInfo.serviceDetails.locationDtls.sla);
    }
    else {
      this.addressDetailsForm.get(['locationDtls', 'addresstype'])?.setValue("1362");
    }


  }

  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    console.log('Document clicked', event);
    this.entiredata=[]
  }

  constructor(private elRef: ElementRef, private renderer: Renderer2,public dialog: MatDialog, private fb: FormBuilder,
    private router: Router, private agentscriptservice: makeServiceRequestService, private authService: MsalService) {
    this.addressDetailsForm = this.fb.group({
      contactDtls: this.fb.group({
        anonymous: [''],
        firstname: ['', [Validators.required]],
        //contact: ['', [Validators.required]],
        lastname: ['', [Validators.required]],
        emailaddress: ['', [Validators.required, this.emailValidator()]],
        phonenumber1: ['', [Validators.minLength(14)]],
        phonenumber2: ['', [Validators.minLength(14)]],
        mailingadd: [''],
      },
      ),
      locationDtls: this.fb.group({
        addresstype: ['1362', [Validators.required]],
        addressline1: ['', [Validators.required]],
        addressline2: [''],
        city: [''],
        state: [''],
        latitude: [''],
        longitude: [''],
        postalcode: [''],
        withinlimits: [''],
        jurisdiction: [''],
        zoning: [''],
        recycleweek: [''],
        collectionday: [''],
        next_collection_day: ['']
        // parcelnumber: ['', [Validators.required]],
        // sla: ['', [Validators.required]],
      })
    });
    this.addressDetailsForm.get(['contactDtls', 'phonenumber1']).valueChanges.subscribe(() => {
      this.checkPhoneNumbers();
    });
    this.addressDetailsForm.get(['contactDtls', 'phonenumber2']).valueChanges.subscribe(() => {
      this.checkPhoneNumbers();
    });
  }
  toggleVisibility(e: any) {
    this.isAnonymous = e.target.checked
    if (this.serviceTypeInfo.serviceDetails.department.id === 199) {
      console.log("in")
      this.isAnonymous = true;
    }
    console.log('Department ID:', this.serviceTypeInfo.serviceDetails.department.id);
    if (e.target.checked) {
      this.addressDetailsForm.get(['contactDtls', 'anonymous'])?.setValue(true);

      this.addressDetailsForm.get(['contactDtls', 'contact'])?.setValue("Anonymous Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'firstname'])?.setValue("Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'lastname'])?.setValue("Anonymous");
      this.addressDetailsForm.get(['contactDtls', 'emailaddress'])?.setValue("anonymous@thesmartcity311.com");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber1'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber2'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'mailingadd'])?.setValue("United States (US)");
    } else {
      this.addressDetailsForm.get(['contactDtls', 'contact'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'firstname'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'lastname'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'emailaddress'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber1'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'phonenumber2'])?.setValue("");
      this.addressDetailsForm.get(['contactDtls', 'mailingadd'])?.setValue("");
    }
  }


  recycleweek = [
    { 'id': 1360, 'value': "A" },
    { 'id': 1361, 'value': "B" },
  ]

  withinlimits = [
    { 'id': 191, 'value': "In Jurisdiction" },
    { 'id': 192, 'value': "Out Jurisdiction" },
  ]

  collectionday = [
    { 'id': 11, 'value': "Monday" },
    { 'id': 12, 'value': "Tuesday" },
    { 'id': 13, 'value': "Wednesday" },
    { 'id': 14, 'value': "Thursday" },
    { 'id': 15, 'value': "Friday" },
    { 'id': 16, 'value': "Saturday" },
    { 'id': 17, 'value': "Sunday" },

  ];
  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  nextdata: any = [];
  gotoNext(data: any, type: any) {
    this.formsubmitted=true
    // if(this.isGisVerified)
    // {

    // }
    // console.log(this.agentscriptservice?.incidentdetails?.locationDtls)
    // console.log(this.agentscriptservice?.incidentdetails)
    // console.log(this.agentscriptservice)
    if (this.agentscriptservice?.incidentdetails && this.agentscriptservice.incidentdetails.locationDtls && this.agentscriptservice.incidentdetails.locationDtls != undefined && this.agentscriptservice.incidentdetails.locationDtls != null) {
      this.agentscriptservice.incidentdetails.locationDtls = this.addressDetailsForm.value.locationDtls
    }
    if (this.agentscriptservice?.incidentdetails && this.agentscriptservice.incidentdetails.contactDtls && this.agentscriptservice.incidentdetails.contactDtls != undefined && this.agentscriptservice.incidentdetails.contactDtls != null) {
      this.agentscriptservice.incidentdetails.contactDtls = this.addressDetailsForm.value.contactDtls
    }
    // console.log(data)
    // this.assignaddress(data);
    // city: [''],
    // state: [''],
    // var citylocation=this.addressDetailsForm.get(['locationDtls','city']).value
    // alert(this.addressDetailsForm.get(['locationDtls','city']).value)

    // if((citylocation==null || citylocation=='' || citylocation==undefined))
    if(this.addressDetailsForm.invalid)
    {
    // alert()
    }
    else if (!this.isGisVerified && this.addressDetailsForm?.get('locationDtls.addressline1')?.value!='') {
      // alert("invalid")

      this.keyupenter();
    }
    else {
      // alert("valid")
      console.log(this.serviceTypeInfo.serviceDetails);
      this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
      this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;
      // this.serviceTypeInfo.serviceDetails=this.nextcollectiondate;
      if (this.serviceTypeInfo.serviceDetails.casetypeid == 367) {
        this.serviceTypeInfo.serviceDetails.nextcollectionday = this.nextcollectiondate;
      }
      data.serviceDetails = this.serviceTypeInfo.serviceDetails;
      this.nextdata = data;
      console.log(this.nextdata);
      this.serviceTypeDetailsOutputEvent.emit(data);
    }

  }
  addressvalue: any
  suggestionSearch() {

    // setTimeout(() => {
    // console.log(this.addressvalue);
    // if(this.addressDetailsForm?.get('locationDtls.addresstype')?.value=='1362')
    // {
    this.addressDetailsForm.get(['locationDtls', 'postalcode'])?.setValue('');
    this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue('');
    this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue('');
    this.addressDetailsForm.get(['locationDtls','collectionday'])?.setValue("")

    this.isGisVerified = false;
    console.log("1 addressline")
    this.addressvalue = this.addressDetailsForm?.get('locationDtls.addressline1')?.value;

    if (this.addressvalue == '') {
      this.entiredata = []
    }
    //  }, 2000);
    // }
    // else{
    //   console.log("2 addresslines")
    //   this.addressvalue = this.addressDetailsForm?.get('locationDtls.addressline1')?.value + '%26' + this.addressDetailsForm?.get('locationDtls.addressline2')?.value
    // }
    console.log(this.addressvalue)

    this.performAddressSearch();
    // }, 2000); // Delay of 2 seconds
    //  this.performAddressSearch(this.addressvalue);
  }


  onDropdownChange() {
    console.log(this.addressDetailsForm.get(['locationDtls', 'addressline1']))
    this.searchAddress = this.addressDetailsForm.get(['locationDtls', 'addressline1']);
    //  this.onSearch();
  }
  clearForm() {
    console.log(this.addressDetailsForm.value.contactDtls)
    // console.log(this.serviceTypeInfo)
    // this.addressDetailsForm.get(['locationDtls','addresstype'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','addressline1'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','addressline2'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','city'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','state'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','longitude'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','latitude'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','postalcode'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','withinlimits'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','jurisdiction'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','zoning'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','recycleweek'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','collectionday'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','parcelnumber'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','sla'])?.setValue("")


    // this.addressDetailsForm.reset();
    // this.addressDetailsForm.get(['locationDtls','addresstype'])?.setValue("1362");
    // this.isLocationSearch =true;
    console.log(this.EsriMapComponent.addressvalue)
    console.log(this.agentscriptservice.servicedetails)
    console.log(this.serviceTypeInfo.serviceDetails)
    // console.log(this.agentscriptservice.servicedetails.contactDtls)
    this.entiredata = [];
    this.isGisVerified = false;
    this.EsriMapComponent.addressvalue = '';
    this.EsriMapComponent.initializeMap();
    this.addressDetailsForm.get('locationDtls')?.reset();
    this.addressDetailsForm.get(['locationDtls', 'addresstype'])?.setValue("1362")
    this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
    this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;
    console.log(this.serviceTypeInfo.serviceDetails["contactDtls"]);


    // this.EsriMapComponent.addressvalue='';
  }
  emailValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const email: string = control.value;
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
      const valid = emailPattern.test(email);

      if (email && !valid) {
        return { 'invalidEmailFormat': true };
      }
      return null;
    };
  }
  fillForm() {
    this.addressDetailsForm.get(['locationDtls', 'addresstype'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'addressline2'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'longitude'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'latitude'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'postalcode'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'withinlimits'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'jurisdiction'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'zoning'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'recycleweek'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'collectionday'])?.setValue("A")
    this.addressDetailsForm.get(['locationDtls', 'parcelnumber'])?.setValue("A")
    // this.addressDetailsForm.get(['locationDtls','sla'])?.setValue("A")
  }
  isLocationSearch: boolean = true;
  // marked: boolean = false;
  isGisVerified: boolean = false;
  isAddresstypeChange() {
    // this.isLocationSearch = true;
    setTimeout(() => {
      this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.patchValue("");
      this.addressDetailsForm.get(['locationDtls', 'addressline2'])?.patchValue("")
    }, 200)

  }
  searchAddress1: any;
  onSearch(item: any) {

    this.EsriMapComponent?.initializeMap();
    this.isLocationSearch = true;
    // this.isGisVerified = true;
    this.searchAddress = this.addressDetailsForm.value.locationDtls.addressline1;
    this.searchAddress1 = this.addressDetailsForm.value.locationDtls.addressline2;
    console.log(this.addressDetailsForm.value.locationDtls.addresstype)
    console.log(this.searchAddress)
    // this.agentscriptservice.itemtext=item.text
    this.agentscriptservice.totalitemtext = item
    this.agentscriptservice.emitAddress(item.text);
    console.log(item)
    // this.clearForm();

    this.addressDetailsForm.get(['locationDtls', 'city'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'state'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'longitude'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'latitude'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'postalcode'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'withinlimits'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','jurisdiction'])?.setValue("")
    // this.addressDetailsForm.get(['locationDtls','zoning'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'recycleweek'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'collectionday'])?.setValue("")
    this.addressDetailsForm.get(['locationDtls', 'parcelnumber'])?.setValue("")


    //  if(this.addressDetailsForm.value.locationDtls.addresstype == "1362"){
    //    this.agentscriptservice.emitAddress(this.searchAddress);
    //   }else{
    //     var concatenateAddress= this.searchAddress + ' & ' + this.searchAddress1
    //     this.agentscriptservice.emitAddress(concatenateAddress);

    //   }

    this.entiredata = []
  }
  isClearable(): boolean {
    return !!this.searchAddress;
  }


  showDropdown: boolean = false;
  entiredata: any;
  addressSuggestions: any;
  showin3dbutton: any

  assignaddress(item: any) {
    this.showin3dbutton = true
    this.displayesri = true
    console.log(item)
    var streetAddress = item?.text.split(',')[0].trim();
    this.addressDetailsForm.get(['locationDtls', 'addressline1'])?.setValue(streetAddress)
    this.onSearch(item);
  }
  keyupenter() {
    // const addline1 = this.addressDetailsForm?.get('locationDtls.addressline1')?.value;
    this.assignaddress({ text: this.addressDetailsForm?.get('locationDtls.addressline1')?.value })

  }
  async performAddressSearch() {
    var searchTerm=this.addressDetailsForm?.get('locationDtls.addressline1')?.value
    // setTimeout(() => {
    console.log(searchTerm)
    try {
      searchTerm.replace('&', 'and')
      var kk = searchTerm.replace(' & ', ' and ')
      console.log(kk)
      searchTerm = kk

      // Trim the search term to remove any leading or trailing spaces
      // const trimmedSearchTerm = searchTerm.trim();
      var trimmedSearchTerm = searchTerm?.trim()



      console.log(trimmedSearchTerm);

      if (trimmedSearchTerm !== '') { // Check if search term is not empty
        this.agentscriptservice.mapsuggestions(trimmedSearchTerm).subscribe((response: any) => {
          console.log(response.suggestions);
          if (this.addressDetailsForm?.get('locationDtls.addressline1')?.value.length > 0) {
            this.entiredata = response.suggestions
            this.addressSuggestions = response.suggestions.map((suggestion: { text: any; }) => suggestion.text);
            console.log(this.addressSuggestions);
          }

        });
      } else {
        // Clear suggestions if search term is empty
        this.addressSuggestions = [];
      }
    }

    catch (error) {
      console.error('Error fetching address suggestions:', error);
    }
    // }, 2000);
  }
  gotoBack(data: any, type: any) {
    this.isGisVerified = true;
    console.log(this.serviceTypeInfo.redirectTo)

    if (this.serviceTypeInfo.redirectTo == 'home') {
      console.log("redirectto is home")
      this.router.navigateByUrl('');
    }

    console.log(data)
    this.serviceTypeInfo.serviceDetails["contactDtls"] = this.addressDetailsForm.value.contactDtls;
    this.serviceTypeInfo.serviceDetails["locationDtls"] = this.addressDetailsForm.value.locationDtls;

    data.serviceDetails = this.serviceTypeInfo.serviceDetails;
    this.agentscriptservice.servicedetails = data.serviceDetails;
    console.log(this.agentscriptservice.servicedetails)
    this.serviceTypeDetailsOutputEvent.emit(data);
  }
}
