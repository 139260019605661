import { Component, ViewChild, Output, EventEmitter, Input } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms';
import { PopupSubmitComponent } from '../popup-submit/popup-submit.component';
import { makeServiceRequestService } from '../make-service-request.service';
import { TabsetComponent, TabDirective } from 'ngx-bootstrap/tabs';

@Component({
  selector: 'app-review-submit',
  templateUrl: './review-submit.component.html',
  styleUrls: ['./review-submit.component.scss']
})


export class ReviewSubmitComponent {
  option: boolean;
  @Input() serviceTypeInfo: any;
  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  agentScriptInfo: any;

  constructor(private makeSerReqService: makeServiceRequestService) {
    this.option = false;

  }
  optionChanged() {
    console.log('Option changed:', this.option);
    // You can add further logic here if needed
  }
  goback(data: any, type: any) {
    console.log(data)
    this.serviceTypeDetailsOutputEvent.emit(data);
  }
  selectedVehicle:any;
  ngOnInit() {
    this.selectVehicle("Vehicle 1 Details");
      this.agentScriptInfo = this.serviceTypeInfo.serviceDetails;

    console.log(this.agentScriptInfo)
    }
    selectVehicle(vehicleKey: any): void {
      console.log("Selected Vehicle:", vehicleKey);
      this.selectedVehicle = vehicleKey;
    }
    isSelectedVehicle(vehicleKey: any): boolean {
      return this.selectedVehicle === vehicleKey;
    }

    getObjectProperties(obj: any): { key: any, value: any }[] {
      if (obj && typeof obj === 'object') {

        return Object.keys(obj).map(key => ({ key, value: obj[key] }));
      } else {
        return [];
      }
    }
    isObject(value: any): boolean {
      return value !== null && typeof value === 'object' && !Array.isArray(value);
    }
    getSortedObjectProperties(obj: any): { key: any, value: any }[] {
      if (obj && typeof obj === 'object') {
        const keys = Object.keys(obj).sort((a, b) => {
          const aNum = a.includes('Details') ? 0 : 1;
          const bNum = b.includes('Details') ? 0 : 1;


          // If both keys contain 'Details', or both don't, compare them as strings
          if (aNum === bNum) {

            return a.localeCompare(b);
          }

          // Otherwise, prioritize the one containing 'Details'
          return aNum - bNum;
        });

        return keys.map(key => ({ key, value: obj[key] }));
      } else {
        return [];
      }
    }









  incident_payload: any
  incident_id: any
  customcreate() {
    // let :any;
    // let incident_id:number
    console.log(this.agentScriptInfo.payload)
    this.makeSerReqService.onSaveMakeSr(this.agentScriptInfo.payload).subscribe((res: any) => {
      console.log("payload is submitted")
      console.log(res);
      this.incident_payload = res
      console.log(this.agentScriptInfo.caseTypeData.casetypeid)
      // if (this.agentScriptInfo.caseTypeData.casetypeid == '211') {

        let additionalpayload = this.agentScriptInfo.additionalpayload;

        this.incident_payload.forEach((ele: any) => {
          if (ele.entityName == 'Incident')
            this.incident_id = ele.payLoad.id;
          this.makeSerReqService.incident_id = ele.payLoad.id;
        })

        if (this.agentScriptInfo.caseTypeData.casetypeid == '211') {
          console.log("211")
          additionalpayload["Addfields.incidentid"] = this.incident_id
          let payload = [
            { entityName: 'Addfields', payLoad: additionalpayload }
          ]
          console.log(payload)
            this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
              console.log("addfields payload is submitted")
              console.log(res);
            });
        }
        else if(this.agentScriptInfo.caseTypeData.casetypeid == '293'){
          console.log("293")
          additionalpayload["Cart.incidentid"] = this.incident_id
          let payload = [
            { entityName: 'Cart', payLoad: additionalpayload }
          ]
          console.log(payload)
            this.makeSerReqService.onSaveMakeSr(payload).subscribe((res: any) => {
              console.log("Cart payload is submitted")
              console.log(res);
            });
        }



        // })

      // }


    });

  }



  // userInfo: any = localStorage.getItem("userInfo");
  // userInfo1: any = localStorage.getItem("searchedPlaces");

  // private map!: google.maps.Map;
  // private markers: google.maps.Marker[] = [];
  // private infoWindows: google.maps.InfoWindow[] = [];

  // reviewForm: FormGroup;
  // popupDialogRef: MatDialogRef<PopupComponent> | undefined;
  // reviewsubmitDialogRef: MatDialogRef<PopupSubmitComponent> | undefined;
  // constructor(public dialog: MatDialog, private fb: FormBuilder) {
  //   this.userInfo = JSON.parse(this.userInfo);
  //   this.reviewForm = this.fb.group({
  //     firstname: [this.userInfo.addressDetails.firstname, [Validators.required]],
  //     lastname: [this.userInfo.addressDetails.lastname, [Validators.required]],
  //     emailaddress: [this.userInfo.addressDetails.emailaddress, [Validators.required]],
  //     phonenumber: [this.userInfo.addressDetails.phonenumber, [Validators.required]],
  //     streetaddress: [this.userInfo.addressDetails.streetaddress, [Validators.required]],
  //     city: [this.userInfo.addressDetails.city, [Validators.required]],
  //     state: [this.userInfo.addressDetails.state, [Validators.required]],
  //     county: [this.userInfo.addressDetails.county, [Validators.required]],
  //     zipcode: [this.userInfo.addressDetails.zipcode, [Validators.required]],
  //     nopotholeNumber: [this.userInfo.additionalInfoDetails.nopotholeNumber, [Validators.required]],
  //     pothole: [this.userInfo.additionalInfoDetails.pothole, [Validators.required]],
  //     potholesize: [this.userInfo.additionalInfoDetails.potholesize, [Validators.required]],
  //     locationdetails: [this.userInfo.additionalInfoDetails.locationdetails, [Validators.required]],
  //     furtherdetails: [this.userInfo.additionalInfoDetails.furtherdetails, [Validators.required]],
  //     // photo: [this.userInfo.additionalInfoDetails.photo, [Validators.required]]
  //   });

  // }
  // openPopup() {
  //   this.dialog.open(PopupComponent, {
  //     width: '400px'
  //   });
  // }
  // @Output() serviceTypeDetailsEvent = new EventEmitter();
  // gotoNext(data: object) {
  //   this.serviceTypeDetailsEvent.emit(data)
  // }
  // reviewSubmit() {
  //   this.userInfo["srID"] = Math.floor((Math.random() * 1000000000000));
  //   this.userInfo["srReportDate"] = new Date();
  //   this.userInfo["srStatus"] = 'UnResolved';
  //   let locationInfo:any = localStorage.getItem("locationInfo");
  //   locationInfo = (locationInfo)? JSON.parse(locationInfo) : "";
  //   this.userInfo["locationInfo"] = locationInfo;
  //   localStorage.setItem("submitSrDetails", JSON.stringify(this.userInfo));
  //   let userListSubmit = localStorage.getItem("userListSubmit");
  //   let ServiceTypeInfoSubmit:any = localStorage.getItem("ServiceTypeInfo");
  //   let userList = (userListSubmit)? JSON.parse(userListSubmit) : [];
  //   let usrListInfo = localStorage.getItem("userList");
  //   if(usrListInfo){
  //     userList = JSON.parse(usrListInfo);
  //   }
  //   ServiceTypeInfoSubmit = (ServiceTypeInfoSubmit)?JSON.parse(ServiceTypeInfoSubmit) : {serviceDetails: {content:"",department:"" } };
  //   if(ServiceTypeInfoSubmit.serviceDetails && ServiceTypeInfoSubmit.serviceDetails.content){
  //     this.userInfo["content"] = ServiceTypeInfoSubmit.serviceDetails.content;
  //     this.userInfo["department"] = ServiceTypeInfoSubmit.department.name;
  //   }
  //   let userAddress = [];
  //   if(this.userInfo.addressDetails){
  //     if(this.userInfo.addressDetails.streetaddress){
  //       userAddress.push(this.userInfo.addressDetails.streetaddress);
  //     }
  //     if(this.userInfo.addressDetails.city){
  //       userAddress.push(this.userInfo.addressDetails.city);
  //     }
  //     if(this.userInfo.addressDetails.state){
  //       userAddress.push(this.userInfo.addressDetails.state);
  //     }
  //     if(this.userInfo.addressDetails.county){
  //       userAddress.push(this.userInfo.addressDetails.county);
  //     }
  //     if(this.userInfo.addressDetails.zipcode){
  //       userAddress.push(this.userInfo.addressDetails.zipcode);
  //     }
  //   }
  //   this.userInfo["serviceAddress"] = userAddress.length ? userAddress.join(', '):"";
  //   this.userInfo["serviceOn"] = "Streets - Pothole in Street";
  //   userList.push(this.userInfo);

  //   // this.addMarkersForSearchedPlaces(this.map);


  //   localStorage.setItem("userListSubmit", JSON.stringify(userList));
  //   this.dialog.open(PopupSubmitComponent, {
  //     width: '400px'
  //   });
  // }
  // addMarkersForSearchedPlaces(map: google.maps.Map) {
  //   const locStorage: any = localStorage.getItem('userListSubmit');

  //   if (locStorage) {
  //     const storedPlaces: any[] = JSON.parse(locStorage);

  //     storedPlaces.forEach((place) => {
  //       if (typeof place === 'object' && place.lat && place.lon) {
  //         const lat = parseFloat(place.lat);
  //         const lon = parseFloat(place.lon);

  //         if (!isNaN(lat) && !isNaN(lon)) {
  //           const location = new google.maps.LatLng(lat, lon);
  //           const marker = new google.maps.Marker({
  //             map,
  //             position: location,
  //             title: place.address,
  //           });
  //           // //  const _self = this;
  //           //  if (this.markers.length > 0) {
  //           //   for (let i = 0; i < this.markers.length; i++) {
  //           //     this.markers[i].setMap(null);
  //           //   }
  //           //   // this.markers = [];
  //           // }

  //           const infoWindow = new google.maps.InfoWindow({
  //             content: `
  //               <div>
  //                 <strong>State:</strong> ${place.state || 'N/A'}<br>
  //                 <strong>City:</strong> ${place.city || 'N/A'}<br>
  //                 <strong>Country:</strong> ${place.country || 'N/A'}
  //               </div>
  //             `,
  //           });
  //           this.markers.push(marker);

  //           this.infoWindows.push(infoWindow);

  //           marker.addListener('click', () => {

  //             this.infoWindows.forEach((info, i) => {
  //               if (i !== this.markers.indexOf(marker)) {
  //                 info.close();
  //               }
  //             });

  //             infoWindow.open(map, marker);
  //           });
  //         }
  //       }
  //     });
  //   }
  // }
}
