import { Component, ViewChild, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormControl, FormArray, FormBuilder, Validators } from '@angular/forms'


@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss']
})
export class AdditionalInfoComponent {
  @Output() serviceTypeDetailsEvent = new EventEmitter();
  userInfo: any = localStorage.getItem("userInfo");
  additionalInfoForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.userInfo = JSON.parse(this.userInfo);
    if(!this.userInfo || !this.userInfo.additionalInfoDetails){
    this.additionalInfoForm = this.fb.group({
      pothole:['', [Validators.required]],
      nopotholeNumber:['', [Validators.required]],
      potholesize:['', [Validators.required]],
      locationdetails:['', [Validators.required]],
      furtherdetails:['', [Validators.required]],
      // photo:['', [Validators.required]],
    })
  }else{
    this.additionalInfoForm = this.fb.group({
      pothole: [this.userInfo.additionalInfoDetails.pothole, [Validators.required]],
      nopotholeNumber: [this.userInfo.additionalInfoDetails.nopotholeNumber, [Validators.required]],
      potholesize: [this.userInfo.additionalInfoDetails.potholesize, [Validators.required]],
      locationdetails: [this.userInfo.additionalInfoDetails.locationdetails, [Validators.required]],
      furtherdetails: [this.userInfo.additionalInfoDetails.furtherdetails, [Validators.required]],
      // photo: [this.userInfo.additionalInfoDetails.photo, [Validators.required]]
    })
  }
  }
  gotoNext(data: object) {
    this.userInfo["additionalInfoDetails"]= this.additionalInfoForm.getRawValue();
    localStorage.setItem("userInfo", JSON.stringify(this.userInfo))
    this.serviceTypeDetailsEvent.emit(data);
  }
}
