import { Component, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { KnowledgebaseService } from '../knowledgebase.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-create-new-sr',
  templateUrl: './create-new-sr.component.html',
  styleUrls: ['./create-new-sr.component.scss']
})

export class CreateNewSRComponent implements OnInit, OnDestroy{
  @Output() componentSelection = new EventEmitter<any>();
  @Input() componentData:any;
  servicetypeinfo:any;


  constructor(private knowledgeSearchService: KnowledgebaseService)
  {

  }
  currDiv: string = 'gridView';
  serviceTypeInfo: any = {};
  private subscription!: Subscription;
  subscriptiondata:any
  dataInfo: any = {};

  ngOnDestroy() {
    console.log("in destroy subscription")
    console.log(this.subscription)
    if (this.subscription) {
      console.log("inside if")
      this.subscription.unsubscribe();
    }
    this.knowledgeSearchService.opentab({})
  }
  ngOnInit(){



    var subscription =this.knowledgeSearchService.functionCall$.subscribe(data => {
      // Call the function you want to call here
      console.log(data)
      this.subscriptiondata=data;
      if(data.serviceDetails)
      {
        this.responseFromChild(this.subscriptiondata);
      }
      // else{

      // }
      // console.log(Object.keys(this.subscriptiondata).length)
      // if(Object.keys(this.subscriptiondata).length === 0)
      // {
      //   this.currDiv='listView'
      // }
      // // this.subscriptiondata={}
      // console.log(this.subscription)
      // subscription.unsubscribe();
    });

console.log(Object.keys(this.subscriptiondata).length === 0)



    console.log(this.subscription)


    console.log("createneweeeesrcomponentData",this.componentData);
    if(this.componentData && this.componentData.viewname == "addressView"){
      this.currDiv = this.componentData.viewname;
      this.serviceTypeInfo["serviceDetails"] = this.componentData.serviceDetails;
    }



  }

  bredCrumbChanges(data: any) {
    this.dataInfo = data;
  }
  divVal=true;
  ShowDiv() {
    this.divVal=!this.divVal
    if(this.divVal)
    this.currDiv = 'gridView'
  else
  this.currDiv = 'listView'
  }
  responseFromChild(e: any) {
    console.log(e)
    this.serviceTypeInfo = e;
    // console.log(e);

    this.dataInfo = this.serviceTypeInfo && this.serviceTypeInfo.serviceDetails ? this.serviceTypeInfo.serviceDetails : this.serviceTypeInfo;
    this.currDiv = e.viewname;
    if(this.currDiv=='listGridView')
    {
      this.ShowDiv();
    }
    if (e.viewname == 'agentscriptView' && e.isSubmit) {
      const payload = {
        "entityName": "Incident",
        "payLoad": {
          "casetype": e.serviceDetails.caseTypeData.casetypeid,
          "casesubtype": e.serviceDetails.caseTypeData.casesubtypeid ? e.serviceDetails.caseTypeData.casesubtypeid : '',
          "department": e.serviceDetails.caseTypeData.department?.id,
          "division": e.serviceDetails.caseTypeData.division?.id,
          "incidentqueue": e.serviceDetails.caseTypeData?.qid,
          ...e.serviceDetails.formScriptData,
          "contactidobject": {
            "emailalt1": e.serviceDetails.caseTypeData.contactDtls?.mailingadd,
            "lastname": e.serviceDetails.caseTypeData.contactDtls?.lastname,
            "alternateph": e.serviceDetails.caseTypeData.contactDtls?.phonenumber2,
            "firstname": e.serviceDetails.caseTypeData.contactDtls?.firstname,
            "phonenumber": e.serviceDetails.caseTypeData.contactDtls?.phonenumber1,
            "email": e.serviceDetails.caseTypeData.contactDtls?.emailaddress,
            "title": "1",
          },
          "addfields":{
            ...e.serviceDetails.addfields
          },
          "cart":{
            ...e.serviceDetails.cart
          },
          "tab":{
            "tab_id":$(".custom-tabs li.active").find("a").attr('data-obj-id')
          },

          "incidentstatus": 1,
          "servicerequestsource": 1356
        }
      }
      // console.log(payload);
      // console.log($(".custom-tabs li.active").find("a").attr('data-obj-id'))
      // console.log(($(".custom-tabs li.active").find("a").attr('data-obj-id').slice(15,$(".custom-tabs li.active").find("a").attr('data-obj-id').length)))
      // this.componentSelection.emit({ id: "incident-new"+$(".custom-tabs li.active").find("a").attr('data-obj-id'), name: 'Incident-new '+($(".custom-tabs li.active").find("a").attr('data-obj-id').slice(15,$(".custom-tabs li.active").find("a").attr('data-obj-id').length)), type: 'object', entityName: 'Incident', newSr: payload, department: e.serviceDetails.caseTypeData.department.name })
      this.componentSelection.emit({ id: "incident-new", name: 'Incident-new', type: 'object', entityName: 'Incident', newSr: payload, department: e.serviceDetails.caseTypeData.department.name })
      this.servicetypeinfo={ id: "incident-new", name: 'Incident-new', type: 'object', entityName: 'Incident', newSr: payload, department: e.serviceDetails.caseTypeData.department.name }
      // localStorage.setItem('servicetypeinfo',JSON.stringify(this.servicetypeinfo))

    }
  }
  gotoMain() {
    this.currDiv = 'gridView';
  }
}
