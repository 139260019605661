<div class="px-2 ">
  <div class="stepper hide-mobile border-radius-20 bg-white mx-2 px-3 py-2 mt-2 mb-3">

    <div class="d-flex align-items-center" style="margin-bottom: 16px;">
      <h3 class="mb-0 me-1">New Service Request (SR): </h3>
      <span class="text-active fw-500"> <span
          style="font-size: 1.2rem;">{{this.serviceTypeInfo.serviceDetails.content}}</span>&nbsp;</span>({{serviceTypeInfo.serviceDetails.division.name}}/{{serviceTypeInfo.serviceDetails.department.name}})&nbsp;&nbsp;<span
        style="cursor:pointer;font-size:12px"
        (click)="gotoNext({viewname:this.serviceTypeInfo.redirectTo?this.serviceTypeInfo.redirectTo:'gridView','serviceDetails':serviceTypeInfo.serviceDetails},0)"
        class="text-active fw-500"><i class="bi bi-pencil"></i>Change</span>
    </div>
    <div class="my-2 p-1 ol justify-content-between">
      <div class='success li'>

        <i class="bi bi-check-circle-fill text-white" style="font-size: 18px;"></i>
        <h2>1. Select a Service Type</h2>



      </div>
      <div class="success li">

        <i class="bi bi-check-circle-fill text-white" style="font-size: 18px;"></i>
        <h2>2. Provide Contact & Address Details</h2>

      </div>
      <div class="li" [ngClass]="{'success':!showscript,'active':showscript}">
        <i *ngIf="!showscript" class="bi bi-check-circle-fill text-white" style="font-size: 18px;"></i>

        <svg *ngIf="showscript" xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 24"
          fill="none">
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M1.19753 20.4147C1.87185 22.8401 3.82122 23.3575 6.08615 22.7254C7.66151 22.2857 9.30152 21.3385 10.6363 20.3758C11.6139 19.6708 12.7824 18.9731 13.9408 18.6212C14.9762 18.3067 16.2033 18.2098 16.7197 19.3675C16.9046 19.7823 16.9722 20.2664 16.9904 20.7168C17.0001 20.9565 17.202 21.144 17.442 21.1346C17.6814 21.1252 17.8692 20.923 17.8595 20.6834C17.7536 18.0687 16.2001 17.0273 13.689 17.79C12.4393 18.1697 11.1839 18.9096 10.1291 19.6703C8.87535 20.5745 7.33491 21.4737 5.85561 21.8866C4.69209 22.2113 3.2963 22.3072 2.50744 21.2135C2.2857 20.906 2.13717 20.5473 2.03614 20.1836C1.97193 19.9524 1.73244 19.816 1.50095 19.8799C1.27 19.9438 1.13323 20.1835 1.19753 20.4147ZM16.6978 1.18953C16.9138 0.973497 17.243 0.973142 17.4589 1.18931C17.6749 1.40547 19.1978 2.93009 19.1978 2.93009C19.4136 3.14611 19.4137 3.28839 19.1978 3.50433C18.9818 3.72027 5.028 17.674 5.028 17.674C4.84624 17.8094 2.33891 18.8409 2.05425 18.9582C1.6017 19.1448 1.14624 18.6927 1.3295 18.2388C1.50665 17.8 2.42507 15.5121 2.6102 15.2769L16.6978 1.18953Z"
            fill="#275A8A" stroke="#275A8A" />
        </svg>

        <h2>3. Provide Additional Information</h2>


      </div>

      <div class="li" [ngClass]="{'success':submitclicked,'active':(!showscript && !submitclicked)}">
        <i *ngIf="submitclicked" class="bi bi-check-circle-fill text-white" style="font-size: 18px;"></i>
        <svg *ngIf="!submitclicked" xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 25"
          fill="none">
          <path
            d="M15.1435 9.42638L4.06655 0.700245C3.74826 0.432636 3.35888 0.26359 2.94604 0.213782C2.53319 0.163975 2.11478 0.235565 1.74198 0.419797C1.21133 0.726018 0.775821 1.17318 0.483714 1.71173C0.191608 2.25028 0.0543107 2.85919 0.087037 3.47098V20.9218C0.0542249 21.5336 0.191485 22.1425 0.4836 22.6811C0.775715 23.2196 1.21127 23.6668 1.74198 23.9729C2.03553 24.1186 2.35837 24.1955 2.68606 24.1979C3.19099 24.1915 3.6788 24.0139 4.06955 23.694L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7204C16.2706 13.2403 16.3696 12.7196 16.3515 12.1964C16.3692 11.6738 16.27 11.1539 16.061 10.6747C15.8519 10.1954 15.5385 9.76895 15.1435 9.42638Z"
            fill="#275A8A" />
          <path fill-rule="evenodd" clip-rule="evenodd"
            d="M16.3229 8.06656L16.2908 8.03872L11.3405 4.13899V3.4707C11.3078 2.85848 11.4455 2.24918 11.7383 1.71053C12.0311 1.17188 12.4676 0.724984 12.9992 0.419516C13.372 0.235474 13.7904 0.163989 14.2032 0.213791C14.616 0.263594 15.0054 0.432529 15.3237 0.699964L26.3992 9.42535C26.7945 9.76789 27.1081 10.1945 27.3173 10.6739C27.5264 11.1533 27.6257 11.6734 27.608 12.1961C27.6258 12.7188 27.5264 13.2388 27.3171 13.7181C27.1078 14.1974 26.7939 14.6238 26.3984 14.9661L15.3237 23.6945C14.9331 24.014 14.4456 24.1916 13.941 24.1984C13.6131 24.1958 13.2901 24.1188 12.9962 23.9734C12.4654 23.6671 12.0297 23.2198 11.7375 22.6812C11.4453 22.1425 11.3079 21.5334 11.3405 20.9215V20.258L16.2911 16.3583L16.3235 16.3302C16.9215 15.8111 17.3958 15.1649 17.7119 14.4389C18.0198 13.7318 18.17 12.9663 18.1523 12.1957C18.1694 11.4259 18.0189 10.6614 17.7109 9.95513C17.3947 9.23007 16.9205 8.58483 16.3229 8.06656ZM11.3405 17.9666L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7203C16.2706 13.2403 16.3697 12.7196 16.3516 12.1964C16.3693 11.6738 16.27 11.1539 16.061 10.6747C15.852 10.1954 15.5385 9.76895 15.1435 9.42638L11.3405 6.43043V17.9666Z"
            fill="#275A8A" />
        </svg>
        <h2 class="pe-2">4. Review & Submit Request</h2>


      </div>

    </div>
  </div>



  <!-- <div class="stepper hide-mobile ">
    <div class="mt-2 mb-0 p-1 ol justify-content-between">
      <div class='success li'>

        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="18" viewBox="0 0 17 18" fill="none">
          <path
            d="M8.49772 12.5423C10.4537 12.5423 12.0394 10.9567 12.0394 9.00065C12.0394 7.04464 10.4537 5.45898 8.49772 5.45898C6.54171 5.45898 4.95605 7.04464 4.95605 9.00065C4.95605 10.9567 6.54171 12.5423 8.49772 12.5423Z"
            fill="#275A8A" />
          <path
            d="M16.2907 8.29167H15.5462C15.2133 4.95046 12.5486 2.28571 9.20736 1.95279V1.20833C9.20736 1.02047 9.13273 0.840304 8.99989 0.707466C8.86705 0.574628 8.68688 0.5 8.49902 0.5C8.31116 0.5 8.13099 0.574628 7.99816 0.707466C7.86532 0.840304 7.79069 1.02047 7.79069 1.20833V1.95279C4.44948 2.28571 1.78473 4.95046 1.45182 8.29167H0.707357C0.519495 8.29167 0.339328 8.36629 0.206489 8.49913C0.0736512 8.63197 -0.000976562 8.81214 -0.000976562 9C-0.000976562 9.18786 0.0736512 9.36803 0.206489 9.50087C0.339328 9.63371 0.519495 9.70833 0.707357 9.70833H1.45182C1.78544 13.0495 4.44948 15.7143 7.79069 16.0472V16.7917C7.79069 16.9795 7.86532 17.1597 7.99816 17.2925C8.13099 17.4254 8.31116 17.5 8.49902 17.5C8.68688 17.5 8.86705 17.4254 8.99989 17.2925C9.13273 17.1597 9.20736 16.9795 9.20736 16.7917V16.0472C12.5486 15.7136 15.2133 13.0495 15.5462 9.70833H16.2907C16.4786 9.70833 16.6587 9.63371 16.7916 9.50087C16.9244 9.36803 16.999 9.18786 16.999 9C16.999 8.81214 16.9244 8.63197 16.7916 8.49913C16.6587 8.36629 16.4786 8.29167 16.2907 8.29167ZM9.19744 14.6192C9.17194 14.2516 8.87373 13.9583 8.49902 13.9583C8.12432 13.9583 7.82611 14.2516 7.80061 14.6192C6.55052 14.4621 5.38833 13.8932 4.49743 13.0023C3.60653 12.1114 3.03764 10.9492 2.88052 9.69913C3.24815 9.67292 3.54069 9.374 3.54069 9C3.54069 8.626 3.24815 8.32708 2.87981 8.30158C3.03693 7.05149 3.60582 5.88931 4.49672 4.99841C5.38762 4.10751 6.54981 3.53862 7.7999 3.3815C7.82611 3.74842 8.12432 4.04167 8.49902 4.04167C8.87373 4.04167 9.17194 3.74842 9.19744 3.38079C10.4475 3.53791 11.6097 4.1068 12.5006 4.9977C13.3915 5.8886 13.9604 7.05079 14.1175 8.30087C13.7499 8.32708 13.4574 8.626 13.4574 9C13.4574 9.374 13.7499 9.67292 14.1182 9.69842C13.9609 10.9486 13.3918 12.1108 12.5008 13.0018C11.6099 13.8928 10.4476 14.4619 9.19744 14.6192Z"
            fill="#275A8A" />
        </svg>
        <h2>1. Select a Service Type</h2>



      </div>
      <div class="success li">

        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 29" fill="none">
          <path d="M19.7096 2.80784C18.3893 1.72674 16.8454 0.952135 15.1895 0.539906C13.5335 0.127678 11.8067 0.0880968 10.1336 0.424018C7.98235 0.86507 6.00322 1.91521 4.43196 3.44935C2.8607 4.9835 1.76355 6.93697 1.27121 9.07707C0.778868 11.2172 0.912083 13.4537 1.65499 15.5202C2.39789 17.5868 3.71917 19.3962 5.46143 20.733C7.45061 22.1888 9.14426 24.0103 10.4518 26.1L11.3427 27.5815C11.4559 27.7697 11.6158 27.9254 11.8069 28.0335C11.998 28.1416 12.2139 28.1984 12.4334 28.1984C12.653 28.1984 12.8688 28.1416 13.06 28.0335C13.2511 27.9254 13.411 27.7697 13.5242 27.5815L14.3782 26.1586C15.5168 24.1571 17.0693 22.4215 18.932 21.0677C20.3926 20.0628 21.5997 18.7322 22.458 17.1809C23.3163 15.6297 23.8024 13.9002 23.878 12.1289C23.9535 10.3576 23.6164 8.59299 22.8932 6.97428C22.1701 5.35558 21.0806 3.92708 19.7109 2.80148L19.7096 2.80784ZM12.4322 16.7455C11.4253 16.7455 10.441 16.4469 9.6038 15.8875C8.7666 15.3281 8.11409 14.533 7.72877 13.6028C7.34345 12.6725 7.24263 11.6489 7.43907 10.6614C7.6355 9.67385 8.12036 8.76673 8.83234 8.05476C9.54432 7.34278 10.4514 6.85792 11.439 6.66148C12.4265 6.46505 13.4501 6.56587 14.3804 6.95118C15.3106 7.3365 16.1057 7.98902 16.6651 8.82622C17.2245 9.66341 17.5231 10.6477 17.5231 11.6546C17.5231 13.0048 16.9867 14.2997 16.032 15.2544C15.0773 16.2091 13.7824 16.7455 12.4322 16.7455Z" fill="#275A8A"/>
          </svg>
          <h2>2. Provide Contact & Address Details</h2>


      </div>
      <div class=" li" [ngClass]="{'success':!showscript,'active':showscript}">

          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="20" viewBox="0 0 20 24" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd"
              d="M1.19753 20.4147C1.87185 22.8401 3.82122 23.3575 6.08615 22.7254C7.66151 22.2857 9.30152 21.3385 10.6363 20.3758C11.6139 19.6708 12.7824 18.9731 13.9408 18.6212C14.9762 18.3067 16.2033 18.2098 16.7197 19.3675C16.9046 19.7823 16.9722 20.2664 16.9904 20.7168C17.0001 20.9565 17.202 21.144 17.442 21.1346C17.6814 21.1252 17.8692 20.923 17.8595 20.6834C17.7536 18.0687 16.2001 17.0273 13.689 17.79C12.4393 18.1697 11.1839 18.9096 10.1291 19.6703C8.87535 20.5745 7.33491 21.4737 5.85561 21.8866C4.69209 22.2113 3.2963 22.3072 2.50744 21.2135C2.2857 20.906 2.13717 20.5473 2.03614 20.1836C1.97193 19.9524 1.73244 19.816 1.50095 19.8799C1.27 19.9438 1.13323 20.1835 1.19753 20.4147ZM16.6978 1.18953C16.9138 0.973497 17.243 0.973142 17.4589 1.18931C17.6749 1.40547 19.1978 2.93009 19.1978 2.93009C19.4136 3.14611 19.4137 3.28839 19.1978 3.50433C18.9818 3.72027 5.028 17.674 5.028 17.674C4.84624 17.8094 2.33891 18.8409 2.05425 18.9582C1.6017 19.1448 1.14624 18.6927 1.3295 18.2388C1.50665 17.8 2.42507 15.5121 2.6102 15.2769L16.6978 1.18953Z"
              fill="#275A8A" stroke="#275A8A" />
          </svg>
          <h2>3. Provide Additional Information</h2>


      </div>

      <div class="li"  [ngClass]="{'active':!showscript}">
          <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 28 25" fill="none">
            <path d="M15.1435 9.42638L4.06655 0.700245C3.74826 0.432636 3.35888 0.26359 2.94604 0.213782C2.53319 0.163975 2.11478 0.235565 1.74198 0.419797C1.21133 0.726018 0.775821 1.17318 0.483714 1.71173C0.191608 2.25028 0.0543107 2.85919 0.087037 3.47098V20.9218C0.0542249 21.5336 0.191485 22.1425 0.4836 22.6811C0.775715 23.2196 1.21127 23.6668 1.74198 23.9729C2.03553 24.1186 2.35837 24.1955 2.68606 24.1979C3.19099 24.1915 3.6788 24.0139 4.06955 23.694L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7204C16.2706 13.2403 16.3696 12.7196 16.3515 12.1964C16.3692 11.6738 16.27 11.1539 16.061 10.6747C15.8519 10.1954 15.5385 9.76895 15.1435 9.42638Z" fill="#275A8A"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M16.3229 8.06656L16.2908 8.03872L11.3405 4.13899V3.4707C11.3078 2.85848 11.4455 2.24918 11.7383 1.71053C12.0311 1.17188 12.4676 0.724984 12.9992 0.419516C13.372 0.235474 13.7904 0.163989 14.2032 0.213791C14.616 0.263594 15.0054 0.432529 15.3237 0.699964L26.3992 9.42535C26.7945 9.76789 27.1081 10.1945 27.3173 10.6739C27.5264 11.1533 27.6257 11.6734 27.608 12.1961C27.6258 12.7188 27.5264 13.2388 27.3171 13.7181C27.1078 14.1974 26.7939 14.6238 26.3984 14.9661L15.3237 23.6945C14.9331 24.014 14.4456 24.1916 13.941 24.1984C13.6131 24.1958 13.2901 24.1188 12.9962 23.9734C12.4654 23.6671 12.0297 23.2198 11.7375 22.6812C11.4453 22.1425 11.3079 21.5334 11.3405 20.9215V20.258L16.2911 16.3583L16.3235 16.3302C16.9215 15.8111 17.3958 15.1649 17.7119 14.4389C18.0198 13.7318 18.17 12.9663 18.1523 12.1957C18.1694 11.4259 18.0189 10.6614 17.7109 9.95513C17.3947 9.23007 16.9205 8.58483 16.3229 8.06656ZM11.3405 17.9666L15.1435 14.9709C15.5389 14.6276 15.8526 14.2004 16.0616 13.7203C16.2706 13.2403 16.3697 12.7196 16.3516 12.1964C16.3693 11.6738 16.27 11.1539 16.061 10.6747C15.852 10.1954 15.5385 9.76895 15.1435 9.42638L11.3405 6.43043V17.9666Z" fill="#275A8A"/>
            </svg>
          <h2 class="pe-2">4. Review & Submit Request</h2>


      </div>

    </div>
  </div> -->
  <div class="stepper show-mobile mb-3">
    <div class="d-flex align-items-center flex-column" style="margin-bottom: 16px;">
      <h3 class="mb-0 me-1">New Service Request (SR): </h3>
      <span class="text-active fw-500"> <span
          style="font-size: 1.2rem;">{{this.serviceTypeInfo.serviceDetails.content}}</span>&nbsp;</span>({{serviceTypeInfo.serviceDetails.division.name}}/{{serviceTypeInfo.serviceDetails.department.name}})&nbsp;&nbsp;<span
        style="cursor:pointer;font-size:12px"
        (click)="gotoNext({viewname:this.serviceTypeInfo.redirectTo?this.serviceTypeInfo.redirectTo:'gridView','serviceDetails':serviceTypeInfo.serviceDetails},0)"
        class="text-active fw-500"><i class="bi bi-pencil"></i>Change</span>
    </div>
    <div class="row">
      <div class="col-md-12">
        <ul class="progressbar">
          <li class="success">Contact</li>
          <li class="success">Address</li>
          <li class="active">Script</li>
          <li>Review</li>
        </ul>
      </div>
    </div>
  </div>


  <div class=" px-2">
    <div class="row">
      <!-- <h1 class="text-center m-0 p-2">CASE TYPE: {{this.agentScriptInfo.content}}</h1> -->
    </div>
    <div [ngStyle]="{'display': showscript ? 'block' : 'none'}" class="panel-body">
      <div id="rn_newSR_v2_1" class="rn_newSR_v2">
        <div id="rn_newSR_1" class="rn_newSR">


          <form (ngSubmit)="onSubmit(f)" #f="ngForm" class="text-center row m-0 pt-2 bg-theme2 agent-script"
            name="myForm">
            <label *ngIf="formSubmitted && countInvalidFields(f) > 0"
              class="alert alert-danger d-flex justify-content-center w-73  m-auto">
              {{ countInvalidFields(f) }} field{{ countInvalidFields(f) > 1 ? 's' : '' }}
              need{{ countInvalidFields(f) > 1 ? '' : 's' }} to be selected/filled up.
              They have been highlighted on the page.
            </label>

            <div class=" m-auto" [ngClass]="{'w-75':colwidth == '','col-md-4 pe-0':colwidth == '2'}">
              <div class="box-agent-script">
<div *ngIf="this.serviceTypeInfo.serviceDetails.casetypeid==367 && this.serviceTypeInfo.serviceDetails.locationDtls?.next_collection_day" class="alert alert-warning mx-2">
  <i class="fa fa-warning" ></i>
  <span class="text-dark fw-bold" [innerHTML]="this.serviceTypeInfo.serviceDetails.locationDtls?.next_collection_day"></span>
</div>

                <ng-container *ngFor="let node of agentScriptArray" [ngTemplateOutlet]="treeNode"
                  [ngTemplateOutletContext]="{ $implicit: node }">
                </ng-container>

                <ng-template #treeNode let-data>
                  <div *ngIf="data.visible" class="row-agent">
                    <div class="form-inline  row">

                      <div *ngIf=" !data.ischild" class="col-md-6 p-0 text-md-end text-sm-start ">
                        <span
                          [ngClass]="{
                          'error': formSubmitted && f && f.controls[data.colName] && f.controls[data.colName].touched && !f.controls[data.colName].valid
                        || (formSubmitted  && data.visible==false  && data.type === 'checkbox' && !areAnyCheckboxesChecked(data.options))}">
                          {{ data.question }}
                        </span><span *ngIf="data.question" class="red">*</span>
                      </div>

                      <div class="col-md-6 text-left d-flex flex-wrap" *ngIf="data.type == 'checkbox'">
                        <div class="col-md-4 rob-check" *ngFor="let opt of data.options">
                          <div class="checkbox">
                            <label
                              [ngClass]="{ 'error-text': formSubmitted && selectControl.touched && !selectControl.valid}">
                              <input type="checkbox" id="open_cb7" [name]="data.colName" [(ngModel)]="opt.checked"
                                (change)="onChange($event, data)" [required]="!areAnyCheckboxesChecked(data.options)"
                                #selectControl="ngModel">
                              {{opt.label}}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div *ngIf="data.type == 'picklist' && !data.ischild && !data.extra && !data.extraa"
                        class="col-md-6">
                        <select class=" form-select" (change)="onChange($event, data)" [name]="data.colName"
                          [(ngModel)]="data.value" required #selectControl="ngModel">
                          <option value="">Select One</option>
                          <option *ngFor="let opt of data.options" [value]="opt.valueen">
                            {{ opt.label }}
                          </option>

                        </select>
                      </div>
                      <div *ngIf="data.type == 'radio'" class="col-md-6 text-left d-flex flex-wrap">
                        <div *ngFor="let opt of data.options; let i = index" class="col-md-4 col-4 ">
                          <label
                            [ngClass]="{ 'error-text': formSubmitted && selectControl.touched && !selectControl.valid}"
                            class="form-check d-flex gap-1">
                            <input class="form-check-input" type="radio" [value]="opt.valueen" [name]="data.colName"
                              [(ngModel)]="data.value" (change)="onChange(opt.valueen,data)" required
                              #selectControl="ngModel" id="Blue">
                            <span class="form-check-label"> {{ opt.label }}</span>
                          </label>
                        </div>
                      </div>



                      <div *ngIf="data.type == 'text'  && !data.ischild " class="col-md-6">
                        <input type="text" class="form-control" [name]="data.colName" [(ngModel)]="data.value" required
                          #selectControl="ngModel" [ngClass]="{
                            'has-error': formSubmitted && selectControl.touched && !selectControl.valid}">
                      </div>

                      <div class="col-md-6" *ngIf="data.type == 'textarea'">
                        <textarea [name]="data.colName" class=" form-control w-100" [(ngModel)]="data.value" required
                          #selectControl="ngModel"
                          [ngClass]="{'has-error': formSubmitted && selectControl.touched && !selectControl.valid}">
                           </textarea>
                      </div>

                      <div class="col-md-6" *ngIf="data.type == 'multiitems'">

                        <select class="form-select" required #multiitem [name]="data.colName" [(ngModel)]="data.value"
                          (change)="onChange($event, data); createForms(multiitem.value)" #selectControl="ngModel"
                          [ngClass]="{
                            'has-error': formSubmitted && selectControl.touched && !selectControl.valid}"
                         >
                          <option value="" disabled>Select One</option>
                          <option *ngFor="let opt of data.options" [value]="opt.valueen"  >
                            {{ opt.label }}
                          </option>
                        </select>


                      </div>
                    </div>
                    <div *ngIf="data.type == 'note' && !data.ischild && !data.extra" class="col-md-12">
                      <div [class]="data.color" [innerHTML]="data.note" class="m-0"></div>

                    </div>



                    <div class="row m-0  box-notes" *ngIf="data.type == 'multinote'" >
                      <div class=""><b>NOTE:</b></div>

                      <ul *ngFor="let opt of data.options">
                        <li [class]="opt.color" [innerHTML]="opt.note"></li>
                      </ul>

                    </div>








                    <div *ngIf="data.type == 'multifields'" class="w-100">

                      <div>
                        <div *ngFor="let formData of formDataArray; let i = index">
                          <p class="alert-info  w-100 text-center h4">{{object_heading}} {{i+1}}</p>
                          <div *ngFor="let opt of data.subq; let j = index" class="secMaintenance">
                            <div class=" row m-0">

                              <div class="row m-0 " *ngIf="opt.type === 'text'">
                                <div class="d-flex justify-content-end p-0">
                                <div class=" col-md-6 text-end">

                                  <span  *ngIf="!opt.extra" class="my-2 line-h-50" [ngClass]="{
                                    'error-text': formSubmitted && selectControl.touched && !selectControl.valid}">{{ opt.question }}</span>
                                  <span *ngIf="opt.question && !opt.extra" class="red ">*</span>

                                </div>
                                <div class="col-md-6 rob-box" >
                                <input #selectControl="ngModel" [(ngModel)]="formData['rv_' + i + '_' + opt.colName]" class="form-control "
                                [ngClass]="{'has-error': formSubmitted && selectControl.touched && !selectControl.valid}" [name]="'rv_' + i + '_' + opt.colName" type="text" required />
                                </div>
                                </div>
                              </div>

                              <div class="row m-0 d-flex justify-content-end p-0" *ngIf="opt.type === 'picklist' && !opt.extra && opt.visible">
                                <div class=" col-md-6 text-end" style="padding-right: 12px;">

                                  <span [ngClass]="{
                                    'error-text': formSubmitted && selectControl.touched && !selectControl.valid}"  *ngIf="!opt.extra" class="my-2 line-h-50">{{ opt.question }}</span>
                                  <span *ngIf="opt.question && !opt.extra" class="red ">*</span>

                                </div>
                                <div class="col-md-6" style="padding-left: 12px;">
                                <select [ngClass]="{'has-error': formSubmitted && selectControl.touched && !selectControl.valid}"  class=" form-select" [name]="opt.colName"
                                  (change)="onmultiChange($event, data,i,opt.id);"
                                  [(ngModel)]="formData['rv_' + i + '_' + opt.colName]"  #selectControl="ngModel"
                                  [name]="'rv_' + i + '_' + opt.colName" required>
                                  <option value="">Select One</option>
                                  <option *ngFor="let x of opt.options" [value]="x.valueen">
                                    {{ x.label }}
                                  </option>
                                </select>
                                </div>

                              </div>


                              <div class="row m-0" *ngIf="checkArray(opt.id,i)">



                                <div *ngIf="opt.type === 'multipicklist'" class="d-flex justify-content-end p-0  cartM">

                                  <div class="col-md-6 text-end" style="padding-right: 12px;">
                                    <span class="line-h-50" [ngClass]="{
                                      'error-text': formSubmitted && selectControl.touched && !selectControl.valid}">{{opt.question }}</span>
                                    <span *ngIf="opt.question" class="red my-2">*</span>
                                  </div>
                                  <div class="col-md-6" style="padding-left: 12px;">
                                    <select class=" form-select " [name]="opt.colName"
                                      (change)="onmultiChange($event, data,i,opt.id);"
                                      [(ngModel)]="formData['rv_' + i + '_' + opt.colName]"
                                      [name]="'rv_' + i + '_' + opt.colName" #selectControl="ngModel" required [ngClass]="{'has-error': formSubmitted && selectControl.touched && !selectControl.valid}">
                                      <option value="">Select One</option>
                                      <option *ngFor="let x of opt.options" [value]="x.valueen">
                                        {{ x.label }}
                                      </option>
                                    </select>
                                  </div>


                                </div>

                                <!-- <div class=" col-md-6 pe-0">
4
                                    <select class=" form-select my-2" [name]="opt.colName"
                                      (change)="onmultiChange($event, data,i,opt.id);"
                                      [(ngModel)]="formData['rv_' + i + '_' + opt.colName]"
                                      [name]="'rv_' + i + '_' + opt.colName" required>
                                      <option value="" disabled >Select One</option>
                                      <option *ngFor="let x of opt.options" [value]="x.valueen">
                                        {{ x.label }}
                                      </option>
                                    </select>
                                  </div> -->
                              </div>
                            </div>
                            <!-- <div class=" row seop" *ngIf="opt.type === 'multipicklist' && opt.extra">

                                                                    </div> -->



                            <!-- [(ngModel)]="initialValues"
                                                                (ngModelChange)="getBoolById($event)" -->


                            <div *ngIf="opt.type == 'boolean'">
                              <input class="form-check-input form-control " [name]="'rv_' + i + '_' + opt.colName"
                                type="hidden" required />
                            </div>

                            <div class="row m-0 " *ngIf="opt.type === 'multitext'">
                              <div class="d-flex justify-content-end p-0" *ngIf="checkArray(opt.id,i)" >
                                <div *ngIf="opt.extra" class="col-md-6 text-end" style="padding-right: 12px;">
                                  <span class="line-h-50" [ngClass]="{
                                    'error-text': formSubmitted && selectControl.touched && !selectControl.valid}">{{opt.multiquestion }}</span>
                                  <span *ngIf="opt.question" class="red my-2">*</span>
                                </div>
                                <div class="col-md-6" style="padding-left: 12px;">
                                  <input #selectControl="ngModel" [ngClass]="{'has-error': formSubmitted && selectControl.touched && !selectControl.valid}" [(ngModel)]="formData['rv_' + i + '_' + opt.colName]" class=" form-control "
                                    [name]="'rv_' + i + '_' + opt.colName" type="text" required />
                                </div>
                              </div>
                            </div>







                            <!-- &&  noteVisibleArray[i] && opt.extra checkArray(opt.id,i) -->
                            <div *ngIf="opt.type == 'note' && !opt.extra">
                              <div *ngIf="checkArray(opt.id,i) ">
                                <div class="row w_note-50">
                                  <div class="col-md-12 ">
                                    <div [class]="opt.color" [innerHTML]="opt.note"></div>
                                  </div>
                                </div>
                              </div>
                            </div>


                            <div *ngIf="opt.type == 'note' && opt.extra">
                              <div *ngIf="checkArray(opt.id,i) ">
                                <div class="row w_note-50">
                                  <div class="col-md-12 ">
                                    <div [class]="opt.color" [innerHTML]="opt.note"></div>
                                  </div>

                                </div>
                              </div>
                            </div>


                          </div>
                        </div>

                      </div>

                    </div>
                    <!-- end of code -->




                  </div>

                  <ng-container *ngIf="data.subq">
                    <div class=" d-none">
                      <ng-container *ngFor="let child of data.subq" [ngTemplateOutlet]="treeNode"
                        [ngTemplateOutletContext]="{ $implicit: child }">
                      </ng-container>
                    </div>
                  </ng-container>
                </ng-template>

              </div>
            </div>

            <!-- <div *ngIf="colwidth" class="float-left" [ngClass]="{'w-100':colwidth == '','col-md-8':colwidth == '2'}">
              <div class="bg-white p-2 text-left">
                <app-knowledgebase [kbSearch]="faqkeyword">

                </app-knowledgebase>
              </div>
            </div> -->


            <div class="row " id="w_further_details">
              <div class="col-sm-12 mt-0">
                <div class="form-group text-center action-from">
                  <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto me-3"
                    (click)="gotoNext({redirectTo:serviceTypeInfo.redirectTo,viewname:'addressView','serviceDetails':serviceTypeInfo.serviceDetails},0)"><i
                      class="bi bi-arrow-left"></i> Back</button>

                  <button *ngIf="shouldHideContinueButton && enablesubmitbutton()" class="btn btn-primary ms-auto ml-2"
                    type="submit">
                    Next <i class="bi bi-arrow-right"></i></button>

                </div>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

    <div *ngIf="!showscript">




      <div class="col-sm-12 p-0">
        <div id="printMe" class="rn_serviceRequestConfirmation ">

          <div class="row ">
            <div class="col-sm-12">


              <div class="accordion" id="accordionPanelsStayOpenExample">
                <div class="accordion-item position-relative">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne">
                      <img src="../../assets/images/contact-details.png"> Contact Details
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne">
                    <form [formGroup]="contactForm">

                      <div class="accordion-body">
                        <i class="fas fa-edit edit-accord" *ngIf="serviceTypeInfo.serviceDetails.department.id !== 199"
                          (click)="toggleEditMode()"></i>

                        <div class="row">

                          <div class="col-md-3">
                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="text" class="did-floating-input" formControlName="firstname"
                                [value]="caseTypeData.contactDtls?.firstname" [disabled]="!editMode"
                                [class.disabled-cursor]="!editMode" [readonly]="!editMode"
                                (input)="updateContactPayload('Contact.firstname', $event.target.value)" required
                                autocomplete="new-password">
                              <label for="" class="did-floating-label">First Name</label>
                              <!-- Validation message -->
                              <div
                                *ngIf="contactForm.get('firstname').invalid && (contactForm.get('firstname').dirty || contactForm.get('firstname').touched)"
                                class="text-danger">
                                <div *ngIf="contactForm.get('firstname').errors.required">First name is required.</div>
                              </div>
                            </div>
                          </div>


                          <div class="col-md-3">

                            <div class="did-floating-label-content   ps-0 mb-3">
                              <input type="text" class="did-floating-input" formControlName="lastname"
                                [value]="caseTypeData.contactDtls?.lastname" [disabled]="!editMode"
                                [class.disabled-cursor]="!editMode" [readonly]="!editMode"
                                (input)="updateContactPayload('Contact.lastname', $event.target.value)" required
                                autocomplete="new-password">
                              <label for="" class="did-floating-label">Last Name</label>
                              <!-- Validation message -->
                              <div
                                *ngIf="contactForm.get('lastname').invalid && (contactForm.get('lastname').dirty || contactForm.get('lastname').touched)"
                                class="text-danger">
                                <div *ngIf="contactForm.get('lastname').errors.required">Last name is required.</div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">

                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="email" class="did-floating-input" formControlName="email"
                                [value]="caseTypeData.contactDtls?.emailaddress" [disabled]="!editMode"
                                [class.disabled-cursor]="!editMode" [readonly]="!editMode"
                                (input)="updateContactPayload('Contact.email', $event.target.value)" required
                                autocomplete="new-password">
                              <label for="" class="did-floating-label">Email ID</label>
                              <!-- Validation message -->
                              <div
                                *ngIf="contactForm.get('email').invalid && (contactForm.get('email').dirty || contactForm.get('email').touched)"
                                class="text-danger">
                                <div *ngIf="contactForm.get('email').errors.required">Email is required.</div>
                                <div *ngIf="contactForm.get('email').errors.invalidEmailFormat">Invalid email format.
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-3">

                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="tel" class="did-floating-input" formControlName="phonenumber"
                                [value]="caseTypeData.contactDtls?.phonenumber1" [disabled]="!editMode"
                                [class.disabled-cursor]="!editMode" [readonly]="!editMode"
                                (input)="updateContactPayload('Contact.phonenumber', $event.target.value)" appPhoneMask
                                minlength="14" maxlength="14" autocomplete="new-password">
                              <label for="" class="did-floating-label">Phone</label>
                              <!-- Validation message -->
                              <!-- <div *ngIf="contactForm.get('phonenumber').invalid && (contactForm.get('phonenumber').dirty || contactForm.get('phonenumber').touched)"
                              class="text-danger">
                              <div *ngIf="contactForm.get('phonenumber').errors.required">Phone number is required.</div>
                            </div> -->
                              <div *ngIf="contactForm.get('phonenumber').invalid &&
                            contactForm.get('phonenumber').touched" class="text-danger">
                                Invalid Phone number
                              </div>
                            </div>

                          </div>
                        </div>


                      </div>
                    </form>
                  </div>
                </div>

                <div class="accordion-item position-relative">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne">
                      <img src="../../assets/images/service-address.png"> Address Details
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne">
                    <form [formGroup]="addressinformation">
                      <div class="accordion-body">
                        <i class="fas fa-edit edit-accord" (click)="toggleEditaddressmode()"></i>
                        <div class="row">
                          <div class="col-md-3">
                            <div class="did-floating-label-content ps-0 mb-3" ngbDropdown>
                              <input type="text" class="form-control did-floating-input " formControlName="addressline1"
                                (keyup)="suggestionSearch($event.target.value)"
                                [value]="caseTypeData.locationDtls?.addressline1" [disabled]="!addresseditmode"
                                [class.disabled-cursor]="!addresseditmode" [readonly]="!addresseditmode"
                                id="addresseditmode" ngbDropdownToggle autocomplete="new-password">
                              <label for="street" class="did-floating-label">Street Address</label>

                              <div ngbDropdownMenu aria-labelledby="addresseditmode" class="dropdown_check">
                                <div *ngIf="addresseditmode" >
                                  <p *ngFor="let item of addressSuggestions" class="dropp " style="cursor: pointer;" ngbDropdownItem>
                                    <span class="ellipsis-span1" (click)="assignAddress(item)"  >{{item}}</span>
                                  </p>
                                </div>
                              </div>
                              <!-- Display validation errors if any -->
                              <div
                                *ngIf="addressinformation.get('addressline1').invalid && (addressinformation.get('addressline1').dirty || addressinformation.get('addressline1').touched)">
                                <small class="text-danger"
                                  *ngIf="addressinformation.get('addressline1').errors.required">Street address is
                                  required.</small>
                              </div>
                            </div>
                          </div>

                          <div class="col-md-3">
                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="text" class="did-floating-input " value="Fort Wayne" disabled>
                              <label for="" class="did-floating-label">City</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="text" class="did-floating-input " value="IN" disabled>
                              <label for="" class="did-floating-label">State</label>
                            </div>
                          </div>
                          <div class="col-md-3">
                            <div class="did-floating-label-content ps-0 mb-3">
                              <input type="text" class="did-floating-input "
                                [value]="caseTypeData.locationDtls?.postalcode" disabled>
                              <label for="" class="did-floating-label">Zipcode</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>


                <div class="accordion-item position-relative">
                  <h2 class="accordion-header" id="panelsStayOpen-headingOne">
                    <button class="accordion-button" type="button" data-bs-toggle="collapse"
                      data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true"
                      aria-controls="panelsStayOpen-collapseOne">
                      <img src="../../assets/images/additional.png"> Additional Information
                    </button>
                  </h2>
                  <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show"
                    aria-labelledby="panelsStayOpen-headingOne">
                    <div class="accordion-body">
                      <i class="fas fa-edit edit-accord" (click)="goback()"></i>
                      <div class="review-data">

                        <div class="row float-start w-100">

                          <div [ngClass]="{'col-md-3':!isObject(item.value)}"
                            *ngFor="let item of this.agentScriptSaveData?.reviewsubmitdata | keyvalue">
                            <div class="did-floating-label-content   ps-0 mb-3" *ngIf="!isObject(item.value)">
                              <input type="text" class="did-floating-input " disabled [name]="item.key"
                                [value]="item.value">
                              <label for="" class="did-floating-label">{{ item.key }}</label>
                            </div>
                          </div>

                        </div>


                        <tabset (select)="selectVehicle($event)" class="float-start w-100 tab-agent">
                          <div *ngFor="let keyValuePair of agentScriptSaveData.reviewsubmitdata | keyvalue">
                            <div *ngIf="isObject(keyValuePair.value)">
                              <tab>
                                <ng-template tabHeading>
                                  <div (click)="selectVehicle(keyValuePair.key)">
                                    {{ keyValuePair.key }}
                                  </div>
                                </ng-template>
                                <div class="nested-box py-3" *ngIf="isSelectedVehicle(keyValuePair.key)">



                                  <!-- <div class="form-group">col-md-6 pe-0
                                        <label>{{ innerKey }}</label>
                                        <input type="text" class="form-control form-control-sm" [name]="innerKey"
                                        [value]="getValueFromInnerObject(keyValuePair.value, innerKey)" disabled>
                                    </div> -->
                                  <div class="row">

                                    <div class="col-md-3" *ngFor="let innerKey of getObjectKeys(keyValuePair.value)">
                                      <div class="did-floating-label-content   ps-0 mb-3">
                                        <input type="text" class="did-floating-input " [name]="innerKey"
                                          [value]="getValueFromInnerObject(keyValuePair.value, innerKey)" disabled>
                                        <label for="" class="did-floating-label">{{ innerKey }}</label>
                                      </div>
                                    </div>
                                  </div>

                                </div>
                              </tab>
                            </div>
                          </div>
                        </tabset>




                        <!-- <div *ngFor="let item of this.agentScriptSaveData?.reviewsubmitdata | keyvalue">

       <div *ngIf="!isObject(item.value)">
       <div class="form-group">
        <label>{{ item.key }}</label>
        <input type="text" class="form-control form-control-sm" disabled [name]="item.key" [value]="item.value">
       </div>
               </div>
          </div> -->

                      </div>



                    </div>
                  </div>
                </div>

                <div class="d-flex " style="clear: both;">
                  <div class="check-oku">
                    <div class="fw-500 me-2 "> Do you want this case to be visible to the public?</div>
                    <div class="form-check gap-5 d-flex">
                    </div>

                    <div style="  margin-right: 15px;" class="d-flex">
                      <input type="radio" class="form-check-input" value="true" name="visible"
                        [(ngModel)]="visibletopublic" id="visible">
                      <label class="form-check-label" for="visible">Yes</label>
                    </div>
                    <div class="d-flex">
                      <input type="radio" class="form-check-input" value="false" name="visible"
                        [(ngModel)]="visibletopublic" id="visible2">
                      <label class="form-check-label" for="visible2">No</label>
                    </div>
                  </div>
                </div>


                <div class="row ">
                  <div class="col-sm-12 mt-0">
                    <div class="form-group text-center action-from">
                      <!-- <button type="button" value="prv-btn" class="btn btn-outline-primary ms-auto " (click)="goback()">
                        <i class="bi bi-arrow-left"></i> Back
                      </button> -->

                      <button [disabled]="isSaveDisabled()" class="btn btn-primary ms-2" type="submit"
                        (click)="customcreate()">
                        <i class="bi bi-save mr-2"></i> Submit</button>


                    </div>
                  </div>
                </div>


              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
