import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { KnowledgebaseService } from '../knowledgebase.service';
import { MatDialog } from '@angular/material/dialog';
import { EmailpopupComponent } from '../emailpopup/emailpopup.component';
import { MsalService } from '@azure/msal-angular';


@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent {
  category: any;
  show: boolean = false;
  categoryData: any[];
  dataArray:any;
  separatedData:any;
  knowledgebasejsondata: string = 'assets/json/knowledgebase.json';
  Share:boolean=false;
  Email:boolean=false;
  printPage() {
    window.print();
  }

  feedback(type:any)
  {
    console.log("positive?"+type)
    localStorage.setItem(this.category,type)
    this.feedbackgiven=false
  }
  // separateItems(data:any) {
  //   const separatedItems = [];

  //   for (const department of data) {
  //     for (const category of department.categories) {
  //       for (const item of category.items) {
  //         separatedItems.push({
  //           department: department.department,
  //           category: category.name,
  //           title: item.title,
  //           question: item.question,
  //           answer: item.answer,
  //           published: item.published,
  //           updated: item.updated,
  //           phone: item.phone,
  //           id:item.id,
  //           fax:item.fax,
  //           tty:item.tty,
  //           email:item.email,
  //           webAddress:item.webAddress,
  //           streetAddress:item.streetAddress,
  //           citizenSquare:item.citizenSquare,
  //           officeHours:item.officeHours

  //         });
  //       }
  //     }
  //   }

  //   return separatedItems;
  // }
  fetchdata() {
//     this.http.get(this.knowledgebasejsondata).subscribe((data:any) => {
//       this.dataArray = data;
// this.separatedData=this.separateItems(this.dataArray);
//       console.log("knowledgebase data");
//       console.log(this.dataArray);
//     });
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(EmailpopupComponent, {
      data: {to:'', subject: this.allfaq.description,message:this.allfaq.solution},
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  stripQuotes(str: string): string {
    if (!str) return ''; // Return empty string if str is falsy
    return str.slice(1, -1); // Extract substring without first and last characters
  }
  constructor(private route: ActivatedRoute,private http:HttpClient,private knowledgeSearchService: KnowledgebaseService,public dialog: MatDialog,
    private authService: MsalService
  ) {
    this.category = this.route.snapshot.paramMap.get('id');
    console.log(this.category)
    this.categoryData = this.dataArray;
    console.log(this.categoryData)
  }
  allfaq:any={};
  // data: any = {};
  stripHtmlAndEntities(htmlString: string): string {
    if (!htmlString) return '';

    // Remove HTML tags
    const withoutTags = htmlString.replace(/<[^>]*>/g, '');
    const trimmedString = withoutTags.trim().replace(/^"/, '').replace(/"$/, '');
    // Remove &nbsp; entities
    const withoutEntities = trimmedString.replace(/&nbsp;/g, ' ');

    // Remove quotation marks
    const withoutQuotationMarks = withoutEntities.replace(/"/g, '');

    // Add space character
    const withSpace = withoutQuotationMarks + ' ';

    return withSpace;
}
logInfo:any;
answerId:any
feedbackgiven:any;
  ngOnInit()
  {
    console.log("in details component")
    console.log(this.category)
    // this.route.params.subscribe(params => {
    //   // Access the path variable named 'id' in this case
    //   this.answerId = +params['id']; // '+' converts string to number
    //   console.log(this.answerId); // This will log '5' if the URL is http://localhost:4200/answers/details/5
      // console.log("local storage item is for answer id:"+this.answerId+" is "+localStorage.getItem(this.answerId))

      console.log(localStorage.getItem(this.category))
    this.feedbackgiven=true
      if(localStorage.getItem(this.category)!=null)
        {
        //  alert('not null')
        this.feedbackgiven=false
        }

    // });

    // if(localStorage.)


    console.log(this.category)
   this. answerdetail(this.category);
    // console.log('test header',this.authService.instance.getAllAccounts());
    // if(this.authService.instance.getAllAccounts().length > 0){
    //   this.logInfo = this.authService.instance.getAllAccounts()[0];
    //   console.log('test header info',this.logInfo);
    // }

    // this.fetchdata();
  }
  answerdetail(prodcatid:any)
  {
    console.log(prodcatid)
    this.knowledgeSearchService.getallanswers(prodcatid).subscribe((data:any)=>
      {
  console.log(data)
  this.allfaq=data;
  console.log(this.allfaq)
  if (this.allfaq?.likeStatus?.flike != null) {
    if (this.allfaq.likeStatus.flike) {
      this.likeClick(this.allfaq.prodcatid);
    } else {
      this.dislikeClick(this.allfaq.prodcatid);
    }
  }

      })
      this.getquicklink();
  }
  like: boolean = false;
  dislike:boolean=true;
  likeClick(prodcatid: any) {
      let params = {
          'prodcatid': prodcatid,
          'flike': true
      };
      this.knowledgeSearchService.likeArticle(params).subscribe((data: any) => {
          console.log(data)
          this.like=data.flike;
          this.dislike=true;

      });
  }

  dislikeClick(prodcatid:any)
  {
    let params={
      "prodcatid":prodcatid,
      "flike":false,
      "options":[1]
    }
    this.knowledgeSearchService.dislikeArticle(params).subscribe((data:any)=>
    {
      console.log(data)
      this.dislike=data.flike;
      this.like=false;

    }


    )

  }
  quickLinks:any;
  getquicklink()
  {
    this.knowledgeSearchService.getquicklinks(this.category).subscribe((data:any)=>
      {
console.log(data);
this.quickLinks=data;
      })

  }
}

