import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-emailsubmitpopup',
  templateUrl: './emailsubmitpopup.component.html',
  styleUrls: ['./emailsubmitpopup.component.scss']
})
export class EmailsubmitpopupComponent {
  constructor(public dialogRef: MatDialogRef<EmailsubmitpopupComponent>)
  {

  }
  onCloseClick(): void {
    this.dialogRef.close();

  }
}
