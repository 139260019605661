import { Injectable } from '@angular/core';
import { Observable, EMPTY } from 'rxjs';
import { HttpClient } from '@angular/common/http';


declare var google: any;

@Injectable({
  providedIn: 'root'
})
export class MapService {
  private map!: google.maps.Map;
  private markers: google.maps.Marker[] = [];
  constructor(private http: HttpClient) { }
  getMap(): google.maps.Map {
    return this.map;
  }
  setMap(map: google.maps.Map) {
    this.map = map;
  }
  subscribeToMapEvent(eventName: string): Observable<google.maps.LatLng | null> {
    return new Observable(observer => {
      if (!this.map) {
        observer.error('Map not initialized');
        return null;
      }
      const eventListener = this.map.addListener(eventName, (event: google.maps.MouseEvent) => {
        observer.next(event.latLng);
      });
      return () => {
        google.maps.event.removeListener(eventListener);
      };
    });
  }
  addMarker(marker: google.maps.Marker) {
    this.markers.push(marker);
  }

  clearMarkers() {
    this.markers.forEach(marker => marker.setMap(null));
    this.markers = [];
  }
  getAddress(latitude: number, longitude: number): Observable<any> {
    const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=AIzaSyDIS9sThtjLI2kahZdfuhQSO1lq1lxber8`;
    return this.http.get(url);
  }
}
