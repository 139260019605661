import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[appPhoneMask]'
})
export class PhoneMaskDirective {
  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    let initialValue = this.el.nativeElement.value;

    if (!initialValue) return;

    // Remove any non-numeric characters
    let formattedValue = initialValue.replace(/\D/g, '');

    // Ensure the length does not exceed 10 digits
    formattedValue = formattedValue.substr(0, 10);

    // Format the phone number
    if (formattedValue.length > 6) {
      formattedValue = formattedValue.replace(/^(\d{3})(\d{3})(\d{0,4})/, '($1) $2-$3');
    } else if (formattedValue.length > 3) {
      formattedValue = formattedValue.replace(/^(\d{3})(\d{0,3})/, '($1) $2');
    } else {
      formattedValue = formattedValue.replace(/^(\d{0,3})/, '($1');
    }

    this.el.nativeElement.value = formattedValue;
  }

  // Prevent input of non-numeric characters
  @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent) {
    // Allow: backspace, delete, tab, escape, enter, and .
    if ([46, 8, 9, 27, 13, 110, 190].indexOf(event.keyCode) !== -1 ||
        // Allow: Ctrl+A
        (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+C
        (event.keyCode === 67 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+V
        (event.keyCode === 86 && (event.ctrlKey || event.metaKey)) ||
        // Allow: Ctrl+X
        (event.keyCode === 88 && (event.ctrlKey || event.metaKey)) ||
        // Allow: home, end, left, right
        (event.keyCode >= 35 && event.keyCode <= 39)) {
      // let it happen, don't do anything
      return;
    }
    if (this.el.nativeElement.value.length >= 14) {
      event.preventDefault();
    }
    // Ensure that it is a number and stop the keypress
    if ((event.shiftKey || (event.keyCode < 48 || event.keyCode > 57)) &&
        (event.keyCode < 96 || event.keyCode > 105)) {
      event.preventDefault();
    }
  }

}
