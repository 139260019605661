import { Component, Output, Input, EventEmitter, SimpleChanges, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Router } from '@angular/router';
import { makeServiceRequestService } from '../make-service-request.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grid-view-sr',
  templateUrl: './grid-view-sr.component.html',
  styleUrls: ['./grid-view-sr.component.scss']
})
export class GridViewSrComponent {
  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  @Input() serviceTypeInfo: any;

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  serviceTypeSearch: any = '';
  selectedServiceType: any = {};
  selectedDepartmentTypes: any = {};
  searchService: any;
  departmentItems: any = [];
  caseTypeVal: any = [];
  dataSource!: MatTableDataSource<any>;
  displayedColumnsGrid: string[] = ['id', 'content', 'actions'];
  newcaseTypeVal : any;
  filterboxes: any;
  ngOnInit() {
    // this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
      this.http.get<any>(environment.backendurl+'getDeptcasedefToCustomer').subscribe((data) => {
      this.departmentItems = data;
      this.departmentItems.forEach((obj: any) => {
        obj.caseType.forEach((ele: any) => {
          this.caseTypeVal.push(ele);
        });
      })
      console.log(this.caseTypeVal)
      this.casetypeDataSource = this.caseTypeVal;
      this.casetypeDataSource1 = this.caseTypeVal

      this.casetypeDataSource.sort((a: { content: string; }, b: { content: string; }) => {
        const contentA = a.content.toLowerCase();
        const contentB = b.content.toLowerCase();

        if (contentA < contentB) return -1;
        if (contentA > contentB) return 1;
        return 0;
    });

    this.casetypeDataSource1=this.casetypeDataSource

      console.log(this.casetypeDataSource)
      console.log(this.casetypeDataSource1)



      // if (this.serviceTypeInfo && this.serviceTypeInfo.serviceDetails && this.serviceTypeInfo.serviceDetails.caseType || this.serviceTypeInfo  && this.serviceTypeInfo.caseType) {
      //   this.selectedDepartmentTypes = this.serviceTypeInfo?this.serviceTypeInfo:this.serviceTypeInfo;
      //   if(this.serviceTypeInfo){
      //     let serviceDetails = {
      //       serviceDetails:this.serviceTypeInfo.serviceDetails
      //     }
      //     this.serviceTypeInfo = serviceDetails;
      //   }
      //   this.getCaseType(this.serviceTypeInfo.serviceDetails)
      // }else if(this.departmentItems && this.departmentItems.length){
      //   this.getCaseType(this.departmentItems[0]);
      // }
    });


    this.http.get<any>('/assets/json/filter_tags.json').subscribe((data) => {
      this.filterboxes = data[0].filter;
      // console.log(data)
      // console.log(this.filterboxes)
    });

    this.newcaseTypeVal = this.caseTypeVal;
  }
  ShowDiv() {
    this.serviceTypeDetailsOutputEvent.emit({viewname:"listGridView"})
  }
  ngOnChanges(changes: SimpleChanges) {
    this.setListView();
  }

  setListView() {
    if (this.selectedDepartmentTypes && this.selectedDepartmentTypes.caseType) {
      this.dataSource = new MatTableDataSource(this.selectedDepartmentTypes.caseType);
    } else {
      this.dataSource = new MatTableDataSource();
    }
    setTimeout(() => {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    })

  }

  clearsearch()
  {
    this.searchterms=""
    this.casetypeDataSource=this.casetypeDataSource1
  }

  filter(data: any) {
    this.searchterms='';
    if(data.includes('Recents'))
      {

        this.makeserviceservice.getrecentsdata().subscribe((data) => {
          
          console.log(data)
    
          var newdata: any = []
          newdata = data
          var filteringarray:any=[];
          filteringarray = this.caseTypeVal.filter((item1:any) =>
            newdata.some((item2:any) => item1.casetypeid == item2.casetype && item1.department.id == item2.department.id && (((item1.casesubtypeid != null && item2.casesubtypeid != null) ? item1.casesubtypeid == item2.casesubtypeid : true)))
          );
    
    
          console.log(filteringarray)
    
          // setTimeout(() => {
            
          this.casetypeDataSource = filteringarray
          // }, 100);
        })
      }
      else{
        this.casetypeDataSource = this.casetypeDataSource1;
    // console.log(data)
    // console.log(this.casetypeDataSource.filteredData)
    // console.log(this.casetypeDataSource1.filteredData)
    this.makeserviceservice.getfilterbyseason(data).subscribe((data) => {
          
      console.log(data)

      var newdata: any = []
      newdata = data
      var filteringarray:any=[];
      filteringarray = this.caseTypeVal.filter((item1:any) =>
        newdata.some((item2:any) => item1.casetypeid == item2.casetype && item1.department.id == item2.department.id && (((item1.casesubtypeid != null && item2.casesubtypeid != null) ? item1.casesubtypeid == item2.casesubtypeid : true)))
      );


      console.log(filteringarray)

      // setTimeout(() => {
        
      this.casetypeDataSource = filteringarray
      // }, 100);
    })
      }
    // console.log(data)
    // console.log(this.casetypeDataSource)
    // console.log(this.casetypeDataSource1)
    

      $("[data-filter-id='" + data+ "']").attr('data-filter-close', 'close')

      $("[data-filter-id]").not("[data-filter-id='" + data + "']").attr('data-filter-close', 'open');

  }
  displayclose(item:any)
  {
    // console.log(item)
    return $("[data-filter-id='" + item+ "']").attr('data-filter-close')
  }
  closefilter(data:any) {
    this.casetypeDataSource = this.casetypeDataSource1;

    $("[data-filter-id='" + data+ "']").attr('data-filter-close', 'open')
  }
  gotoNext(data: any) {
    console.log(data)
    // var casetypeid=data.serviceDetails.casetypeid;
    // var departmentid=data.serviceDetails.department.id
    // var divisionid=data.serviceDetails.division.id
    // var qid=data.serviceDetails.qid
    // console.log(casetypeid+"  "+departmentid+"  "+divisionid)
    let serviceDetails = data.serviceDetails;
    this.selectedServiceType["serviceDetails"] = serviceDetails;
    this.selectedDepartmentTypes = serviceDetails;
    data.serviceDetails['caseType'] = this.casetypeDataSource.filteredData;
    this.serviceTypeDetailsOutputEvent.emit(data)

  }
  displayedColumns: string[] = ['id', 'content', 'actions'];
  caseTypeCard: Boolean = false;
  departmentDataSource = new MatTableDataSource(this.departmentItems);
  casetypeDataSource :any;
  casetypeDataSource1:any;

  constructor(private makeserviceservice:makeServiceRequestService,private http: HttpClient,private router: Router) { }
  getCaseType(item: any) {
    this.selectedServiceType = item;
    this.selectedDepartmentTypes = item;
    this.serviceTypeSearch = "";
    this.caseTypeCard = true;
    this.casetypeDataSource = (item.caseType.length) ? new MatTableDataSource(item.caseType) : new MatTableDataSource(this.departmentItems.caseType);
    this.setListView();
  }

  searchterms = '';
  applyFilter(event: Event) {
    // console.log(this.dataSource)
    // console.log(event)

    this.searchterms = (event.target as HTMLInputElement).value.toString().trim().toLowerCase();
    // console.log(this.caseTypeVal)
    // console.log(this.searchterms)

    // this.dataSource.filter = this.searchterms;
    // if (this.dataSource.paginator) {
    //   this.dataSource.paginator.firstPage();
    // }

    this.casetypeDataSource=[...this.casetypeDataSource1].filter((x:any)=>
      // x.content.toLowerCase().includes(this.searchterms)
    x.content.toLowerCase().includes(this.searchterms) || x.department.name.toLowerCase().includes(this.searchterms) 
    )
    $("[data-filter-id]").attr('data-filter-close', 'open');



    // console.log(this.caseTypeVal)

  }

  onSelect(item: any) {
    this.searchterms = item.content

    this.departmentDataSource.filter = item.content.trim().toLowerCase();
    this.casetypeDataSource.filter = item.content.trim().toLowerCase();
  }

  back() {
    this.caseTypeCard = false;
  }
  onEnter(event: any) {
    const filterValue = event.option.value;
    this.departmentDataSource.filter = filterValue.trim().toLowerCase();
    this.casetypeDataSource.filter = filterValue.trim().toLowerCase();
  }
  onDeptEnter(event: any) {
    const filterValue = event.option.value;
    this.departmentDataSource.filter = filterValue.trim().toLowerCase();
    this.casetypeDataSource.filter = filterValue.trim().toLowerCase();
  }

}
