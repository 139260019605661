import { HttpInterceptor,HttpRequest,HttpHandler,HttpEvent } from "@angular/common/http";
import {Observable} from 'rxjs';
// import { environment } from "src/environments/environment";
import {environment} from "../environments/environment"



export class CustomInterceptor implements HttpInterceptor{


     authtoken:any;
    intercept(request: HttpRequest<any>, next:HttpHandler): Observable<HttpEvent<any>> {

        //  console.log("in interceptor",request.url);


        if(request.url.includes("localhost") || request.url.includes(environment.backendurl))
        {
            // console.log('intercepting');
            // console.log("token id : ", JSON.parse(sessionStorage.getItem('2ebc4550-936a-44b6-b7c9-eae1fb287454-b2c_1_logindemov2.4da207a1-0130-43ac-93c2-52960d47390a-nebulogicsmart.b2clogin.com-idtoken-676a12b0-6624-460f-83a8-59d9cd4b9a4c----')).secret);
                //  let authtoken=
                // console.log(Object.keys(sessionStorage));

                // Object.keys(sessionStorage).forEach(element => {
                //     // console.log(element.match("idtoken"));
                //     if(element.match("idtoken")){
                //           this.authtoken=element

                //     }

                // });
                // console.log(this.authtoken)



                // const authToken = sessionStorage.getItem(this.authtoken);

                // if (authToken !== null) {
                //   console.log(authToken)
                //     request = request.clone({
                //         setHeaders: {
                //             Authorization: JSON.parse(authToken).secret
                //         }
                //     });
                // } else {
                //     // Handle the case where the authentication token is not found in sessionStorage
                //     // For example, you could log an error or take some other action.
                //     console.log(request.url,"Authentication token not found in sessionStorage");
                // }

            request = request.clone({
                setHeaders: {
                    // Authorization: localStorage.getItem('msal.idtoken')+""

                    // Authorization:JSON.parse(sessionStorage.getItem('6d27f133-f47b-436e-962d-ca6f284bce96-b2c_1_logindemov2.4da207a1-0130-43ac-93c2-52960d47390a-nebulogicsmart.b2clogin.com-idtoken-676a12b0-6624-460f-83a8-59d9cd4b9a4c----')).secret

                    // Authorization:JSON.parse(sessionStorage.getItem(this.authtoken)).secret
                    // Authorization:JSON.parse(sessionStorage.getItem(this.authtoken)).secret

                }
              });
            //return next.handle(request);

        }
        return next.handle(request);

    }

}
