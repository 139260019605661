import { AfterViewInit, Component, ViewChild, Output, EventEmitter, Input, ElementRef, OnInit } from '@angular/core';
import { MatPaginator, MatPaginatorModule } from '@angular/material/paginator';
import { MatSort, MatSortModule, Sort } from '@angular/material/sort';
import { MatTableDataSource, MatTableModule } from '@angular/material/table';
import { HttpClient } from '@angular/common/http';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { environment } from 'src/environments/environment';
import { makeServiceRequestService } from '../make-service-request.service';

declare var $:any;
@Component({
  selector: 'app-select-service-type',
  templateUrl: './select-service-type.component.html',
  styleUrls: ['./select-service-type.component.scss']
})

export class SelectServiceTypeComponent implements OnInit {
  @Output() serviceTypeDetailsOutputEvent = new EventEmitter();
  @Input() serviceTypeInfo: any;
  // @ViewChild('empTbSort') empTbSort = new MatSort();

  @ViewChild(MatPaginator, { static: true })
  paginator!: MatPaginator;

  searchterms:any='';
  departmentDataSource: any;
  caseTypeVal: any=[];
input: any;
  gotoNext(data: object, rowInfo?: Object) {
    console.log(data)
    console.log(this.makeserviceservice.servicedetails)
    // if(this.makeserviceservice.servicedetails!=undefined)
    //   {
    //     console.log("in if")
    //     data=this.makeserviceservice.servicedetails;

    //   }

    // console.log(this.makeserviceservice.servicedetails);
    console.log(data)
    if (rowInfo) {
      let dataVal: any = rowInfo;
      localStorage.setItem("contentNameForListView", JSON.stringify(dataVal));
      localStorage.setItem("ServiceTypeInfo", "");
    }
    this.serviceTypeDetailsOutputEvent.emit(data)
    localStorage.setItem("userInfo", '');

  }
  // dataSource:any;
  dataSource1:any;
  dataSource!: MatTableDataSource<any>
  displayedColumns: string[] = ['id','favourites', 'casetype','division','resolution', 'actions'];
  searchTerm: string = '';
  objListVal: any = [];
  departmentItems: any = [];
  pageSize:any=5;
  pageSizeOptions:any=[ 10, 15, 20, 25];
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  // @ViewChild(MatSort) sort!: MatSort;

  constructor(private makeserviceservice:makeServiceRequestService, private http: HttpClient,private _liveAnnouncer: LiveAnnouncer) {
    // console.log("page");
  }

  togglefavourites(data:any)
  {
    console.log(data)
  }

  clearsearch()
  {
    // $('.dataTables_filter input').val("").keyup();
    this.searchterms=""
    $('.dt-search input').val(this.searchterms).keyup();
    this.dataSource=this.dataSource1
    console.log(this.dataSource1)

    // this.dataSource.filter = '';
    // this.dataSource.paginator = this.paginator;
    // this.newcaseTypeVal=this.dataSource.filteredData
  }

  filterboxes: any;
  newcaseTypeVal:any=[];
  allcaseTypeVal:any=[]

  @ViewChild(MatSort)
  sort!: MatSort;
  @ViewChild('dataTable')
  dataTable!: ElementRef;

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`);
    } else {
      this._liveAnnouncer.announce('Sorting cleared');
    }
  }

  ngOnInit() {
    console.log(this.makeserviceservice.servicedetails);
    console.log(this.serviceTypeInfo)
    let lookup = {};
    // this.http.get<any>('/assets/json/dataArray.json').subscribe((data) => {
      this.http.get<any>(environment.backendurl+'getDeptcasedefToCustomer').subscribe((data) => {
      this.departmentItems = data;
      this.departmentItems.forEach((obj: { caseType: any[]; }) => {
        obj.caseType.forEach((ele: any) => {
          this.caseTypeVal.push(ele);
        });

      })



      console.log(this.caseTypeVal)
      this.newcaseTypeVal=this.caseTypeVal
      this.allcaseTypeVal=this.caseTypeVal


      this.datatablepagination()

    });



    this.http.get<any>('/assets/json/filter_tags.json').subscribe((data) => {
      this.filterboxes = data[0].filter;
      // console.log(data)
      // console.log(this.filterboxes)
    });

  }

  ShowDiv() {
    this.serviceTypeDetailsOutputEvent.emit({viewname:"listGridView"})
  }
  datatableload:any
  datatablepagination()
  {
    console.log("in datatable")
    $("#listviewtable_wrapper").remove();
// alert(this.tableId)
    // $("#listviewtable").DataTable().destroy();
    const screenWidth = window.innerWidth;
    const isSmallScreen = screenWidth <= 768;

    setTimeout(() => {
     
      ($('#listviewtable' ) as any).dataTable({
        "language": {
          "paginate": {
            "previous": "",
            "next": ""
          },
          "lengthMenu": "Records per page _MENU_",
        },
        // responsive: isSmallScreen ? true : false,

        pageLength: 10,
        "order": [[ 1, "asc" ]],

        "columnDefs": [
          { "orderable": false, "targets": 0 },
          { 'orderable': false, 'targets': 3 },
          // { 'orderable': false, 'targets': 4 },
         ],
         fixedColumns: {
          start:isSmallScreen ? 0 : 0,
          end: isSmallScreen ? 1 : 1,
      },
        "lengthMenu": [[10, 20, -1], [10, 20, "All"]],
        //   fixedColumns: {
        //     start: 1,
        //     end: 1
        // },
        paging: true,
        fixedHeader: true,
        // scrollX: false,
        scrollY: 450,
        destroy:true,
        dom: 'frtlip',

        drawCallback: function (settings) {
          const api = this.api();
          const rows = api.rows({ page: 'current' }).nodes();
          let startIndex =  1;

          $(rows).each(function (index) {
            $('td', this).eq(0).html(startIndex.toString()); // Convert to string before setting as HTML content
            startIndex++;
          });
        },

      });
    },200);
    this.datatableload=true
  }


  closefilter(data:any) {

    this.datatableload=false
    console.log(this.newcaseTypeVal)
    console.log(data)
    // this.dataSource.filteredData = this.dataSource1.filteredData;

    $("[data-filter-id='" + data+ "']").attr('data-filter-close', 'open')
    this.caseTypeVal=[]
    console.log(this.caseTypeVal)
    this.caseTypeVal=this.newcaseTypeVal

    console.log(this.caseTypeVal)

    setTimeout(() => {
    this.datatablepagination()
    }, 100);

    // this.dataSource.filter = '';
    // this.newcaseTypeVal=this.dataSource.filteredData
  }

  displayclose(item:any)
  {
    // console.log(item)
    return $("[data-filter-id='" + item+ "']").attr('data-filter-close')
  }

  filter(data: any) {
    console.log(data)
    console.log(this.caseTypeVal)
    this.caseTypeVal = this.newcaseTypeVal

    if(data.includes('Recents'))
      {
        this.datatableload=false
        this.makeserviceservice.getrecentsdata().subscribe((data) => {

          console.log(data)

          var newdata: any = []
          newdata = data
          var filteringarray:any=[];
          filteringarray = this.caseTypeVal.filter((item1:any) =>
            newdata.some((item2:any) => item1.casetypeid == item2.casetype && item1.department.id == item2.department.id && (((item1.casesubtypeid != null && item2.casesubtypeid != null) ? item1.casesubtypeid == item2.casesubtypeid : true)))
          );


          console.log(filteringarray)

          // setTimeout(() => {

          this.caseTypeVal = filteringarray
          this.datatablepagination()
          // }, 100);
        })
      }
      else
      {
        console.log(this.caseTypeVal)
        this.datatableload=false
        this.makeserviceservice.getfilterbyseason(data).subscribe((data) => {

          console.log(data)

          var newdata: any = []
          newdata = data
          var filteringarray:any=[];
          filteringarray = this.caseTypeVal.filter((item1:any) =>
            newdata.some((item2:any) => item1.casetypeid == item2.casetype && item1.department.id == item2.department.id && (((item1.casesubtypeid != null && item2.casesubtypeid != null) ? item1.casesubtypeid == item2.casesubtypeid : true)))
          );


          console.log(filteringarray)

          // setTimeout(() => {

          this.caseTypeVal = filteringarray
          this.datatablepagination()
          // }, 100);
        })
      }




    //     // caseTypeVal

    // this.caseTypeVal=this.allcaseTypeVal
    // console.log(this.caseTypeVal)
    // console.log(this.allcaseTypeVal)
    // console.log(data)
    // this.searchterms=''
    // $("[data-filter-id='" + data+ "']").attr('data-filter-close', 'close')

    //   $("[data-filter-id]").not("[data-filter-id='" + data + "']").attr('data-filter-close', 'open');

    // this.caseTypeVal=[...this.caseTypeVal].filter((x:any)=>x.filteringvalues && x.filteringvalues.includes(data))


    // this.datatablepagination()
      $("[data-filter-id='" + data+ "']").attr('data-filter-close', 'close')

      $("[data-filter-id]").not("[data-filter-id='" + data + "']").attr('data-filter-close', 'open');



  }

  // onSelect(item:any)
  // {
  //   $('.dataTables_filter input').val(this.searchterms).keyup();
  //   this.searchterms=item.content
  //   // this.dataSource.filter = this.searchterms;
  //   // if (this.dataSource.paginator) {
  //   //   this.dataSource.paginator.firstPage();
  //   // }
  // }

  // customFilterPredicate(): (data: any, filter: string) => boolean {
  //   const filterFunction = (data: any, filter: string): boolean => {
  //     const searchString = filter.toLowerCase();
  //     console.log(searchString)
  //     return (
  //       data.content.toLowerCase().includes(searchString) ||
  //       data.department.name.toLowerCase().includes(searchString)
  //     );
  //   };
  //   return filterFunction;
  // }
  applyFilter(event: Event) {
    this.newcaseTypeVal=this.allcaseTypeVal

    // $('.dataTables_filter input').val(this.searchterms).keyup();
    // $('.dataTables_filter input').val(this.searchterms).keyup();
    this.searchterms = this.searchterms.toLowerCase(); // Convert to lowercase

    $("[data-filter-close]").attr('data-filter-close', 'open');
    $('.dt-search input').val(this.searchterms).keyup();
    this.newcaseTypeVal=[...this.newcaseTypeVal].filter((x:any)=>
      x.content.toLowerCase().includes(this.searchterms)
    )
  }


  onEnter(event: any) {
    const filterValue = (event.option.value && event.option.value.name || '').toString().toLowerCase();
    // this.dataSource.filter = filterValue;
  }

}
