/// <reference types="@types/googlemaps" />
import { Component, HostListener, Renderer2, ElementRef, ViewEncapsulation, ViewChild, Inject } from '@angular/core';
import { Router, ResolveStart, NavigationCancel, NavigationError, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { MSAL_GUARD_CONFIG, MsalGuardConfiguration, MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus, PopupRequest, RedirectRequest, EventMessage, EventType } from '@azure/msal-browser';
import * as CryptoJS from 'crypto-js';
import { NgForm, FormBuilder, Validators } from '@angular/forms';
import { ChatbotService } from './services/chatbot.service';
import { v4 as uuidv4 } from 'uuid';
declare var $: any;


export interface User {
  message: string;
  allmessages: any[];
  sendMsg: string;
}
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent {
  showFeedbackQuestion: boolean = false;
  showFeedbackPopup: boolean = false;
  countdownValue: number = 60;
  isAgreed: boolean = false;
  countdownInProgress: boolean = false;
  initialDateTime: any;
  sessionTimer: any;
  title = 'City Of fortwayne';
  isLandingPage: boolean = false;
  isLogin: boolean = false;
  authtoken: any;
  isUserLoggedIn: boolean = false;
  userName?: string = '';
  isChatwindow: boolean = false;
  conversationId: string | undefined;
  //token:any;
  user: User = {
    message: "",
    allmessages: [],
    sendMsg: ""
  };
  showPopchat: boolean = false;
  countdownInterval: any;
  feedbackForm: any;
  feedback: any;
  likeStatus: boolean;
  constructor(private renderer: Renderer2, private el: ElementRef, public router: Router, private fb: FormBuilder,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalBroadCastService: MsalBroadcastService,
    private authService: MsalService,
    private chatbotService: ChatbotService
  ) {

    const navEndEvent$ = router.events.pipe(
      filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: any) => {

      Object.keys(sessionStorage).forEach(element => {
        // console.log(element.match("idtoken"));
        if (element.match("idtoken")) {
          this.authtoken = element
        }

      });
      if(this.authtoken){
        var token = JSON.parse(sessionStorage.getItem(this.authtoken)).secret;
        token = token.split(".")[1];
        token = atob(token);
        var json = JSON.parse(token);
        var exp = json.exp * 1000;
        var current = Date.now();
        console.log('exp',exp)

        if (exp < current) {

          // this.modalService.open(this.SessionExpired);
          setTimeout(() => {
            this.logout();
          }, 1000)

        }
      }


      //console.log('app compo',this.authService.instance.getAllAccounts().length)
      if (this.authService.instance.getAllAccounts().length > 0) {
        this.isLogin = true;
      } else {
        this.isLogin = false;

      }
      if (e.url == '/') {
        this.isLandingPage = true;
      }
      else {
        this.isLandingPage = false;
      }
      console.log(this.isLandingPage)
    });

    // this.feedbackForm = this.fb.group({
    //   // name: ['', [Validators.required]],
    //   feedback: ['', [Validators.required]],
    // })
  }

  logout()
  {
    // localStorage.clear();
    //     sessionStorage.clear();
        // this.userService.logout();
        this.authService.logout();
        localStorage.clear()
        sessionStorage.clear()
  }
  @HostListener('window:scroll', ['$event'])


  // onScroll(event: any) {

  //   const scrollY = window.scrollY;
  //   const element = this.el.nativeElement.querySelector('.scroll-container');

  //   if (scrollY > 30) {
  //     this.renderer.addClass(element, 'scroll-effect');
  //     document.body.classList.add('scroll-body');

  //   } else {
  //     this.renderer.removeClass(element, 'scroll-effect');
  //     document.body.classList.remove('scroll-body');

  //   }
  // }

  isLoginCall(isLogin: any) {
    this.isLogin = isLogin;
  }
  // openFeedbackPopup() {
  //   this.showFeedbackPopup = true;
  //   clearTimeout(this.sessionTimer);
  // }


  // closeFeedbackPopup() {
  //   this.showFeedbackPopup = false;
  //   this.feedbackForm.reset();

  // }
  // submitFeedback(likeStatus: boolean) {
  //   console.log("likestatus",likeStatus);
  //     const thankYouMessage = {
  //         query: likeStatus? "<i class='fa fa-thumbs-up'></i>": "<i class='fa fa-thumbs-down'></i>" ,
  //         response: likeStatus ? "Thank you for the feedback": "I am sorry to hear that we will improve it.Is there anything else that I can help you with?",
  //         timestamp: new Date().getTime()
  //     };
  //     this.user.allmessages.push(thankYouMessage);
  //     setTimeout(() => {
  //         $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
  //     }, 200);
  //     this.showFeedbackQuestion=false;
  //   this.feedback = this.feedbackForm.value.feedback
  //   let payLoad = {
  //     "conversation_id": this.conversationId,
  //     "feedback": this.feedback,
  //     "likeStatus": likeStatus
  //   }
  //   console.log('payLoad', payLoad);
  //   this.chatbotService.submitFeedback(payLoad).subscribe((res: any) => {
  //     this.feedbackForm.reset();
  //     this.showFeedbackPopup = false;
  //     // this.clearChat()

  //   })
  // }
  // stayChat() {
  //   this.countdownInProgress = false;
  //   this.showPopchat = false;
  //   clearTimeout(this.sessionTimer);
  //   this.startSessionTimer();
  // }

  // closeChat() {
  //   this.clearChat();
  //   this.countdownInProgress = false;
  //   clearTimeout(this.sessionTimer);

  // }

  // startCountdown() {
  //   this.countdownValue = 60;
  //   this.countdownInProgress = true;
  //   const countdownInterval = setInterval(() => {
  //     this.countdownValue--;
  //     if (this.countdownValue <= 0) {
  //       clearInterval(countdownInterval);
  //       this.clearChat();
  //       this.countdownInProgress = false;
  //     }
  //   }, 1000);
  //   if (this.countdownInterval) {
  //     clearInterval(this.countdownInterval);
  //   }
  //   this.countdownInterval = countdownInterval;
  // }
  // startSessionTimer() {
  //   if (this.isAgreed) {
  //     this.sessionTimer = setTimeout(() => {
  //       this.openPopup();
  //       this.startCountdown();
  //     }, 5 * 60 * 1000);
  //   }
  // }
  // resetSessionTimer() {
  //   clearTimeout(this.sessionTimer);
  //   this.countdownValue = 60;
  //   this.startSessionTimer();
  // }
  // closeBox() {
  //   this.showPopchat = !this.showPopchat
  //   clearTimeout(this.sessionTimer);
  //   this.startSessionTimer();

  // }

  // openPopup() {
  //   if (this.isChatwindow && this.isAgreed) {
  //     this.showPopchat = true;
  //     return;
  //   }
  //   this.isChatwindow = false;
  //   clearTimeout(this.sessionTimer);

  // }
  // generateUniqueID() {
  //   return uuidv4();
  // }
  // getCurrentDate(): string {
  //   return new Date().toLocaleString('en-US', { year: 'numeric', month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit', hour12: true });
  // }
  // isInputEmpty(): boolean {
  //   return !this.user.sendMsg || this.user.sendMsg.trim() === '' ||this.isMaxLengthExceeded;
  // }
  // openChat() {
  //   this.showFeedbackPopup=false;
  //   this.isAgreed = false
  //   if (!this.isChatwindow) {
  //     this.minimizeChat();
  //     clearTimeout(this.sessionTimer);
  //     this.startSessionTimer();
  //   } else {
  //     this.isChatwindow = false;
  //   }
  // }
  // minimizeChat() {
  //   this.isChatwindow = true;
  //   clearTimeout(this.sessionTimer);
  //   this.startSessionTimer();
  // }

  // clearChat() {
  //   this.user.allmessages = [];
  //   this.conversationId = "";
  //   this.isChatwindow = false;
  //   this.showPopchat = false;

  // }

  // sendMsg: any;
  // agreeContinue() {
  //   this.isAgreed = true;
  //   this.initialDateTime = this.getCurrentDate();
  //   if (this.isChatwindow && (!this.user.allmessages || this.user.allmessages.length === 0)) {
  //     this.conversationId = this.generateUniqueID();

  //     this.startSessionTimer();

  //     console.log("generated conversationid", this.conversationId);
  //   }

  // }
  @ViewChild('elementToMeasure') elementToMeasure: ElementRef;

  ngAfterViewInit() {
    this.applyConditionBasedOnWidth();
  }

  applyConditionBasedOnWidth() {
    const width = this.elementToMeasure?.nativeElement?.offsetWidth;
    if (width > 993) { // Check if the width is 300px
      // Apply your condition here
      console.log('Width is 300px');
      // For example, you can add a class to the element
      this.elementToMeasure?.nativeElement.classList.add('specific-width');
    } else {
      // Remove the class if width is not 300px
      this.elementToMeasure?.nativeElement.classList.remove('specific-width');
    }
  }
  ngOnInit() {

    // this.authService.logout()
    // localStorage.clear()
    this.msalBroadCastService.msalSubject$

      .pipe(

        filter((msg: EventMessage) => msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED),
      )

      .subscribe((result: EventMessage) => {

        console.log("result", result)
        if (this.authService.instance.getAllAccounts().length === 0) {

          window.location.pathname = "/";


        } else {
         console.log("else")
        }

      });

    this.msalBroadCastService.inProgress$
      // .pipe
      // (filter((interactionStatus:InteractionStatus)=>
      // interactionStatus==InteractionStatus.None),
      // takeUntil(this._destroy))
      .subscribe((x: any) => {
        console.log("test app", x);
        //location.reload();
        //if(x == 'none'){
        //this.router.navigateByUrl("/login");
        //}

        this.isUserLoggedIn = this.authService.instance.getAllAccounts().length > 0;
        // console.log('test---',this.isUserLoggedIn);

        if (this.isUserLoggedIn) {
          // console.log("login success");
          // window.alert("loggedin")

          this.userName = this.authService.instance.getAllAccounts()[0].name;
          console.log('test---', this.userName);
          // console.log(x.get)
          // alert(JSON.stringify(this.authService.instance.getTokenCache()))
          // console.log(this.authService.instance.acquireTokenSilent(x))
          // console.log(this.authService.instance.acquireTokenByCode(x))
          // console.log(this.authService.instance.acquireTokenPopup(x))

        }
        // this.azureAdDemoSerice.isUserLoggedIn.next(this.isUserLoggedIn);
      })
  }
  // isSignupRoute(): boolean {
  //   return this.router.url.includes('signup');
  // }
  // loading = false;
  // isMaxLengthExceeded = false;

  // checkMaxLength() {
  //   this.isMaxLengthExceeded = this.user.sendMsg.length > 250;
  // }
  // OnSubmit(form: NgForm) {
  //   this.showFeedbackQuestion = false;
  //   $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
  //   console.log(this.user);
  //   const currentTime:any = new Date().getTime();
  //   const expiresIn:any =currentTime + (5 * 60 * 1000);
  //   var header = {
  //     "alg": "HS256",
  //     "typ": "JWT"
  //   };

  //   var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  //   var encodedHeader = this.base64url(stringifiedHeader);

  //   var data:any = {
  //     "query": this.user.sendMsg,
  //     "conversation_id": this.conversationId,
  //     "exp": parseInt((expiresIn / 1000).toString()),
  //     "iat":parseInt((currentTime/1000).toString()),
  //     "iss":'Customer portal'
  //   };
  //   console.log("submitdata", data);
  //   var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  //   var encodedData = this.base64url(stringifiedData);

  //   var token = encodedHeader + "." + encodedData;
  //   var secret = "cqLfvq3KRTmP5vE8f2SdHyiKViPTx0L0";
  //   var signature: any = CryptoJS.HmacSHA256(token, secret);
  //   signature = this.base64url(signature);

  //   var signedToken = token + "." + signature;
  //   console.log("token", signedToken);
  //   let payLoad = {
  //     "query": signedToken
  //   }
  //   console.log(payLoad);
  //   this.loading = true;

  //   const userMessage = {
  //     query: this.user.sendMsg,
  //     response: '<img src="../assets/images/writing-loading.gif" width="50px" height="25px">'
  //   };
  //   this.user.allmessages.push(userMessage);
  //   this.user.sendMsg = "";
  //   this.chatbotService.sendMessage(payLoad).subscribe((res: any) => {
  //     this.loading = false;
  //     res.timestamp = new Date().getTime();
  //     this.user.allmessages[this.user.allmessages.length - 1].timestamp = res.timestamp;
  //     this.user.allmessages[this.user.allmessages.length - 1].response = res.response;
  //     this.showFeedbackQuestion = true;
  //     setTimeout(() => {
  //       $(".chat-body").animate({ scrollTop: $('.chat-body').prop("scrollHeight") }, 1000);
  //     }, 200)
  //     this.resetSessionTimer();

  //   })
  // }
  // base64url(source: any) {
  //   // Encode in classical base64
  //   let encodedSource = CryptoJS.enc.Base64.stringify(source);

  //   // Remove padding equal characters
  //   encodedSource = encodedSource.replace(/=+$/, '');

  //   // Replace characters according to base64url specifications
  //   encodedSource = encodedSource.replace(/\+/g, '-');
  //   encodedSource = encodedSource.replace(/\//g, '_');

  //   return encodedSource;
  // }
}
