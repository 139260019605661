import { Component } from '@angular/core';
import { MatDialogRef,MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormControl,FormArray, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Inject } from '@angular/core';
import { HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent {
  loginUserInfoForm!: FormGroup;
  userData:any;
  constructor(private fb:FormBuilder,private http:HttpClient,private router: Router, private dialogRef: MatDialogRef<PopupComponent>, @Inject(MAT_DIALOG_DATA) public popupData: any) {}

  onClose(): void {
    this.dialogRef.close();
  }
  ngOnInit(){
    this.loginUserInfoForm= this.fb.group({
      username:['',[Validators.required]],
      password:['',[Validators.required]]
    })
    this.http.get<any>('/assets/userdata.json').subscribe(data=> {
      this.userData=data;
    })
  }
  logIn(){
    let luname = this.loginUserInfoForm.get("username")?.value;
    luname = luname.toLowerCase();
    let logInUser = this.userData.filter((item: any) => item.username ==luname);
    logInUser = (logInUser && logInUser.length) ? logInUser[0] : "";
    if (logInUser) {
      logInUser.username = this.loginUserInfoForm.get("username")?.value
      localStorage.setItem("loginUserInfoDetails", JSON.stringify(logInUser));
      let loginUserInfo = this.loginUserInfoForm.getRawValue();
      localStorage.setItem('loginUserInfo', JSON.stringify(loginUserInfo));
      this.dialogRef.close();
      if (this.popupData && this.popupData.fromLogin == 'my-service-requests') {
        this.router.navigateByUrl('/my-service-request');
      } else {
        location.href = '/';
        localStorage.setItem("userInfo", "");
      }
    }else{
      alert("Invalid Username");
    }
 
  }
}
