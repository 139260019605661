import { Component, EventEmitter, Output } from '@angular/core';
import * as jq from 'jquery';
import { MatDialog } from '@angular/material/dialog';
import { PopupComponent } from '../popup/popup.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';


@Component({
  selector: 'app-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss']
})
export class SideNavComponent {
  // jq(document).on('click', '.sidebar-nav li', function () {
  //   jq('.sidebar-nav li').removeClass("active");
  //   jq(this).addClass("active");

  // });

  menuOpen:boolean = false;
  isActiveRouter:any = false;
  @Output() isLoginCall=new EventEmitter();
  constructor(public dialog: MatDialog,private router: Router, private readonly location: Location){
      this.isActiveRouter=this.location.path();
  }

  clickNavbarMenu(){
  this.menuOpen = !this.menuOpen;

  if(this.menuOpen){
   jq("#wrapper").parents().find('.all-section.toggled').removeClass('toggled');
  }else{
   jq("#wrapper").parents().find('.all-section').addClass('toggled');
  }

 }
 openPopup() {
  let logInfo=localStorage.getItem("loginUserInfoDetails");
  if(!logInfo){

 this.dialog.open(PopupComponent, {
   width: '440px' ,
   data:{
     fromLogin: 'my-service-requests'
   }
 }).afterClosed()
 .subscribe(item => {
  let logInfo=localStorage.getItem("loginUserInfoDetails");
   let status = logInfo?true:false;
   if(status) this.isActiveRouter='/my-service-request';
   this.isLoginCall.emit(status);
 });;

}else{
 this.isLoginCall.emit(true);
 this.isActiveRouter='/my-service-request';
 this.router.navigateByUrl('/my-service-request');
}
}
isSignupRoute(): boolean {
  return this.router.url.includes('signup');
}
ngOnInit() {



$(document).ready(function(){

    if (window.innerWidth < 992) {
      $("#sidebar-wrapper a").addClass('smallViewNav');
      $('html').click(function(event) {
        if ($(event.target).closest('#wrapper').length === 0) {
            $("#wrapper").parents().find('.all-section').addClass('toggled');
            $("#wrapper").find('#sidebar-wrapper .track.change').removeClass('change');

        }
    });
    } else {
      $("#sidebar-wrapper a").removeClass('smallViewNav');
    }
    $('a.smallViewNav').click(function(){
      $("#wrapper").parents().find('.all-section').addClass('toggled');
      $("#wrapper").find('#sidebar-wrapper .track.change').removeClass('change');

    });
});

}

isActiveRouteCall(pathURL:any){
  this.isActiveRouter = pathURL;
}

}
