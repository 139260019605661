<!-- <div id="services" style="height: 60px;visibility: hidden;position: absolute;width: 100%;top: -62px;"></div> -->
<div class="col-lg-12 col-md-12 col-sm-12 col-xs-12 px-0 py-1 ">


  <!-- <div *ngIf="currDiv=='listView' || currDiv=='gridView'">
    <div class="box-btn" (click)="ShowDiv()">
      <button class="btn-action "  [class.active-b]="currDiv == 'listView'">
        <i class="bi bi-list-task"></i> </button>
      <button class="btn-action "  [class.active-b]="currDiv == 'gridView'">
        <i class="bi bi-grid-3x3-gap-fill"></i> </button>
    </div> -->
    <app-select-service-type *ngIf="currDiv =='listView'" (serviceTypeDetailsOutputEvent)="responseFromChild($event)">
    </app-select-service-type>
    <app-grid-view-sr *ngIf="currDiv =='gridView'" [serviceTypeInfo]="serviceTypeInfo"
      (serviceTypeDetailsOutputEvent)="responseFromChild($event)" >
    </app-grid-view-sr>
    <!-- <app-grid-view *ngIf="currDiv =='gridView'" [serviceTypeInfo]="serviceTypeInfo"
    (serviceTypeDetailsOutputEvent)="responseFromChild($event)" >
  </app-grid-view> -->
  <!-- </div> -->
  <app-address-details *ngIf="currDiv =='addressView'" [serviceTypeInfo]="serviceTypeInfo"
    (serviceTypeDetailsOutputEvent)="responseFromChild($event)">
  </app-address-details>
  <app-additional-info *ngIf="currDiv =='additionalinfoView'"
    (serviceTypeDetailsOutputEvent)="responseFromChild($event)">
  </app-additional-info>
  <app-agent-script *ngIf="currDiv =='agentscriptView'" [serviceTypeInfo]="serviceTypeInfo"
    (serviceTypeDetailsOutputEvent)="responseFromChild($event)" (bredCrumbChanges)="bredCrumbChanges($event)">
  </app-agent-script>
  <app-review-submit *ngIf="currDiv =='reviewsubmit'" [serviceTypeInfo]="serviceTypeInfo"
   (serviceTypeDetailsOutputEvent)="responseFromChild($event)">
  </app-review-submit>


</div>
