<!-- ======= Footer ======= -->
<section id="footer" class="footer px-2" *ngIf="!isSignupRoute()">

  <div class="container footer-top">
    <div class="row ">

      <div class="col-md-3 ">
        <div class="card-header border-bottom">Contact Us</div>
        <div class="card-body">
          <p class="mb-1">Citizens Square</p>
          <p class="mb-1">200 East Berry Street</p>
         <p class="mb-1">Suite 470</p>
         <p class="mb-1">Fort Wayne, IN 46802</p>

          <p class="mt-3">Phone:<a href="tel:2604278311" target="_self"> (260) 427-8311 </a></p>
          <!-- Email: <a href="mailto:311@fortwayne.gov" class="text-primary">311@fortwayne.gov</a> -->
        </div>
      </div>

      <div class="col-md-3 ">

        <div class="card-header border-bottom">Useful Links</div>
        <div class="card-body u-link-r">
          <a href="https://www.cityoffortwayne.org/privacy-and-security.html" > Privacy and Security</a>
          <a href="https://www.cityoffortwayne.org/contact-webmaster.html" > Contact the Webmaster </a>
          <a href="https://www.cityoffortwayne.org/ada-title-vi-compliance.html" > ADA Compliance </a>
          <a href="https://www.cityoffortwayne.org/index.php?format=feed&type=rss&Itemid=0" > RSS Feed </a>
          <a href="https://www.cityoffortwayne.org/site-map.html" > Site Map </a>

        </div>

      </div>


      <div class="col-md-3 ">

        <div class="card-header border-bottom">Connect Us On</div>
        <p class="fs-3 social-icon-fwn">
          <a class="btn btn-trans" href="https://twitter.com/fortwayne_gov" target="_blank" >
           <img src="../../assets/images/twitter.svg" title="Twitter">
          </a>
          <a href="https://www.facebook.com/Cityoffortwayne/" target="_blank" class="btn btn-trans" title="facebook">
            <img src="../../assets/images/facebook.svg" title="facebook">

          </a>
          <a href="https://www.youtube.com/user/FortWayneIND" target="_blank" class="btn btn-trans" title="youtube">
            <img src="../../assets/images/youtube.svg" title="instagram">

          </a>
          <a href="https://www.cityoffortwayne.org/city-blog.html" target="_blank" class="btn btn-trans" title="blogger">
            <img src="../../assets/images/blogger.svg" title="blogger">

          </a>

        </p>

      </div>


      <div class="col-md-3  pe-0">

        <div class="card-header border-bottom">Download the App</div>
        <div class="card-body">
          <p class="fs-3">
            <a href="/" class="btn btn-trans" title="App Apple" style="pointer-events: none;">
              <i class="bi bi-apple text-secondary"></i> </a>
            <a href="/" class="btn btn-trans" style="pointer-events: none;"
              title="App Android"><i class="bi bi-android2 " style="color: #99cc00;"></i></a>
          </p>


        </div>

      </div>


    </div>
  </div>
  <div class="container copyright text-center mt-4 p-2">
    <p class="mb-0 fs-small">© 2024 Built by <a href="https://nebulogic.com" target="_blank" >NebuLogic
        Technologies</a>, Powered by SMART<sup>®</sup> ICE</p>

  </div>

</section><!-- End Footer -->
