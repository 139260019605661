<div class="container p-2">
  <div class=" col-sm-12 mb-4 border-radius-20 bg-white overflow-hidden pt-3 position-relative p-2"
    *ngIf="!showSeparatedData">
    <div class="row top10 m-0">
      <div class="col-sm-12 p-0">
        <ul class="fw-bc d-flex align-items-center gap-1">
          <li><a [routerLink]="['/']" class="tex-primary fw-bold">Home</a></li>&nbsp;
          <li> <i class="fa-solid fa-chevron-right"></i></li>&NonBreakingSpace;
          <li>SMART® Knowledge Base</li>&NonBreakingSpace;

        </ul>
      </div>
    </div>
    <div class="advance-search">
      <!-- <h1 class="kheading">Smart FAQ</h1> -->
      <!-- <div class="toggle-container">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" id="toggleAdvanceSearch" (change)="toggleAdvanceSearch($event.target.checked)">
          <label class="form-check-label" for="toggleAdvanceSearch">AI Advance Search</label>
        </div>
      </div> -->
    </div>
    <!-- <app-knowledgebaseai *ngIf="isAdvancedSearchActive"></app-knowledgebaseai> -->
    <!-- <hr> -->

    <div>
      <div id="rn_SearchControls" class="w-100 py-2">
        <h1 class="rn_ScreenReaderOnly">Search</h1>
        <form style="position: relative;z-index: 9;">
          <div class="d-search-tb d-flex">

            <div class=" boxT position-relative" *ngIf="!isAdvancedSearchActive">

              <div class="search-box">
                <label class="seaech-ico"><i class="bi bi-search"></i></label>
                <input placeholder="Find the answer to your question" type="text" maxlength="255"
                  [(ngModel)]="searchQuery1" [ngModelOptions]="{standalone: true}" class="form-control"
                  autocomplete="new-password">
                <button type="submit" (click)="onSearch1(searchQuery1)" class="btn btn-primary d-none">Search</button>
              </div>


            </div>
            <div class="filter-box" [class.openaikb]="isAdvancedSearchActive">
              <span class="btn btn-light filter-btn toggle-ai" [class.active]="isAdvancedSearchActive">
                <input type="checkbox" id="toggleAdvanceSearch" [(ngModel)]="checked"
                  [ngModelOptions]="{standalone: true}" (change)="toggleAdvanceSearch($event.target.checked)">
                <label for="toggleAdvanceSearch">AI Search <span class="aibeta">BETA</span></label>
              </span>
              <span class="btn btn-light filter-btn active" [class.active]="faqtitle ==='All' && !checked"
                (click)="loadFAQs()">All</span>
              <span class="btn btn-light filter-btn" (click)="getpopularfaq()"
                [class.active]="faqtitle ==='Popular' && !checked">Popular</span>
              <span class="btn btn-light filter-btn " [class.active]="faqtitle ==='smartFAQ' && !checked"
                (click)="getsmartFAQ()">FAQ</span>
              <span class="btn btn-light filter-btn" (click)="getrecentfaq()"
                [class.active]="faqtitle ==='Recent' && !checked" *ngIf="logInfo!=null">Recently Viewed</span>


            </div>
          </div>

        </form>
        <app-knowledgebaseai *ngIf=" isAdvancedSearchActive"></app-knowledgebaseai>

        <div *ngIf="displayfilters && (selectedDepartment || selectedCategory)">
          <strong>Search filters applied</strong>
          <div *ngIf="selectedDepartment">
            Product <a (click)="removeproductfilter();">Remove Product filter</a>
            <p><span style="border: 1px solid black;">{{selectedDepartment}}</span></p>
          </div>
          <div *ngIf="selectedCategory">
            Category <a (click)="removecategoryfilter();">Remove Category filter</a>
            <p><span style="border: 1px solid black;">{{selectedCategory}}</span></p>
          </div>
        </div>
      </div>
    </div>
    <div>
      <div *ngIf="advancesearchpopup" id="rnDialog1_c" aria-labelledby="rn_Dialog_1_Title"
        class="yui3-widget yui3-panel yui3-widget-positioned yui3-widget-modal yui3-widget-stacked rn_AdvancedSearchDialog yui3-panel-focused"
        role="dialog" tabindex="0" style="right: 18px; top: 36px; z-index: 9999;">
        <div id="rnDialog1" class="rn_Dialog yui3-panel-content yui3-widget-stdmod rn_ActionDialog">
          <div class="yui3-widget-hd" id="yui_3_18_1_3_1698644510030_147">
            <span class="rn_ScreenReaderOnly">Beginning of dialog, please dismiss dialog before continuing</span>
            <div class="d-flex">
              <h3 class="m-0">Advanced Search </h3>
              <a class="btn btn-link text-underline  ms-4"
                onclick="window.open('/app/utils/help_search', '', 'scrollbars,resizable,width=720,height=700'); return false;">Search
                Tips</a>
              <button type="button" (click)="closepopup()" class="btn btn-link ms-auto"> <i class="bi bi-x-lg"></i>
              </button>
            </div>
          </div>
          <div class="popup">
            <div class="d-block text-right ">


              <div class="row mb-2 align-items-center">
                <div class="col-md-5 p-0 text-left">
                  <label for="rn_KeywordText_2_Text">Search terms:</label>
                </div>
                <div class="col-md-7">
                  <input placeholder="Search" type="text" value="" [(ngModel)]="searchterms" class="form-control">
                </div>
              </div>

              <!-- <div class="row mb-2 align-items-center">
                  <div class="col-md-5 p-0 text-left">
                    <label for="departmentDropdown">Select a department:</label>
                  </div>
                  <div class="col-md-7">
                    <select id="departmentDropdown" [(ngModel)]="selectedDepartment" (change)="updateCategories()" class="form-select">
                      <option [value]="null" selected disabled>Select a department</option>
                      <option *ngFor="let dept of dataArray">{{ dept.department }}</option>
                    </select>
                </div>
                </div> -->
              <div class="row mb-2 align-items-center">
                <div class="col-md-5 p-0 text-left">
                  <label for="departmentDropdown">Select a product:</label>
                </div>
                <div class="col-md-7">
                  <select id="departmentDropdown" [(ngModel)]="selectedDepartment" (change)="updateCategories()"
                    class="form-select">
                    <option [value]="null" selected disabled>Select a department</option>
                    <option *ngFor="let dept of dataArray">{{ dept.department }}</option>
                  </select>
                </div>
              </div>
              <div class="row mb-2 align-items-center">
                <div class="col-md-5 p-0 text-left">
                  <label for="departmentDropdown">Select a category:</label>
                </div>
                <div class="col-md-7">
                  <select id="categoryDropdown" [(ngModel)]="selectedCategory" class="form-select">
                    <option value="">Select a category</option>
                    <ng-container *ngFor="let dp of dataArray">
                      <ng-container *ngIf="dp.department === selectedDepartment">
                        <option *ngFor="let category of dp.categories" [value]="category.name">
                          {{ category.name }}
                        </option>


                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="row mb-2 align-items-center">
                <div class="col-md-5 p-0 text-left">
                  <label for="departmentDropdown">Sort by:</label>
                </div>
                <div class="col-md-7">
                  <select id="categoryDropdown" [(ngModel)]="selectedCategory" class="form-select">
                    <option value="">Select a category</option>
                    <ng-container *ngFor="let dp of dataArray">
                      <ng-container *ngIf="dp.department === selectedDepartment">
                        <option *ngFor="let category of dp.categories" [value]="category.name">
                          {{ category.name }}
                        </option>


                      </ng-container>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="row mb-2 align-items-center">
                <div class="col-md-5 p-0 text-left">
                  <label for="departmentDropdown">Order by:</label>
                </div>
                <div class="col-md-7">
                  <div class="radio-group  align-items-center">
                    <div class="radio-option d-flex">
                      <input type="radio" class="ng-untouched ng-pristine ng-invalid" id="ascending" name="orderRadios">
                      <label for="ascending"> Ascending</label>
                    </div>
                    <div class="radio-option d-flex align-items-center mt-2">
                      <input type="radio" class="ng-untouched ng-pristine ng-invalid" id="descending"
                        name="orderRadios">
                      <label for="descending"> Descending</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="rn_AdvancedFilter rn_AdvancedSubWidget">
              </div>
              <div class="rn_AdvancedSort rn_AdvancedSubWidget">
              </div>
            </div>
            <div class="w-100 d-block text-center mt-3">

              <button class="btn btn-primary " (click)="onSearch();closepopup()">Search</button>
              <button class="btn btn-outline-primary mx-2" (click)="closepopup()">Reset</button>


              <span class="rn_ScreenReaderOnly">End of dialog, please dismiss dialog before continuing</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ms-2 toggle-filter print-hide">

    </div>





    <div class="col-sm-12" *ngIf="!isAdvancedSearchActive">
      <div id="rn_PageContent" class="rn_AnswerList boxed">

        <div class="row m-0">
          <div class="col-sm-12 p-0">

            <div *ngIf="jsonData && jsonData.length > 0">
              <div id="rn_ResultInfo_10_Results" class="rn_Results py-3 fw-bold">
                Results <span>{{ calculateRange().start }}</span> - <span>{{ calculateRange().end }}</span> of <span>{{
                  totalResults }}</span>
              </div>

              <!-- </div>  -->
              <div id="rn_TopicWords_11" class="rn_TopicWords">
                <div class="rn_Title h5 mb-2">Recommended Links</div>
              </div>
            </div>
            <div id="rn_Multiline_12" class="rn_Multiline">
              <div id="rn_Multiline_12_Alert" role="alert" class="rn_ScreenReaderOnly"></div>
              <div id="rn_Multiline_12_Loading"></div>
              <div id="rn_Multiline_12_Content" class="rn_Content">

                <ul>
                  <li>

                    <!-- <div *ngIf="searchPerformed">
                      <div *ngIf="allfaq.length > 0; else noResultsFound">
                          <div class="rn_Element">


                            <div *ngIf="allfaq.length > 0">

                              <table class="table"  id="knowledgebasetablesearch" *ngIf="searchdatatableload">
                                <thead>
                                    <tr>
                                        <th >#</th>
                                         <th style="width: 160px;">Answer#</th>
                                        <th >Description</th>
                                        <th >Category</th>
                                        <th>Title</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of allfaq;let i=index" class="table-row">
                                        <td >{{i+1}}</td>
                                        <td >{{data.answerid}}</td>
                                        <td>
                                          <span class="rn_Element3">
                                            <div [innerHTML]="stripHtmlAndEntities(data.description)"></div>
                                        </span>
                                        </td>
                                      <td>{{data.categoryname}}</td>
                                        <td >
                                         <span class="rn_Element3">
                                            <div [innerHTML]="stripHtmlAndEntities1(data.solution,searchQuery1)"></div>
                                        </span>
                                      </td>
                                      <td style="vertical-align: middle;"><button type="button" class="btn btn-light-track" [routerLink]="['/answers/details', data.prodcatid]">
                                        <img src="../../assets/images/expand-icon.png">  </button>
                                      </td>

                                        </tr>
                                </tbody>
                            </table>
                            </div>
                          </div>

                      </div>

                      <ng-template #noResultsFound >
                          <div class="rn_NoResultsMessage" >
                              No results found.
                              Suggestions:
                              Make sure all words are spelled correctly
                              Use different words that mean the same thing
                          </div>

                      </ng-template>
                  </div> -->




                    <div *ngIf="!loadsmartFAQ">

                      <!-- <div class="rn_Element" *ngFor="let data of allfaq">
                          <h4><a [routerLink]="['/answers/details', data.prodcatid]">{{ data.summary }}</a></h4>
                          <span class="rn_Element3">
                              <div [innerHTML]="stripHtmlAndEntities(data.solution)"></div>
                          </span>
                      </div> -->
                      <table class="table" id="knowledgebasetable" *ngIf="datatableload">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Answer#</th>
                            <th>Summary</th>
                            <th>Title</th>
                            <th>Category</th>
                            <th>Description</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let data of allfaq;let i=index" class="table-row">
                            <!-- style="    overflow-y: auto;display: block; height: calc(100vh - 400px);" -->
                            <td>{{i+1}}</td>
                            <td>{{data.answerid}}</td>
                            <td>{{data.summary}}</td>
                            <td>
                              <span class="rn_Element3">
                                <div [innerHTML]="stripHtmlAndEntities(data.description)"
                                  [ngbTooltip]="stripHtmlAndEntities(data.description)"></div>
                              </span>
                            </td>
                            <td>{{data.categoryname}}</td>
                            <!-- <td [innerHTML]="stripHtmlAndEntities(item.answeridobject.description+item.answeridobject.solution)" (click)="addTab(item.answerid , item.id,item.answeridobject.description);getDetailsByArticleID(item.id)"></td> -->
                            <td>
                              <!-- <h4><a [routerLink]="['/answers/details', data.prodcatid]">{{ data.summary }}</a></h4> -->

                              <!-- <p  class="question" [innerHTML]="stripHtmlAndEntities1(item.answeridobject.description, faqForm.get('name').value)"> <a [routerLink]="['/answers/details', item.prodcatid]"></a></p> -->
                              <span class="rn_Element3">
                                <div [innerHTML]="stripHtmlAndEntities(data.solution)"></div>
                              </span>
                            </td>
                            <td style="vertical-align: middle;">
                              <button type="button" class="btn btn-light-track"
                                (click)="navigateToDetails(data.prodcatid)">
                                <img src="../../assets/images/expand-icon.png"
                                  (click)="navigateToDetails(data.prodcatid)"> </button>
                            </td>

                            <!-- <td width="150px" class="text-center">[ID:{{item.ansid}} |
                                    Category:{{item.categoryidpicklist.valueen}}]</td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div *ngIf="loadsmartFAQ">

                      <div class="accordion" id="accordionExample">
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingOne">
                            <button class="accordion-button" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                              Why was 311 created?
                            </button>
                          </h2>
                          <div id="collapseOne" class="accordion-collapse collapse show" aria-labelledby="headingOne"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>The 311 service is part of an ongoing effort by the City of Fort Wayne to make City
                                government easier to access and more responsive to the needs of residents, its business
                                owners, and visitors. The 311 Call Center has now expanded into the Department of
                                Citizen Services. Through Citizen Services, city government continues to engage with the
                                public to make improvements throughout the city, measures internal departmental service
                                performance, and through the use of innovation, collects community voice - empowering
                                citizens to drive change.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                              Who can call 311?
                            </button>
                          </h2>
                          <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>311 can be dialed by anyone within the Fort Wayne city limits at no charge. If using
                                your cell phone or pay phone airtime charges do apply.</p>

                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingThree">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                              When can call 311?
                            </button>
                          </h2>
                          <div id="collapseThree" class="accordion-collapse collapse" aria-labelledby="headingThree"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>Citizen Service Specialists will staff the phones from 8:00a.m. until 5:00p.m. Monday
                                through Friday. During after hours, including weekends, you may leave a message via
                                voicemail or opt out to be connected directly to a dispatcher.</p>
                              <p class="m-0"> <small>Date Updated: 01/06/2023</small></p>
                            </div>
                          </div>
                        </div>

                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFour">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                              What happens after I call 311?
                            </button>
                          </h2>
                          <div id="collapseFour" class="accordion-collapse collapse " aria-labelledby="headingFour"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>You will be assisted by a qualified member of our team to help resolve your issue. Upon
                                completion of your call, you will be issued a service request number for tracking
                                purposes. City departments will respond to all 311 service requests in the order in
                                which they are received. Your request will be logged into an advanced tracking system
                                that will show city leadership how responsive departments are in their service delivery.
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingFive">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                              What should I do if dialing 311 does not work from my phone or I am outside the city
                              limits?
                            </button>
                          </h2>
                          <div id="collapseFive" class="accordion-collapse collapse" aria-labelledby="headingFive"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>There are a few wireless carriers that do not provide their customers with the ability
                                to dial 311. In this instance, or if you are outside of the city, you may reach a 311
                                Citizen Service Specialist by calling (260)-427-8311.

                              </p>

                            </div>
                          </div>
                        </div>
                        <div class="accordion-item">
                          <h2 class="accordion-header" id="headingSix">
                            <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse"
                              data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                              311 or 911?
                            </button>
                          </h2>
                          <div id="collapseSix" class="accordion-collapse collapse" aria-labelledby="headingSix"
                            data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                              <p>The 911 service is set up to handle emergencies, such as house fires and violent crimes
                                that require an immediate response by police, fire crews, or an ambulance. Please
                                contact 311 for all other city nonemergency services.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>




                  </li>
                </ul>
              </div>
            </div>

            <!-- <div class="rn_PaginationLinks">
              <pagination-controls (pageChange)="p = $event" class="float-right"></pagination-controls>
            </div>
            Records per page 1 - {{answerlistlength}} of {{totalpagesize}} -->


            <div class="dropdown map-drop kb-footer" *ngIf="!loadsmartFAQ">
              <span style=" margin-top: 10px;">Records per page</span>

              <ng-container *ngIf="totalpagesize != 0; else nopagination">
                <button class="btn btn-secondary dropdown-toggle custom-select" type="button" id="dropdownMenu2"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  {{pageSize}}

                </button>
                <span style=" margin-top: 10px;">{{getRecordsRange()}}</span>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                  <li *ngFor="let pagesize of size">
                    <button class="dropdown-item" type="button" (click)="onpagesize(pagesize)">{{ pagesize }}</button>
                  </li>
                </ul>
              </ng-container>
              <ng-template #nopagination>
                <button class="btn btn-secondary dropdown-toggle custom-select" type="button" id="dropdownMenu2"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  0

                </button>
                <span style=" margin-top: 10px;">{{getRecordsRange()}}</span>
                <!-- <ul class="dropdown-menu" aria-labelledby="dropdownMenu2">
                <li *ngFor="let pagesize of size">
                  <button class="dropdown-item" type="button" (click)="onpagesize(pagesize)">{{ pagesize }}</button>
                </li>
              </ul> -->
              </ng-template>

              <div class="paginate_button ms-auto">
                <ng-container *ngIf="totalpagesize != 0; else noPages">
                  <button type="button" (click)="previousPage2()" [disabled]="currentPage === 0"
                    [ngClass]="{'disabled-button': currentPage === 0}" class="Previous">
                  </button>
                  <button type="button" *ngFor="let pageNumber of getPageNumbers()" (click)="goToPage(pageNumber)"
                    [class.highlighted]="pageNumber === currentPage">
                    {{ pageNumber === 0 ? 1 : pageNumber + 1 }}
                  </button>
                  <button type="button" (click)="nextPage2()" [disabled]="currentPage === endpage"
                    [ngClass]="{'disabled-button': currentPage === endpage}" class="next"></button>

                </ng-container>
                <ng-template #noPages>
                  <button type="button" disabled class="Previous"></button>

                  <!-- <button type="button" [class.highlighted]="pageNumber === currentPage"> -->

                  <button type="button" disabled class="next"></button>

                  <!-- </button> -->
                </ng-template>

              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div>
  <!-- Display your data here -->
  <!-- <ul>
    <li *ngFor="let faq of allfaq">{{ faq }}</li>

  </ul> -->
  <!-- <div class="rn_Element" *ngFor="let data of allfaq">

                          <h4><a [routerLink]="['/details', data.id]">{{ data.summary }}</a></h4>
                          <span class="rn_Element3">
                              <div [innerHTML]="stripHtmlAndEntities(data.solution)"></div>
                          </span>
                          <span class="rn_ElementsHeader rn_ElementsData">Date Updated: {{ data.createdtime }}</span>
                      </div> -->
  <!-- Pagination controls -->


</div>